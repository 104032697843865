import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Context, { useAlgoType } from 'context/Context'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useSimDataUpdater from 'utils/useSimDataUpdater'
import axiosApi from 'utils/axiosApi'

import { TotalRow } from 'components/Common/TotalRow'
import { AllTransactionsTables } from 'components/AllTransactions/AllTransactionsTables'
import { Loader } from 'components/Common/Loader'
import { HiddenSm } from 'components/Common/hiddensm'
import { SecTypes } from 'utils/const'

export const AllTransactions = () => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)
  const [algoType] = useAlgoType()

  const [fetching, setFetching] = useState(false)
  const [totalRow, setTotalRow] = useState(null)

  const calcDescForUrl = useCalcDescUrl()

  const isBonds = secType && secType === SecTypes.allBond

  useEffect(() => {
    const loadRows = () => {
      setFetching(true)

      axiosApi
        .get(`all_changes/header/?${calcDescForUrl}`)
        .then(response => {
          if (response.data) {
            const { followingFundsValue, totalSecurities, projectedChangeValue, projectedPayment } = response.data
            setTotalRow([
              {
                title: t('equity.totalRow.followingFundsValue'),
                value: followingFundsValue,
                format: 'currency',
              },
              {
                title: t('equity.totalRow.totalSecurities'),
                value: totalSecurities,
                format: 'simple',
              },
              {
                title: t('equity.totalRow.projectedChangeValue'),
                value: projectedChangeValue,
                color: '#B8741A',
                format: 'currency',
                subData: [
                  isBonds && {
                    title: t('equity.totalRow.projectedPayment'),
                    value: projectedPayment,
                    format: 'currency',
                  },
                ],
              },
            ])
          }

          setFetching(false)
        })
        .catch(() => {
          setTotalRow(null)
          setFetching(false)
        })
    }

    if (algoType) {
      loadRows()
    }
  }, [t, algoType, secType, calcDescForUrl, isBonds])

  return useSimDataUpdater(
    <>
      <HiddenSm>{fetching ? <Loader /> : <TotalRow className="xs-hidden" data={totalRow} />}</HiddenSm>

      {algoType && <AllTransactionsTables />}
    </>,
  )
}
