import styled from 'styled-components'
import Loading from 'assets/loading.svg'

export const LoaderIcon = styled.img.attrs({
  src: Loading,
  alt: 'loading...',
})`
  width: ${props => props.loaderIconWidth};
`
export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.containerHeight};
  width: 100%;
`
