import React from 'react'
import PropTypes from 'prop-types'

import { SelectSearch } from 'components/Common/SelectSearch'
import { LinkToTase } from 'components/Common/LinkToTase'
import { IndexSelectorContainer } from './styles'
import { useTranslation } from 'react-i18next'
import { isEng } from '../../../i18n'
import find from 'lodash/find'

export const IndexSelector = ({ data, value, onChange }) => {
  const { t } = useTranslation()

  if (!data) return null

  const selectedIndex = find(data, { value: value })

  const isExternalIndex = selectedIndex && selectedIndex.value >= 1000

  return (
    <IndexSelectorContainer>
      <SelectSearch options={data} value={value} onChange={onChange} />
      {selectedIndex && !isExternalIndex && (
        <LinkToTase
          href={`https://www.tase.co.il/${isEng() ? 'en' : 'he'}/market_data/index/${value}/about`}
          popoverText={`${t('equity.table.indexInfo')} - ${selectedIndex.label}`}
        />
      )}
    </IndexSelectorContainer>
  )
}

IndexSelector.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

IndexSelector.defaultProps = {
  value: '',
  data: null,
}
