import styled from 'styled-components'

export const TableFilterRowWrapper = styled.div`
  overflow-x: auto;
`

export const FiltersContentContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
`

export const TableLegendWrapper = styled.div`
display: flex;
flex-grow: 1;
justify-content: end;
`