import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { getPaymentsDescription_CapitalListedEvent } from 'utils/paymentUtils'

export default function({ data }) {
  if (!data) return null

  const { t } = useTranslation()

  let { currTaseEffectiveDate } = data

  let popoverText, capitalListedEvents

  capitalListedEvents = getPaymentsDescription_CapitalListedEvent({ t, timeBasedData: currTaseEffectiveDate })
  if (!capitalListedEvents) return null

  popoverText = t('equity.changesSummary.specialUpdateOfCapitalListed')
  capitalListedEvents.forEach(event => {
    popoverText += `\n${t('equity.changesSummary.exDate')} : ${dayjs(event.exDate).format('DD/MM/YYYY')} (${event.eventType})`
  })

  return <TableCellExtraContent popoverText={popoverText} content={<FontAwesomeIcon icon={faChartPie} />} key="specialEvents" />
}
