import styled from 'styled-components'

export const DataMgmtWrapper = styled.div`
  padding-top: 20px;
`

export const StatsCellWrapper = styled.span`
  font-size: 0.8rem;
`

export const ParametersStatsWrapper = styled(StatsCellWrapper)`
  position: absolute;
  top: 3px;
`

export const AlgoActionsWrapper = styled.div`
  display: inline-flex;
  overflow-x: auto;
`

export const AlgoActionsInfoWrapper = styled.span`
  ${props => (props.dir === 'ltr' ? 'margin-left: 5px;' : 'margin-right: 5px;')}
`
