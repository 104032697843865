import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import get from 'lodash/get'

import { isEng } from '../../../i18n'

import { ContactSupportContainer } from 'components/General/ContactSupport/styles'
import Icon from 'components/General/ContactSupport/Icon'
import { Dialog } from 'components/General/ContactSupport/Dialog'
import { SuccessAlert } from 'components/General/ContactSupport/SuccessAlert'
import { BlurScreen } from 'components/Common/BlurScreen'

import axiosApi from 'utils/axiosApi'

const CONTACT_SUPPORT_ELEMENT_ID = 'contact-support-container'

const ERRORS_TRANSLATIONS_KEYS = {
  EMPTY_SERVER_RES: 'EMPTY_RES',
  SERVER_ERROR: 'SERVER_ERROR',
  MIN_TEXT_SIZE: 'MIN_TEXT_SIZE',
}

const ContactSupport = () => {
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleWindowResize = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const modalStyles = useMemo(() => {
    const styles = {}
    if (windowWidth > 768 && windowWidth < 992) {
      styles.right = isEng() ? 'unset' : 0
    } else if (windowWidth >= 992) {
      const position = isEng() ? { left: 'unset', right: '30px' } : { right: 'unset', left: '30px' }
      Object.assign(styles, position)
    }

    if (showSuccessAlert && !isModalOpen) {
      styles.textAlign = isEng() ? 'left' : 'right'
    }

    return styles
  }, [windowWidth, showSuccessAlert, isModalOpen])

  const getTranslatedError = key => {
    return t(`contactSupport.error.${key}`)
  }

  const handleMsgChange = e => {
    setMessage(e.target.value)
    setError('')
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const sendRequest = async () => {
    if (!message || message.length < 5) {
      setError(getTranslatedError(ERRORS_TRANSLATIONS_KEYS.MIN_TEXT_SIZE))
      return
    }

    setIsLoading(true)
    try {
      const res = await axiosApi.post('users/contact-support', { message })
      if (!res) {
        setIsLoading(false)
        setError(getTranslatedError(ERRORS_TRANSLATIONS_KEYS.EMPTY_SERVER_RES))
        return
      }
      const { success } = res.data
      if (success) {
        setMessage('')
        setError('')
        setIsLoading(false)
        setIsModalOpen(false)
        setShowSuccessAlert(true)
        setTimeout(() => {
          reset()
        }, 3000)
      } else {
        console.error('Should not happen. Probably wrong res.status from server...')
        setIsLoading(false)
        setError(getTranslatedError(ERRORS_TRANSLATIONS_KEYS.SERVER_ERROR))
      }
    } catch (error) {
      const errorKey = get(error, 'response.data.error') || ERRORS_TRANSLATIONS_KEYS.SERVER_ERROR
      setIsLoading(false)
      setError(getTranslatedError(errorKey))
    }
  }

  const reset = () => {
    setMessage('')
    setError('')
    setIsLoading(false)
    setIsModalOpen(false)
    setShowSuccessAlert(false)
  }

  return (
    <ContactSupportContainer id={CONTACT_SUPPORT_ELEMENT_ID}>
      <Icon onClick={toggleModal} />
      <BlurScreen
        show={isModalOpen || showSuccessAlert}
        onClick={toggleModal}
        withPortal
        portalElementId={CONTACT_SUPPORT_ELEMENT_ID}
        cursor={'pointer'}
      >
        {showSuccessAlert ? (
          <SuccessAlert style={modalStyles} />
        ) : isModalOpen ? (
          <Dialog
            style={modalStyles}
            message={message}
            handleTextChange={handleMsgChange}
            isLoading={isLoading}
            error={error}
            onSubmit={sendRequest}
          />
        ) : null}
      </BlurScreen>
    </ContactSupportContainer>
  )
}

export { ContactSupport }
