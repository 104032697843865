import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import find from 'lodash/find'
import some from 'lodash/some'
import difference from 'lodash/difference'
import isNumber from 'lodash/isNumber'
import numbro from 'numbro'

import Context, { useAlgoType, useSelectedSecurity, useTaseEffectiveDate } from 'context/Context'

import axiosApi from 'utils/axiosApi'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useSimDataUpdater from 'utils/useSimDataUpdater'
import { useSelectedTab } from 'context/Context'
import dayjs from 'dayjs'
import compact from 'lodash/compact'
import buildPriceChangeText from 'utils/buildPriceChangeText'
import { SecTypes, AlgoTypes } from 'utils/const'

import { SecuritySelectorBlock } from 'components/Securities/SecuritySelectorBlock'
import { SecurityAnalysisTotalBlock } from 'components/Securities/SecurityAnalysis/SecurityAnalysisTotalBlock'
import { SecurityAnalysisTable } from 'components/Securities/SecurityAnalysis/SecurityAnalysisTable'
import { GoalSeekTable } from 'components/Securities/SecurityAnalysis/GoalSeekTable'

import { Loader } from 'components/Common/Loader'
import { HiddenSm } from 'components/Common/hiddensm'
import { Badge } from 'components/Common/Badge'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { TabNav, TabNavLink } from './styles'

import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import { isEng } from '../../../i18n'

const doesSecurityExist = (securityId, securityList) => securityId && find(securityList, { value: securityId })

const getConditionalColor = (var1, var2) => {
  const dif = var1 - var2
  if (dif > 0) return 'green'
  else if (dif < 0) return 'red'
  else return ''
}

export const SecurityAnalysis = () => {
  const { secType, recordDate, effectiveDate, simDataUpdateFlag, setSecurityName } = useContext(Context)
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const [selectedSecurity, setSelectedSecurity] = useSelectedSecurity()
  const { t } = useTranslation()

  const [selectedSecurityData, setSelectedSecurityData] = useState({})
  const [fetchingHeader, setFetchingHeader] = useState(false)
  const [fetchingSecuritiesList, setFetchingSecuritiesList] = useState(false)
  const [securityList, setSecurityList] = useState([])
  const [totalRow1, setTotalRow1] = useState(null)
  const [totalRow2, setTotalRow2] = useState(null)
  const [metadataRow, setMetadataRow] = useState(null)
  const [currentFunds, setСurrentFunds] = useState(null)
  const [projectedFunds, setProjectedFunds] = useState(null)
  const calcDescForUrl = useCalcDescUrl()
  const [selectedTab, setSelectedTab] = useSelectedTab()

  const isComponents = algoType === AlgoTypes.components
  const isParameters = algoType === AlgoTypes.parameters
  const isEquity = secType === SecTypes.stock
  const isBond = secType === SecTypes.allBond
  const isBondsRebalance = isBond && isParameters
  const eng = isEng()
  const isNewMethodology = dayjs(taseEffectiveDate).isAfter('2023-11-01')

  // mount
  useEffect(() => {
    setSelectedTab(selectedTab => selectedTab || 'projectedIndexesTab')
  }, [setSelectedTab])

  // unmount
  useEffect(() => {
    return () => {
      setSelectedSecurity(undefined, 'replaceIn')
      setSelectedTab(undefined, 'replaceIn')
      setSecurityName(null)
    }
  }, [setSelectedSecurity, setSelectedTab, setSecurityName])

  // load securities list
  useEffect(() => {
    const loadSecurities = () => {
      setFetchingSecuritiesList(true)

      axiosApi
        .get(`security_overview/filter?${calcDescForUrl}&lang=${eng ? 'en' : 'he'}`)
        .then(response => {
          if (some(response.data)) {
            const securitiesList = response.data.map(item => {
              const { nameEnglish, nameHebrew, securityNo } = item
              return {
                label: `${eng ? nameEnglish : nameHebrew} (${securityNo})`,
                value: item.securityNo,
              }
            })
            setSecurityList(securitiesList)
          }

          setFetchingSecuritiesList(false)
        })
        .catch(() => setFetchingSecuritiesList(false))
    }

    loadSecurities()
  }, [eng, algoType, secType, setSecurityList, calcDescForUrl])

  const securityExist = doesSecurityExist(selectedSecurity, securityList)

  useEffect(() => {
    if (some(securityList)) {
      // if nothing is selected or selected value irrelevant
      if (!selectedSecurity || !securityExist) {
        // console.log(`nothing, set to ${securityList[0].value}`)
        setSelectedSecurity(securityList[0].value, 'replaceIn')
        setSecurityName(null)
      }
    }
  }, [selectedSecurity, setSelectedSecurity, securityList, securityExist, setSecurityName])

  useEffect(() => {
    if (selectedSecurityData?.security) {
      const { companyNameHeb, companyNameEng } = selectedSecurityData.security
      setSecurityName({
        he: companyNameHeb,
        en: companyNameEng,
      })
    }
  }, [selectedSecurityData, setSecurityName])

  // load headers
  useEffect(() => {
    const removeDisplayDataFromState = () => {
      setTotalRow1(null)
      setTotalRow2(null)
      setMetadataRow(null)
      setFetchingHeader(false)
    }
    const buildOverrideData = (rd, field, format) => {
      const override = find(rd.overrides, override => override.field === field)
      return override
        ? {
            title: t('equity.totalRow.overriden'),
            value: override.oldValue,
            format,
          }
        : undefined
    }

    const loadRows = () => {
      setFetchingHeader(true)

      axiosApi
        .get(`security_overview/header/${selectedSecurity}?${calcDescForUrl}`)
        .then(response => {
          const rd = response.data
          console.log('security: ', rd)
          if (rd) {
            const isGovBond = !!rd.governmentBond

            setSelectedSecurityData(rd)
            setСurrentFunds(rd.sumCurrFundsMarketValue)
            const isEnteringIndexes = some(difference(rd.security.projectedInIndexes, rd.security.currInIndexes))

            setTotalRow1([
              {
                title: t('equity.totalRow.projectedChange'),
                value: rd.sumProjectedFundsChange,
                format: 'currency-long',
                background: getConditionalColor(rd.sumProjectedFundsChange, 0),
                color: 'white',
                subData: isBondsRebalance && [
                  {
                    title: t('equity.generalSecurity.projectedPayment'),
                    value: rd.sumProjectedPayment,
                    format: 'currency-long',
                    color: getConditionalColor(rd.sumProjectedFundsChange, 0) ? '#BFBFBF' : '#7F7F7F',
                  },
                ],
              },
            ])

            setTotalRow2(
              compact([
                !rd.security.isSimulated &&
                  isEquity &&
                  isNewMethodology && {
                    title: t('equity.totalRow.projectedLiquidationGroup'),
                    value: rd.security.projectedLiquidationGroup,
                    format: 'liquidationGroup',
                    badge: compact([
                      rd.security.turnoverStatsStartDate && rd.security.turnoverStatsEndDate && (
                        <Badge
                          key={'infoBadge'}
                          icon={faInfoCircle}
                          popover={t('common.table.colHeaders.liquidationGroupDetailed', {
                            startDate: dayjs(rd.security.turnoverStatsStartDate).format('DD/MM/YYYY'),
                            endDate: dayjs(rd.security.turnoverStatsEndDate).format('DD/MM/YYYY'),
                          })}
                        />
                      ),
                    ]),
                  },
                // hide turnover values as they are made up defaults for simulated secs:
                !rd.security.isSimulated && {
                  title: t('equity.totalRow.averageTurnover'),
                  value: rd.avgTurnover,
                  format: 'currency-long',
                },
                !rd.security.isSimulated && {
                  title: t('equity.totalRow.turnoverRatio'),
                  value: isNumber(rd.sumProjectedFundsMarketValue) ? Math.abs(rd.sumProjectedFundsChange / rd.avgTurnover).toFixed(1) : '-',
                  format: 'noFormat',
                  valueColor: isEquity && isEnteringIndexes && Math.abs(rd.sumProjectedFundsChange / rd.avgTurnover).toFixed(1) >= 30 && 'red',
                  badge: compact([
                    isEquity && isEnteringIndexes && Math.abs(rd.sumProjectedFundsChange / rd.avgTurnover).toFixed(1) >= 30 && (
                      <Badge key={'infoBadge'} icon={faInfoCircle} popover={t('equity.securityAnalysis.highChangeOverTurnoverText')} />
                    ),
                  ]),
                },
                !rd.security.isSimulated &&
                  isEquity && {
                    title: t('common.table.colHeaders.changeOverFreeFloat'),
                    value: Math.abs(rd.sumProjectedFundsChange / rd.security.projectedFreeFloatValue) * 100,
                    format: '0,0.0%',
                    valueColor: isEnteringIndexes && Math.abs(rd.sumProjectedFundsChange / rd.security.projectedFreeFloatValue) * 100 >= 5 && 'red',
                    badge: compact([
                      isEnteringIndexes && Math.abs(rd.sumProjectedFundsChange / rd.security.projectedFreeFloatValue) * 100 >= 5 && (
                        <Badge key={'infoBadge'} icon={faInfoCircle} popover={t('equity.securityAnalysis.highChangeOverFFVText')} />
                      ),
                    ]),
                  },
                {
                  title: t('equity.totalRow.projectedValueInFunds'),
                  value: rd.sumProjectedFundsMarketValue,
                  format: 'currency-long',
                  subData: [
                    {
                      title: t('equity.totalRow.currently'),
                      value: rd.sumCurrFundsMarketValue,
                      format: 'currency-long',
                    },
                  ],
                },
                dayjs()
                  .startOf('day')
                  .isAfter(recordDate) &&
                  !rd.security.isSimulated &&
                  !isBondsRebalance && {
                    title: t('equity.totalRow.priceChange'),
                    value: rd.security.priceChange,
                    format: '%*100.00',
                    color: getConditionalColor(rd.security.priceChange, 0),
                    badge: compact([
                      <Badge
                        key={'infoBadge'}
                        icon={faInfoCircle}
                        popover={buildPriceChangeText({
                          t,
                          recordDate,
                          effectiveDate,
                          closingPrice: rd.security.closingPrice,
                          effectiveDateClosingPrice: rd.security.effectiveDateClosingPrice,
                          exAdjuestedClosingPrice: rd.security.exAdjuestedClosingPrice,
                        })}
                      />,
                      rd.security.exDate && (
                        <TableCellExtraContent
                          popoverText={`${t('equity.allChanges.exDateOccuredOn')} ${dayjs(rd.security.exDate).format('DD/MM/YYYY')}`}
                          content={<span className="badge badge-secondary">{t('equity.allChanges.ex')}</span>}
                          key="exDateIndication"
                        />
                      ),
                    ]),
                  },
              ]),
            )

            const mdRow1 = compact([
              isEquity && {
                title: t('equity.totalRow.capitalListedForTrading'),
                value: isEquity ? rd.capitalListedForTrading : rd.security.currTaseEffectiveDateCapitalListedForTrading,
                format: 'number-long',
                subData: compact([
                  !rd.security.isSimulated && {
                    title: t('equity.totalRow.indexAdjustedShares'),
                    value: rd.currIndexAdjustedNoOfSharesIans,
                    format: 'number-long',
                  },
                ]),
              },
              isBond && {
                title: t('equity.totalRow.projectedFNV'),
                value: rd.security.currTaseEffectiveDateIans,
                format: 'number-long',
                color: getConditionalColor(rd.security.currTaseEffectiveDateIans, rd.dayBeforeUpdateIans),
                subData: [
                  {
                    title: t('equity.totalRow.capitalListedForTrading'),
                    value: isEquity ? rd.capitalListedForTrading : rd.security.currTaseEffectiveDateCapitalListedForTrading,
                    format: 'number-long',
                  },
                ],
              },

              isEquity && {
                title: t('equity.totalRow.freeFloatRate'),
                value: rd.freeFloatRate,
                format: '%',
                subData: compact([
                  {
                    title: t('equity.totalRow.ProjectedIndexAdjusted'),
                    value: rd.projectedIndexAdjustedFreeFloatRate,
                    format: '%',
                    color: getConditionalColor(rd.projectedIndexAdjustedFreeFloatRate, rd.currIndexAdjustedFreeFloatRate),
                  },
                  !rd.security.isSimulated && {
                    title: t('equity.totalRow.currently'),
                    value: rd.currIndexAdjustedFreeFloatRate,
                    format: '%',
                  },
                ]),
              },
              isEquity && {
                title: t('equity.totalRow.projectedLiquidationGroup'),
                value: rd.security.projectedLiquidationGroup,
                format: 'liquidationGroup',
                subData: compact([
                  {
                    title: t('equity.totalRow.semiAnnualTurnoverSpeedMedian'),
                    value: `${numbro(rd.security.semiAnnualTurnoverSpeedMedian).format('0.00')} (${rd.security.semiAnnualTurnoverSpeedMedianGroup})`,
                    format: 'string',
                  },
                  {
                    title: t('equity.totalRow.semiAnnualMedianTurnover'),
                    value: `${numbro(rd.security.semiAnnualMedianTurnover).format('0,0,0$')} (${rd.security.semiAnnualMedianTurnoverGroup})`,
                    format: 'string',
                  },
                  {
                    title: t('equity.totalRow.currently'),
                    value: rd.security.previousUpdateLiquidationGroup,
                    format: 'liquidationGroup',
                  },
                ]),
              },
              isEquity && {
                title: t('equity.totalRow.ProjectedIndexAdjustedFreeFloat'),
                value: rd.projectedIndexAdjustedFreeFloat,
                format: 'number-long',
                color: getConditionalColor(rd.projectedIndexAdjustedFreeFloat, rd.currIndexAdjustedFreeFloat),
                subData: compact([
                  !rd.security.isSimulated && {
                    title: t('equity.totalRow.currently'),
                    value: rd.currIndexAdjustedFreeFloat,
                    format: 'number-long',
                  },
                ]),
              },
              isEquity &&
                isComponents && {
                  title: t('equity.totalRow.freeFloatRateForSpecificFFRecordDate'),
                  value: rd.security.freeFloatRateForSpecificFFRecordDate,
                  format: '%',
                  subData: compact([rd.security.isSimulated && buildOverrideData(rd, 'freeFloatRateForSpecificFFRecordDate', '%')]),
                },
              isEquity && {
                title: t(`equity.totalRow.${isComponents ? 'AvgPublicMarketValueForComponents' : 'publicMarketValueForComponents'}`),
                value: rd.security.publicMarketValueForComponents,
                format: 'currency',
                subData: compact([
                  rd.security.isSimulated && buildOverrideData(rd, 'publicMarketValueForComponents', '%'),
                  {
                    title: t('equity.generalSecurity.effectiveDatePublicMarketValue'),
                    value: rd.security.effectiveDatePublicMarketValue,
                    format: 'currency',
                  },
                ]),
              },
              isEquity && {
                title: t(`equity.totalRow.${isComponents ? 'avgMarketValue' : 'marketValue'}`),
                value: rd.security.avgMarketValue,
                format: 'currency',
              },
              isBond && {
                title: t('equity.totalRow.marketValue'),
                value: rd.security.taseEffectiveDateMarketValue,
                format: 'currency',
              },
              isBond && {
                title: t('equity.totalRow.marketValueForComponents'),
                value: rd.security.marketValueForRanking,
                format: 'currency',
                subData: compact([
                  {
                    title: t('equity.totalRow.marketValueForWeights'),
                    value: rd.security.taseEffectiveDateMarketValue,
                    format: 'currency',
                  },
                ]),
              },
              {
                title: t('equity.totalRow.avgPrice'),
                value: rd.security.avgPrice,
                format: 'number',
                subData: compact([
                  {
                    title: t('equity.totalRow.closingPrice'),
                    value: rd.security.closingPrice,
                    format: 'number',
                  },
                ]),
              },
              !isGovBond && {
                title: t(`equity.totalRow.foreignIssuer`),
                value: (isEquity && rd.security.foreignStock) || rd.security.foreignSecurity ? t('yes') : t('no'),
                format: 'noFormat',
              },
              !isGovBond && {
                title: t(`equity.totalRow.sector`),
                value: `${rd.security[`companySector${isEng() ? 'English' : 'Hebrew'}`]} - ${
                  rd.security[`companySubsector${isEng() ? 'English' : 'Hebrew'}`]
                }`,
                format: 'noFormat',
              },
              isBond &&
                !isGovBond && {
                  valueGroup: [
                    {
                      title: t(`equity.totalRow.ratingMidroog`),
                      value: rd.security.ratingMidroog,
                      format: 'ratingMidroog',
                    },
                    {
                      title: t(`equity.totalRow.ratingMaalot`),
                      value: rd.security.ratingMaalot,
                      format: 'ratingMaalot',
                    },
                  ],
                },
              isBond && {
                title: t(`equity.totalRow.linkage`),
                value: t(`simulation.form.linkageTypes.${rd.security.linkage}`),
                format: 'noFormat',
              },
              isBond && {
                title: t(`equity.totalRow.interestType`),
                value: t(`simulation.form.interestTypes.${rd.security.interestType}`),
                format: 'noFormat',
              },
              isBond && {
                title: t(`equity.totalRow.firstTradingDate`),
                value: rd.security.firstTradingDate,
                format: 'date',
              },
              isBond && {
                title: t(`equity.totalRow.maturityDate`),
                value: rd.security.minMaturityDate,
                format: 'date',
              },
              isBond && {
                title: `${t(`equity.totalRow.yearsToRedemption`)} (${t(`common.onRecordDate`)})`,
                value: rd.security.yearsToRedemption,
                format: 'number',
                subData: compact([
                  !isGovBond && {
                    title: `${t(`equity.generalSecurity.averageSeniorityPerRedemption`)} (${t(`common.onRecordDate`)})`,
                    value: rd.security.averageSeniorityPerRedemption,
                    format: 'number',
                    key: 'averageSeniorityPerRedemption',
                  },
                ]),
              },
            ])
            setMetadataRow(mdRow1)
            setProjectedFunds(rd.sumProjectedFundsMarketValue)
          } else {
            removeDisplayDataFromState()
          }
          setFetchingHeader(false)
        })
        .catch(() => {
          removeDisplayDataFromState()
        })
    }

    if (securityExist) {
      // console.log(`load rows sec ${selectedSecurity}`)
      loadRows()
    }
  }, [
    t,
    secType,
    algoType,
    selectedSecurity,
    securityExist,
    setTotalRow1,
    setTotalRow2,
    setMetadataRow,
    setProjectedFunds,
    setСurrentFunds,
    calcDescForUrl,
    recordDate,
    effectiveDate,
    isComponents,
    isEquity,
    isBond,
    simDataUpdateFlag,
    isBondsRebalance,
    isNewMethodology,
  ])

  const projectedInIndexesTable = (
    <SecurityAnalysisTable
      headerText={t('equity.securityAnalysis.tableTitle')}
      apiUrl={`security_overview/table/${selectedSecurity}?${calcDescForUrl}`}
      defaultSortBy={{ id: 'projectedChange', desc: true }}
      selectedSecurity={doesSecurityExist(selectedSecurity, securityList)}
    />
  )

  const withSelectedSecurity = useSimDataUpdater(
    <>
      {fetchingHeader ? (
        <Loader containerHeight="200px" />
      ) : (
        <HiddenSm>
          <SecurityAnalysisTotalBlock totalRow1={totalRow1} totalRow2={totalRow2} metadataRow={metadataRow} />
        </HiddenSm>
      )}

      {isBondsRebalance ? (
        // not displaying goalseek on bonds rebalance
        projectedInIndexesTable
      ) : (
        <Tab.Container id="left-tabs-example" activeKey={selectedTab ? selectedTab : 'projectedIndexesTab'} onSelect={tab => setSelectedTab(tab)}>
          <TabNav variant="pills" style={{ justifyContent: 'center', marginTop: '15px' }}>
            <Nav.Item>
              <TabNavLink eventKey="projectedIndexesTab" dir={isEng() ? 'ltr' : 'rtl'}>
                {t('equity.securityAnalysis.projectedIndexes')}
              </TabNavLink>
            </Nav.Item>
            <Nav.Item>
              <TabNavLink eventKey="goalSeekTableTab" dir={isEng() ? 'ltr' : 'rtl'}>
                {t('equity.securityAnalysis.indexesCriteria')}
              </TabNavLink>
            </Nav.Item>
          </TabNav>

          <Tab.Content>
            <Tab.Pane eventKey="projectedIndexesTab">{projectedInIndexesTable}</Tab.Pane>
            <Tab.Pane eventKey="goalSeekTableTab">
              <GoalSeekTable
                headerText={t('equity.securityAnalysis.tableTitleGoalSeek')}
                apiUrl={`security_overview/goalSeekTable/${selectedSecurity}?${calcDescForUrl}`}
                defaultSortBy={{ id: 'projectedChange', desc: true }}
                selectedSecurity={doesSecurityExist(selectedSecurity, securityList)}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </>,
  )

  return useSimDataUpdater(
    <>
      {fetchingSecuritiesList ? (
        <Loader />
      ) : (
        <SecuritySelectorBlock
          projectedChangeValue={projectedFunds - currentFunds}
          data={securityList}
          value={selectedSecurity}
          selectedSecurityData={selectedSecurityData}
          onChange={secValue => {
            setSelectedSecurity(secValue.value)
          }}
        />
      )}

      {securityExist && withSelectedSecurity}
    </>,
  )
}
