import React from 'react'
import some from 'lodash/some'

import { FiltersContentContainer, TableLegendWrapper, TableFilterRowWrapper } from './styles'

import { GlobalFilter } from '../filters'
import { TableLegend } from '../TableLegend'

export default function FiltersWrapper({ legends, ReactTableBag }) {
    const { state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = ReactTableBag
    return (
        <TableFilterRowWrapper>
            <FiltersContentContainer>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {some(legends) && (
                    <TableLegendWrapper>
                        <TableLegend
                            legends={legends}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </TableLegendWrapper>

                )}
            </FiltersContentContainer>

        </TableFilterRowWrapper>
    )
}
