import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import auth from 'utils/auth'
import ReactGA from 'react-ga'
import { isProduction } from '../../../utils/appEnv'

import { NavBarComponent } from './NavBarComponent'

const screensWithoutNavbar = ['/', '/resetPassword', '/forgotPassword']

export const Navbar = () => {
  const location = useLocation()

  const trackPage = page => {

    if (isProduction) {
      ReactGA.set({ page })
      ReactGA.pageview(page)
    }
  }

  useEffect(() => {
    trackPage(location.pathname + location.search)
  }, [location])

  return !screensWithoutNavbar.includes(location.pathname) && <NavBarComponent auth={auth} />
}
