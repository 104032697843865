import styled from 'styled-components'

import { H2 } from 'styles/Typography'

export const SecuritySelectorBlockContainer = styled.div`
  display: grid;
  justify-content: center;
  padding: 5px 10px 0px 10px;
  align-items: center;
  grid-template-areas: '. . . txt txt txt sl sl pch pch pch pch';
  @media (max-width: 1055px) {
    grid-template-areas:
      'txt txt txt txt txt txt sl sl sl . . .'
      'pch pch pch pch pch pch pch pch pch pch pch pch';
  }
  @media (max-width: 806px) {
    grid-template-areas:
      'txt txt txt txt txt txt txt txt txt txt txt txt'
      'sl sl sl sl sl sl sl sl sl sl sl sl'
      'pch pch pch pch pch pch pch pch pch pch pch pch';
  }
`

export const SelectorText = styled(H2)`
  grid-area: txt;
  margin-right: 8px;
  text-align: right;
  @media (max-width: 806px) {
    text-align: center;
  }
`

export const SelectorWrapper = styled.div`
  grid-area: sl;
  @media (max-width: 806px) {
    width: 100%;
    justify-self: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`
