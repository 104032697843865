import ReactGA from 'react-ga'
import { FullStoryAPI, log } from 'react-fullstory'
import auth from 'utils/auth'
import { i18n } from '../../i18n'
import { isProduction } from 'utils/appEnv'
import localStorageService from 'utils/localStorageService'

const runProductionLoginStepsConditionally = (_id, email, name) => {
  if (isProduction) {
    const initLogMsg = `analytics init with params _id:  ${_id}, email: ${email}, name: ${name}`
    console.log(initLogMsg)
    //logs to fullStory.
    log('info', initLogMsg)
    ReactGA.set({ userId: _id })
    ReactGA.set({ dimension1: email })
    FullStoryAPI('identify', _id, {
      displayName: name,
      email,
    })
  }
}

const updateLocalStorage = (token, refreshToken, user, defaultLanguage) => {
  auth.login({
    token,
    refreshToken,
    user,
    cb: () => {
      if (localStorageService.getIsNotFirstLogin() && defaultLanguage) {
        i18n.changeLanguage(defaultLanguage)
      }
      localStorageService.setIsNotFirstLogin(true)
    },
  })
}

export const successfulLoginLogic = loginResponse => {
  const { token, refreshToken, user } = loginResponse.data
  const { _id, defaultLanguage, name, email } = user
  runProductionLoginStepsConditionally(_id, email, name)
  updateLocalStorage(token, refreshToken, user, defaultLanguage)
}
