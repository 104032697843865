import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import localStorageService from 'utils/localStorageService'

import get from 'lodash/get'

import { TOS } from 'components/General/TOS'
import axiosApi from 'utils/axiosApi'
import auth from 'utils/auth'

import { MenuLanguageSwitcher } from './MenuLanguageSwitcher'
import { AdminMenu } from './AdminMenu'

import { CustomDropdownButton, CustomDropdownMenu } from './styles'

export const DropdownMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [tos, setTosOpen] = useState(null)

  const logout = () => {
    const handleError = error => {
      const errorMsg = get(error, 'response.data.errors.msg') || error.message
      console.log(errorMsg)
    }

    axiosApi
      .post('/logout', { email: localStorage.getItem('email') })
      .then(response => {
        if (response instanceof Error) {
          handleError(response)
          return
        }

        return auth.logout(() => {
          history.replace('/')
        })
      })
      .catch(error => handleError(error))
  }

  const openTermsModal = () => {
    axiosApi
      .get('profile')
      .then(({ data }) => {
        if (data.agreement) {
          return Promise.all([
            data.agreement,
            axiosApi.get(`tos/${data.agreement.tosId}`),
          ])
        }

        return null
      })
      .then(([agreement, { data }]) => {
        setTosOpen({ ...data, agreementDate: agreement.created_at })
      })
      .catch(() => {
        logout()
      })
  }

  const closeTermsModal = () => {
    setTosOpen(null)
  }

  return (
    <Dropdown>
      <CustomDropdownButton>
        { localStorageService.getEmail() }
      </CustomDropdownButton>
      <CustomDropdownMenu direction={t('get_current_language_code')}>
        <MenuLanguageSwitcher />
        <Dropdown.Divider />
        <Dropdown.Item onClick={openTermsModal}>
          {t('menu.termsOfService')}
        </Dropdown.Item>
        <Dropdown.Item onClick={logout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          {t('menu.logout')}
        </Dropdown.Item>
        {auth.isAdmin() && <AdminMenu />}
      </CustomDropdownMenu>
      {tos && <TOS tos={tos} onClose={closeTermsModal} />}
    </Dropdown>
  )
}
