import styled from 'styled-components'

export const SectionContainer = styled.div`
  margin-top: 60px;
`

export const DataWrapper = styled.form`
  transition: filter 150ms linear;
  ${props => props.dirty && 'filter: opacity(0.4) blur(1px);'}
`
