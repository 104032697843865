/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTranslation } from 'react-i18next'

import useColumnsData from 'utils/useColumnsData'
import useIndexesList from 'utils/useIndexesList'

import { Table } from 'components/Common/Table'

import get from 'lodash/get'
import find from 'lodash/find'

import { columns } from './columns'

export const getReasonFieldByName = t => {
  switch (t) {
    case 'minFreeFloatRate':
      return 'freeFloatRate'
    case 'minAvgPrice':
      return 'avgPrice'
    case 'minClosingPrice':
      return 'closingPrice'
    case 'minPublicMarketValue':
      return 'publicMarketValueForComponents'
    default:
      return t
  }
}

export const GoalSeekTable = props => {
  const [indexesList] = useIndexesList()
  const { t } = useTranslation()

  const conditionalRowProps = row => {
    const projectedWeight = get(row.original, 'projectedWeight')
    const weight = get(row.original, 'effectiveDateWeight')

    if (projectedWeight === 0 && weight !== 0) return { className: 'dangerTR' }
    if (weight === 0 && projectedWeight !== 0) return { className: 'successTR' }
  }

  const conditionalCellProps = (row, cell) => {
    const colName = get(cell, 'column.id')
    const { reasons } = row.original

    const reason = find(reasons, r => r.t === colName)
    if (reason) return { className: 'dangerCell' }
  }

  const legends = [
    {
      color: '#e4ffe1',
      label: t('equity.indexOverview.legends.enteringSecurities'),
    },
    {
      color: '#ffe4e1',
      label: t('equity.indexOverview.legends.leaveingSecurities'),
    },
  ]

  const { selectedSecurity } = props
  const exportFileNameContext = selectedSecurity ? selectedSecurity.label : ''

  return (
    <Table
      withExportButton
      columns={columns({ ...useColumnsData(), indexesList })}
      {...props}
      noDataMsg={t('goalSeek.noData')}
      conditionalRowProps={conditionalRowProps}
      conditionalCellProps={conditionalCellProps}
      legends={legends}
      exportFileNameContext={exportFileNameContext}
      virtualized={true}
      rowHeight={70}
    />
  )
}
