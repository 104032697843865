/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Context from 'context/Context'
import { SecTypes } from 'utils/const'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useColumnsData from 'utils/useColumnsData'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Table } from 'components/Common/Table'

import { columns } from './columns'

export const AllTransactionsTables = props => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)

  const calcDescForUrl = useCalcDescUrl()

  const apiUrlInflows = `all_changes/table/inflows?${calcDescForUrl}`
  const apiUrlOutflows = `all_changes/table/outflows?${calcDescForUrl}`

  const legends = useCallback(() => {
    const isGovBond = row => {
      const { securityName } = row.original
      // has 'ממשל' or 'gov' or 'ILCPI' or 'ILFRNO' or 'מ.ק.מ' or 'TREASR BND' in the name
      return /ממשל|gov|ILCPI|ILFRNO|מ.ק.מ|TREASR BND|(GALIL|גליל) \d+/i.test(securityName)
    }

    const isCorpBond = row => !isGovBond(row)

    if (secType && secType === SecTypes.allBond) {
      return [
        {
          label: t('equity.allChanges.govBonds'),
          filter: isGovBond,
        },
        {
          label: t('equity.allChanges.corpBonds'),
          filter: isCorpBond,
        },
      ]
    }
  }, [secType, t])

  return (
    <Row>
      <Col md={12} lg={6}>
        <Table
          withExportButton
          headerText={t('equity.allChanges.table1Title')}
          headerBackground="#388e3b"
          rowBackgroundColor="#dff0d8"
          headerTextColor="white"
          columns={columns(useColumnsData())}
          {...props}
          apiUrl={apiUrlInflows}
          defaultSortBy={{ id: 'projectedChange', desc: true }}
          virtualized={true}
          legends={legends()}
        />
      </Col>
      <Col md={12} lg={6}>
        <Table
          withExportButton
          headerText={t('equity.allChanges.table2Title')}
          headerBackground="#b71c1b"
          rowBackgroundColor="#f4c7c3"
          headerTextColor="white"
          columns={columns(useColumnsData())}
          {...props}
          apiUrl={apiUrlOutflows}
          defaultSortBy={{ id: 'projectedChange', desc: false }}
          virtualized={true}
          legends={legends()}
        />
      </Col>
    </Row>
  )
}
