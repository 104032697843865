import React from 'react'
import PropTypes from 'prop-types'
import some from 'lodash/some'
import { useTranslation } from 'react-i18next'
import { SelectSearch } from 'components/Common/SelectSearch'
import { LinkToTase } from 'components/Common/LinkToTase'
import DualListedBadge from 'components/Common/Table/TableCellExtraContent/DualListedBadge'
import NewStockBadge from 'components/Common/Table/TableCellExtraContent/NewStockBadge'
import RemovalBadge from 'components/Common/Table/TableCellExtraContent/RemovalBadge'
import GradualUniverseFactorBadge from 'components/Common/Table/TableCellExtraContent/GradualUniverseFactorBadge'
import SpecialEventBadge from 'components/Common/Table/TableCellExtraContent/SpecialEventBadge'

import { SecuritySelectorBlockContainer, SelectorWrapper } from './styles'

export const SecuritySelectorBlock = ({ data, value, onChange, selectedSecurityData }) => {
  const { t } = useTranslation()

  if (!data) return null
  if (!value) return null

  const tradeCodeNo = some(selectedSecurityData) && selectedSecurityData.security.tradeCodeNo

  return (
    <SecuritySelectorBlockContainer>
      <SelectorWrapper>
        <SelectSearch options={data} value={value} onChange={onChange} />
        <LinkToTase href={`https://www.tase.co.il/he/market_data/security/${value}`} popoverText={t('equity.table.marketData')} />
        {tradeCodeNo && <DualListedBadge tradeCodeNo={tradeCodeNo} />}
        <NewStockBadge data={selectedSecurityData?.security} />
        <RemovalBadge data={selectedSecurityData?.security} />
        <RemovalBadge data={selectedSecurityData?.security} />
        <GradualUniverseFactorBadge data={selectedSecurityData?.security} />
        <SpecialEventBadge data={selectedSecurityData?.security} />
      </SelectorWrapper>
    </SecuritySelectorBlockContainer>
  )
}

SecuritySelectorBlock.defaultProps = {
  projectedChangeValue: null,
  value: null,
  data: null,
  onChange: () => {},
}

SecuritySelectorBlock.propTypes = {
  projectedChangeValue: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.number,
}
