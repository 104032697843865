import some from 'lodash/some'
import get from 'lodash/get'
import filter from 'lodash/filter'

const PaymentTypes = {
  finalInterestRate: 'Final Interest Rate', // eventTypeId = 8
  dividend: 'Dividend', // eventTypeId = 22
  partialRedemption: 'Partial Redemption', // eventTypeId = 9
  interestRate: 'Interest Rate', // eventTypeId = 7
  forcedRedemption: 'Forced Redemption', // eventTypeId = 12
  rightsAllocation: 'Rights Allocation', // eventTypeId = 21
  finalRedemption: 'Final Redemption', // eventTypeId = 10
  finalExercise: 'Final Exercise', // eventTypeId = 4
  rightsExercise: 'Rights Exercise', // eventTypeId = 5
  finalConversion: 'Final Conversion', // eventTypeId = 2
}

const tPrefix = 'equity.changesSummary'

export const getPaymentsDescription = ({ t, timeBasedData, isIndexChange }) => {
  // console.log(timeBasedData)
  if(!timeBasedData) return null
  isIndexChange = some(isIndexChange) && some(isIndexChange.filter(idx => idx.isIndexChange))

  const events = []
  if (some(timeBasedData.redemptions)) {
    events.push(t(`${tPrefix}.redemption`))
  }
  if (get(timeBasedData, 'interest')) {
    events.push(t(`${tPrefix}.interest`))
  }
  if (get(timeBasedData, 'capitalListedExtension')) {
    events.push(t(`${tPrefix}.bondExtension`))
  }
  if (get(timeBasedData, 'removal')) {
    events.push(t(`${tPrefix}.removal`))
  }
  if (timeBasedData?.finalRedemption) {
    events.push(t(`${tPrefix}.finalRedemption`))
  }
  if (isIndexChange) {
    events.push(t(`${tPrefix}.indexChange`))
  }
  return some(events) ? events.join(', ') : null
}

export const getPaymentsDescription_CapitalListedEvent = ({ t, timeBasedData }) => {
  const relevantPayments = new Set([PaymentTypes.partialRedemption, PaymentTypes.forcedRedemption, PaymentTypes.finalRedemption])
  const capitalListedEvents = filter(timeBasedData?.payments, payment => relevantPayments.has(payment.eventTypeEng))

  if (!some(capitalListedEvents)) return null

  return capitalListedEvents.map(payment => {
    let eventType
    if (some(timeBasedData.redemptions)) {
      eventType = t(`${tPrefix}.redemption`)
    }
    if (get(timeBasedData, 'capitalListedExtension')) {
      eventType = t(`${tPrefix}.bondExtension`)
    }
    if (timeBasedData?.finalRedemption) {
      eventType = t(`${tPrefix}.finalRedemption`)
    }
    return {
      exDate: payment.exDate || payment.paymentDate,
      eventType,
    }
  })
}
