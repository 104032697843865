import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import localStorageService from 'utils/localStorageService'
import { AuthContext } from 'context/AuthContext'

import { Card } from 'components/Common/Card'
import { loggedInRedirectUrl } from './loggedInRedirectUrl'
import { InitializeLogin } from './initializeLogin'
import { RegisterPhone } from './registerPhone'
import { SmsLogin } from './smsLogin'
import { PasswordLogin } from './passwordLogin'
import { TermsOfUse } from './termsOfUse'
import { DeniedTermsOfUse } from './deniedTermsOfUse'
import { loginScenariosDictionary } from './loginScenariosDictionary'
import { successfulLoginLogic } from './successfulLoginLogic'
import { LoginWrapper } from './styles'

export const LoginScreen = () => {
  const history = useHistory()
  const { loginScenario, setLoginScenario } = useContext(AuthContext)

  const handleSuccessfulLoginResponse = (response, acceptedTermsOfUse = false) => {
    validateSuccessfullLoginResponse(response)
    const userShouldAcceptTermsOfUse = isTermsOfUseNeeded(response, acceptedTermsOfUse)
    if (userShouldAcceptTermsOfUse) {
      setLoginScenario(loginScenariosDictionary.needToAcceptTermsOfUse)
      return
    }
    successfulLoginLogic(response)
    const redirectURL = loggedInRedirectUrl(history)
    history.replace(redirectURL)
  }

  useEffect(() => {
    if (localStorageService.getAccessToken()) {
      history.replace(loggedInRedirectUrl(history))
    }
  }, [history])

  const loginComponentByScenario = () => {
    switch (loginScenario) {
      case loginScenariosDictionary.initialization:
        return <InitializeLogin />
      case loginScenariosDictionary.phoneRegistration:
        return <RegisterPhone />
      case loginScenariosDictionary.smsLogin:
        return <SmsLogin handleSuccessfulLoginResponse={handleSuccessfulLoginResponse} />
      case loginScenariosDictionary.passwordLogin:
        return <PasswordLogin handleSuccessfulLoginResponse={handleSuccessfulLoginResponse} />
      case loginScenariosDictionary.needToAcceptTermsOfUse:
        return <TermsOfUse handleSuccessfulLoginResponse={handleSuccessfulLoginResponse} />
      case loginScenariosDictionary.deniedTermsOfUse:
        return <DeniedTermsOfUse />
      default:
        return <InitializeLogin />
    }
  }

  return (
    <LoginWrapper>
      <Card>{loginComponentByScenario()}</Card>
    </LoginWrapper>
  )
}

const isTermsOfUseNeeded = (loginResponse, acceptedTermsOfUse) => {
  const userIsOnFirstLoginEver = loginResponse.data.user?.tos
  const userShouldAcceptTermsOfUse = userIsOnFirstLoginEver && !acceptedTermsOfUse
  return userShouldAcceptTermsOfUse
}

const validateSuccessfullLoginResponse = loginResponse => {
  const successfullLoginResponseRecived = loginResponse?.data?.token
  if (!successfullLoginResponseRecived) {
    throw new Error('handleSuccessfulLoginResponse called without successfull login response')
  }
}
