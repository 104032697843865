import React, { useEffect, useState } from 'react'
import useCalcDescUrl from 'utils/useCalcDescUrl'

import axiosApi from 'utils/axiosApi'
import keyBy from 'lodash/keyBy'
import some from 'lodash/some'
import useColumnsData from 'utils/useColumnsData'

import { Columns } from './Columns'
import { Table } from 'components/Common/Table'
import Alert from 'react-bootstrap/Alert'

export const AlgoLogsTable = ({ calcDesc }) => {
  const [securityList, setSecurityList] = useState([])
  const [totalLogCount, setTotalLogCount] = useState(0)
  const calcDescForUrl = useCalcDescUrl()

  useEffect(() => {
    const loadSecurities = () => {
      axiosApi
        .get(`security_overview/filter?${calcDescForUrl}&lang=he`)
        .then(response => {
          if (some(response.data)) {
            const securitiesList = keyBy(response.data, 'securityNo')
            setSecurityList(securitiesList)
          }
        })
    }

    loadSecurities()
  }, [setSecurityList, calcDescForUrl])

  useEffect(() => {
    const getTotalLogCount = () => {
      axiosApi
        .get(`index_calc_results/getAlgoLogsCountByDesc?${calcDescForUrl}`)
        .then(response => {
          if (response.data) {
            setTotalLogCount(response.data)
          }
        })
    }

    getTotalLogCount()
  }, [calcDescForUrl])

  const MAX_LOG_COUNT = 2000
  const withTotalLogCount = React.useMemo(() => {
    if (totalLogCount > MAX_LOG_COUNT) {
      return (
        <Alert variant="warning">
          notice: displaying only <strong>{MAX_LOG_COUNT}</strong> logs out of a
          total of <strong>{totalLogCount}!</strong>
        </Alert>
      )
    } else return null
  }, [totalLogCount])

  return (
    <>
      <Table
        withExportButton
        exportFileType="csv"
        columns={Columns({...useColumnsData(), securityList})}
        apiUrl={`index_calc_results/getAlgoLogsByDesc?${calcDescForUrl}`}
        defaultSortBy={{ id: 'time', desc: false }}
        headerText="Algo Logs"
        virtualized={true}
      />
      {withTotalLogCount}
    </>
  )
}
