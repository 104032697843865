const ALL_RATINGS = [
  'Aaa / AAA',
  'Aa1 / AA+',
  'Aa2 / AA',
  'Aa3 / AA−',
  'A1 / A+',
  'A2 / A',
  'A3 / A−',
  'Baa1 / BBB+',
  'Baa2 / BBB',
  'Baa3 / BBB−',
  'Ba1 / BB+',
  'Ba2 / BB',
  'Ba3 / BB−',
  'B1 / B+',
  'B2 / B',
  'B3 / B−',
  'Caa1 / CCC+',
  'Caa / Caa2 / CCC',
  'Caa3 / CCC-',
  'Ca / CC',
  'C / C',
]

const RatingScoreToDesc = ALL_RATINGS.reduce((acc, rating, idx) => {
  const ratingLevel = ALL_RATINGS.length - idx
  acc[ratingLevel] = rating
  const spl = rating.split(/\s*\/\s/)
  acc[ratingLevel + 'Midroog'] = spl[0]
  acc[ratingLevel + 'Maalot'] = spl[1]
  return acc
}, {})

const RatingOpts = ALL_RATINGS.map((label, idx) => ({
  label,
  value: ALL_RATINGS.length - idx,
}))

function getRatingDescFromScore(ratingScore, agency) {
  return RatingScoreToDesc[`${ratingScore}${agency ? agency : ''}`]
}

module.exports = {
  getRatingDescFromScore,
  RatingOpts,
}
