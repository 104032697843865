import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import axiosApi from 'utils/axiosApi'
import Context from 'context/Context'
import auth from 'utils/auth'
import { SecTypes } from 'utils/const'

import { EquityBondTables } from 'components/General/EquityBondTables'

export const StocksScreen = ({
  // eslint-disable-next-line react/prop-types
  match: {
    // eslint-disable-next-line react/prop-types
    params: { tab },
  },
}) => {
  const { secType, setSecType, setSelectedUpdateScheduleRecord } = useContext(Context)
  const history = useHistory()

  useEffect(() => {
    if (secType !== SecTypes.stock) {
      setSelectedUpdateScheduleRecord(null)
      setSecType(SecTypes.stock)
    }
  }, [secType, setSecType, setSelectedUpdateScheduleRecord])

  if (secType !== SecTypes.stock) return <div>Loading</div>

  if (!auth.isSubscribed(SecTypes.stock)) {
    // redirect to main page
    axiosApi.post('/users/track', { action: 'load', target: 'locked-location', metadata: { location: history.location, secType } })
    history.replace('/')
  }

  return (
    <>
      <EquityBondTables tab={tab} />
    </>
  )
}
