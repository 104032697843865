import styled, { css } from 'styled-components'
import { H1, H2 } from 'styles/Typography'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import {Button} from 'components/Common/button'

export const ServerError = styled.span`
  display: inline-block;
  color: #dd3d4d;
  height: 1.5rem;
  text-align: center;
  width: 100%;
`

export const Label = styled.div`
  ${props =>
    props.field &&
    css`
      margin-top: 6px;
    `}
  color: gray;
`

export const UpdateFormWrapper = styled.div`
  padding: 20px;
  margin: 20px;
  background: #cde6fe;
`

export const FormTitle = styled(H1)`
  padding-top: 0px;
`
export const UserName = styled(H2)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`

export const DataRowWrapper = styled(Row)`
  justify-content: center;
  margin-bottom: 5px;
`

export const DataCol = styled(Col)`
  text-align: right;

  @media (max-width: 576px) {
    text-align: left;
  }
`

export const StyledModalHeader = styled(Modal.Header)`
  padding: 5px;
  border: 0;
`

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
`

export const StyledButton = styled(Button)`
  margin-top: 20px;
  flex-basis:40%;
`





