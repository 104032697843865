import React from 'react'

import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { TableCell } from 'components/Common/Table/TableCell'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

export default function(row) {
  const { t } = useTranslation()

  const rank = row.value
  const isSpecial = rank >= 1000
  const value = !isSpecial ? (
    rank
  ) : (
    <span>
      <FontAwesomeIcon icon={faBan} />
    </span>
  )

  return (
    <TableCell
      value={value}
      noPopup
      extraContent={
        isSpecial && (
          <TableCellExtraContent
            popoverText={t(`equity.componentRanks.${rank}`)}
            content={<FontAwesomeIcon icon={faInfoCircle} />}
            color="black!important"
          />
        )
      }
      color={isSpecial ? 'red' : undefined}
    />
  )
}
