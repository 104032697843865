import React from 'react'
import { TableCell } from 'components/Common/Table/TableCell'

import get from 'lodash/get'

export const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export const buildDefaultColumn = () => {
  const getAccaptableDifference = filter => {
    switch (filter) {
      case '%':
        return 0.00001

      case '%-short':
      case 'number':
      case 'number-short':
      case 'currency-long':
        return 0.001

      case '%-shorter':
      case 'number-long':
      case 'currency':
      case 'agorot':
      default:
        return 0.1
    }
  }

  const compareResultClass = ({ v1, v2, format }) => {
    const acceptableDiff = getAccaptableDifference(format)
    const dif = v2 - v1

    if (dif === 0 || Math.abs(dif) <= acceptableDiff) return 'black'

    if (dif > 0) return 'green'

    //if abs dif is more than acceptable and is lower than 0
    return 'red'
  }

  return {
    width: 90, // width is used for both the flex-basis and flex-grow
    Filter: () => null,
    Cell: row => {
      return (
        <TableCell
          {...row.cell}
          {...row.column.cellFormat}
          link={row.column.link}
          columnId={row.column.id}
          {...(row.column.coloredReferenceSelector
            ? {
                color: compareResultClass({
                  v1: get(row.row.original, row.column.coloredReferenceSelector, 0),

                  v2: row.value,
                  format: row.column.cellFormat.format,
                }),
              }
            : {})}
        />
      )
    },
    sortType: 'basic',
  }
}
