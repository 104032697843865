import styled from 'styled-components'
import { Button } from 'components/Common/button'

export const TablePageContainer = styled.div`
  border: ${props => (props.styles && props.styles.border ? props.styles.border : '2px solid #969696')};
  flex: 1;
  background: ${props => (props.styles && props.styles.background ? props.styles.background : '#f2f2f2')};
  ${props => props.styles && props.styles.padding && `padding: ${props.styles.padding};`}
  margin-top: 20px;
  margin-bottom: 20px;

  select {
    min-width: 30px;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }

  .table-responsive {
    max-height: 70vh;
  }
`

export const TableHeaderContainer = styled.div`
  border-bottom: 2px solid #969696;
  background: ${props => (props.background ? props.background : '#d7d7d7')};
  color: ${props => (props.color ? props.color : 'black')};
  text-align: center;
  padding: 10px 20px;
  display: block;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    grid-template-areas: 'txt txt txt txt txt txt txt txt txt' 'btn btn btn btn btn btn btn btn btn';
  }
`

export const TableHeaderText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 600;
  grid-area: txt;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
`

export const ExportButton = styled(Button)`
  position: absolute;
  ${props => (props.direction === 'ltr' ? 'right: 0;' : 'left: 0;')};
  width: auto;
`

export const ErrorContainer = styled.div`
  padding: 100px 0px 100px 0px;
  color: red;
  text-align: center;
`

export const GlobalFilterInput = styled.input`
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  min-height: 38px;
  outline: 0 !important;
  box-sizing: border-box;
  width: 20vw;
  padding: 0 10px 0px 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

export const GlobalFilterWrapper = styled.span`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TableWrapper = styled.div``
