import React from 'react'
import compact from 'lodash/compact'
import get from 'lodash/get'

import useColumnsData from 'utils/useColumnsData'
import { SecTypes } from 'utils/const'
import { Columns } from './Columns'
import { Table } from 'components/Common/Table'
import { getLiquidationGroupDesc } from 'utils/liquidationGroupUtils'

export const ParametersTable = ({ calcDesc }) => {
  const isEquity = calcDesc.secType === SecTypes.stock

  const hasInvalidReason = row => {
    return get(row.original, 'invalid')
  }

  const isMissingOnAlgo = row => {
    const {
      capitalListedForTrading: indexAdjustedNoOfSharesIans,
      taseIndexAdjustedNoOfSharesIans,
      projectedIndexAdjustedFreeFloatRate,
    } = row.original

    const isMissing = isEquity
      ? isNaN(indexAdjustedNoOfSharesIans) &&
        isNaN(projectedIndexAdjustedFreeFloatRate) &&
        !isNaN(taseIndexAdjustedNoOfSharesIans) &&
        !hasInvalidReason(row)
      : isNaN(indexAdjustedNoOfSharesIans) && !isNaN(taseIndexAdjustedNoOfSharesIans) && !hasInvalidReason(row)

    return isMissing
  }

  const hasDeltaInFFR = row => {
    const { projectedIndexAdjustedFreeFloatRate, taseProjectedIndexAdjustedFreeFloatRate } = row.original
    return Math.abs(projectedIndexAdjustedFreeFloatRate - taseProjectedIndexAdjustedFreeFloatRate) >= 0.1
  }

  const hasDeltaInIANS = row => {
    const { capitalListedForTrading, taseIndexAdjustedNoOfSharesIans } = row.original
    return Math.abs(capitalListedForTrading - taseIndexAdjustedNoOfSharesIans) >= 0.1
  }

  const hasDeltaInLiquidityRatio = row => {
    const { projectedLiquidationGroup, taseNewLiquidityRatio } = row.original
    const formatted = {
      ours: getLiquidationGroupDesc(projectedLiquidationGroup),
      tase: getLiquidationGroupDesc(taseNewLiquidityRatio),
    }

    return formatted.ours !== formatted.tase
  }

  const taseIansNotFound = row => {
    const { taseIndexAdjustedNoOfSharesIans } = row.original
    return isNaN(taseIndexAdjustedNoOfSharesIans)
  }

  const conditionalRowProps = row => {
    let classNames = []
    if (isMissingOnAlgo(row)) classNames.push('dangerTR')
    if (hasInvalidReason(row)) classNames.push('dangerTRAlt')
    if (isEquity && hasDeltaInFFR(row)) classNames.push('warningTR')
    if (hasDeltaInIANS(row)) classNames.push('warningTRAlt')
    if (hasDeltaInLiquidityRatio(row)) classNames.push('warningTRAlt2')

    return { className: classNames.join(' ') }
  }

  const legends = compact([
    {
      color: '#ffeded',
      label: 'Invalid Securities',
      filter: hasInvalidReason,
    },
    {
      color: '#ffe4e1',
      label: 'missing from algo, found on TASE',
      filter: isMissingOnAlgo,
    },
    isEquity && {
      color: '#fff6e1',
      label: 'Delta in FFR',
      filter: hasDeltaInFFR,
    },
    {
      color: '#ffebbc',
      label: 'Delta in IANS',
      filter: hasDeltaInIANS,
    },
    isEquity && {
      color: '#ffd4a4',
      label: 'Delta in Liquidity Ratio',
      filter: hasDeltaInLiquidityRatio,
    },
    {
      color: '#e1f5fe',
      label: 'TASE IANS not found',
      filter: taseIansNotFound,
    },
  ])

  return (
    <Table
      withExportButton
      exportFileType="csv"
      columns={Columns(useColumnsData())}
      apiUrl={`/index_calc_results/getIANSandFloatResultsByDescId/${calcDesc._id}`}
      exportFileNameOverrideDate={calcDesc.taseEffectiveDate}
      defaultSortBy={{ id: 'securityId', desc: true }}
      conditionalRowProps={conditionalRowProps}
      headerText="Index Calc Results"
      virtualized={true}
      legends={legends}
    />
  )
}
