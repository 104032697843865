export const AlgoTypes = {
  components: 'components',
  parameters: 'parameters',
}

export const SecTypes = {
  stock: 'stock',
  corpBond: 'bond',
  govBond: 'govBond',
  allBond: 'allBond',
}

export const UpdateType = {
  components: 'Semi Annual Components Update',
}

export const SCREEN = {
  [SecTypes.corpBond]: 'corpBonds',
  [SecTypes.stock]: 'stocks',
  [SecTypes.govBond]: 'govBonds',
  [SecTypes.allBond]: 'allBonds',
}

export const SuggestedFieldFormats = {
  securityName: 'noFormat',
  indexName: 'noFormat',
  securityId: 'noFormat',
  companySupersector: 'noFormat',
  companySector: 'noFormat',
  projectedChange: 'currency',
  avgTurnover: 'currency',
  turnoverRatio: 'number-short',
  closingPrice: 'agorot',
  basePrice: 'agorot',
  avgPrice: 'agorot',
  capitalListedForTrading: 'number-long',
  currRecordDateCapitalListedForTrading: 'number-long',
  currTaseEffectiveDateIans: 'number-long',
  freeFloatRate: '%-short',
  freeFloatRateForSpecificFFRecordDate: '%-short',
  indexAdjustedFreeFloatRate: '%-shorter',
  projectedIndexAdjustedFreeFloatRate: '%-shorter',
  projectedComponentsRank: 'noFormat',
  projectedComponentsMarketValue: 'currency-long',
  projectedWeight: '%',
  weight: '%',
  weightFactor: 'number-short',
  projectedWeightFactor: 'number-short',
  currFundsMarketValue: 'currency',
  projectedFundsMarketValue: 'currency',
  minMarketValue: 'currency',
  indexAdjustedNoOfSharesIans: 'number-long',
  avgMarketValue: 'currency',
  currTotalIndexes: 'noFormat',
  projectedTotalIndexes: 'noFormat',
  ratingMaalot: 'noFactor',
  ratingMidroog: 'noFormat',
  maturityDate: 'noFormat',
  linkage: 'noFormat',
  ratingLevel: 'rating',
  publicMarketValueForComponents: 'currency',
  semiAnnualMedianTurnover: 'number-long',
}
