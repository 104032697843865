import localStorageService from './localStorageService'
import find from 'lodash/find'
import axiosApi from 'utils/axiosApi'
import { SecTypes } from 'utils/const'

class Auth {
  constructor() {
    this.role = localStorageService.getRole()
    this.authenticated = !!localStorageService.getAccessToken()
  }

  login({ token, refreshToken, user, cb }) {
    console.log(`auth: logging in`, { token, user })
    this.authenticated = true
    const { _id, email, name, role, subsPerSecType } = user
    this.role = role

    localStorageService.setAccessToken(token)
    localStorageService.setRefreshToken(refreshToken)
    localStorageService.setRole(role)
    localStorageService.setEmail(email)
    localStorageService.setName(name)
    localStorageService.setId(_id)

    localStorageService.setEquitySubscription(find(subsPerSecType, { secType: SecTypes.stock })?.sub)
    localStorageService.setAllBondsSubscription(find(subsPerSecType, { secType: SecTypes.allBond })?.sub)
    localStorageService.setShowAnnouncement(true)
    cb()
  }

  async refreshToken() {
    try {
      const refreshToken = localStorageService.getRefreshToken()
      if (!refreshToken) {
        Promise.reject('no refresh token')
      }

      const data = await axiosApi.post('/token', { refreshToken })
      if (!data?.data) {
        Promise.reject('no data')
      }

      const accessToken = data.data

      localStorageService.setAccessToken(accessToken)

      return accessToken
    } catch (err) {
      console.log('error refreshing token', err)
      Promise.reject(err)
    }
  }

  logout(cb) {
    console.log(`auth: logging out`)
    this.authenticated = false
    this.role = null
    localStorageService.cleanStorage()
    cb()
  }

  isAuthenticated() {
    return this.authenticated
  }

  isSubscribed(secType) {
    if (!this.isAuthenticated()) {
      return false
    }

    if (secType === SecTypes.stock) {
      return !localStorageService.getEquitySubscription() || localStorageService.getEquitySubscription() !== 'blocked'
    } else if (secType === SecTypes.allBond) {
      return !localStorageService.getAllBondsSubscription() || localStorageService.getAllBondsSubscription() !== 'blocked'
    } else {
      return false // TODO: add other sec types?
    }
  }

  isAdmin() {
    return this.role === 'admin'
  }
}

export default new Auth()
