import React, { useContext } from 'react'
import * as yup from 'yup'
import some from 'lodash/some'
import { Formik, FieldArray, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { isHeb } from '../../i18n'
import axiosApi from 'utils/axiosApi'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import Context, { useTaseEffectiveDate, useExternalIndexes } from 'context/Context'
import { useSimulationSecurityList } from 'components/Simulation/simulationHooks'

import { InlinedFormGroup, TrashButtonWrapper, FlexCenter, RowModal, RowModalRTL, RowModalHeader } from './styles'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { SelectSearch } from 'components/Common/SelectSearchFlex'
import { ConfirmDialog } from 'components/Common/ConfirmDialog'

import { Button } from 'components/Common/button'
import { Input } from 'components/Common/inputFlex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export const ExternalIndexModal = ({ selectedExtIdx, onHide, deleteDialogOpen, setDeleteDialogOpen, setLatestUpdatedIndex }) => {
  const { t } = useTranslation()
  const { secType, setExternalIndexesUpdateFlag, externalIndexesUpdateFlag } = useContext(Context)
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const [securityList] = useSimulationSecurityList()
  // eslint-disable-next-line no-unused-vars
  const [displayExternalIndexes, setDisplayExternalIndexes] = useExternalIndexes()

  const calcDescForUrl = useCalcDescUrl()

  const externalIndexSchema = yup.object().shape({
    name: yup.string().required('must have a name'),
    securities: yup
      .array()
      .of(
        yup.object().shape({
          securityId: yup
            .string()
            .matches(/^[0-9-]+$/, 'must be a number')
            .min(6, 'too short')
            .max(9, 'too long')
            .required('Required'), // these constraints take precedence
          projectedChange: yup
            .string()
            .matches(/^[0-9-]+$/, 'must be a number')
            .min(4, 'too short')
            .required('Required'), // these constraints take precedence
        }),
      )
      .required('Must have securties'), // these constraints are shown if and only if inner constraints are satisfied
    // .min(3, 'Minimum of 3 friends'),
  })

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { name, securities } = values
    const securitiesWithId = securities.map((sec, index) => ({ ...sec, id: index }))
    const data = {
      name,
      securities: securitiesWithId,
      _id: selectedExtIdx?._id,
    }

    try {
      const res = await axiosApi.post(`/users/external-indexes?${calcDescForUrl}`, data)
      setSubmitting(false)
      setExternalIndexesUpdateFlag(!externalIndexesUpdateFlag)
      setDisplayExternalIndexes(true)
      setLatestUpdatedIndex(res.data)
      onHide()
    } catch (err) {
      console.log({ err })
      setSubmitting(false)
      setErrors({ submit: err.message })
    }
  }

  const handleDelete = async () => {
    try {
      await axiosApi.delete(`/users/external-indexes/${selectedExtIdx._id}`)
      setExternalIndexesUpdateFlag(!externalIndexesUpdateFlag)
      onHide()
    } catch (err) {
      console.log({ err })
    }
  }

  const ModalWithLocalization = isHeb() ? RowModalRTL : RowModal

  return (
    <ModalWithLocalization show={!!selectedExtIdx} onHide={onHide} size="lg">
      <ConfirmDialog
        isOpen={deleteDialogOpen}
        text={`${t('externalIndexes.form.deleteExtended')} "${selectedExtIdx?.name}"?`}
        textColor="red"
        buttons={[
          {
            text: t('externalIndexes.confirmDialog.cancelText'),
            color: 'outline-secondary',
            onClick: () => setDeleteDialogOpen(false),
          },
          {
            text: t('externalIndexes.confirmDialog.confirmText'),
            color: 'outline-danger',
            onClick: () => {
              setDeleteDialogOpen(false)
              handleDelete()
            },
          },
        ]}
      />

      <Formik
        initialValues={selectedExtIdx}
        enableReinitialize
        validationSchema={externalIndexSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values, handleBlur, handleChange, handleSubmit, errors, touched }) => (
          <>
            <RowModalHeader closeButton>
              <Input
                name="name"
                type="text"
                onBlur={handleBlur}
                label={null}
                placeholder={t('externalIndexes.form.name')}
                value={values?.name || values?.name}
                onChange={handleChange}
                width="500px"
                style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
              />
            </RowModalHeader>
            <Modal.Body>
              <Alert variant="warning">
                {t(`externalIndexes.lang.${secType}`)} - {t('externalIndexes.lang.effectiveDate')} {dayjs(taseEffectiveDate).format('DD/MM/YYYY')}
              </Alert>

              <FieldArray
                name="securities"
                render={arrayHelpers => (
                  <div>
                    {typeof errors.securities === 'string' ? <span>{errors.securities}</span> : null}
                    {some(values?.securities) &&
                      values.securities.map((security, index) => (
                        <InlinedFormGroup key={index}>
                          <Field name={`securities[${index}].securityId`}>
                            {({ field, form, meta }) => {
                              return (
                                <SelectSearch
                                  name={'securityId'}
                                  getChangedValueWithEvent
                                  options={securityList}
                                  value={+field?.value}
                                  onChange={val => {
                                    form.setFieldValue(`securities.${index}.securityId`, val.value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(`securities.${index}.securityId`, true)
                                  }}
                                  isValid={false}
                                  isInvalid={meta.error && meta.touched}
                                  errorText={meta.error}
                                  label={index ? null : t('externalIndexes.lang.securityId')}
                                />
                              )
                            }}
                          </Field>

                          <Field name={`securities[${index}].projectedChange`}>
                            {({ field, form, meta }) => {
                              return (
                                <Input
                                  name={`securities[${index}].projectedChange`}
                                  type="number"
                                  onBlur={form.handleBlur}
                                  label={index ? null : t('externalIndexes.lang.projectedChange')}
                                  placeholder={'10,000,000'}
                                  value={field.value}
                                  onChange={form.handleChange}
                                  width="500px"
                                  isInvalid={meta.touched && meta.error}
                                  errorText={meta.error}
                                />
                              )
                            }}
                          </Field>

                          <TrashButtonWrapper isFirst={index === 0}>
                            <FontAwesomeIcon icon={faTrash} onClick={() => arrayHelpers.remove(index)} />
                          </TrashButtonWrapper>
                        </InlinedFormGroup>
                      ))}
                    <FlexCenter>
                      <Button type="Button" onClick={() => arrayHelpers.push({ securityId: '', projectedChange: '' })}>
                        {t('externalIndexes.form.addSecurity')}
                      </Button>
                    </FlexCenter>
                  </div>
                )}
              />
            </Modal.Body>
            <Modal.Footer>
              <InlinedFormGroup>
                <Button variant="secondary" onClick={onHide}>
                  {t('externalIndexes.form.cancel')}
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  {t('externalIndexes.form.save')}
                </Button>
                {selectedExtIdx?._id && (
                  <Button variant="danger" onClick={() => setDeleteDialogOpen(true)}>
                    {t('externalIndexes.form.delete')}
                  </Button>
                )}
              </InlinedFormGroup>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </ModalWithLocalization>
  )
}
