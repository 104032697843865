import { useEffect, useContext } from 'react'
import axiosApi from 'utils/axiosApi'
import { isEng } from '../i18n'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import some from 'lodash/some'
import Context, { useTaseEffectiveDate, useAlgoType } from 'context/Context'

export default function useIndexesList() {
  const calcDescForUrl = useCalcDescUrl()
  const { indexesData, setIndexesData, indexesList, setIndexesList, externalIndexesUpdateFlag, secType } = useContext(Context)
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const eng = isEng()

  // unmount
  useEffect(() => {
    return () => {
      setIndexesData([])
      setIndexesList([])
    }
  }, [setIndexesData, setIndexesList])

  // reset indexeses list on externalIndexesUpdateFlag change
  useEffect(() => {
    console.log('externalIndexesUpdateFlag', externalIndexesUpdateFlag)

    setIndexesList([])
    setIndexesData([])
  }, [externalIndexesUpdateFlag, setIndexesList, setIndexesData])

  // load index list for select
  useEffect(() => {
    const loadIndexes = () => {
      axiosApi.get(`indexes/?${calcDescForUrl}&lang=${eng ? 'en' : 'he'}`).then(response => {
        if (some(response.data)) {
          setIndexesData(response.data)
        }
      })
    }

    const hasRequiredData = taseEffectiveDate && algoType && secType
    if (!some(indexesData) && hasRequiredData) {
      loadIndexes()
    }
  }, [indexesData, setIndexesList, calcDescForUrl, eng, setIndexesData, externalIndexesUpdateFlag, taseEffectiveDate, algoType, secType])

  useEffect(() => {
    if (some(indexesData)) {
      const indexesList = indexesData.map(item => ({
        label: item[eng ? 'indexNameEng' : 'indexNameHeb'],
        value: item.indexNo,
      }))
      setIndexesList(indexesList)
    }
  }, [eng, indexesData, setIndexesList])

  return [indexesList, setIndexesList, setIndexesData]
}
