import dayjs from 'dayjs'
import getFormattedValue from './getFormattedValue'

const buildDatePriceString = (title, date, value) => {
  return `${title} (${dayjs(date).format('DD/MM')}): ${getFormattedValue({
    value,
    formatOverride: 'number',
  })}`
}

export default function({
  t,
  closingPrice,
  exAdjuestedClosingPrice,
  effectiveDateClosingPrice,
  effectiveDateClosingPriceDate,
  recordDate,
  effectiveDate,
}) {
  const recordDateString = exAdjuestedClosingPrice
    ? buildDatePriceString(t('equity.allChanges.AdjustedClosingPrice'), recordDate, exAdjuestedClosingPrice)
    : buildDatePriceString(t('equity.allChanges.recordDateClosingPrice'), recordDate, closingPrice)

  const effectiveDateString = buildDatePriceString(
    t('equity.allChanges.latestClosingPrice'),
    effectiveDateClosingPriceDate,
    effectiveDateClosingPrice,
  )

  return `${recordDateString}; ${effectiveDateString}`
}
