import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Context, { useAlgoType, useSelectedIndex } from 'context/Context'
import { SCREEN } from 'utils/const'
import { isEng } from '../../../../i18n'

import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ConfirmDialog } from 'components/Common/ConfirmDialog'
import get from 'lodash/get'
import numbro from 'numbro'
import { ActionButton, ActionButtonWrapper, FieldPeak, RowAlert, RowSummeryLink } from '../styles'

export const IndexRowSummery = ({ arrayHelpers, condition, securityList, index, setShowSecurityRowForm, fieldList }) => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)
  const pathPrefix = SCREEN[secType]
  const [algoType] = useAlgoType()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const direction = isEng() ? 'ltr' : 'rtl'

  const [, setSelectedIndex] = useSelectedIndex()
  const history = useHistory()

  return (
    <RowAlert variant="info">
      <ConfirmDialog
        isOpen={cancelDialogOpen}
        text={t('simulation.deleteRowDialog.text')}
        buttons={[
          {
            text: t('simulation.deleteRowDialog.return'),
            color: 'secondary',
            onClick: () => setCancelDialogOpen(false),
          },
          {
            text: t('simulation.deleteRowDialog.cancel'),
            color: 'outline-secondary',
            onClick: () => {
              arrayHelpers.remove(index)
              setCancelDialogOpen(false)
            },
          },
        ]}
      />
      <div>
        <RowSummeryLink
          onClick={() => {
            if (get(history, 'location.pathname').includes('index')) {
              setSelectedIndex(condition.indexNo)
            } else {
              history.replace(`/${pathPrefix}/index/?algoType=${algoType}&index=${condition.indexNo}`)
            }
          }}
        >
          {condition.indexName}
        </RowSummeryLink>
        <ActionButtonWrapper direction={direction}>
          <ActionButton
            icon={faEdit}
            onClick={() => {
              setShowSecurityRowForm(index)
            }}
            direction={direction}
          />
          <ActionButton icon={faTimes} onClick={() => setCancelDialogOpen(true)} direction={direction} color="#dc3545" />
        </ActionButtonWrapper>
      </div>
      <div>
        <FieldPeak direction={direction}>
          {t('simulation.form.weightDistMethod')}: {t(`simulation.form.${condition.weightDistMethod}`)}
        </FieldPeak>
        <FieldPeak direction={direction}>
          {t('simulation.form.followingFundsValue')}: {numbro(condition.followingFundsValue).format('0.0a$')}
        </FieldPeak>
      </div>
    </RowAlert>
  )
}
