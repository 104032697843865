import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

import { i18n } from '../../../i18n'

const Styles = {
  control: styles => {
    return {
      ...styles,
      width: '20vw',
      zIndex: '100',
      '@media only screen and (max-width: 450px)': {
        ...styles['@media only screen and (max-width: 450px)'],
        width: '80vw',
      },
    }
  },
  menu: styles => {
    return {
      ...styles,
      marginTop: 0,
      zIndex: '150',
    }
  },
  option: styles => {
    return {
      ...styles,
      zIndex: '150',
      textAlign: i18n.language === 'hb' ? 'right' : 'left',
    }
  },
  container: styles => {
    return {
      ...styles,
      margin: '15px 0',
    }
  },
}

export const SelectSearch = ({ options, value, onChange, customStyle, isDisabled, getChangedValueWithEvent, onBlur, name }) => {
  const ready = !isEmpty(options)

  return (
    <Select
      name={name}
      isDisabled={!ready || isDisabled}
      isLoading={!ready}
      options={options}
      placeholder=""
      value={ready && find(options, { value })}
      getChangedValueWithEvent={getChangedValueWithEvent}
      onChange={onChange}
      onBlur={onBlur}
      styles={{ ...Styles, ...customStyle }}
    />
  )
}

SelectSearch.defaultProps = {
  options: null,
}

SelectSearch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
}
