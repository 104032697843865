import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

// Create Context Object
const SimContext = createContext(null)

export const SimContextProvider = ({simAlgoProgress, setSimAlgoProgress,  children }) => {
  const [securityList, setSecurityList] = useState(null)

  const contextValue = {
    securityList,
    setSecurityList,
    simAlgoProgress,
    setSimAlgoProgress,
  }

  return (
    <SimContext.Provider value={contextValue}>{children}</SimContext.Provider>
  )
}

SimContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SimContext
