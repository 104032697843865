import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { useDefaultSimName } from './simulationHooks'

import Context from 'context/Context'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import some from 'lodash/some'

import { hasFieldUpdated } from 'components/Simulation/SimulationWrapper'
import { Input } from 'components/Common/input'
import { NameForm, StatusBox } from './styles'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

export const SimNameForm = () => {
  const { t } = useTranslation()
  const [isNameChanged, setIsNameChanged] = useState(false)

  const { selectedSim } = useContext(Context)
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    status,
  } = useFormikContext()

  useEffect(() => {
    setIsNameChanged(
      () => some(selectedSim) && hasFieldUpdated(values, selectedSim, 'name'),
    )
  }, [values, selectedSim])

  useDefaultSimName(values)

  const withSuccessStatus = useCallback(() => {
    const success = get(status, 'success')
    if (isNumber(success)) {
      if (success === 1) {
        return (
          <StatusBox color="green">
            {t('simulation.form.saveSuccess')}{' '}
            <FontAwesomeIcon icon={faCheck} />
          </StatusBox>
        )
      } else {
        // === -1
        return (
          <StatusBox color="red">
            {t('simulation.form.saveFail')} <FontAwesomeIcon icon={faTimes} />
          </StatusBox>
        )
      }
    } else return null
  }, [status, t])

  return (
    <NameForm noValidate onSubmit={handleSubmit}>
      <Input
        name="name"
        type="text"
        onBlur={handleBlur}
        label={null}
        placeholder={values.computedName}
        value={values.name || values.computedName}
        onChange={handleChange}
        width="500px"
      />
      {isNameChanged && (
        <Button type="submit">{t('simulation.form.save')}</Button>
      )}
      {!isNameChanged && withSuccessStatus()}
    </NameForm>
  )
}
