import React from 'react'
import compact from 'lodash/compact'
import dayjs from 'dayjs'
import { TableCell } from 'components/Common/Table/TableCell'
import find from 'lodash/find'
import { Link } from 'react-router-dom'

export const columns = onUserClicked => {
  return compact([
    {
      Header: 'Email',
      accessor: 'email',
      cellFormat: { noFormat: true },
      Cell: row => {
        return (
          <TableCell
            value={row.row.original.email}
            onCellClick={() => {
              onUserClicked(row.row.original)
            }}
            noFormat
          />
        )
      },
    },
    {
      Header: 'Subscription Type',
      accessor: 'subscription',
    },
    {
      id: 'equitySub',
      Header: 'Equity Subscription',
      accessor: row => {
        return find(row.subsPerSecType, { secType: 'stock' })?.sub
      },
    },
    {
      id: 'bondSub',
      Header: 'Bonds Subscription',
      accessor: row => {
        return find(row.subsPerSecType, { secType: 'allBond' })?.sub
      },
    },
    {
      Header: 'Default Language',
      accessor: 'defaultLanguage',
    },
    {
      Header: 'Signed TOS time',
      accessor: 'tosTime',
      Cell: row => {
        const val = row.row.original.tosTime
        return <TableCell value={val && dayjs(val).format('DD/MM/YYYY, h:mm:ss a')} noFormat />
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: row => {
        const val = row.row.original.createdAt
        return <TableCell value={val && dayjs(val).format('DD/MM/YYYY, h:mm:ss a')} noFormat />
      },
    },
    {
      Header: 'last Login Time',
      accessor: 'lastLoginTime',
      Cell: row => {
        const val = row.row.original.lastLoginTime
        return <TableCell value={val && dayjs(val).format('DD/MM/YYYY, h:mm:ss a')} noFormat />
      },
    },
    {
      Header: 'Simulations',
      accessor: 'userId',
      Cell: row => {
        return <Link to={`/admin/users/simulations?userId=${row.row.original._id}`}>Simulations</Link>
      },
    },
  ])
}
