import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
// import { isProduction } from 'utils/appEnv'
import localStorageService from 'utils/localStorageService'
import { FullStoryAPI, log } from 'react-fullstory'
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import { isDevelopment, isStaging, isProduction } from 'utils/appEnv'
class Analytics {
  static init() {
    ReactGA.initialize('UA-158716456-1', {
      // debug: !isProduction,
      titleCase: false,
    })
  }

  static setUser(email) {
    ReactGA.set({ dimension1: email })
  }

  static unsetUser() {
    ReactGA.set({ dimension1: null })
  }

  static pageView(pageDesc) {
    process.nextTick(() => {
      process.nextTick(() => {
        ReactGA.set({
          page: pageDesc,
        })
        ReactGA.pageview(pageDesc, null, document.title)
      })
    })
  }

  static event({ category, action, label, nonInteraction } = { nonInteraction: false }) {
    ReactGA.event({ category, action, label, nonInteraction })
  }
}

const useGoogleAnalytics = () => {
  const location = useLocation()
  const email = localStorageService.getEmail()

  useEffect(() => {
    Analytics.init()
  }, [])

  useEffect(() => {
    Analytics.setUser(email)
  }, [email])

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    Analytics.pageView(currentPath)
  }, [location])
}

const initializeFullStoryAnalytics = () => {
  const email = localStorageService.getEmail(),
    name = localStorageService.getName(),
    _id = localStorageService.getId()

  const initLogMsg = `analytics init with params _id:  ${_id}, email: ${email}, name: ${name}`
  console.log(initLogMsg)
  //logs to fullStory.
  log('info', initLogMsg)

  if (email) {
    FullStoryAPI('identify', _id, {
      displayName: name,
      email: email,
    })
  }
}

class OpenReplayInterface {
  constructor() {
    const projectKey = this.#getProjectKey()
    if (!projectKey) console.log('Missing openReplay project key')

    const ingestPoint = process.env.REACT_APP_OPEN_REPLAY_INGEST_POINT
    if (!ingestPoint) console.log('Missing openReplay ingest point')

    console.log('OpenReplay initializing...', { projectKey, ingestPoint })

    this.tracker = new Tracker({
      projectKey,
      ingestPoint,
      obscureTextEmails: false,
      obscureInputEmails: false,
      verbose: true,
      // __DISABLE_SECURE_MODE: true,
    })
    this.tracker.use(trackerAssist({}))
  }

  #getProjectKey = () => {
    if (isProduction) {
      return process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY_PROD
    } else if (isStaging) {
      return process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY_STAGING
    } else if (isDevelopment) {
      return process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY_DEV
    }

    return null
  }

  async start() {
    if (!this.tracker?.isActive()) {
      console.log('OpenReplay starting...')
      await this.tracker.start()
    }

    if (!this.tracker?.isActive()) {
      console.log('OpenReplay failed to start')
    } else {
      console.log('OpenReplay started.', { isActive: this.tracker.isActive(), sessionID: this.tracker.getSessionID() })
    }
  }

  setUser(email) {
    this.tracker.setUserID(email)
  }

  unsetUser() {
    this.tracker.setUserID(null)
    this.tracker.setMetadata(null)
  }

  setMetadata(metadata) {
    this.tracker.setMetadata(metadata)
  }
}

// singleton
const openReplayInterface = new OpenReplayInterface({})

const useOpenReplay = () => {
  const [email, setEmail] = useState(localStorageService.getEmail())
  const [name, setName] = useState(localStorageService.getName())

  useEffect(() => {
    function handleChangeStorage() {
      const emailFromStorage = localStorageService.getEmail()
      const nameFromStorage = localStorageService.getName()
      if (emailFromStorage !== email || nameFromStorage !== name) {
        setEmail(emailFromStorage)
        setName(nameFromStorage)
        console.log('useOpenReplay: handleChangeStorage', { email, name })
      }
    }

    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [email, name])

  useEffect(() => {
    console.log('useOpenReplay: ', { email, name })
    openReplayInterface.start()

    if (email) {
      openReplayInterface.setUser(email)
    }

    if (name) {
      openReplayInterface.setMetadata({ name })
    }
  }, [email, name])
}

export { Analytics, useGoogleAnalytics, initializeFullStoryAnalytics, useOpenReplay }
