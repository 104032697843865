import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Context from 'context/Context'

import { SecurityGeneralTable } from 'components/Securities/SecurityGeneral/SecurityGeneralTable'

import { isEng } from '../../../i18n'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useSimDataUpdater from 'utils/useSimDataUpdater'

export const SecurityGeneral = () => {
  const { simDataUpdateFlag } = useContext(Context)
  const calcDescForUrl = useCalcDescUrl()

  const { t } = useTranslation()

  const eng = isEng()

  return useSimDataUpdater (
    <>
      <SecurityGeneralTable
        headerText={t('equity.generalSecurity.tableTitle')}
        apiUrl={`security_overview/general?${calcDescForUrl}&lang=${
          eng ? 'en' : 'he'
        }`}
        defaultSelectorForSort="securityName"
        defaultSortOrder={1}
        simDataUpdateFlag={simDataUpdateFlag}
      />
    </>
  )
}
