import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'

import Button from 'react-bootstrap/Button'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

import { Select } from 'components/Common/Select'
import { Loader } from 'components/Common/Loader'
import { AlgoFormLabel, AlgoRowForm } from './styles'

export const AlgoRow = ({
  job,
  updateSchedules,
  getJobBtnStyle,
  time,
  handleAlgoSubmit,
}) => {
  const [effectiveDates, setEffectiveDates] = useState([])

  useEffect(() => {
    if (some(updateSchedules)) {
      const nextOne = updateSchedules.find(e =>
        // today is NOT after effective date (AKA isSameOrBefore)
        !(
          dayjs().startOf('day').isAfter(
            dayjs(e.effectiveDate).startOf('day')
        )
      )
    )
      const newEffectiveDates = updateSchedules
        .map(e => ({
          value: e.effectiveDate,
          label: `${dayjs(e.effectiveDate).format('DD/MM/YYYY')} ${e === nextOne ? '(upcoming)' : ''}`
        }))
      setEffectiveDates(newEffectiveDates)
    } else {
      setEffectiveDates([])
    }
  }, [updateSchedules, setEffectiveDates])

  if (isEmpty(effectiveDates)) return <Loader />

  return (
    <Formik
      initialValues={{ effectiveDate: effectiveDates.find(e => /upcoming/.test(e.label))?.value }}
      onSubmit={({ effectiveDate }) => {
        handleAlgoSubmit({ ...job, effectiveDate }, 'algo')
      }}
    >
      {({ values, handleSubmit, handleChange }) => (
        <AlgoRowForm>
          <AlgoFormLabel>{job.label}:</AlgoFormLabel>
          <Select
            name="effectiveDate"
            value={values.effectiveDate}
            onChange={handleChange}
            getChangedValueWithEvent
            data={effectiveDates}
          />

          <Button variant={getJobBtnStyle} type="submit" onClick={handleSubmit}>
            Run {job.category} {job.updateType} Algo
          </Button>

          <AlgoFormLabel>Latest Run: {time}</AlgoFormLabel>
        </AlgoRowForm>
      )}
    </Formik>
  )
}
