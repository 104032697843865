/* eslint-disable no-bitwise */
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Context, { useAlgoType, useSelectedIndex } from 'context/Context'

import find from 'lodash/find'
import some from 'lodash/some'
import compact from 'lodash/compact'

import axiosApi from 'utils/axiosApi'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useSimDataUpdater from 'utils/useSimDataUpdater'
import useIndexesList from 'utils/useIndexesList'

import { TotalRow } from 'components/Common/TotalRow'
import { IndexSelector } from 'components/Indexes/IndexSelector'
import { HiddenSm } from 'components/Common/hiddensm'
import { IndexOverviewTable } from './IndexOverviewTable'

const doesIndexExist = (indexNum, indexesList) => indexNum && find(indexesList, { value: indexNum })

export const IndexOverview = () => {
  const { t } = useTranslation()
  const { secType, setIndexName } = useContext(Context)
  const [algoType] = useAlgoType()
  const [selectedIndex, setSelectedIndex] = useSelectedIndex()

  const [indexesList] = useIndexesList()
  const [totalRow, setTotalRow] = useState(null)
  const [indexData, setIndexData] = useState({})

  const calcDescForUrl = useCalcDescUrl()

  // unmount
  useEffect(() => {
    return () => {
      setSelectedIndex(undefined, 'replaceIn')
      setIndexName(null)
    }
  }, [setSelectedIndex, setIndexName])

  const indexExist = doesIndexExist(selectedIndex, indexesList)

  useEffect(() => {
    if (some(indexesList)) {
      // if nothing is selected or selected value irrelevant
      if (!selectedIndex || !indexExist) {
        setSelectedIndex(indexesList[0].value, 'replaceIn')
        setIndexName(null)
      }
    }
  }, [setIndexName, selectedIndex, setSelectedIndex, indexesList, indexExist])

  useEffect(() => {
    const loadRows = () => {
      axiosApi
        .get(`index_overview/header/${selectedIndex}?${calcDescForUrl}`)
        .then(response => {
          if (response.data) {
            const { indexNameHeb, indexNameEng } = response.data.indexData
            setIndexName({
              he: indexNameHeb,
              en: indexNameEng,
            })
            const { followingFundsValue, totalInIndexSecurities, projectedChangeValue, indexData } = response.data
            setTotalRow(
              compact([
                // not for external indexes
                !indexData.createdBy && {
                  title: t('equity.totalRow.followingFundsValue'),
                  value: followingFundsValue,
                  format: 'currency',
                },

                {
                  title: t('equity.totalRow.totalSecurities'),
                  value: totalInIndexSecurities,
                  format: 'simple',
                },

                { title: t('equity.totalRow.projectedChangeValue'), value: projectedChangeValue, color: '#B8741A', format: 'currency' },
              ]),
            )
            setIndexData(indexData)
          }
        })
        .catch(() => {
          setTotalRow(null)
        })
    }

    if (indexExist) {
      loadRows()
    }
  }, [selectedIndex, t, algoType, secType, setTotalRow, indexExist, calcDescForUrl, setIndexName])

  const withSelectedIndex = useSimDataUpdater(
    <>
      <HiddenSm>
        <TotalRow data={totalRow} />
      </HiddenSm>

      <IndexOverviewTable
        headerText={t('equity.indexOverview.tableTitle')}
        apiUrl={`index_overview/table/${selectedIndex}?${calcDescForUrl}`}
        defaultSelectorForSort="projectedComponentsRank"
        defaultSortOrder={1}
        selectedIndex={doesIndexExist(selectedIndex, indexesList)}
        indexData={indexData}
      />
    </>,
  )

  return (
    <>
      <IndexSelector
        data={indexesList}
        value={selectedIndex}
        onChange={indexValue => {
          setSelectedIndex(indexValue.value)
        }}
      />

      {indexExist && withSelectedIndex}
    </>
  )
}
