import React, { useContext, useEffect, useState } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import Context, { useAlgoType } from 'context/Context'
import { useSimulationSecurityList, useFieldsList } from '../simulationHooks'
import { hasFieldUpdated } from 'components/Simulation/SimulationWrapper'
import SimContext from 'context/SimulationContext'
import { AlgoTypes, SecTypes } from 'utils/const'

import { useTranslation } from 'react-i18next'
import numbro from 'numbro'
import some from 'lodash/some'
import get from 'lodash/get'
import isNull from 'lodash/isNull'

import { Loader } from 'components/Common/Loader'
import Form from 'react-bootstrap/Form'
import { RowFormik } from './RowFormik'
import { RowSummery } from './RowSummery'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { RowAlert, ActionButtonNoMargin, FormWrapper, FormSummeryWrapper, SimProgressBarWrapper, SimLoadingWrapper } from './styles'

export const SimulationForm = () => {
  const { t } = useTranslation()
  const [showSecurityRowForm, setShowSecurityRowForm] = useState(null)
  const { secType, selectedSim, isAlgoRunning, shouldRunSim, setShouldRunSim, simInitialValuesOverride, setSimInitialValuesOverride } = useContext(
    Context,
  )
  const [algoType] = useAlgoType()
  const [securityList, fetchingSecuritiesList] = useSimulationSecurityList()
  const [fieldList, fetchingIssuersList] = useFieldsList()
  const { handleSubmit, values, setFieldValue } = useFormikContext()
  const { simAlgoProgress } = useContext(SimContext)

  useEffect(() => {
    setShouldRunSim(() => hasFieldUpdated(values, selectedSim, 'conditions'))
  }, [selectedSim, setShouldRunSim, isAlgoRunning, values])

  useEffect(() => {
    if (isAlgoRunning) return

    const isDeprecated = get(selectedSim, 'isDeprecated')
    if (isDeprecated) {
      console.log(`stale results, recalculating`, { isDeprecated })
      handleSubmit()
    }
  }, [selectedSim, isAlgoRunning, handleSubmit])

  useEffect(() => {
    values.conditions.forEach((securityRow, index) => {
      if (!securityRow.indexNo && (!securityRow.securityId || !some(securityRow.fields))) {
        setShowSecurityRowForm(index)
      }
    })
  }, [values, setShowSecurityRowForm])

  useEffect(() => {
    console.log(simInitialValuesOverride)
    if (simInitialValuesOverride?.conditions) {
      setFieldValue('conditions', simInitialValuesOverride.conditions)
    }
    setSimInitialValuesOverride(null)
    setShowSecurityRowForm(0)
  }, [setFieldValue, setSimInitialValuesOverride, simInitialValuesOverride, setShowSecurityRowForm])

  const onRowUpdate = ({ values, index, arrayHelpers }) => {
    if (!!values.indexNo) {
      // ** index mode here **
      setFieldValue(`conditions.${index}`, values)
      return
    }

    // ** security mode here **

    // remove empty fields
    values.fields = values.fields.reduce((fields, field) => {
      if (!isNull(field.value) && field.value !== '') {
        fields.push(field)
      }
      return fields
    }, [])

    // only save if there are fields
    if (some(values.fields)) {
      setFieldValue(`conditions.${index}`, values)
    } else {
      arrayHelpers.remove(index)
    }
  }

  const isBondsComponents = secType === SecTypes.corpBond && algoType === AlgoTypes.components

  const formOrLoader = () => {
    if (isAlgoRunning) {
      const percentageDone = get(simAlgoProgress, 'percentageDone') || 0

      return (
        <SimLoadingWrapper>
          <SimProgressBarWrapper>
            <ProgressBar
              animated
              now={percentageDone}
              label={`${t(`simulation.progress.${get(simAlgoProgress, 'description')}`)} - ${numbro(percentageDone).format('0.00')}%`}
              style={{ height: '2rem' }}
            />
          </SimProgressBarWrapper>
        </SimLoadingWrapper>
      )
    } else if (fetchingSecuritiesList || (isBondsComponents && fetchingIssuersList)) {
      return <Loader />
    } else {
      return (
        <Form noValidate onSubmit={handleSubmit}>
          <FormSummeryWrapper fluid>
            <FieldArray
              name="conditions"
              render={arrayHelpers => (
                <>
                  {some(values.conditions) &&
                    values.conditions.map((condition, index) => (
                      <div key={`RowSummery${index}`} style={{ display: 'flex' }}>
                        <RowFormik
                          condition={condition}
                          conditions={get(values, 'conditions')}
                          securityRowIndex={index}
                          onRowUpdate={values => onRowUpdate({ values, arrayHelpers, index })}
                          onDelete={() => arrayHelpers.remove(index)}
                          showSecurityRowForm={showSecurityRowForm === index}
                          securityList={securityList}
                          fieldList={fieldList}
                          newRow={() => arrayHelpers.push({})}
                          handleSimSubmit={handleSubmit}
                          setShowSecurityRowForm={setShowSecurityRowForm}
                        />
                        <RowSummery
                          arrayHelpers={arrayHelpers}
                          condition={condition}
                          securityList={securityList}
                          index={index}
                          setShowSecurityRowForm={setShowSecurityRowForm}
                          fieldList={fieldList}
                        />
                      </div>
                    ))}

                  <RowAlert variant="info" style={{ display: 'flex', alignItems: 'center' }}>
                    <ActionButtonNoMargin icon={faPlus} onClick={() => arrayHelpers.push({})} />
                  </RowAlert>
                  {shouldRunSim && (
                    <Button size="lg" type="submit">
                      {t('simulation.form.saveAndRun')}
                    </Button>
                  )}
                </>
              )}
            />
          </FormSummeryWrapper>
        </Form>
      )
    }
  }
  return <FormWrapper>{formOrLoader()}</FormWrapper>
}
