/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

import auth from '../../../utils/auth'

export const ProtectedRoute = ({
  component: Component,
  adminOnly,
  ...rest
}) => {
  let path = '/'
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          if ((adminOnly && auth.isAdmin()) || !adminOnly)
            return <Component {...props} />
          else path = '/equity'
        }

        return (
          <Redirect
            to={{
              pathname: path,
              state: {
                // eslint-disable-next-line react/prop-types
                from: props.location,
                referrer: props.location.pathname + props.location.search
              },
            }}
          />
        )
      }}
    />
  )
}
ProtectedRoute.defaultProps = {
  component: null,
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  adminOnly: PropTypes.bool.isRequired,
}
