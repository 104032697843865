import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'

export const ResultsContainer = styled.div`
  margin-top: 50px;
  padding: 20px;
  background: #cde7fe;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
export const CalcDescCardContainer = styled(Card)`
  margin: 10px;
`

export const ActionsContainer = styled(Card.Body)`
  display: flex;
  justify-content: space-evenly;
`

export const TabSelector = styled(Nav.Item)`
  flex-grow: 1;
  text-align: center;
`

export const TabSelectorNav = styled(Nav)`
  display: flex;
`
