import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { Button } from 'components/Common/button'

export const FieldPeak = styled.span`
  ${props => (props.direction === 'ltr' ? 'margin-right: 10px;' : 'margin-left: 10px;')}
  font-style: italic;
  font-size: 0.8rem;
`
export const ActionButton = styled(FontAwesomeIcon)`
  opacity: 0.5;
  cursor: pointer;

  ${props => (props.direction === 'ltr' ? 'margin-left: 13px;' : 'margin-right: 13px;')}

  ${props => (props.color ? `color:${props.color};` : 'color:inherit;')}

  &:hover {
    opacity: 1;
  }
`

export const ActionButtonWrapper = styled.span`
  float: ${props => (props.direction === 'ltr' ? 'right' : 'left')};
`
export const ActionButtonNoMargin = styled(ActionButton)`
  margin-left: unset;
  margin-right: unset;
`

export const RowAlert = styled(Alert)`
  margin-bottom: unset;
  margin-left: ${props => (props.noMargin ? 'unset' : '10px')};
  width: max-content;
  max-width: 600px;
`

export const FormWrapper = styled.div`
  margin-top: 66px;
`
export const FormSummeryWrapper = styled(Container)`
  padding: 10px;
  background-color: #e8e8e8 !important;
  text-align: initial;
  display: flex;
  overflow-x: auto;
`

export const SecurityForm = styled(Form)`
  padding: '10px';
`

export const InlinedFormLabel = styled(Form.Label)`
  display: inline-flex;
  & > small {
    margin: auto 0.5rem;
  }
`

export const InlinedFormGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-bottom: unset;
  & > div {
    flex-grow: 1;
    ${props => (props.direction === 'rtl' ? 'margin-right: 0.5rem' : 'margin-left: 0.5rem')}
  }
`

export const SecurityFormInnerContainer = styled(Container)`
  // overflow-y: auto;
  // overflow-x: hidden;
`

export const NewRowButton = styled(Button)`
  margin-bottom: 16px;
`

export const RowModalFooter = styled(Modal.Footer)`
  flex-wrap: nowrap;
`

export const RowModalHeader = styled(Modal.Header)`
  & > .close {
    margin: -1rem;
  }
`

export const FieldRow = styled(Row)`
  padding-top: 10px;
  border-top: 1px solid lightgray;
  position: relative;
`

export const FieldsWrapper = styled.div`
  padding-top: 8px;
  position: relative;
`

export const ActionRow = styled(FieldRow)`
  padding: 10px;
  margin: unset;
`

export const RowModal = styled(Modal)`
  direction: ltr;
  text-align: left;
`

export const RowModalRTL = styled(Modal)`
  direction: rtl;
  text-align: right;
`

export const RowSummeryLink = styled.strong`
  color: #007bff !important;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export const HiddenWrapper = styled.div`
  display: none;
`

export const SimLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`

export const SimProgressBarWrapper = styled.div`
  width: 90vw;
`
