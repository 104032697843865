import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import useSimDataUpdater from 'utils/useSimDataUpdater'

import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { SecTypes, AlgoTypes } from 'utils/const'
import axiosApi from 'utils/axiosApi'
import { Loader } from 'components/Common/Loader'
import { SelectSearch } from 'components/Common/SelectSearch'
import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'
import { DateElements } from './DateElements'

import { DatePicker } from '../../Common/DatePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

import { SelectorLabel, SelectorLabelCol, SelectorRow, SelectorWrapper } from './styles'
import getFormattedValue from 'utils/getFormattedValue'

const SELECTOR_VALUE_DELIMITER = '||'

export const ProjectedChangesUpcoming = ({ navClearance }) => {
  const history = useHistory()
  const calcDescForUrl = useCalcDescUrl()

  const { selectedUpdateScheduleRecord, setSelectedUpdateScheduleRecord, updateRecordeDate, secType, selectedSim } = useContext(Context)

  const [algoType, setAlgoType] = useAlgoType()
  const [taseEffectiveDate, setTaseEffectiveDate] = useTaseEffectiveDate()
  const { t } = useTranslation()
  const [updatesScheduleData, setUpdatesScheduleData] = useState(null)
  const [selectUpdateScheduleOptions, setSelectUpdateScheduleOptions] = useState(null)
  const [upcomingOnlyMode, setUpcomingOnlyMode] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [extraDataForPicker, setExtraDataForPicker] = useState(null)

  const updateContextWithSelecetedSchedule = useCallback(
    updateScheduleRecord => {
      setAlgoType(updateScheduleRecord.updateType)
      setSelectedUpdateScheduleRecord(updateScheduleRecord)
      updateRecordeDate(updateScheduleRecord.recordDate)
      //for url params
      setTaseEffectiveDate(dayjs(updateScheduleRecord.effectiveDate).toDate())
    },
    // eslint-disable-next-line
    [setAlgoType, setSelectedUpdateScheduleRecord, updateRecordeDate, setTaseEffectiveDate, selectedSim],
  )

  // load the updates schedule for this sec type
  useEffect(() => {
    const loadUpdateSchedule = () => {
      setFetching(true)

      axiosApi
        .get(`update_schedule?secType=${secType}`)
        .then(response => {
          if (isEmpty(response.data)) {
            console.warn('no response data for update_schedule', response)
          } else {
            let resUpdateScheds = sortBy(Object.values(response.data).flat(), 'effectiveDate')

            setUpdatesScheduleData(resUpdateScheds)
          }

          setFetching(false)
        })
        .catch(() => setFetching(false))
    }

    if (secType) {
      loadUpdateSchedule()
    }
  }, [secType, setUpdatesScheduleData, history])

  // setUpcomingOnlyMode
  useEffect(() => {
    if (some(updatesScheduleData)) {
      const localUpcomingOnlyMode = uniqBy(updatesScheduleData, e => e?.updateType).length === updatesScheduleData.length
      setUpcomingOnlyMode(localUpcomingOnlyMode)
    }
  }, [updatesScheduleData, setUpcomingOnlyMode])

  // set algo type according to next update sched (its sorted) unless already chosen (eg page reload)
  useEffect(() => {
    if (updatesScheduleData && !algoType) {
      const nextUpdateRecord = updatesScheduleData[0]
      const nextAlgoType = nextUpdateRecord.updateType
      setAlgoType(nextAlgoType)
    }
  }, [algoType, setAlgoType, updatesScheduleData])

  const taseDateFromSelecetedRecord = useMemo(() => get(selectedUpdateScheduleRecord, 'effectiveDate'), [selectedUpdateScheduleRecord])

  // build the select options if got update sched data from server and a selected algo type
  useEffect(() => {
    if (some(updatesScheduleData) && algoType && upcomingOnlyMode !== null) {
      const selectOptions = updatesScheduleData.reduce((options, record) => {
        const isBondRebalance = secType === SecTypes.allBond && record?.updateType === AlgoTypes.parameters

        if (isBondRebalance) {
          if (!find(options, option => option.value === AlgoTypes.parameters))
            options.push({
              label: t(`equity.projectedChangesUpcoming.parametersDaily`),
              value: AlgoTypes.parameters,
            })
          return options
        }

        if (record) {
          let updateTypeLabel =
            secType === SecTypes.allBond && record.updateType === AlgoTypes.components
              ? t('equity.projectedChangesUpcoming.monthlyComponents')
              : t(`equity.projectedChangesUpcoming.${record?.updateType}`)

          options.push({
            label: updateTypeLabel + (` ${dayjs(record?.effectiveDate).format('DD/MM')}`),
            value: [record?.updateType, record?.effectiveDate].join(SELECTOR_VALUE_DELIMITER),
          })
        }
        return options
      }, [])

      // console.log(`selectOptions`, selectOptions)
      setSelectUpdateScheduleOptions(selectOptions)
      const startOfToday = dayjs().startOf('day')
      const taseDate = taseEffectiveDate || taseDateFromSelecetedRecord

      const selectedSchedByAlgoTypeAndTaseEffDate = updatesScheduleData.find(
        e =>
          (!selectedUpdateScheduleRecord || e.updateType === algoType) &&
          (taseDate ? dayjs(e.effectiveDate).isSame(dayjs(taseDate), 'day') : !startOfToday.isAfter(dayjs(e.effectiveDate))),
      )

      updateContextWithSelecetedSchedule(selectedSchedByAlgoTypeAndTaseEffDate || updatesScheduleData[0])
    }
  }, [
    algoType,
    taseEffectiveDate,
    updatesScheduleData,
    t,
    secType,
    selectedUpdateScheduleRecord,
    setSelectUpdateScheduleOptions,
    updateContextWithSelecetedSchedule,
    taseDateFromSelecetedRecord,
    upcomingOnlyMode,
  ])

  const datesForPicker = useMemo(
    () =>
      some(updatesScheduleData)
        ? updatesScheduleData.reduce((dates, schedule) => {
          if (schedule?.category === SecTypes.allBond && schedule.updateType === AlgoTypes.parameters) {
            dates.push(dayjs(schedule.effectiveDate).toDate())
          }
          return dates
        }, [])
        : [],
    [updatesScheduleData],
  )

  useEffect(() => {
    const fetchProjectetMarketValuePerDate = async () => {
      const res = await axiosApi.get(
        `update_schedule/getProjectetMarketValueByTaseEffectiveDates?dates=${datesForPicker.map(d => d.toISOString()).join('||')}&${calcDescForUrl}`,
      )
      if (some(res.data)) {
        const extraData = datesForPicker.map(taseEffDate => {
          const matchingCalcDesc = find(res.data, calcDesc => dayjs(calcDesc.taseEffectiveDate).isSame(taseEffDate))
          const projectedChangeValue = get(matchingCalcDesc, 'projectedChangeValue')
          return !projectedChangeValue
            ? '-'
            : getFormattedValue({
              value: projectedChangeValue,
              formatOverride: 'currency',
              toShort: true,
            })
        })
        setExtraDataForPicker(extraData)
      }
    }

    if (secType === SecTypes.allBond && algoType === AlgoTypes.parameters && some(datesForPicker) && taseEffectiveDate) {
      fetchProjectetMarketValuePerDate()
    }
  }, [datesForPicker, algoType, secType, calcDescForUrl, taseEffectiveDate, selectedSim])

  const onDateChange = useCallback(
    date => {
      const updateScheduleRecord = updatesScheduleData.find(d => d.updateType === AlgoTypes.parameters && dayjs(d.effectiveDate).isSame(dayjs(date)))

      updateContextWithSelecetedSchedule(updateScheduleRecord)
    },
    [updatesScheduleData, updateContextWithSelecetedSchedule],
  )

  const selectValue = useMemo(() => {
    if (secType === SecTypes.allBond && algoType === AlgoTypes.parameters) return AlgoTypes.parameters

    return [algoType, get(selectedUpdateScheduleRecord, 'effectiveDate')].join('||')
  }, [secType, algoType, selectedUpdateScheduleRecord])

  const onChange = ({ value }) => {
    const [updateType, effectiveDate] = value.split(SELECTOR_VALUE_DELIMITER)

    const updateScheduleRecord = updatesScheduleData.find(d =>
      effectiveDate ? d.updateType === updateType && d.effectiveDate === effectiveDate : d.updateType === updateType,
    )

    updateContextWithSelecetedSchedule(updateScheduleRecord)
  }

  const warningText = useMemo(() => {
    // if secType is allBond, algoType is components and update is after the upcoming one - show warning
    if (
      updatesScheduleData &&
      selectedUpdateScheduleRecord &&
      (secType === SecTypes.stock || (secType === SecTypes.allBond && algoType === AlgoTypes.components)) &&
      taseEffectiveDate
    ) {
      const today = dayjs().startOf('day')
      // updatesScheduleData is sorted by effectiveDate

      const filter = e => {
        if (secType === SecTypes.stock) {
          return dayjs(e.effectiveDate).isSameOrAfter(today)
        } // secType === SecTypes.allBond
        return e.updateType === algoType && dayjs(e.effectiveDate).isSameOrAfter(today)
      }

      const upcomingUpdate = updatesScheduleData.find(filter)

      console.log({ upcomingUpdate, selectedUpdateScheduleRecord, taseEffectiveDate, today })
      if (upcomingUpdate && dayjs(selectedUpdateScheduleRecord.effectiveDate).isAfter(dayjs(upcomingUpdate.effectiveDate))) {
        return t('equity.projectedChangesUpcoming.futureUpdateWarning')
      }
    }
    return null
  }, [updatesScheduleData, selectedUpdateScheduleRecord, secType, algoType, taseEffectiveDate, t])

  let toRender
  if (fetching || !selectUpdateScheduleOptions) {
    toRender = <Loader />
  } else {
    toRender = selectedUpdateScheduleRecord && (
      <SelectorRow navClearance={navClearance}>
        <SelectorLabelCol md={1}>
          {warningText && (
            <TableCellExtraContent
              popoverText={warningText}
              content={<FontAwesomeIcon icon={faExclamationTriangle} />}
              key={warningText}
              placement="bottom"
              color="red"
            />
          )}
          <SelectorLabel>{t('equity.projectedChangesUpcoming.label')}</SelectorLabel>
        </SelectorLabelCol>

        <SelectorWrapper>
          <SelectSearch
            onChange={onChange}
            value={selectValue}
            options={selectUpdateScheduleOptions}
            customStyle={{
              control: styles => {
                return { ...styles, width: '18vw', margin: '0 2px' }
              },
            }}
            isDisabled={some(selectedSim)}
          />
        </SelectorWrapper>

        {selectedUpdateScheduleRecord && secType === SecTypes.allBond && algoType === AlgoTypes.parameters && some(datesForPicker) ? (
          <DatePicker
            dates={datesForPicker}
            extraData={extraDataForPicker}
            onDateChange={onDateChange}
            initialValue={findIndex(datesForPicker, date => dayjs(date).isSame(dayjs(taseEffectiveDate)))}
            disabled={some(selectedSim)}
          />
        ) : <DateElements selectedUpdateScheduleRecord={selectedUpdateScheduleRecord} />}
      </SelectorRow>
    )
  }

  return useSimDataUpdater(toRender)
}
