import React from 'react'

import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

export default function({tradeCodeNo}) {
  const { t } = useTranslation()

  const isDualListed = tradeCodeNo === 3

  if (!isDualListed) return null

  return (
    <TableCellExtraContent
      popoverText={`${t('equity.securityAnalysis.dualListedCompany')}`}
      content={<span className="badge badge-secondary">{t('equity.securityAnalysis.dualListed')}</span>}
      key="duallyListedBadge"
    />
  )
}
