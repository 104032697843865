import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import auth from 'utils/auth'
import axiosApi from 'utils/axiosApi'

export const SubscriptionValidator = () => {
  const [interval, setInterval] = useState(0)
  const history = useHistory()
  useEffect(() => {
    axiosApi
      .post('subscription', { email: localStorage.getItem('email') })
      .then(() => {})
      .catch(() => {
        auth.logout(() => history.replace('/'))
      })
    setTimeout(() => setInterval(interval + 1), process.env.NODE_ENV === 'production' ? 180000: 1_000_000)
  }, [interval, history])

  return null // nothing to render
}
