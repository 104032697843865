import React, { useMemo, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Context, { useAlgoType, useSelectedSecurity } from 'context/Context'
import { SCREEN } from 'utils/const'
import { isEng } from '../../../../i18n'
import getFormattedValue from 'utils/getFormattedValue'
import { sectorSubSectorMap } from 'utils/sectorUtils'

import find from 'lodash/find'
import get from 'lodash/get'
import some from 'lodash/some'

import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FieldPeak, ActionButton, ActionButtonWrapper, RowAlert, RowSummeryLink } from '../styles'
import { ConfirmDialog } from 'components/Common/ConfirmDialog'

export const SecurityRowSummery = ({ arrayHelpers, condition, securityList, index, setShowSecurityRowForm, fieldList }) => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)
  const pathPrefix = SCREEN[secType]
  const [algoType] = useAlgoType()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)

  // eslint-disable-next-line
  const [selectedSec, setSelectedSecurity] = useSelectedSecurity()
  const history = useHistory()
  const securityInfo = useMemo(
    () =>
      // eslint-disable-next-line
      find(securityList, sec => sec.value == get(condition, 'securityId')),
    [securityList, condition],
  )

  const securityName = useMemo(
    () =>
      condition.newSimulatedSecurity
        ? // get from form:
          get(
            find(condition.fields, field => field.name === 'securityName'),
            'value',
          )
        : //get from security list:
          get(securityInfo, `data.${isEng() ? 'nameEnglish' : 'nameHebrew'}`) || null,
    [securityInfo, condition.fields, condition.newSimulatedSecurity],
  )

  return (
    <RowAlert variant="info">
      <ConfirmDialog
        isOpen={cancelDialogOpen}
        text={t('simulation.deleteRowDialog.text')}
        buttons={[
          {
            text: t('simulation.deleteRowDialog.return'),
            color: 'secondary',
            onClick: () => setCancelDialogOpen(false),
          },
          {
            text: t('simulation.deleteRowDialog.cancel'),
            color: 'outline-secondary',
            onClick: () => {
              arrayHelpers.remove(index)
              setCancelDialogOpen(false)
            },
          },
        ]}
      />
      <div>
        <RowSummeryLink
          onClick={() => {
            if (get(history, 'location.pathname').includes('security')) {
              setSelectedSecurity(condition.securityId)
            } else {
              history.replace(`/${pathPrefix}/security/?algoType=${algoType}&security=${condition.securityId}`)
            }
          }}
        >
          {securityName
            ? `${securityName} (${condition.newSimulatedSecurity ? t('simulation.general.simulation') : condition.securityId})`
            : condition.securityId}
        </RowSummeryLink>
        <ActionButtonWrapper direction={isEng() ? 'ltr' : 'rtl'}>
          <ActionButton
            icon={faEdit}
            onClick={() => {
              setShowSecurityRowForm(index)
            }}
            direction={isEng() ? 'ltr' : 'rtl'}
          />
          <ActionButton icon={faTimes} onClick={() => setCancelDialogOpen(true)} direction={isEng() ? 'ltr' : 'rtl'} color="#dc3545" />
        </ActionButtonWrapper>
      </div>
      <div>
        {some(condition.fields) &&
          condition.fields.map((field, index) => {
            const fieldLabel = get(
              // eslint-disable-next-line
              find(fieldList, fieldItem => fieldItem.value == field.name),
              'label',
            )

            let formattedValue
            switch (field.name) {
              case 'issuer':
                // try to replace with a name:
                if (field.value === 9999) {
                  //new issuer
                  formattedValue = t('simulation.form.newIssuer')
                } else {
                  const fieldOpts = find(fieldList, fieldItem => fieldItem.value === field.name)
                  const options = get(fieldOpts, 'options')
                  const issuer = some(options) && options.find(opt => opt.value === field.value)

                  formattedValue = issuer ? issuer.label : field.value
                }
                break
              case 'interestType':
                formattedValue = t(`simulation.form.interestTypes.${field.value}`)
                break
              case 'linkage':
                formattedValue = t(`simulation.form.linkageTypes.${field.value}`)
                break
              case 'sector':
                formattedValue = get(sectorSubSectorMap, `${field.value}.${isEng() ? 'companySectorEnglish' : 'companySectorHebrew'}`)
                break
              case 'subSector':
                const sector = condition.fields.find(f => f.name === 'sector')
                formattedValue = get(
                  sectorSubSectorMap,
                  `${sector.value}.subSectors.${field.value}.${isEng() ? 'companySubsectorEnglish' : 'companySubsectorHebrew'}`,
                )
                break
              case 'firstTradingDate':
              case 'maturityDate':
                formattedValue = getFormattedValue({
                  value: field.value,
                  formatOverride: 'date',
                  t,
                })
                break
              case 'foreignIssuer':
                if (field.value) {
                  return (
                    <FieldPeak direction={isEng() ? 'ltr' : 'rtl'} key={`secRow${index}`}>
                      {fieldLabel}
                    </FieldPeak>
                  )
                } else return null

              default:
                formattedValue = getFormattedValue({
                  value: field.value,
                  name: field.name,
                  t,
                })
            }

            return (
              <FieldPeak direction={isEng() ? 'ltr' : 'rtl'} key={`secRow${index}`}>
                {fieldLabel}: {formattedValue}
              </FieldPeak>
            )
          })}
      </div>
    </RowAlert>
  )
}
