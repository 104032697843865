import React from 'react'
import PropTypes from 'prop-types'

import { TabStyled } from './styles'

export const Tab = ({ title, isActive, changeActiveTab, tabIndex }) => {
  return (
    <TabStyled onClick={() => changeActiveTab(tabIndex)} isActive={isActive}>
      {title}
    </TabStyled>
  )
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
}
