import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
`

export const ShowMetaDataBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f59a23;
  background: rgba(242, 242, 242, 1);
  border: 1px solid rgba(215, 215, 215, 1);
  margin: 5px;
  padding: 5px;
  flex-direction: column;
  max-width: 72px;

  &:hover {
    cursor: pointer;
  }
`

export const ShowMetaDataBtnTxt = styled.div`
  margin-bottom: 15px;
  text-align: center;
`
