import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { AdminMenuButton } from './styles'

export const AdminMenu = () => {
  const { t } = useTranslation()
  const [isOpen, setisOpen] = useState(false)

  return (
    <>
      <Dropdown.Divider />
      <AdminMenuButton onClick={() => setisOpen(!isOpen)}>
        <>
          <FontAwesomeIcon icon={faCog} />
          {t('menu.adminMenu.title')}
        </>
        <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
      </AdminMenuButton>
      {isOpen && (
        <>
          <Dropdown.Item href="/data-managment">
            {t('menu.adminMenu.dataManagement')}
          </Dropdown.Item>
          <Dropdown.Item href="/admin/users">
            {t('menu.adminMenu.users')}
          </Dropdown.Item>
          <Dropdown.Item href="/agenda">
            {t('menu.adminMenu.agenda')}
          </Dropdown.Item>
        </>
      )}
    </>
  )
}
