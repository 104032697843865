import styled from 'styled-components'
import { H2 } from 'styles/Typography'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const TaseBlockContainer = styled.div`
  padding: 20px;
  background: #cde7fe;
  margin-bottom: 50px;
`

export const TaseTitle = styled(H2)`
  text-align: left;
`

export const TaseRow = styled(Row)`
  margin-bottom: 10px;
`

export const TaseFirstCol = styled.div`
  display: flex;
`

export const TaseSecondColText = styled.div`
  text-align: left;
`

export const TaseSecondCol = styled(Col)`
  display: flex;
  align-items: center;
`
export const DataSource = styled.a`
  color: blue;
  margin-right: 10px;
`
