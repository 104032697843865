import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TotalRow } from 'components/Common/TotalRow'
import { Row, ShowMetaDataBtn, ShowMetaDataBtnTxt } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './style.scss'
export const SecurityAnalysisTotalBlock = ({
  totalRow1,
  totalRow2,
  metadataRow,
}) => {
  const [showMetaData, setShowMetaData] = useState(false)
  const { t } = useTranslation()

  const mdRow = metadataRow && showMetaData && (
    <CSSTransition classNames="example" timeout={{ enter: 500, exit: 300 }}>
      <Row>
        <TotalRow
          margin="5px"
          flex="1"
          data={metadataRow}
          color="rgba(222, 222, 222, 1)"
          isMetadataRow={true}
        />
      </Row>
    </CSSTransition>
  )

  return (
    <>
      {totalRow1 && (
        <Row>
          <TotalRow
            margin="5px"
            flex="1"
            data={totalRow1}
            color={
              totalRow1[0].background === 'red'
                ? '#b71c1b'
                : totalRow1[0].background
            }
          />
          <TotalRow margin="5px" flex="4" data={totalRow2} />
          <ShowMetaDataBtn onClick={() => setShowMetaData(!showMetaData)}>
            <ShowMetaDataBtnTxt>
              {t('equity.securityAnalysis.showMetaData')}
            </ShowMetaDataBtnTxt>
            <FontAwesomeIcon icon={faAngleDown} />
          </ShowMetaDataBtn>
        </Row>
      )}

      <div>
        <TransitionGroup>{mdRow}</TransitionGroup>
      </div>
    </>
  )
}

SecurityAnalysisTotalBlock.propTypes = {
  totalRow1: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
      isMetadataRow: PropTypes.bool,
    }),
  ),
  totalRow2: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
      isMetadataRow: PropTypes.bool,
    }),
  ),
  metadataRow: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
      isMetadataRow: PropTypes.bool,
    }),
  ),
}

SecurityAnalysisTotalBlock.defaultProps = {
  totalRow1: null,
  totalRow2: null,
  metadataRow: null,
}
