import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Context from 'context/Context'
import auth from 'utils/auth'
import { SecTypes } from 'utils/const'
import axiosApi from 'utils/axiosApi'

import { EquityBondTables } from 'components/General/EquityBondTables'

export const AllBondsScreen = ({
  // eslint-disable-next-line react/prop-types
  match: {
    // eslint-disable-next-line react/prop-types
    params: { tab },
  },
}) => {
  const { secType, setSecType, setSelectedUpdateScheduleRecord } = useContext(Context)
  const history = useHistory()

  useEffect(() => {
    if (secType !== SecTypes.allBond) {
      setSelectedUpdateScheduleRecord(null)
      setSecType(SecTypes.allBond)
    }
  }, [secType, setSecType, setSelectedUpdateScheduleRecord])

  if (secType !== SecTypes.allBond) return <div>Loading</div>

  if (!auth.isSubscribed(SecTypes.allBond)) {
    // redirect to main page
    axiosApi.post('/users/track', { action: 'load', target: 'locked-location', metadata: { location: history.location, secType } })
    history.replace('/')
  }

  return (
    <>
      <EquityBondTables tab={tab} />
    </>
  )
}
