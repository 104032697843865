import styled from 'styled-components'

export const TableWrapper = styled.div`
  overflow-x: scroll;
`

export const StyledTable = styled.div`
  background-color: white;
  font-size: 0.87rem;
  ${props =>
    `direction: ${props.isEng ? 'ltr' : 'rtl'};
    text-align: ${props.isEng ? 'left' : 'right'};`}

  .globalFilter {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: white;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    min-height: 38px;
    outline: 0 !important;
    box-sizing: border-box;
    width: 20vw;
    padding: 0 10px 0px 10px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
`
export const StyledHead = styled.div``

export const StyledRow = styled.div`
  background-color: ${props => props.rowBackgroundColor || 'white'};

  &:hover {
    color: #212529;
    filter: ${props => (props.rowBackgroundColor ? 'brightness(1.1)' : 'brightness(0.9)')};
  }

  &.dangerTR {
    background: #ffe4e1;
  }

  &.dangerTRAlt {
    background: #ffeded;
  }

  &.successTR {
    background: #e4ffe1;
  }

  &.warningTR {
    background: #fff6e1;

    &.warningTRAlt {
      background: linear-gradient(90deg, #fff6e1 40%, #ffebbc 60%);
    }

    &.warningTRAlt2 {
      background: linear-gradient(90deg, #fff6e1 40%, #ffd4a4 60%);
    }

    &.warningTRAlt.warningTRAlt2 {
      background: linear-gradient(90deg, #fff6e1 30%, #ffd4a4 60%, #ffebbc 90%);
    }
  }

  &.warningTRAlt {
    background: #ffebbc;

    &.warningTRAlt2 {
      background: linear-gradient(90deg, #ffebbc 40%, #ffd4a4 60%);
    }
  }

  &.warningTRAlt2 {
    background: #ffd4a4;
  }
`

export const StyledHeaderRow = styled(StyledRow)`
  background-color: rgb(242, 242, 242);

  &:hover {
    color: rgb(242, 242, 242);
    filter: none;
  }
`

export const StyledCell = styled.div`
  padding: 7px;
  border-top: 2px solid #e8e2e2;
  border-left: 2px solid #eae8e8;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  &.italic {
    font-style: italic;
  }

  &.warningCell {
    background: #fff6e1;
  }

  &.warningCellStrong {
    background: #e6b64a !important;
    color: white;
  }

  &.dangerCell {
    background: #fdcece;
    color: #ad0000;
  }
`

export const StyledHeaderCell = styled(StyledCell)`
  overflow: visible;
  padding: unset;
  display: flex;
  font-weight: 700;
  color: #212529;
  border: 2px solid #d0d0d0;
  border-right: none;
  background-color: rgb(242, 242, 242);

  &.sortingUp {
    -webkit-box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
    -moz-box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
    box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
  }

  &.sortingDown {
    -webkit-box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
    -moz-box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
    box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
  }
`

export const StyledHeaderCellInner = styled.div`
  width: auto;
  height: auto;
  display: flex;
  padding: 10px 7px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`

export const Resizer = styled.div`
  display: inline-block;
  background: #d0d0d0;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  ${props => (props.isEng ? 'right: 0; transform: translateX(50%);' : 'left: 0; transform: translateX(-50%);')}
  z-index: 1;
  ${'' /* prevents from scrolling while dragging on touch devices */}
  touch-action:none;

  &:hover {
    width: 7px;
    background: #797979;
  }

  &.isResizing {
    background: #676767;
  }
`
