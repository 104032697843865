import styled from 'styled-components'
import { H2 } from 'styles/Typography'

export const LookupsBlockContainer = styled.div`
  margin-bottom: 50px;
  padding: 20px;
  background: #cde7fe;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LookupsTitle = styled(H2)`
  text-align: left;
  align-self: flex-start;
`
