/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { Table } from 'components/Common/Table'

import { columns } from './columns'

export const UsersTable = props => {
  return (
    <Table
      columns={columns(props.onUserClicked)}
      {...props}
      virtualized={true}
      withExportButton={true}
    />
  )
}
