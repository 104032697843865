import * as yup from 'yup'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import { WEIGHT_DIST_METHODS } from 'components/Simulation/Form/const'

const uniquePropertyTest = function(value, propertyName, message) {
  if (
    this.parent
      .filter(v => v !== value)
      .some(v => {
        const firstValue = get(value, propertyName)
        const secondValue = get(v, propertyName)
        return !!firstValue && !!secondValue && firstValue === secondValue
      })
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    })
  }

  return true
}

yup.addMethod(yup.object, 'uniqueProperties', function(propertyNames) {
  return this.test('unique', '', function(value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message)
        } catch (error) {
          return error
        }
      })
      .filter(error => error instanceof yup.ValidationError)

    if (!isEmpty(errors)) {
      throw new yup.ValidationError(errors)
    }

    return true
  })
})

yup.addMethod(yup.array, 'atLeastOne', function(propertyName) {
  return this.test('hasValue', 'simulation.form.errors.atLeastOne', fields => {
    if (some(fields) && some(fields.filter(field => !!field[propertyName]))) {
      return true
    }
    return false
  })
})

yup.addMethod(yup.array, 'requireAll', function(propertyName) {
  return this.test(
    'hasAllValues',
    'simulation.form.errors.requireAll', // TODO localize error
    fields => {
      if (some(fields) && some(fields.filter(field => !field[propertyName]))) {
        return false
      }
      return true
    },
  )
})

export const ConditionSchema = yup.object().shape({
  securityId: yup.string().when('indexNo', {
    is: indexNo => !indexNo,
    then: yup.string().required('simulation.form.errors.security'),
    otherwise: yup.string().default(undefined),
  }),
  indexNo: yup.string(),
  newSimulatedSecurity: yup.boolean().when('securityId', {
    is: securityId => !securityId,
    then: yup.boolean().default(undefined),
    otherwise: yup.boolean().default(false),
  }),
  fields: yup.array().when('securityId', {
    is: securityId => !securityId,
    then: yup.array().default(undefined),
    otherwise: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            name: yup.string().required('simulation.form.errors.field'),
            value: yup.string(),
          })
          .uniqueProperties([['name', 'simulation.form.errors.sameValue']]),
      )
      .when('newSimulatedSecurity', {
        is: true,
        then: yup.array().requireAll('value'),
        otherwise: yup.array().atLeastOne('value'),
      })
      .required('simulation.form.errors.changes'),
  }),
  indexName: yup.string().when('indexNo', {
    is: indexNo => indexNo,
    then: yup.string().required('simulation.form.errors.indexName'),
    otherwise: yup.string().default(undefined),
  }),
  weightDistMethod: yup.string().when('indexNo', {
    is: indexNo => indexNo,
    then: yup
      .string()
      .required('simulation.form.errors.weightDistMethod')
      .oneOf(Object.values(WEIGHT_DIST_METHODS)),
    otherwise: yup.string().default(undefined),
  }),
  weightCap: yup.number().when('indexNo', {
    is: indexNo => indexNo,
    then: yup
      .number()
      .required('simulation.form.errors.weightCap')
      .min(0)
      .max(100),
    otherwise: yup.number().default(undefined),
  }),
  followingFundsValue: yup.number().when('indexNo', {
    is: indexNo => indexNo,
    then: yup
      .number()
      .required('simulation.form.errors.followingFundsValue')
      .min(0),
    otherwise: yup.number().default(undefined),
  }),
  securityIds: yup.array().when('indexNo', {
    is: indexNo => indexNo,
    then: yup
      .array()
      .of(yup.string())
      .required('simulation.form.errors.securityIds')
      .min(2, 'simulation.form.errors.securityIds')
      .test('is-unique', 'simulation.form.errors.sameSecurity', function(value) {
        const set = new Set(value)
        return set.size === value.length
      }),
    otherwise: yup.array().default(undefined),
  }),
})

export const SimulationSchema = yup.object().shape({
  computedName: yup.string().required('simulation.form.errors.name'),
  name: yup.string('simulation.form.errors.name'),
  conditions: yup.array().of(ConditionSchema.uniqueProperties([['securityId', 'cannot set same sec twice']])),
})
