import { useContext, useState } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import some from 'lodash/some'
import last from 'lodash/last'
import isEqual from 'lodash/isEqual'

import useInterval from 'use-interval'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import axiosApi from 'utils/axiosApi'

import Context from 'context/Context'

export const useAlgoProgress = ({ calcDesc, startTime, selectedSim }) => {
  const [selectedCalcDesc, setSelectedCalcDesc] = useState(null)
  const [currentProgressStep, setCurrentProgressStep] = useState(null)
  const calcDescForUrl = useCalcDescUrl()
  const { isAlgoRunning, setIsAlgoRunning } = useContext(Context)

  useInterval(async () => {
    const fetchCalcDesc = async () => {
      try {
        console.log('polling algo progress')

        let calcDesc
        if (get(selectedSim, '_id')) {
          const res = await axiosApi.get(`simulation/getCalcDescBySimId/${selectedSim._id}`)
          calcDesc = get(res, 'data.calcDesc')
        } else if (selectedCalcDesc) {
          calcDesc = await axiosApi.get(`index_calc_results/getAlgoCalcDescById?${calcDesc._id}`)
        } else {
          calcDesc = await axiosApi.get(`index_calc_results/getLatestCalcDesc?${calcDescForUrl}`)
        }

        if (get(calcDesc, '_id')) {
          console.log('got calcDesc current progress', { calcDesc })
          setSelectedCalcDesc(calcDesc)

          if ((!selectedSim || calcDesc.updatedAt >= selectedSim.updatedAt) && calcDesc.status === 'success') {
            setIsAlgoRunning(false)
          }

          const progress = get(calcDesc, 'progress')

          if (some(progress)) {
            const lastProgress = last(progress)

            // ensure it's not residue progress:
            if ((!startTime || dayjs(startTime).isBefore(lastProgress.createdAt)) && !isEqual(lastProgress, currentProgressStep)) {
              setCurrentProgressStep(lastProgress)
            }
          }
        }
      } catch (error) {
        console.log('error trying to fetch calcDesc', { error })
      }
    }

    // if (get(currentProgressStep, 'percentageDone') === 100) return

    if (isAlgoRunning) {
      if (!selectedCalcDesc && calcDesc) {
        setSelectedCalcDesc(calcDesc)
      }
      await fetchCalcDesc()
    }
  }, 1000)

  // for development - log new progresses
  // React.useEffect(() => {
  //   console.log(currentProgressStep)
  // }, [currentProgressStep])

  return { currentProgressStep, selectedCalcDesc }
}
