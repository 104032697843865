import React from 'react'
import { ReformContainer, ReformTitle, ReformMainTitle, ReformMainSubTitle, ReformSubTitle, ReformBody, ContactButton, ButtonLink, ReformMainText, ClosingText } from './styles'

export default function ReformMsg() {

    const emailSubject = "קבלת מידע נוסף אודות שינויים צפויים במדדי האג״ח"
    return (
        <ReformContainer>
            <ReformTitle>מתכוננים לרפורמה במדדי האג׳׳ח!</ReformTitle>
            <ReformSubTitle>בקרוב תבוצע רפורמה במדדי האג״ח שעורכת הבורסה, על בסיס <a href="https://maya.tase.co.il/reports/details/1446128" target="_blank" rel="noopener noreferrer">הטיוטה שפורסמה ב-27.4.2022</a></ReformSubTitle>
            <ReformBody>
                <ReformMainTitle>עיקרי השינויים הצפויים:</ReformMainTitle>
                <ReformMainText><ReformMainSubTitle>מועדי עדכון</ReformMainSubTitle> - עדכון הרכבי המאגרים והמדדים יבוצע על בסיס חודשי במקום השיטה הנוכחית של עדכון חצי שנתי לאג״ח קונצרני או עדכון יומי לאג״ח ממשלתי</ReformMainText>
                <ReformMainText><ReformMainSubTitle>פרמטרים לחישוב משקל</ReformMainSubTitle> - התאמת המשקלים היומית תבוטל וייעשה שימוש בענ״ק (ערך נקוב קובע) חודשי ובפקטור משקל, בדומה לשיטה במדדי המניות</ReformMainText>
                <ReformMainText><ReformMainSubTitle>תקרת משקל למנפיק</ReformMainSubTitle> - שימוש ב״פקטור מנפיק״ לפי מקסימום משקל של 20% לכל סדרות המנפיק, במקום המגבלות הנוכחיות של כמות סדרות למנפיק</ReformMainText>
                <ReformMainText><ReformMainSubTitle>גריעה והוספה מדורגת למדדים</ReformMainSubTitle> - כניסה ויציאה מהמדדים באופן מדורג על פני 3 עדכונים באמצעות פקטור משקל מדורג (הכפלה של הפקטור המחושב בפקטור נוסף). <span style={{ color: '#D9001B' }}>תמיכה בשינוי זה דורשת לחשב עבור כל עדכון גם עדכונים עתידיים וכן שמירת אינדיקציות פקטור מדורג מעדכונים קודמים.</span></ReformMainText>
                <ClosingText>אנחנו על זה.</ClosingText>
                <ContactButton>
                    <ButtonLink href={`mailto:support@smartbull.co.il?subject=${emailSubject}`} target="_blank" rel="noopener noreferrer">לחץ ליצירת קשר וקבלת מידע נוסף</ButtonLink>
                </ContactButton>
            </ReformBody>
        </ReformContainer >
    )
}
