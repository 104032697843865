import React, { useState, useRef } from 'react'
import { LinkWrapper } from './styles'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import { isHeb } from '../../../i18n'

export const LinkToTase = ({ href, popoverText }) => {
  const taseLinkRef = useRef(null)
  const [isPopoverOpen, setPopoverIsOpen] = useState(false)

  return (
    <>
      <LinkWrapper
        ref={taseLinkRef}
        onMouseLeave={() => setPopoverIsOpen(false)}
        onMouseEnter={() => setPopoverIsOpen(true)}
        href={href}
        target="_blank"
      >
        <img src={'/market-data-icon.png'} alt={'tase link'} />
      </LinkWrapper>

      <Overlay
        target={taseLinkRef.current}
        show={isPopoverOpen}
        placement={isHeb() ? 'left' : 'right'}
      >
        {props => (
          <Tooltip id="cellValuePopover" {...props}>
            {popoverText}
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
