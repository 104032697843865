import React, { useCallback, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import Context, { useAlgoType } from 'context/Context'
import { SecTypes, AlgoTypes } from 'utils/const'

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

import { RowFormActionRow } from './RowFormActionRow'
import { RowFormFields } from './RowFormFields/RowFormFields'
import { SecurityForm, SecurityFormInnerContainer } from './styles'
import { SIMULATION_TYPES } from './const'

export const RowForm = ({
  setCancelDialogOpen,
  condition,
  securityRows,
  securityRowIndex,
  securityList,
  setSubmitCallback,
  setEnableValidation,
  newRow,
  handleSimSubmit,
  setShowSecurityRowForm,
  fieldLabels,
  filteredInitialValues,
}) => {
  const { secType } = useContext(Context)
  const [algoType] = useAlgoType()

  const { t } = useTranslation()
  const { handleSubmit, setErrors, setTouched, values, setValues } = useFormikContext()

  const setInitialValues = selectedTab => {
    let initialValues
    switch (selectedTab) {
      case SIMULATION_TYPES.newSecurity:
        // clean up form assign random unique security ID for new secs
        initialValues = filteredInitialValues(SIMULATION_TYPES.newSecurity)
        setValues(initialValues)
        break
      case SIMULATION_TYPES.newIndex:
        initialValues = filteredInitialValues(SIMULATION_TYPES.newIndex)
        setValues(initialValues)
        break
      case SIMULATION_TYPES.existingSecurity:
      default:
        // clean up form
        initialValues = filteredInitialValues(SIMULATION_TYPES.existingSecurity)
        setValues(initialValues)
        break
    }
  }

  const handleSimTypeChange = selectedTab => {
    let currentType
    if (values.securityId) {
      currentType = values.newSimulatedSecurity ? SIMULATION_TYPES.newSecurity : SIMULATION_TYPES.existingSecurity
    } else if (values.indexNo) {
      currentType = SIMULATION_TYPES.newIndex
    }

    if (currentType === selectedTab) return

    // clean errors, and other state
    setInitialValues(selectedTab)
    setErrors({})
    setTouched({})
    setEnableValidation(false)
  }

  const isBondsRebalance = secType === SecTypes.corpBond && algoType === AlgoTypes.parameters

  const getDefaultTabKey = useCallback(condition => {
    if (!condition) return SIMULATION_TYPES.existingSecurity

    if (condition.indexNo) return SIMULATION_TYPES.newIndex

    return condition.newSimulatedSecurity ? SIMULATION_TYPES.newSecurity : SIMULATION_TYPES.existingSecurity
  }, [])

  return (
    <SecurityForm noValidate onSubmit={handleSubmit} autoComplete="off">
      <SecurityFormInnerContainer>
        <Tab.Container defaultActiveKey={() => getDefaultTabKey(condition)} id="simFormTabs">
          {!isBondsRebalance && (
            <Nav
              variant="tabs"
              onSelect={handleSimTypeChange}
              style={{
                margin: '8px 0px',
              }}
            >
              <Nav.Item>
                <Nav.Link eventKey={SIMULATION_TYPES.existingSecurity}>{t('simulation.form.fields.existingSecurity')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={SIMULATION_TYPES.newSecurity}>{t('simulation.form.fields.newSecurity')}</Nav.Link>
              </Nav.Item>
              <Nav.Item />
              <Nav.Link eventKey={SIMULATION_TYPES.newIndex}>{t('simulation.form.fields.newIndex')}</Nav.Link>
            </Nav>
          )}

          <RowFormFields securityList={securityList} fieldLabels={fieldLabels} />
        </Tab.Container>
      </SecurityFormInnerContainer>
      <RowFormActionRow
        setCancelDialogOpen={setCancelDialogOpen}
        handleSubmit={handleSubmit}
        newRow={newRow}
        securityRowIndex={securityRowIndex}
        setSubmitCallback={setSubmitCallback}
        setEnableValidation={setEnableValidation}
        securityRows={securityRows}
        handleSimSubmit={handleSimSubmit}
        setShowSecurityRowForm={setShowSecurityRowForm}
      />
    </SecurityForm>
  )
}
