import styled from 'styled-components'

export const ContactSupportContainer = styled.div`
  position: relative;
  margin-inline-start: 12px;
`

// Dialog
export const ContactSupportDialog = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 12px;
  background-color: #d7d7d7;
  z-index: 999;
  cursor: auto;

  @media (min-width: 768px) {
    position: absolute;
    top: 50px;
    width: 300px;
  }

  @media (min-width: 992px) {
    right: 30px;
  }
`

export const DialogTitle = styled.h1`
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  display: flex;
  justify-content: start;
`

export const DialogBody = styled.main`
  margin-top: 12px;
  min-height: 150px;
`

export const DialogTextArea = styled.textarea`
  width: 100%;
  height: 120px;
  resize: none;
  color: #000;
`

export const DialogErrorMsg = styled.p`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`

export const SubmitButton = styled.button`
  background-color: #1e98d7;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font-weight: bold;
  width: 75px;
  margin-top: 12px;
`

// Success Alert

export const SuccessAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 12px;
  background-color: #dff0d8;
  z-index: 999;
  cursor: auto;

  @media (min-width: 768px) {
    position: absolute;
    top: 50px;
    width: 300px;
  }

  @media (min-width: 992px) {
    right: 30px;
  }
`

export const SuccessAlertTitle = styled.h1`
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
  margin: 0;
`

export const SuccessAlertSubText = styled.p`
  font-size: 1rem;
  color: #747171ab;
  font-weight: 500;
  margin: 0;
`

// Icon
export const ContactSupportIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ContactSupportIcon = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: black;
  cursor: pointer;
`
