import styled from 'styled-components'

export const ReformContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: right;
`

export const ReformTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
`

export const ReformSubTitle = styled.h3`
  font-size: 20px;
  line-height: 39px;
`

export const ReformBody = styled.div`
  margin-top: 20px;
`

export const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 155, 213, 1);
  width: 300px;
  height: 50px;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
  margin-top: 15px;
  position: absolute;
  right: 20px;
`
export const ButtonLink = styled.a`
  color: #fff !important;
  font-weight: 700;
  margin: 0;
  font-size: 20px;
  text-decoration: none !important;
`
export const ReformMainText = styled.li`
  margin: 0;
  font-size: 20px;
  line-height: 39px;
`

export const ReformMainTitle = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 39px;
  text-decoration: underline;
`

export const ReformMainSubTitle = styled.span`
  text-decoration: underline;
  font-weight: 700;
`

export const ClosingText = styled.p`
  margintop: 20px;
  fontweight: 500;
  font-size: 20px;
  line-height: 39px;
`
