import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'context/AuthContext'

import { loginScenariosDictionary } from '../loginScenariosDictionary'

export const DeniedTermsOfUse = () => {
  const { setLoginScenario } = useContext(AuthContext)

  const { t } = useTranslation()
  return (
    <div>
      <p>{t('deniedTermsOfUse.description')}</p>
      <button onClick={() => setLoginScenario(loginScenariosDictionary.needToAcceptTermsOfUse)}>{t('deniedTermsOfUse.button')}</button>
    </div>
  )
}
