import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import localStorageService from 'utils/localStorageService'
import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'
import some from 'lodash/some'
import compact from 'lodash/compact'
import { SCREEN, AlgoTypes, SecTypes } from 'utils/const'
import auth from 'utils/auth'

import { EquityTabs } from 'components/General/EquityTabs'
import { SubscriptionValidator } from 'components/General/SubscriptionValidator'
import { ProjectedChangesUpcoming } from 'components/General/UpdateSchedule'
import { IndexOverview } from 'components/Indexes/IndexOverview'
import { SecurityAnalysis } from 'components/Securities/SecurityAnalysis'
import { SecurityGeneral } from 'components/Securities/SecurityGeneral'
import { AllTransactions } from 'components/AllTransactions'
import { ChangesSummary } from 'components/ChangesSummary'
import { BondsRebalanceChangesSummary } from 'components/BondsRebalanceChangesSummary'
import { Announcement } from 'components/General/Announcement'
import { DataWrapper } from './styles'

export const EquityBondTables = ({ tab }) => {
  const history = useHistory()
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const { secType, selectedSim, isAlgoRunning, shouldRunSim } = useContext(Context)

  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const isBondsRebalance =
    (secType === SecTypes.corpBond || secType === SecTypes.govBond || secType === SecTypes.allBond) && algoType === AlgoTypes.parameters

  const tabs = useMemo(
    () =>
      compact([
        {
          route: 'summary',
          title: 'equity.tabs.ChangesSummary',
          Render: () => (isBondsRebalance ? <BondsRebalanceChangesSummary /> : <ChangesSummary />),
        },
        {
          route: 'transactions',
          title: 'equity.tabs.AllTransactions',
          Render: () => <AllTransactions />,
        },
        {
          route: 'index',
          title: 'equity.tabs.indexOverview',
          Render: () => <IndexOverview />,
        },
        {
          route: 'security',
          title: 'equity.tabs.securityAnalysis',
          Render: () => <SecurityAnalysis />,
        },
        auth.isAdmin() && {
          route: 'general',
          title: 'equity.tabs.securityGeneral',
          Render: () => <SecurityGeneral />,
        },
      ]),
    [isBondsRebalance],
  )

  const [activeTab, setActiveTab] = useState(-1)

  useEffect(() => {
    const getCurrentTab = () => {
      const index = tabs.findIndex(item => item.route === tab)

      return index === -1 ? 0 : index
    }

    const current = getCurrentTab()

    setActiveTab(current)
  }, [tab, tabs, activeTab, history, secType])

  const changeActiveTab = useCallback(
    tabIndex => {
      const { route } = tabs[tabIndex]
      history.push({
        pathname: `/${SCREEN[secType]}/${route}`,
        search: history.location.search,
      })
      setActiveTab(tabIndex)
    },
    [secType, history, tabs],
  )

  // useEffect(() => {
  //   let showAnnouncement = localStorageService.getShowAnnouncement()
  //   if (secType === SecTypes.stock && dayjs(taseEffectiveDate).isAfter('2023-11-01')) {
  //     // already viewing the announcement's link
  //     showAnnouncement = false
  //   }
  //   setShowAnnouncement(showAnnouncement)
  // }, [secType, taseEffectiveDate])

  const onAnnouncementClose = () => {
    setShowAnnouncement(false)
    localStorageService.setShowAnnouncement(false)
  }

  return (
    <>
      <SubscriptionValidator />
      {showAnnouncement && <Announcement onClose={onAnnouncementClose} linkTo={'/stocks/all?algoType=components&taseEffectiveDate=02-11-2023'} />}
      <ProjectedChangesUpcoming navClearance={!some(selectedSim) && !showAnnouncement} />
      {taseEffectiveDate && (
        <DataWrapper dirty={some(selectedSim) && (shouldRunSim || isAlgoRunning)}>
          <EquityTabs changeActiveTab={tabIndex => changeActiveTab(tabIndex)} activeTab={activeTab} tabs={tabs} />
          {tabs[activeTab] && tabs[activeTab].Render()}
        </DataWrapper>
      )}
    </>
  )
}

EquityBondTables.defaultProps = {
  tab: 'all',
}

EquityBondTables.propTypes = {
  tab: PropTypes.string,
}
