import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { Card } from 'components/Common/Card'
import axiosApi from 'utils/axiosApi'

import { CreateNewPasswordInvalidToken } from './CreateNewPasswordInvalidToken'
import { CreateNewPasswordForm } from './CreateNewPasswordForm'

const Errors = {
  INVALID_PASSWORD: 'resetPassword.form.password.error.notValid',
  MISSING_PARAMS: 'resetPassword.form.errors.noParams',
}

export const ResetPassword = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [resetPasswordSuccessfully, setResetPasswordSuccessfully] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false)

  useEffect(() => {
    validateResetToken()
  }, [])

  const getServerError = err => {
    const prefix = 'resetPassword.form.errors.server.'
    return prefix + err
  }

  const validatePassword = password => {
    return /\d/.test(password) && /[a-zA-Z]/.test(password)
  }

  const displayError = errOrResponse => {
    const errorMsg =
      get(errOrResponse, 'response.data.msg') || // Handle error object
      get(errOrResponse, 'msg') || // Handles direct response object
      'UNKNOWN'
    const fullErrPath = getServerError(errorMsg)
    setError(fullErrPath)
  }

  const getTokenAndUserId = () => {
    const query = new URLSearchParams(search)
    const token = query.get('token')
    const userId = query.get('userId')
    return { token, userId }
  }

  const handleApiRequest = async (url, data, onSuccess) => {
    try {
      const res = await axiosApi.post(url, data)
      const { success } = res.data

      if (success) {
        onSuccess()
      } else {
        displayError(res.data)
      }
    } catch (error) {
      displayError(error)
    }
  }

  const validateResetToken = async () => {
    const { token, userId } = getTokenAndUserId()

    if (!token || !userId) {
      setError(Errors.MISSING_PARAMS)
      return
    }

    handleApiRequest('reset_password/validate-reset-token', { token, userId }, () => {
      setError(null)
      setIsTokenValid(true)
    })
  }

  const resetPassword = async ({ password }) => {
    if (!validatePassword(password)) {
      setError(Errors.INVALID_PASSWORD)
      return
    }

    const { token, userId } = getTokenAndUserId()

    handleApiRequest('reset_password', { token, userId, password }, () => {
      setError(null)
      setResetPasswordSuccessfully(true)
    })
  }

  const onSetError = newErrorTxt => {
    setError(newErrorTxt)
  }

  return (
    <div data-testid="ResetPassword-screen">
      <Card>
        {!isTokenValid && <CreateNewPasswordInvalidToken t={t} error={error} />}
        {isTokenValid && (
          <CreateNewPasswordForm
            t={t}
            resetPassword={resetPassword}
            error={error}
            onSetError={onSetError}
            resetPasswordSuccessfully={resetPasswordSuccessfully}
          />
        )}
      </Card>
    </div>
  )
}
