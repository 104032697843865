import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import some from 'lodash/some'
import sumBy from 'lodash/sumBy'
import get from 'lodash/get'
import numbro from 'numbro'

import Context, { useAlgoType, useSelectedIndex, useExternalIndexes, useTaseEffectiveDate } from 'context/Context'
import { Analytics } from 'services/analytics'
import axiosApi from 'utils/axiosApi'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import { isHeb } from '../../i18n'
import useIndexesList from 'utils/useIndexesList'

import { SCREEN } from 'utils/const'

import { initialValues } from './utils'
import { ExternalIndexModal } from 'components/ExternalIndexes/ExternalIndexModal'
import { Loader } from 'components/Common/Loader'
import { StyledNavDropdown, StyledBadge } from 'components/General/navbar/styles'
import { WeekSpan } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export const ExternalIndexes = () => {
  const { t } = useTranslation()
  const calcDescForUrl = useCalcDescUrl()
  const history = useHistory()
  const [algoType] = useAlgoType()
  // eslint-disable-next-line no-unused-vars
  const [selectedIndex, setSelectedIndex] = useSelectedIndex()
  const [fetchingExternalIndexesList, setFetchingExternalIndexesList] = useState(false)
  const [selectedExtIdx, setSelectedExtIdx] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [latestUpdatedIndex, setLatestUpdatedIndex] = useState(null)
  const [displayExternalIndexes, setDisplayExternalIndexes] = useExternalIndexes()
  // eslint-disable-next-line no-unused-vars
  const [indexesList, setIndexesList, setIndexesData] = useIndexesList()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const { secType, externalIndexes, setExternalIndexes, externalIndexesUpdateFlag, setExternalIndexesUpdateFlag } = useContext(Context)

  //fetch external indexes list
  useEffect(() => {
    const fetchExternalIndexesList = async () => {
      setFetchingExternalIndexesList(true)
      try {
        const { data } = await axiosApi.get(`/users/external-indexes?${calcDescForUrl}`)
        setExternalIndexes(data)
      } catch (error) {
        console.log(error)
      } finally {
        setFetchingExternalIndexesList(false)
      }
    }

    const hasRequiredData = taseEffectiveDate && algoType && secType
    hasRequiredData && fetchExternalIndexesList()
  }, [calcDescForUrl, externalIndexesUpdateFlag, setExternalIndexes, taseEffectiveDate, algoType, secType])

  const pathPrefix = SCREEN[secType]

  useEffect(() => {
    if (latestUpdatedIndex) {
      Analytics.event('External Indexes', 'Submit', latestUpdatedIndex)

      const indexNo = latestUpdatedIndex?.indexNo
      if (indexNo) {
        if (get(history, 'location.pathname').includes('index')) {
          setSelectedIndex(indexNo)
          setIndexesData(null)
          setIndexesList(null)
        } else {
          history.replace(`/${pathPrefix}/index/?algoType=${algoType}&index=${indexNo}`)
        }
      }
      setLatestUpdatedIndex(null)
    }
  }, [latestUpdatedIndex, history, pathPrefix, algoType, setSelectedIndex, setIndexesData, setIndexesList])

  const renderExternalIndexes = () => {
    return fetchingExternalIndexesList ? (
      <>
        <StyledNavDropdown.Divider />
        <StyledNavDropdown.Item>
          <Loader />
        </StyledNavDropdown.Item>
      </>
    ) : some(externalIndexes) ? (
      <>
        {externalIndexes.map(externalIndex => (
          <StyledNavDropdown.Item key={externalIndex._id} onClick={() => setSelectedExtIdx(externalIndex)}>
            {externalIndex.name}{' '}
            <WeekSpan>
              {t('externalIndexes.lang.totalChanges')} : {numbro(sumBy(externalIndex.securities, 'projectedChange')).format('0,0$')}
            </WeekSpan>
          </StyledNavDropdown.Item>
        ))}
      </>
    ) : null
  }

  const addNewIndex = () => {
    setSelectedExtIdx(initialValues)

    // push event to GA
    Analytics.event({
      category: 'External Indexes',
      action: 'Add external index clicked (manual)',
    })
  }

  const onHideModal = () => {
    setSelectedExtIdx(null)
    setDeleteDialogOpen(false)
  }

  const handleDisplayExternalIndexes = () => {
    setDisplayExternalIndexes(!displayExternalIndexes)
    setExternalIndexesUpdateFlag(!externalIndexesUpdateFlag)
  }

  return (
    <StyledNavDropdown
      title={
        <>
          {t('externalIndexes.lang.externalIndexes')}
          <StyledBadge>{t('lang.new')}</StyledBadge>
        </>
      }
      textalign={isHeb() ? 'right' : 'left'}
    >
      <ExternalIndexModal
        selectedExtIdx={selectedExtIdx}
        onHide={onHideModal}
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setLatestUpdatedIndex={setLatestUpdatedIndex}
      />
      <StyledNavDropdown.Item onClick={() => addNewIndex()}>{t('externalIndexes.form.addIndex')}</StyledNavDropdown.Item>
      <>
        <StyledNavDropdown.Divider />
        {renderExternalIndexes()}

        <StyledNavDropdown.Item onClick={handleDisplayExternalIndexes}>
          {t('externalIndexes.lang.displayExternalIndexes')}
          <span style={{ marginRight: '0.5rem' }}>{displayExternalIndexes && <FontAwesomeIcon icon={faCheck} />}</span>
        </StyledNavDropdown.Item>
      </>
    </StyledNavDropdown>
  )
}
