import React from 'react'
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import map from "lodash/map"

import { DateElement } from "./DateElement"

import { ProjectedChangesCol } from "./styles"

import { getRecordDateTooltipText } from './utils'

const DateElementConfigs = {
    freeFloatRecordDate: {
        withTooltip: false,
    },
    recordDate: {
        withTooltip: true,
        buildTooltipText: getRecordDateTooltipText,
    },
    announcementDate: {
        withTooltip: false,
    },
    effectiveDate: {
        withTooltip: false,
    },
}

export const DateElements = ({ selectedUpdateScheduleRecord }) => {
    const { t } = useTranslation()

    return map(DateElementConfigs, (config, dateType) => {
        if (selectedUpdateScheduleRecord[dateType]) {
            const date = dayjs(selectedUpdateScheduleRecord[dateType])

            const withTooltip = !!config?.withTooltip
            const tooltipText = withTooltip ? config.buildTooltipText(t, date) : null

            return (
                <ProjectedChangesCol key={dateType} md={2}>
                    <DateElement text={t(`equity.projectedChangesUpcoming.${dateType}`)} date={date} withTooltip={withTooltip} tooltipText={tooltipText} />
                </ProjectedChangesCol>
            )
        }
        return null
    })
}