import React from 'react'
/* eslint-disable react/jsx-props-no-spreading */

import { Table } from 'components/Common/Table'

import { columns } from './columns'

export const SimulationsTable = props => {
  return (
    <Table
      columns={columns(props.onShareClicked)}
      {...props}
      virtualized={true}
      withExportButton={true}
      defaultSortBy={{ id: 'updatedAt', desc: true }}
    />
  )
}
