import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { isHeb } from '../../../i18n'

import some from 'lodash/some'

import { RowModal, RowModalHeader, RowModalRTL } from './styles'

export const RowModalForm = ({ showSecurityRowForm, setShowSecurityRowForm, setCancelDialogOpen, setSubmitCallback, onDelete, children }) => {
  const { t } = useTranslation()
  const ModalWithLocalization = isHeb() ? RowModalRTL : RowModal

  const { handleSubmit, validateForm, resetForm, values } = useFormikContext()

  return (
    <ModalWithLocalization
      show={showSecurityRowForm}
      onHide={async () => {
        let isEmpty = true

        if (values.indexNo && some(values.securityIds)) {
          isEmpty = false
        } else if (values.securityId && some(values.fields)) {
          isEmpty = false
        }

        if (isEmpty) {
          resetForm()
          onDelete()
          setShowSecurityRowForm(null)
          return
        }

        const errors = await validateForm()
        if (some(errors)) {
          setCancelDialogOpen(true)
        } else {
          setSubmitCallback(() => () => setShowSecurityRowForm(null))
          handleSubmit()
        }
      }}
    >
      <RowModalHeader closeButton>{t('simulation.form.changeValuesForm')}</RowModalHeader>
      {children}
    </ModalWithLocalization>
  )
}
