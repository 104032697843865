import React from 'react'

import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { CellImage } from 'components/Common/Table/TableCellExtraContent/styles'

export default function(row) {
  const { t } = useTranslation()
  const secId = row.row.original.securityId || row.row.original.securityNo

  return (
    <TableCellExtraContent
      href={`https://www.tase.co.il/he/market_data/security/${secId}/major_data`}
      popoverText={`${row.value} - ${t('equity.table.marketData')}`}
      content={<CellImage src={'/market-data-icon.png'} alt={'tase link'} />}
      key="linktotase"
    />
  )
}
