import React from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

import { FormGroupStyled, Label, StyledError } from './styles'

export const Select = ({
  label,
  data,
  value,
  onChange,
  getChangedValueWithEvent,
  name,
  fullWidth,
  onBlur,
  isInvalid,
  errorText,
}) => {
  return (
    <FormGroupStyled fullWidth={fullWidth}>
      {label && <Label>{label}</Label>}
      <Form.Control
        isInvalid={isInvalid}
        onBlur={onBlur}
        value={value}
        name={name}
        onChange={e => {
          if (getChangedValueWithEvent) {
            onChange(e)
          } else {
            onChange(e.target.value)
          }
        }}
        as="select"
        style={{ display: '-webkit-inline-box' }}
      >
        {data.map(item => (
          <option
            value={item.value}
            key={item.value}
            disabled={item.disabled ? true : null}
          >
            {item.label}
          </option>
        ))}
      </Form.Control>
      {errorText && isInvalid && <StyledError type="invalid">{errorText}</StyledError>}
    </FormGroupStyled>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  getChangedValueWithEvent: PropTypes.bool,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
}

Select.defaultProps = {
  label: null,
  value: '',
  onChange: null,
  getChangedValueWithEvent: false,
  name: null,
  fullWidth: false,
}
