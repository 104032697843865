import { FieldArray, useFormikContext } from 'formik'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import get from 'lodash/get'
import getFormattedValue from 'utils/getFormattedValue'
import { getSubSectorSelectOptions } from 'utils/sectorUtils'
import { isEng, isHeb } from '../../../../i18n'

import find from 'lodash/find'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'

import { Input } from 'components/Common/input'
import { Alert, Col, Form } from 'react-bootstrap'

import { SelectSearch } from 'components/Common/SelectSearch'
import { FieldsWrapper, HiddenWrapper, InlinedFormGroup, InlinedFormLabel } from '../styles'
import { useErrorProps } from './RowFormFields'

export const RowFormSecurityFields = ({ securityList, fieldLabels }) => {
  const { t } = useTranslation()
  const direction = isHeb() ? 'rtl' : 'ltr'
  const { values, handleChange, errors, handleBlur, setFieldValue } = useFormikContext()

  const { getErrorProps } = useErrorProps()

  const getTextForOldValue = value => {
    if (value)
      return `
        ${t('simulation.general.from')}
            ${value}
         ${t('simulation.general.to')}:
      `
    else return null
  }

  const securityInfo = useMemo(
    () =>
      // eslint-disable-next-line
      find(securityList, sec => sec.value === get(values, 'securityId')),
    [securityList, values],
  )

  const FieldInput = React.useCallback(
    ({ index, fieldRow, field }) => {
      switch (field.type) {
        case 'select':
          const sector = values.fields.find(f => f.name === 'sector')
          let options = field.value === 'subSector' ? getSubSectorSelectOptions(sector.value, isEng()) : field.options

          if (!field.sorted) {
            options = sortBy(options, opt => opt.label)
          }

          return (
            (field.value !== 'subSector' || !!sector) && (
              <SelectSearch
                name={`fields.${index}.value`}
                getChangedValueWithEvent
                options={options}
                value={fieldRow.value}
                onChange={val => {
                  setFieldValue(`fields.${index}.value`, val.value)
                }}
                onBlur={handleBlur}
                {...getErrorProps(`fields.${index}.value`)}
                customStyle={{
                  control: styles => {
                    return {
                      ...styles,
                      width: 'auto',
                    }
                  },
                }}
              />
            )
          )
        case 'boolean':
          return (
            <Form.Check
              type="switch"
              id={`field${index}switch`}
              label={''}
              checked={values.fields[index].value}
              onChange={e => setFieldValue(`fields.${index}.value`, e.target.checked)}
            />
          )
        case 'date':
          return (
            <Input
              name={`fields.${index}.value`}
              type={'date'}
              min={field.min}
              max={field.max}
              onBlur={handleBlur}
              value={fieldRow.value}
              onChange={handleChange}
              {...getErrorProps(`fields.${index}.value`)}
            />
          )
        case 'text':
        default:
          return (
            <Input
              name={`fields.${index}.value`}
              type={field.type}
              onBlur={handleBlur}
              placeholder={t('simulation.general.Amount')}
              value={fieldRow.value}
              onChange={handleChange}
              {...getErrorProps(`fields.${index}.value`)}
            />
          )
      }
    },
    [handleBlur, getErrorProps, handleChange, setFieldValue, t, values.fields],
  )

  return (
    <span>
      {!values.newSimulatedSecurity && some(securityList) && (
        <InlinedFormGroup controlId="securityId" direction={direction}>
          <Form.Label>{t('simulation.form.security')}</Form.Label>
          <SelectSearch
            name={'securityId'}
            getChangedValueWithEvent
            options={securityList}
            value={values.securityId}
            onChange={securityIdValue => {
              setFieldValue('securityId', securityIdValue.value)
            }}
            onBlur={handleBlur}
            {...getErrorProps(`securityId`)}
            customStyle={{
              control: styles => {
                return {
                  ...styles,
                  width: 'auto',
                }
              },
            }}
          />
        </InlinedFormGroup>
      )}

      {errors.fields && <Alert variant="warning">{t(errors.fields)}</Alert>}

      {(values.newSimulatedSecurity || !!get(values, 'securityId')) && (
        <FieldsWrapper>
          <FieldArray
            name="fields"
            render={arrayHelpers =>
              some(values.fields) &&
              values.fields.map((fieldRow, index) => {
                const field = find(fieldLabels, label => label.value === fieldRow.name)
                return (
                  <InlinedFormGroup controlId={`fields.${index}.value`} direction={direction} key={`secRow${index}`}>
                    <Col sm={values.newSimulatedSecurity ? 6 : 8} style={{ padding: 'unset' }}>
                      <HiddenWrapper>
                        <Input
                          name={`fields.${index}.name`}
                          value={fieldRow.name}
                          getChangedValueWithEvent
                          onChange={handleChange}
                          onBlur={handleBlur}
                          {...getErrorProps(`fields.${index}.name`)}
                          type={'text'}
                          readOnly
                        />
                      </HiddenWrapper>
                      <InlinedFormLabel>
                        {get(field, 'label')}
                        {some(securityInfo) && !values.newSimulatedSecurity && get(securityInfo, `data.${fieldRow.name}`) && (
                          <Form.Text className="text-muted">
                            {getTextForOldValue(
                              getFormattedValue({
                                value: get(securityInfo, `data.${fieldRow.name}`),
                                name: fieldRow.name,
                                t,
                              }),
                            )}
                          </Form.Text>
                        )}
                      </InlinedFormLabel>
                    </Col>
                    <Col sm={values.newSimulatedSecurity ? 6 : 4}>{FieldInput({ index, fieldRow, field })}</Col>
                  </InlinedFormGroup>
                )
              })
            }
          />
        </FieldsWrapper>
      )}
    </span>
  )
}
