import styled from 'styled-components'

export const Container = styled.div`
  width: 250px;
  @media (min-width: 395px) {
    width: 300px;
  }
`

export const TextsWrapper = styled.div`
  padding: 12px;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`

export const SubText = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #cac4c4;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.marginTop || '2.5rem'};
  gap: 0.5rem;
  width: 100%;
`

export const ErrorMsg = styled.div`
  text-align: center;
  margin: 0;
  color: #dc3545;
`