import styled from 'styled-components'
import Nav from 'react-bootstrap/Nav'

export const TabNav = styled(Nav)`
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

export const TabNavLink = styled(Nav.Link)`
  border: 1px solid #007bff;
  ${props =>
    props.dir === 'ltr'
      ? props.eventKey === 'projectedIndexesTab'
        ? `
      border-top-left-radius: 4px!important;
      border-bottom-left-radius: 4px!important;
      border-top-right-radius: 0px!important;
      border-bottom-right-radius: 0px!important;
          `
        : `
      border: 1px solid #007bff!important;
      border-radius: 0px!important;
      border-top-right-radius: 4px!important;
      border-bottom-right-radius: 4px!important;
          `
      : props.eventKey === 'projectedIndexesTab'
      ? `
      border-top-right-radius: 4px!important;
      border-bottom-right-radius: 4px!important;
      border-top-left-radius: 0px!important;
      border-bottom-left-radius: 0px!important;
          `
      : `
      border: 1px solid #007bff!important;
      border-radius: 0px!important;
      border-top-left-radius: 4px!important;
      border-bottom-left-radius: 4px!important;
          `}
`
