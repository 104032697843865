import React from 'react'
import { Formik } from 'formik'

import axiosApi from 'utils/axiosApi'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Button from 'react-bootstrap/Button'
import { Input } from 'components/Common/input'
import Form from 'react-bootstrap/Form'
import { LookupsBlockContainer, LookupsTitle, WatchDogRowForm } from './styles'

export const DataManagementWatchDogBlock = ({ allJobs, update }) => {
  const today = dayjs().startOf('day')

  const inputDateFormat = 'YYYY-MM-DD'
  return (
    <LookupsBlockContainer>
      <LookupsTitle>WatchDog</LookupsTitle>

      <Formik
        initialValues={{
          morningTest: true,
          eveningTest: true,
          effectiveDate: today.format(inputDateFormat),
        }}
        onSubmit={async values => {
          await axiosApi.post('jobs/', {
            definition: 'WATCHDOG',
            data: values,
          })
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <WatchDogRowForm>
            <Input
              name="effectiveDate"
              type="date"
              label={null}
              max={today.format(inputDateFormat)}
              value={values.effectiveDate}
              onChange={handleChange}
            />
            <Form.Check name="morningTest" type="switch" id="morningTest" label="Morning Test" checked={values.morningTest} onChange={handleChange} />

            <Form.Check type="switch" name="eveningTest" id="eveningTest" label="Evening Test" checked={values.eveningTest} onChange={handleChange} />
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Run WatchDog
            </Button>
          </WatchDogRowForm>
        )}
      </Formik>
    </LookupsBlockContainer>
  )
}

DataManagementWatchDogBlock.propTypes = {
  allJobs: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
}
