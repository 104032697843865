import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ContactSupportDialog,
  DialogBody,
  DialogErrorMsg,
  DialogFooter,
  DialogTextArea,
  DialogTitle,
  SubmitButton,
} from 'components/General/ContactSupport/styles'
import { Loader } from 'components/Common/Loader'

const Dialog = ({ isLoading, error, message, handleTextChange, onSubmit, style }) => {
  const { t } = useTranslation()

  return (
    <ContactSupportDialog style={style}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <header>
            <DialogTitle>{t('contactSupport.dialog.title')}</DialogTitle>
          </header>
          <DialogBody>
            <DialogTextArea placeholder={t('contactSupport.dialog.placeholder')} value={message} onChange={handleTextChange} />
            {error && <DialogErrorMsg>{error}</DialogErrorMsg>}
          </DialogBody>
          <DialogFooter>
            <SubmitButton onClick={onSubmit}>{t('contactSupport.dialog.submit')}</SubmitButton>
          </DialogFooter>
        </>
      )}
    </ContactSupportDialog>
  )
}

export { Dialog }
