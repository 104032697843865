import styled from 'styled-components'
import { Button } from 'components/Common/button'
import PhoneInput from 'react-phone-number-input'

export const LoginButton = styled(Button)`
  margin: 1rem 0;
  margin-top: ${props => props.margin};
`

export const Separator = styled.hr`
  border: 1px solid #c9c9c9;
`

export const FormDescription = styled.p`
  max-width: 250px;
  display: inline-block;
  color: #dd3d4d;
  width: 100%;
  margin-bottom: 20px;
`

export const ServerError = styled.span`
  max-width: fit-content;
  display: inline-block;
  color: #dd3d4d;
  height: 1.5rem;
  width: 100%;
`

export const ServerErrorWrapper = styled.div`
  text-align: center;
`

export const ResetPasswordButton = styled.div`
  cursor: pointer;
  color: #337ab7;
  &:hover {
    text-decoration: underline;
  }
`

const PhoneInputWrapper = styled(PhoneInput)`
  direction: ltr;
  text-align: center;
  border: 0.5px solid;
  border-radius: 3px;
  height: 40px;
  padding: 0 8px 0 16px;
  input {
    border: none;
    height: 100%;
    margin: 0 0 0 8px;
    padding: 0 8px;
  }
`
export const LoginWrapper = styled.div`
  text-align: center;
  direction: rtl;
  ${styled.form} {
    max-width: 250px;
  }
`

export { PhoneInputWrapper as PhoneInput }
