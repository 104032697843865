import PropTypes from 'prop-types'

export const userProps = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  subscription: PropTypes.string.isRequired,
})

export const TOSProps = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
})
