import React, { useContext, useEffect, useState } from 'react'
import { useDefaultSimValues, useFieldsList } from './simulationHooks'
import { useAlgoProgress } from 'utils/useAlgoProgress'
import { useHistory } from 'react-router-dom'
import { Analytics } from 'services/analytics'
import { useTranslation } from 'react-i18next'
import axiosApi from 'utils/axiosApi'
import { Formik } from 'formik'
import dayjs from 'dayjs'

import Context, { useAlgoType, useTaseEffectiveDate, useSelectedSecurity } from 'context/Context'
import { SimContextProvider } from 'context/SimulationContext'

import { SCREEN } from 'utils/const'
import { sectorSubSectorMap } from 'utils/sectorUtils'
import { SimulationSchema } from './SimulationSchema'
import { isEng } from '../../i18n'
import getFormattedValue from 'utils/getFormattedValue'

import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import get from 'lodash/get'
import map from 'lodash/map'
import some from 'lodash/some'
import join from 'lodash/join'
import find from 'lodash/find'

import { SimulationForm } from 'components/Simulation/Form'
import { SimulationStatusBanner } from 'components/Simulation/SimulationStatusBanner'

export const hasFieldUpdated = (values, initialValues, field = 'conditions') => {
  //destructuring syntax is for mutating!
  const initialField = get(initialValues, field)
  const currentField = get(values, field)

  return !isEqual(initialField, currentField)
}

const SimFormik = ({ children }) => {
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const history = useHistory()
  // eslint-disable-next-line
  const [selectedSec, setSelectedSecurity] = useSelectedSecurity()
  const [fieldList] = useFieldsList()
  const { t } = useTranslation()
  const [simAlgoProgress, setSimAlgoProgress] = useState(null)

  const { selectedSim, setSelectedSim, secType, setIsAlgoRunning } = useContext(Context)
  const { currentProgressStep } = useAlgoProgress({
    startTime: selectedSim.updatedAt,
    selectedSim,
  })

  const pathPrefix = SCREEN[secType]

  useEffect(() => {
    if (currentProgressStep) {
      const { percentageDone, createdAt } = currentProgressStep
      // make sure it's a new progress update
      if (dayjs(selectedSim.updatedAt).isBefore(createdAt)) {
        if (!isEqual(simAlgoProgress, currentProgressStep)) {
          // got a new progress, first update
          setSimAlgoProgress(currentProgressStep)

          // then check if last one, if so, update view
          if (percentageDone === 100) {
            console.log('simulation is ready for render!')

            setSelectedSim(selectedSim => ({
              ...selectedSim,
              isFirstRun: false,
            }))

            const newSec = selectedSim.securities.find(sec => sec.newSimulatedSecurity)
            if (newSec) {
              if (get(history, 'location.pathname').includes('security')) {
                setSelectedSecurity(newSec.securityId)
              } else {
                history.replace(`/${pathPrefix}/security/?algoType=${algoType}&security=${newSec.securityId}`)
              }
            }
          }
        }
      }
    }
  }, [
    algoType,
    history,
    pathPrefix,
    selectedSim.securities,
    selectedSim.updatedAt,
    setSelectedSecurity,
    setSelectedSim,
    simAlgoProgress,
    currentProgressStep,
  ])

  const sendSimGAEvent = (simulation, isNew) => {
    let action
    if (isNew) {
      action = 'Simulation Created'
    } else {
      if (hasFieldUpdated(simulation, selectedSim, 'securities')) {
        action = 'Simulation Updated'
      } else {
        action = 'Simulation Recalculated'
      }
    }

    const securitiesSummery = map(simulation.securities, sec => {
      let fieldsSummery = []
      for (const field of sec.fields) {
        let formattedValue

        switch (field.name) {
          case 'issuer':
            // try to replace with a name:
            if (field.value === 9999) {
              //new issuer
              formattedValue = t('simulation.form.newIssuer')
            } else {
              const fieldOpts = find(fieldList, fieldItem => fieldItem.value === field.name)
              const options = get(fieldOpts, 'options')
              const issuer = some(options) && options.find(opt => opt.value === field.value)

              formattedValue = issuer ? issuer.label : field.value
            }
            break
          case 'interestType':
            formattedValue = t(`simulation.form.interestTypes.${field.value}`)
            break
          case 'linkage':
            formattedValue = t(`simulation.form.linkageTypes.${field.value}`)
            break
          case 'sector':
            formattedValue = get(sectorSubSectorMap, `${field.value}.${isEng() ? 'companySectorEnglish' : 'companySectorHebrew'}`)
            break
          case 'subSector':
            const sector = sec.fields.find(f => f.name === 'sector')
            formattedValue = get(
              sectorSubSectorMap,
              `${sector.value}.subSectors.${field.value}.${isEng() ? 'companySubsectorEnglish' : 'companySubsectorHebrew'}`,
            )
            break
          case 'firstTradingDate':
            formattedValue = getFormattedValue({
              value: field.value,
              formatOverride: 'date',
              t,
            })
            break
          case 'foreignIssuer':
            formattedValue = field.value ? true : null
            break
          default:
            formattedValue = getFormattedValue({
              value: field.value,
              name: field.name,
              t,
            })
        }
        if (formattedValue) {
          fieldsSummery.push(`${field.name}: ${formattedValue}`)
        }
      }
      return `{${sec.newSimulatedSecurity ? 'new simulated sec' : `security #${sec.securityId}`} : [${join(fieldsSummery, ', ')}]}`
    })

    const label = `[${join(securitiesSummery, ', ')}]`

    Analytics.event({
      category: 'Simulations',
      action,
      label,
    })
  }

  const applySimulation = async (simulation, actions) => {
    console.log('applying simulation with params', simulation)
    try {
      let res
      const shouldRecalc = hasFieldUpdated(simulation, selectedSim, 'conditions') || get(selectedSim, 'isDeprecated')
      const isNew = !(selectedSim && selectedSim._id)

      if (simulation.name === simulation.computedName) {
        delete simulation.name
      }

      // separate conditions to securities and indexes
      // believe me, this was the only way to do it without rewriting the whole fucking system, which i tried actually.
      const { conditions } = simulation
      simulation.securities = []
      simulation.indexes = []

      for (const condition of conditions) {
        if (!!condition?.indexNo) {
          simulation.indexes.push(condition)
        } else {
          simulation.securities.push(condition)
        }
      }

      if (!isNew) {
        res = await axiosApi.patch(`/simulation/${selectedSim._id}`, {
          ...simulation,
          shouldRecalc,
        })
      } else {
        res = await axiosApi.post('/simulation', {
          simulation: merge(cloneDeep(simulation), {
            secType,
            algoType,
            taseEffectiveDate,
          }),
        })
      }

      const data = get(res, 'data')
      if (data) {
        // re-merge securities and indexes back together for the form to work.
        data.conditions = [...data.securities, ...data.indexes]
        setSelectedSim({ ...data, isFirstRun: isNew })
        setSimAlgoProgress(null)
        setIsAlgoRunning(shouldRecalc || isNew)

        actions && actions.setStatus({ success: 1 })
      }

      // send a GA event
      sendSimGAEvent(simulation, isNew)
    } catch (error) {
      console.log(error)
      if (actions) {
        const errorMsg = get(error, 'error.response.data.errors.msg.0', error.message)
        actions.setFieldError('general', errorMsg || 'Something went wrong..')
        actions.setStatus({ success: -1 })
      }
    }
  }

  const newSimValues = useDefaultSimValues()

  const initialValues = selectedSim && selectedSim !== true ? selectedSim : newSimValues

  return (
    <SimContextProvider simAlgoProgress={simAlgoProgress} setSimAlgoProgress={setSimAlgoProgress}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={applySimulation} validationSchema={SimulationSchema} initialStatus={{}}>
        <>{children}</>
      </Formik>
    </SimContextProvider>
  )
}

export const SimulationWrapper = ({ children }) => {
  const { selectedSim, isAlgoRunning, shouldRunSim, setSimDataUpdateFlag } = useContext(Context)

  useEffect(() => {
    if (selectedSim && !isAlgoRunning && !selectedSim.isFirstRun && !shouldRunSim) {
      console.log('updating Data based on sim res')
      setSimDataUpdateFlag(current => !current)

      // update on unmount too
      return function cleanup() {
        setSimDataUpdateFlag(current => !current)
      }
    }
  }, [selectedSim, isAlgoRunning, shouldRunSim, setSimDataUpdateFlag])

  const renderForm = () => {
    return (
      <SimFormik selectedSim={selectedSim}>
        {children}
        <SimulationForm />
        <SimulationStatusBanner />
      </SimFormik>
    )
  }

  return selectedSim ? renderForm() : <>{children}</>
}
