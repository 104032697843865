import React, { useEffect } from 'react'

import { useSelectedTab, useSelectedCalcDescId, usePrevRuns } from 'context/Context'

import { JobSelector } from 'components/Admin/JobSelector'
import { AlgoManagementTable } from 'components/Admin/AlgoManagementTable'
import { CalcResults } from 'components/Admin/RebalanceResults/CalcResults'
import PrevRunsTable from 'components/Admin/PrevRunsTable'

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { H1 } from 'styles/Typography'
import { TabSelector, PageContainer } from './styles'
import { AlgoTypes, SecTypes } from 'utils/const'

export const DataManagment = () => {
  const [selectedTab, setSelectedTab] = useSelectedTab()
  const [selectedCalcDescId] = useSelectedCalcDescId()
  const [prevRuns] = usePrevRuns()

  // mount
  useEffect(() => {
    setSelectedTab(selectedTab => selectedTab || 'JobSelector')
  }, [setSelectedTab])

  // unmount
  useEffect(() => {
    return () => {
      setSelectedTab(undefined, 'replaceIn')
    }
  }, [setSelectedTab])

  console.log('prevRuns', prevRuns)

  return (
    <PageContainer>
      <H1>Data Management</H1>

      {prevRuns ? (
        <PrevRunsTable />
      ) : selectedCalcDescId ? (
        <CalcResults calcDescId={selectedCalcDescId} />
      ) : (
        <Tab.Container id="DataManagmentTabs" activeKey={selectedTab ? selectedTab : 'JobSelector'} onSelect={tab => setSelectedTab(tab)}>
          <Nav variant="tabs">
            <TabSelector>
              <Nav.Link eventKey="JobSelector">TASE and Lookups</Nav.Link>
            </TabSelector>
            <TabSelector>
              <Nav.Link eventKey="EquityMgmt">Equity Algo</Nav.Link>
            </TabSelector>
            <TabSelector>
              <Nav.Link eventKey="AllBondsCompMgmt">All-Bond Components Algo</Nav.Link>
            </TabSelector>
            <TabSelector>
              <Nav.Link eventKey="AllBondsParamMgmt">All-Bond Paramaters Algo</Nav.Link>
            </TabSelector>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="JobSelector">
              <JobSelector />
            </Tab.Pane>
            <Tab.Pane eventKey="EquityMgmt">
              <AlgoManagementTable secType={SecTypes.stock} />
            </Tab.Pane>
            <Tab.Pane eventKey="AllBondsCompMgmt">
              <AlgoManagementTable secType={SecTypes.allBond} algoType={AlgoTypes.components} />
            </Tab.Pane>
            <Tab.Pane eventKey="AllBondsParamMgmt">
              <AlgoManagementTable secType={SecTypes.allBond} algoType={AlgoTypes.parameters} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </PageContainer>
  )
}
