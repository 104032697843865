import React, { useContext } from 'react'

import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { NavLink as Link } from 'react-router-dom'

import axiosApi from 'utils/axiosApi'
import { AuthContext } from 'context/AuthContext'

import { Input } from 'components/Common/input'
import { LoginSchema } from './loginSchema'
import { LoginButton, ResetPasswordButton, Separator } from '../styles'

import { ERROR_RESPONSES } from 'errors/login/passwordLogin'

export const PasswordLogin = ({ handleSuccessfulLoginResponse }) => {
  const { t } = useTranslation()
  const { setLoginResponse, loginResponse, email } = useContext(AuthContext)

  const onSubmit = async (values, { setErrors }) => {
    try {
      const { password } = values
      const response = await axiosApi.post('/checkPasswordAndLogin', { email, password })
      if (response instanceof Error) {
        throw response
      }
      setLoginResponse(response)
      console.log(loginResponse)
      handleSuccessfulLoginResponse(response)
    } catch (error) {
      if (error.message === 'Request failed with status code 504') {
        setErrors({ password: t('generalErrors.noResponse') })
        return
      }
      const errorName = error?.response?.data?.errors?.msg?.[0]?.param
      switch (errorName) {
        case ERROR_RESPONSES.WRONG_PASSWORD:
          setErrors({ password: t('passwordLogin.form.password.errors.wrongPassword') })
          break
        case ERROR_RESPONSES.BLOCKED_USER:
          setErrors({ password: t('passwordLogin.form.password.errors.blockedUser') })
          break
        case ERROR_RESPONSES.BLOCKED_SUBSCRIPTION:
          setErrors({ password: t('passwordLogin.form.password.errors.blockedSubscription') })
          break
        default:
          setErrors({ password: t('generalErrors.unknownError') })
          break
      }
    }
  }

  return (
    <Formik initialValues={{ password: '' }} onSubmit={onSubmit} validationSchema={LoginSchema} validateOnChange={false} validateOnBlur={false}>
      {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Input
              name="password"
              type="password"
              label={null}
              onBlur={handleBlur}
              placeholder={t('passwordLogin.form.password.label')}
              isValid={false}
              isInvalid={!!errors.password && touched.password}
              value={values.password}
              errorText={t(errors.password)}
              onChange={handleChange}
              style={{ direction: 'ltr', textAlign: 'center' }}
            />
            <LoginButton
              type="submit"
              icon={faLock}
              margin={errors.email ? '1rem' : '0.8rem'}
            >
              {t('passwordLogin.form.submit')}
            </LoginButton>
            <ResetPasswordButton>
              <Link to={'/forgotPassword'}>{t('passwordLogin.forgotPasswordLink')}</Link>
            </ResetPasswordButton>
            <Separator />
          </Form>
        )
      }}
    </Formik>
  )
}
