import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { i18n } from '../../../i18n'

export const MenuLanguageSwitcher = () => {
  const changeLanguage = lng => i18n.changeLanguage(lng)
  return (
    <>
      <Dropdown.Item onClick={() => changeLanguage('en')}>
        English
        {i18n.language === 'en' && <FontAwesomeIcon icon={faCheckCircle} />}
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('hb')}>
        עברית
        {i18n.language === 'hb' && <FontAwesomeIcon icon={faCheckCircle} />}
      </Dropdown.Item>
    </>
  )
}
