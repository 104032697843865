import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Input } from 'components/Common/input'
import { Button } from 'components/Common/button'
import { Select } from 'components/Common/Select'

import { RegisterSchema } from './registerSchema'

import { ServerError, RegisterFormWrapper, Title, CustomCol } from './styles'

export const RegisterUserForm = ({ onRegister }) => {
  const { t } = useTranslation()

  const subscriptionsTypes = [
    { value: 'trial', label: t('trial') },
    { value: 'active', label: t('active') },
    { value: 'blocked', label: t('blocked') },
  ]
  const defaultLangs = [
    { value: 'en', label: 'English' },
    { value: 'hb', label: 'Hebrew' },
  ]

  return (
    <RegisterFormWrapper>
      <Title>{t('userManagment.newUser')}</Title>
      <Formik
        initialValues={{
          email: '',
          password: '',
          name: '',
          subscription: 'active',
          stockSubscription: 'active',
          allBondSubscription: 'active',
          defaultLanguage: 'hb',
        }}
        onSubmit={onRegister}
        validationSchema={RegisterSchema}
      >
        {({ handleSubmit, handleChange, values, isValid, errors, touched, handleBlur }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <CustomCol>
                  <Input
                    wrappermarginbottom="0"
                    name="name"
                    type="text"
                    label={t('userForm.name.label')}
                    placeholder={t('userForm.name.label')}
                    onBlur={handleBlur}
                    isInvalid={!!errors.name && touched.name}
                    value={values.name}
                    errorText={t(errors.name)}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Input
                    wrappermarginbottom="0"
                    name="email"
                    type="email"
                    label={t('userForm.email.label')}
                    placeholder={t('userForm.email.label')}
                    onBlur={handleBlur}
                    isInvalid={!!errors.email && touched.email}
                    value={values.email}
                    errorText={t(errors.email)}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Input
                    wrappermarginbottom="0"
                    name="password"
                    type="password"
                    onBlur={handleBlur}
                    label={t('userForm.password.label')}
                    placeholder={t('userForm.password.label')}
                    isInvalid={!!errors.password && touched.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Select
                    name="stockSubscription"
                    label={t('userForm.stockSubscription')}
                    getChangedValueWithEvent
                    value={values.stockSubscription}
                    data={subscriptionsTypes}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Select
                    name="allBondSubscription"
                    label={t('userForm.allBondSubscription')}
                    getChangedValueWithEvent
                    value={values.allBondSubscription}
                    data={subscriptionsTypes}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Select
                    name="subscription"
                    label={t('userForm.subscriptionType')}
                    getChangedValueWithEvent
                    value={values.subscription}
                    data={subscriptionsTypes}
                    onChange={handleChange}
                  />
                </CustomCol>
                <CustomCol>
                  <Select
                    name="defaultLanguage"
                    value={values.defaultLanguage}
                    label={t('userForm.defaultLanguage')}
                    getChangedValueWithEvent
                    data={defaultLangs}
                    onChange={handleChange}
                  />
                </CustomCol>
              </Row>
              <div
                style={{
                  height: (!!errors.email && touched.email) || (!!errors.name && touched.name) ? 9 : 32,
                }}
              />
              <Row className="justify-content-md-end">
                <Col md="2">
                  <Button
                    style={{
                      marginTop: 16,
                    }}
                    type="submit"
                    disabled={!isValid}
                  >
                    {t('create_user')}
                  </Button>
                </Col>
              </Row>
              {errors.general && <ServerError>{errors.general}</ServerError>}
            </Form>
          )
        }}
      </Formik>
    </RegisterFormWrapper>
  )
}

RegisterUserForm.propTypes = {
  onRegister: PropTypes.func.isRequired,
}
