import React from 'react'
import compact from 'lodash/compact'
import get from 'lodash/get'
import getFormattedValue from 'utils/getFormattedValue'
import { AlgoActionsCell } from './AlgoActionsCell'
import { SecTypes, AlgoTypes } from 'utils/const'
import { StatsCellWrapper, ParametersStatsWrapper } from './style'
import dayjs from 'dayjs'

const tPrefix = 'dataManagement.algoManagementTable'

export const Columns = ({ t, secType, algoType }) => {
  const isComponents = algoType === AlgoTypes.components
  const isEquity = secType === SecTypes.stock
  const isAllBonds = secType === SecTypes.allBond
  const isBonds = secType === SecTypes.corpBond || secType === SecTypes.govBond
  const isBondsComponents = isBonds && isComponents
  const isBondsRebalance = isBonds && !isComponents

  return compact([
    {
      id: 'category',
      Header: t(`${tPrefix}.category`),
      accessor: 'category',
      cellFormat: { noFormat: true },
    },
    {
      id: 'updateType',
      Header: t(`${tPrefix}.updateType`),
      accessor: 'updateType',
      cellFormat: { noFormat: true },
    },
    {
      id: 'recordDate',
      Header: t(`${tPrefix}.recordDate`),
      accessor: 'recordDate',
      cellFormat: { format: 'date' },
    },
    {
      id: 'announcementDate',
      Header: t(`${tPrefix}.announcementDate`),
      accessor: 'announcementDate',
      cellFormat: { format: 'date' },
    },
    {
      id: 'effectiveDate',
      Header: t(`${tPrefix}.effectiveDate`),
      accessor: 'effectiveDate',
      cellFormat: { format: 'date' },
    },
    {
      id: 'lastAlgoDate',
      Header: t(`${tPrefix}.lastAlgoDate`),
      accessor: 'lastAlgoDate',
      cellFormat: { format: 'date-time' },
    },
    (isEquity || isAllBonds) && {
      id: 'weightStats',
      Header: t(`${tPrefix}.weightStats`),
      accessor: 'weightStats',
      width: 205,
      Cell: row => {
        const weightStats = get(row.row.original, 'latestAlgoCalc.weightStats')

        if (weightStats) {
          const { missingOnAlgo, missingOnTase, diffCount, biggestDelta } = weightStats

          return (
            <StatsCellWrapper>
              <div>
                <strong>missing on algo: </strong>
                {missingOnAlgo}; <strong>missing on tase: </strong>
                {missingOnTase}
              </div>
              <div>
                <strong>biggest delta: </strong>{' '}
                {getFormattedValue({
                  value: biggestDelta,
                  formatOverride: 'float',
                })}
                ; <strong>diff count: </strong>
                {diffCount}
              </div>
            </StatsCellWrapper>
          )
        } else return '-'
      }, //TODO localisation?
      cellFormat: { noFormat: true },
    },
    isEquity && {
      id: 'parametersStats',
      Header: t(`${tPrefix}.parametersStats`),
      accessor: 'parametersStats',
      width: 120,
      Cell: row => {
        const parametersStats = get(row.row.original, 'latestAlgoCalc.parametersStats')

        if (parametersStats) {
          const { missingOnAlgo, diffInFFR, diffInIANS } = parametersStats

          return (
            <ParametersStatsWrapper>
              <div>
                <strong>missing on algo: </strong>
                {missingOnAlgo}
              </div>
              <div>
                <strong>diff in FFR: </strong>
                {diffInFFR}
              </div>
              <div>
                <strong>diff in IANS: </strong>
                {diffInIANS}
              </div>
            </ParametersStatsWrapper>
          )
        } else return '-'
      }, //TODO localisation?
      cellFormat: { noFormat: true },
    },
    isBondsComponents && {
      id: 'ratingStats',
      Header: t(`${tPrefix}.ratingStats`),
      accessor: row => {
        const diffCount = get(row, 'latestAlgoCalc.ratingStats.diffCount')
        if (!diffCount && diffCount !== 0) return 'no data'

        return diffCount
      },
    },
    !isBondsRebalance && {
      id: 'turnoverStats',
      Header: t(`${tPrefix}.turnoverStats`),
      accessor: 'turnoverStats',
      width: 180,
      Cell: row => {
        const effectiveDate = get(row.row.original, 'effectiveDate')
        const turnoverStats = get(row.row.original, 'latestAlgoCalc.turnoverStats')

        if (
          dayjs()
            .startOf('day')
            .isAfter(effectiveDate) &&
          turnoverStats
        ) {
          const { maxChange, minChange, AvgChange } = turnoverStats

          return (
            <ParametersStatsWrapper>
              <div>
                <strong>max change: </strong>
                {getFormattedValue({
                  value: maxChange,
                  formatOverride: '%-shorter',
                })}
              </div>
              <div>
                <strong>min change: </strong>
                {getFormattedValue({
                  value: minChange,
                  formatOverride: '%-shorter',
                })}
              </div>
              <div>
                <strong>avg. change: </strong>
                {getFormattedValue({
                  value: AvgChange,
                  formatOverride: '%-shorter',
                })}
              </div>
            </ParametersStatsWrapper>
          )
        } else return '-'
      }, //TODO localisation?
      cellFormat: { noFormat: true },
    },

    {
      id: 'actions',
      Header: t(`${tPrefix}.actions`),
      accessor: 'actions',
      width: 200,
      Cell: AlgoActionsCell,
    },
  ])
}
