import React from 'react'

import Container from 'react-bootstrap/Container'

export const NoSubscription = () => {
  console.log('reached NoSubscription')
  return (
    <Container style={{ marginTop: '100px', textAlign: 'center' }}>
      <h1>Subscribe to Indexes</h1>
      <p>You are currently not subscribed to any index plan. please reach us at join@smartbull.co.il for more information. </p>
    </Container>
  )
}
