/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import compact from 'lodash/compact'
import { useAlgoType } from 'context/Context'
import useColumnsData from 'utils/useColumnsData'
import { Table } from 'components/Common/Table'
import auth from 'utils/auth'

import { columns } from './columns'

const WEIGHT_TOLERANCE = 5

export const IndexOverviewTable = props => {
  const [algoType] = useAlgoType()

  const isComponents = algoType === 'components'
  const { entryRanking, exitRanking } = props.indexData

  const { t } = useTranslation()

  const isEntering = row => {
    const { projectedWeight, effectiveDateWeight: weight } = row.original
    return weight === 0 && projectedWeight !== 0
  }

  const isExiting = row => {
    const { projectedWeight, effectiveDateWeight: weight } = row.original
    return projectedWeight === 0 && weight !== 0
  }

  const isOutForLowRanking = row => {
    const { projectedWeight, effectiveDateWeight: weight } = row.original
    return projectedWeight === 0 && weight === 0
  }

  const isSwinging = row => {
    const rank = get(row.original, 'projectedComponentsRank')
    return entryRanking && exitRanking && rank && rank > entryRanking && rank < exitRanking
  }

  const isSwingingExtended = row => {
    const rank = get(row.original, 'projectedComponentsRank')
    const extraRows = 2
    return entryRanking && exitRanking && rank && rank > toNumber(entryRanking) - extraRows && rank < exitRanking
  }

  const calcPrecision = row => {
    const actualWeight = get(row.original, 'actualWeight')
    const projectedWeight = get(row.original, 'projectedWeight')
    return actualWeight && projectedWeight ? (projectedWeight / actualWeight) * 100 : 0
  }

  const isLowPrecision = row => {
    const precision = calcPrecision(row)
    return Math.abs(precision - 100) > WEIGHT_TOLERANCE
  }

  const conditionalRowProps = row => {
    if (isExiting(row)) return { className: 'dangerTR' }
    if (isEntering(row)) return { className: 'successTR' }
    if (isOutForLowRanking(row)) return { className: 'warningTR' }
  }

  const conditionalCellProps = (row, cell) => {
    if (algoType === 'components' && cell.column.id === 'projectedComponentsRank') {
      if (isSwinging(row)) return { className: 'warningCellStrong' }
    }

    if (cell.column.id === 'precision') {
      const precision = calcPrecision(row)

      if (Math.abs(precision - 100) > WEIGHT_TOLERANCE) {
        return { className: 'warningCell' }
      }
    }
  }

  const legends = compact([
    {
      color: '#e4ffe1',
      label: t('equity.indexOverview.legends.enteringSecurities'),
      filter: isEntering,
    },
    {
      color: '#ffe4e1',
      label: t('equity.indexOverview.legends.leaveingSecurities'),
      filter: isExiting,
    },
    {
      color: '#fff6e1',
      label: t('equity.indexOverview.legends.onTheVerge'),
      filter: isOutForLowRanking,
    },
    isComponents &&
      entryRanking && {
        color: '#e6b64a',
        label: t('equity.indexOverview.legends.exitRanking'),
        filter: isSwingingExtended,
      },
    auth.isAdmin() && {
      color: '#e6b64a',
      label: t('equity.indexOverview.legends.lowPrecision'),
      filter: isLowPrecision,
    },
  ])

  const { selectedIndex } = props
  const exportFileNameContext = selectedIndex ? selectedIndex.label : ''

  return (
    <>
      <Table
        columns={columns(useColumnsData())}
        defaultSortBy={algoType === 'parameters' ? { id: 'projectedChange', desc: true } : { id: 'projectedComponentsRank', desc: false }}
        conditionalRowProps={conditionalRowProps}
        conditionalCellProps={conditionalCellProps}
        {...props}
        withExportButton
        legends={legends}
        exportFileNameContext={exportFileNameContext}
        virtualized={true}
      />
    </>
  )
}
