import styled from 'styled-components'
import { Container } from 'components/Common/Container'

import { H1 } from 'styles/Typography'

export const AdminWrapper = styled(Container)`
  margin-top: 70px;
`

export const Title = styled(H1)`
  margin-bottom: 1.5rem;
  padding-top: 1.1rem;
`
