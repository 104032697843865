import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import hb from './translation/hb.json'
import en from './translation/en.json'

// the translations
const resources = {
  en: { translation: en },
  hb: { translation: hb },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'hb',
    detection: {
      // order and from where user language should be detected
      order: [localStorage],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(err => console.error('Error loading translation files', err))
// .on('languageChanged', () => {})

const isHeb = () => i18n.language === 'hb'
const isEng = () => i18n.language === 'en'
const isRtl = isHeb

//on language change and on init
const setDocumentLang = lng => {
  document.dir = isRtl() ? 'rtl' : 'ltr'
  document.documentElement.lang = lng
  //text-align
  document.body.style.textAlign = isRtl() ? 'right' : 'left'
}

i18n.on('languageChanged', lng => {
  setDocumentLang(lng)
})

setDocumentLang(i18n.language)

export { i18n, isHeb, isEng, isRtl }
