import React, { useContext } from 'react'
import Form from 'react-bootstrap/Form'

import { Formik } from 'formik'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import axiosApi from 'utils/axiosApi'
import { AuthContext } from 'context/AuthContext'

import { Input } from 'components/Common/input'
import { RegisterPhoneSchema } from './registerPhoneSchema'
import { LoginButton, Separator } from '../styles'
import { PhoneInputField } from './phoneInputField'
import { loginScenariosDictionary } from '../loginScenariosDictionary'
import { FormDescription } from '../styles'

import { ERROR_RESPONSES } from 'errors/login/registerPhone'

export const RegisterPhone = () => {
  const { t } = useTranslation()
  const { setLoginScenario, email, setPhoneEnding } = useContext(AuthContext)

  const submitForm = async (formValues, { setErrors }) => {
    try {
      let phone = formValues.phone
      const password = formValues.password
      await axiosApi.post('/registerPhoneAndSendPincode', { phone, email, password })
      const phoneEnding = phone.slice(-4)
      setPhoneEnding(phoneEnding)
      setLoginScenario(loginScenariosDictionary.smsLogin)
    } catch (error) {
      if (error.message === 'Request failed with status code 504') {
        setErrors({ password: t('generalErrors.noResponse') })
        return
      }
      const errorName = error?.response?.data?.errors?.msg?.[0]?.param
      switch (errorName) {
        case ERROR_RESPONSES.BAD_PHONE_NUMBER:
          setErrors({ phone: t('registerPhone.form.phone.errors.badPhoneNumber') })
          break
        case ERROR_RESPONSES.PHONE_ALREADY_VALIDATED:
          setErrors({ phone: t('registerPhone.form.phone.errors.phoneAlreadyValidated') })
          break
        case ERROR_RESPONSES.TOO_MANY_SMS_SENT:
          setErrors({ phone: t('registerPhone.form.phone.errors.tooManySmsSent') })
          break
        case ERROR_RESPONSES.WRONG_PASSWORD:
          setErrors({ password: t('registerPhone.form.password.errors.wrongPassword') })
          break
        case ERROR_RESPONSES.USER_NOT_FOUND:
          setErrors({ password: t('registerPhone.form.password.errors.userNotFound') })
          break
        case ERROR_RESPONSES.BLOCKED_USER:
          setErrors({ password: t('registerPhone.form.password.errors.blockedUser') })
          break
        default:
          setErrors({ password: t('generalErrors.unknownError') })
          break
      }
    }
  }

  return (
    <Formik
      initialValues={{ phone: '', password: '' }}
      onSubmit={submitForm}
      validationSchema={RegisterPhoneSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} style={{ maxWidth: '250px' }}>
            <FormDescription>{t('registerPhone.form.description')}</FormDescription>
            <PhoneInputField name="phone" type="text" onBlur={handleBlur} label={null} placeholder={t('registerPhone.form.phone.label')} />
            {!!errors.phone && <Form.Text className="text-danger">{t(errors.phone)}</Form.Text>}
            <Input
              name="password"
              type="password"
              label={null}
              onBlur={handleBlur}
              placeholder={t('registerPhone.form.password.label')}
              isValid={false}
              isInvalid={!!errors.password && touched.password}
              value={values.password}
              errorText={t(errors.password)}
              onChange={handleChange}
              maxLength="8"
              style={{ direction: 'ltr', textAlign: 'center', marginTop: '20px' }}
            />

            <LoginButton type="submit" icon={faLock} margin={errors.email ? '1rem' : '0.8rem'}>
              {t('registerPhone.form.submit')}
            </LoginButton>

            <Separator />
          </Form>
        )
      }}
    </Formik>
  )
}
