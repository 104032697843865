import React, { useState, useEffect } from 'react'

/* eslint-disable no-shadow */
import { SimulationsTable } from 'components/Admin/SimulationsTable'
import { ShareModal } from 'components/Admin/SimulationsTable/ShareModal'
import { useUserId } from 'context/Context'
import some from 'lodash/some'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import axiosApi from 'utils/axiosApi'

export const SimulationsManagement = () => {
  const { t } = useTranslation()
  const [userId] = useUserId()
  const [selectedSim, setSelectedSim] = useState(null)
  const [showSimShareModal, setShowSimShareModal] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const history = useHistory()

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await axiosApi.get('/users')
      setAllUsers(users?.data)
    }

    fetchUsers()
  }, [setAllUsers])

  useEffect(() => {
    if (some(allUsers)) {
      const user = allUsers.find(user => user._id === userId)
      setUserDetails(user)
    }
  }, [allUsers, userId])

  const onShareClicked = simulation => {
    setSelectedSim(simulation)
    setShowSimShareModal(true)
  }

  const handleClose = () => {
    setShowSimShareModal(false)
  }

  if (!userId) {
    history.push('/admin/users')
    return null
  }

  if (!userDetails) return <Spinner animation="border" />

  return (
    <div style={{ marginTop: '70px' }}>
      <h1>{userDetails?.email}'s simulations</h1>
      <SimulationsTable
        apiUrl={`/simulation/listByUserId/${userId}`}
        onShareClicked={onShareClicked}
        headerBackground="#cde6fe"
        headerText={t('userManagment.tableHeader')}
      />

      <ShareModal show={showSimShareModal} onClose={handleClose} simulation={selectedSim} allUsers={allUsers} />
    </div>
  )
}
