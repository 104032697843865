import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { H1, H2 } from 'styles/Typography'
import CardSvg from 'assets/card.svg'

import Logo from '../../General/Logo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledWrapper = styled.div`
  background-image: url(${CardSvg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 700px;
  width: 500px;
  min-height: 20px;
  min-width: 20px;
  @media (max-width: 768px) {
    background-image: none;
    width: 100%;
    border: 1px solid #ababab;
  }
`

export const Card = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <H1>{t('loginPage.companyName')}</H1>
      <H2>{t('loginPage.subHeader')}</H2>
      <StyledWrapper>
        <StyledCard>
          <Logo />
          {children}
        </StyledCard>
      </StyledWrapper>
    </Container>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}
