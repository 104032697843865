import React, { useContext } from 'react'

import { AuthContext } from 'context/AuthContext'

import { TOS } from 'components/General/TOS'
import { loginScenariosDictionary } from '../loginScenariosDictionary'

export const TermsOfUse = ({ handleSuccessfulLoginResponse }) => {
  const { loginResponse, setLoginScenario } = useContext(AuthContext)

  const onCloseModal = () => {
    setLoginScenario(loginScenariosDictionary.deniedTermsOfUse)
  }

  const onApproveTOS = () => {
    handleSuccessfulLoginResponse(loginResponse, true)
  }

  const user = loginResponse.data.user

  return <TOS tos={user.tos} onClose={onCloseModal} onApprove={onApproveTOS} user={{ _id: user._id, token: loginResponse.data.token }} />
}
