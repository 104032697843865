/* eslint-disable no-shadow */
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import getFormattedValue from 'utils/getFormattedValue'

import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

import { LinkInner, TableCellContainer, ContentWrapper } from './styles'

export const TableCell = ({
  noPopup,
  value,
  note,
  color,
  link,
  onCellClick,
  greenRedValues,
  format,
  popoverContent,
  extraContent,
  columnId,
  direction,
  overrideWrapperStyle,
}) => {
  const [isPopover1Open, setPopover1IsOpen] = useState(false)
  const valueRef = useRef(null)

  const { t } = useTranslation()

  if (value === undefined) return null
  if (value === null) return '-'
  if (Math.abs(value) < 0.1e-6) value = 0

  const getCellContent = () => {
    const val = getFormattedValue({
      value,
      name: columnId,
      toShort: true,
      t,
      formatOverride: format || undefined,
    })
    if (link) return <LinkInner to={link}>{val}</LinkInner>
    if (onCellClick) return <LinkInner onClick={onCellClick}>{val}</LinkInner>
    return val
  }
  return (
    <TableCellContainer color={color} value={value} greenRedValues={greenRedValues} style={overrideWrapperStyle}>
      <ContentWrapper
        ref={valueRef}
        onMouseLeave={() => setPopover1IsOpen(false)}
        onMouseEnter={() => setPopover1IsOpen(true)}
        direction={direction || 'ltr'}
      >
        {getCellContent()} {note}
      </ContentWrapper>

      <Overlay target={valueRef.current} show={isPopover1Open && !noPopup} placement="top">
        {props => (
          <Tooltip onMouseLeave={() => setPopover1IsOpen(false)} onMouseEnter={() => setPopover1IsOpen(true)} id="cellValuePopover" {...props}>
            {popoverContent
              ? popoverContent
              : getFormattedValue({
                  value,
                  name: columnId,
                  t,
                  formatOverride: format || undefined,
                })}
          </Tooltip>
        )}
      </Overlay>

      {extraContent}
    </TableCellContainer>
  )
}

TableCell.defaultProps = {
  value: null,
  color: null,
  link: null,
  format: null,
  greenRedValues: false,
  noFormat: false,
  noPopup: false,
}

TableCell.propTypes = {
  color: PropTypes.oneOf(['red', 'green', 'black']),
  link: PropTypes.string,
  greenRedValues: PropTypes.bool,
  format: PropTypes.oneOf([
    '%-3dcm',
    '0,0.0%',
    '%*100.00',
    '%',
    '%-short',
    '%-shorter',
    'currency',
    'string',
    'number',
    'number-short',
    'number-long',
    'date',
    'time',
    'rating',
    'float',
    'agorot',
    'noFormat',
    'date-time',
  ]), // if null/undefined using typeof
  noFormat: PropTypes.bool,
  noPopup: PropTypes.bool,
}
