import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { isHeb } from '../../../../i18n'

import { Input } from 'components/Common/input'
import { Button, Form } from 'react-bootstrap'

import { SelectSearch } from 'components/Common/SelectSearch'
import { FieldsWrapper, InlinedFormGroup } from '../styles'
import { useErrorProps } from './RowFormFields'
import { WEIGHT_DIST_METHODS } from 'components/Simulation/Form/const'

export const RowFormIndexFields = ({ securityList }) => {
  const { t } = useTranslation()
  const direction = isHeb() ? 'rtl' : 'ltr'
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext()

  const { getErrorProps } = useErrorProps()

  return (
    <FieldsWrapper>
      <InlinedFormGroup controlId={'indexName'} direction={direction}>
        <Form.Label>{t('simulation.form.indexName')}</Form.Label>
        <Input
          name={'indexName'}
          type={'text'}
          onBlur={handleBlur}
          value={values.indexName}
          onChange={handleChange}
          {...getErrorProps('indexName')}
        />
      </InlinedFormGroup>
      <InlinedFormGroup controlId={'followingFundsValue'} direction={direction}>
        <Form.Label>{t('simulation.form.followingFundsValue')}</Form.Label>
        <Input
          name={'followingFundsValue'}
          type={'number'}
          min={0}
          step={1000}
          onBlur={handleBlur}
          value={values.followingFundsValue}
          onChange={handleChange}
          {...getErrorProps('followingFundsValue')}
        />
      </InlinedFormGroup>
      <InlinedFormGroup controlId={'isEqualWeight'} direction={direction}>
        <Form.Check
          type={'radio'}
          id="hasWeightCap"
          label={t('simulation.form.hasWeightCap')}
          name={'weightDistMethod'}
          onBlur={handleBlur}
          defaultChecked={values.weightDistMethod === WEIGHT_DIST_METHODS.hasWeightCap}
          onChange={() => setFieldValue('weightDistMethod', 'hasWeightCap')}
          {...getErrorProps('hasWeightCap')}
        />
        <Form.Check
          type={'radio'}
          id="isEqualWeight"
          label={t('simulation.form.isEqualWeight')}
          name={'weightDistMethod'}
          onBlur={handleBlur}
          defaultChecked={values.weightDistMethod === WEIGHT_DIST_METHODS.isEqualWeight}
          onChange={() => setFieldValue('weightDistMethod', 'isEqualWeight')}
          {...getErrorProps('isEqualWeight')}
        />
      </InlinedFormGroup>
      <InlinedFormGroup controlId={'weightCap'} direction={direction}>
        <Form.Label>{t('simulation.form.weightCap')}</Form.Label>
        <Input
          name={'weightCap'}
          type={'number'}
          min={0}
          max={100}
          step={0.001}
          onBlur={handleBlur}
          value={values.weightCap}
          onChange={handleChange}
          readOnly={values.weightDistMethod !== WEIGHT_DIST_METHODS.hasWeightCap}
          {...getErrorProps('weightCap')}
        />
      </InlinedFormGroup>
      <Form.Label>{t('simulation.form.securities')}:</Form.Label>
      <FieldArray
        name="securityIds"
        render={arrayHelpers => {
          const Securities = () =>
            values.securityIds.map((securityId, index) => (
              <SecurityField key={`secRow_${index}`} index={index} securityId={securityId} arrayHelpers={arrayHelpers} securityList={securityList} />
            ))

          const errorProps = getErrorProps('securityIds')

          return (
            <>
              {errorProps.errorText && (
                <div className="invalid-feedback" style={{ display: 'unset' }}>
                  {errorProps.errorText}
                </div>
              )}
              <Securities />
              <Button onClick={() => arrayHelpers.push('')}>{t('simulation.form.addSecurity')}</Button>
            </>
          )
        }}
      />
    </FieldsWrapper>
  )
}

export const SecurityField = ({ arrayHelpers, securityId, index, securityList }) => {
  const direction = isHeb() ? 'rtl' : 'ltr'
  const { values, handleBlur } = useFormikContext()
  const { getErrorProps } = useErrorProps()

  return (
    <InlinedFormGroup controlId={`securityIds.${index}`} direction={direction} key={`secRow${index}`}>
      <SelectSearch
        name={`securityIds.${index}`}
        getChangedValueWithEvent
        options={securityList}
        value={securityId}
        onChange={securityIdValue => {
          arrayHelpers.replace(index, securityIdValue.value)
        }}
        onBlur={handleBlur}
        {...getErrorProps(`securityIds.${index}`)}
        customStyle={{
          control: styles => {
            return {
              ...styles,
              width: 'auto',
            }
          },
        }}
      />
      <Button
        disabled={values.securityIds.length === 1}
        variant="danger"
        onClick={() => {
          // if it's the last one, (and somehow the user managed to click) replace it with empty string
          if (values.securityIds.length === 1) {
            arrayHelpers.replace(index, '')
          } else {
            arrayHelpers.remove(index)
          }
        }}
      >
        X
      </Button>
    </InlinedFormGroup>
  )
}
