import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

export const HeaderLogo = styled.img.attrs({
  // src: Loading,
  alt: 'SmartBull Indexes',
})`
  width: 1rem;
  margin-right: 0.2rem;
`

export const MobileNavbarRight = styled.div`
  display: flex;
  align-items: center;
`

export const StyledNavbar = styled(Navbar)`
  height: 66px;
  padding: 0rem;
  ${props =>
    props.bg === 'dark'
      ? `
    background: -webkit-linear-gradient(
      top,
      rgb(64, 63, 63) 0%,
      rgb(44, 44, 44) 43%,
      rgb(35, 35, 35) 100%
    );
    background: -o-linear-gradient(
      top,
      rgb(64, 63, 63) 0%,
      rgb(44, 44, 44) 43%,
      rgb(35, 35, 35) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgb(64, 63, 63) 0%,
      rgb(44, 44, 44) 43%,
      rgb(35, 35, 35) 100%
    );
    background: -moz-linear-gradient(
      top,
      rgb(64, 63, 63) 0%,
      rgb(44, 44, 44) 43%,
      rgb(35, 35, 35) 100%
    );
    background: linear-gradient(
      to bottom,
      rgb(64, 63, 63) 0%,
      rgb(44, 44, 44) 43%,
      rgb(35, 35, 35) 100%
    );
  `
      : ``}
  @media (max-width: 768px) {
    height: auto;
  }
`

export const StyledBadge = styled.span`
  padding: 0.2rem;
  background: #027db4;
  color: white;
  font-size: 0.7rem;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 2px;
`

export const NavBarDate = styled.span`
  color: hsla(0, 0%, 100%, 0.5);
  @media (max-width: 768px) {
    display: none;
  }
  font-size: 13px;
`

export const StyledNavLink = styled(Nav.Link)`
  height: 66px;
  display: flex;
  align-items: center;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.2s;
  background:linear-gradient( to bottom, rgb(64,63,63) 0%, rgb(44,44,44) 43%, rgb(35,35,35) 100% );
  &.current {
    background: unset;
    color: #ffffff;
    background-color: #000;
  }

  &:hover {
    color: white !important;
    text-decoration: ${props => (props.striked ? 'line-through' : 'none')};
  }
  ${props => props.striked && 'text-decoration: line-through;'}
`
export const StyledNavDropdown = styled(NavDropdown)`
  height: 66px;
  display: flex;
  align-items: center;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  text-decoration: none;
  transition: 0.2s;
  background: linear-gradient( to bottom, rgb(64,63,63) 0%, rgb(44,44,44) 43%, rgb(35,35,35) 100% );
  &.current {
    background: unset;
    color: #ffffff;
    background-color: #000;
  }

  & > a:hover {
    color: white !important;
    text-decoration: none;
  }

  ${props =>
    props.textalign &&
    `& > div { 
    text-align: ${props.textalign};
  }`}
`
export const CustomPopover = styled.div`
  width: 320px;
  display: block;
  position: relative;
  top: 30px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  border-radius: 5px;
  z-index: 1050;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);
  font-size: 13px;

  @media (max-width: 768px) {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 0;
    width: 20px;
    height: 20px;
    margin: -10px;
    background: #ffffff;
    border-radius: 0 0 5px 0;
    transform: rotate(45deg);
  }
`
