import { useContext, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'
import { SecTypes, AlgoTypes } from 'utils/const'

import { isEng } from '../i18n'

export const useContextualTitle = () => {
  const DEFAULT_TITLE = isEng() ? 'SmartBull Indexes' : 'סמארטבול מדדים'

  const { t } = useTranslation()
  const tPrefix = 'titles'

  const [title, setTitle] = useState(DEFAULT_TITLE)
  const [algoType] = useAlgoType()
  const [effectiveDate] = useTaseEffectiveDate()
  const { securityName, indexName, secType } = useContext(Context)
  const { pathname } = useLocation()

  const getLocalizedAlgoType = useCallback(
    (titleStr, screenType, tabNameLocalized, date) => {
      const algoTypeLocalized = t(`${tPrefix}.${algoType}`)
      let chronoContext
      if (secType === SecTypes.allBond) {
        if (algoType === AlgoTypes.parameters) {
          chronoContext = t('lang.daily')
        } else {
          chronoContext = t('lang.monthly')
        }
      } else if (secType === SecTypes.stock) {
        if (algoType === AlgoTypes.parameters) {
          chronoContext = t('lang.monthly')
        } else {
          chronoContext = t('lang.semiAnnual')
        }
      }
      if (isEng()) {
        titleStr = `${chronoContext} ${algoTypeLocalized} ${date} - `
      } else {
        titleStr = `${algoTypeLocalized} ${chronoContext} ${date} - `
      }

      if (securityName || indexName) {
        const localize = isEng() ? 'en' : 'he'
        const name = securityName ? securityName[localize] : indexName[localize]
        titleStr += isEng() ? name + ' ' + tabNameLocalized : tabNameLocalized + ' ' + name
        return titleStr
      } else if (tabNameLocalized) return titleStr + tabNameLocalized
      return DEFAULT_TITLE
    },
    [DEFAULT_TITLE, algoType, indexName, securityName, secType, t],
  )

  const getTitleWithSelectedTab = useCallback(
    (screenType, selectedTab, screenNameLocalized) => {
      const date = dayjs(effectiveDate).format('DD.MM')
      const tabNameLocalized = t(`${tPrefix}.${selectedTab}`)
      let titleStr = `${screenNameLocalized} -  `
      return algoType && date ? getLocalizedAlgoType(titleStr, screenType, tabNameLocalized, date) : DEFAULT_TITLE
    },
    [DEFAULT_TITLE, algoType, effectiveDate, t, getLocalizedAlgoType],
  )

  const getTitle = useCallback(
    (selectedTab, screenType, screenNameLocalized) => {
      if (selectedTab) {
        return getTitleWithSelectedTab(screenType, selectedTab, screenNameLocalized)
      } else if (screenNameLocalized && screenType) {
        screenNameLocalized = isEng() ? `SmartBull ${screenNameLocalized}` : `${screenNameLocalized} סמארטבול`
        return screenNameLocalized
      } else return DEFAULT_TITLE
    },
    [DEFAULT_TITLE, getTitleWithSelectedTab],
  )

  useEffect(() => {
    // eslint-disable-next-line
    const [path, screenType, selectedTab] = pathname.split('/')
    let screenNameLocalized = t(`${tPrefix}.${screenType}`)
    setTitle(getTitle(selectedTab, screenType, screenNameLocalized))
    return () => {
      setTitle(DEFAULT_TITLE)
    }
  }, [pathname, securityName, indexName, effectiveDate, algoType, DEFAULT_TITLE, t, getTitle])

  return title
}
