import React, { useCallback, useMemo } from 'react'

import { Formik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import axiosApi from 'utils/axiosApi'
import { FormTitle, StyledModalHeader } from './styles'
import { ShareModalForm } from './ShareModalForm'

export const ShareModal = ({ simulation, show, onClose, allUsers }) => {
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        await axiosApi.post('/simulation/share', {
          simulationId: simulation._id,
          userId: values.user,
        })
        onClose()
      } catch (err) {
        actions.setFieldError('general', err?.response?.data?.message || t('error'))
      }
    },
    [t, simulation, onClose],
  )

  const handleValidate = useCallback(
    values => {
      const errors = {}
      if (!values.user) {
        errors.user = t('required')
      }
      return errors
    },
    [t],
  )

  const selectOptions = useMemo(() => {
    return allUsers.map(user => ({
      label: user.email,
      value: user._id,
    }))
  }, [allUsers])

  const initialValues = useMemo(() => {
    return {
      user: '',
    }
  }, [])

  if (!selectOptions) return null

  return (
    <Modal show={show} onHide={onClose}>
      <>
        <StyledModalHeader closeButton />

        <FormTitle>{'Simulations Share'}</FormTitle>

        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={handleValidate}>
          <ShareModalForm selectOptions={selectOptions} />
        </Formik>
      </>
    </Modal>
  )
}
