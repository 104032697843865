import React, { useState, useEffect } from 'react'
import axiosApi from 'utils/axiosApi'
import { Spinner } from 'react-bootstrap'

export const JobsQueue = () => {
  const [jobs, setJobs] = useState([])
  const [fetching, setFetching] = useState(false)
  const [initialFetch, setInitialFetch] = useState(true)
  const [error, setError] = useState(null)

  // useEffect hook to periodically fetch jobs from the server
  useEffect(() => {
    if (error) {
      return
    }

    const fetchJobs = async () => {
      try {
        setFetching(true)
        const response = await axiosApi.get('/jobs/queued') // Assuming /api/jobs is the endpoint to fetch jobs
        setJobs(response.data)
        setFetching(false)
      } catch (error) {
        setError(error)
        console.error('Failed to fetch jobs:', error)
      }
    }

    // Fetch jobs initially
    if (initialFetch) {
      setInitialFetch(false)
    }

    fetchJobs()

    // Set an interval to fetch jobs every 10 seconds (or any other desired interval)
    const interval = setInterval(fetchJobs, 3000)

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval)
  }, [error, initialFetch])

  if (!initialFetch && !jobs.length) {
    return null
  }

  if (initialFetch && fetching) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <div
      style={{
        maxHeight: '300px',
        overflow: 'auto',
        marginBottom: '1rem',
      }}
    >
      {error && <div>Error fetching jobs</div>}
      <h5>
        Jobs Queue - {jobs.length} jobs
        {fetching && <Spinner animation="border" size="sm" style={{ marginLeft: '10px' }} />}
      </h5>

      <table
        className="table table-striped table-bordered"
        style={{
          fontSize: '0.7rem',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Data</th>
            <th>Repeating</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <tr key={job._id}>
              <td>{job.name}</td>
              <td>{job.status}</td>
              <td>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    maxWidth: '800px',
                    overflow: 'auto',
                    maxHeight: '100px',
                  }}
                >
                  {JSON.stringify(job.data)}
                </pre>
              </td>
              <td>{job.repeating ? 'Yes' : 'No'}</td>
              <td>{job._id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
