import React from 'react'
import compact from 'lodash/compact'
import dayjs from 'dayjs'

import some from 'lodash/some'
import { differenceInCalendarDays } from 'date-fns'
import buildPriceChangeText from 'utils/buildPriceChangeText'
import { isEng } from '../../../i18n'
import { AlgoTypes, SecTypes } from 'utils/const'

import { TableCell } from 'components/Common/Table/TableCell'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import SecurityName from 'components/Common/Table/TableCell/SecurityName'

const tPrefix = 'common.table.colHeaders'

export const columns = ({ t, algoType, secType, recordDate, effectiveDate, taseEffectiveDate }) => {
  const recordDateDaysDiff = recordDate ? differenceInCalendarDays(new Date(recordDate), new Date()) : 0
  const taseEffectiveDateDaysDiff = taseEffectiveDate ? differenceInCalendarDays(new Date(taseEffectiveDate), new Date()) : 0

  const isBondsRebalance = algoType === AlgoTypes.parameters && secType === SecTypes.corpBond
  const isEquity = secType === SecTypes.stock
  const isGovBond = secType === SecTypes.govBond

  const isNewMethodology = dayjs(taseEffectiveDate).isAfter('2023-11-01')

  return compact([
    {
      id: 'securityName',
      Header: t('common.security'),
      accessor: row => {
        let name = isEng() ? row.securityName : row.securityNameHebrew
        if (some(row.fastTrackIndexes)) {
          return `${name} ${t('equity.securityAnalysis.exceptions.fastTrack')}`
        } else return name
      },
      Cell: SecurityName,
      width: 205,
    },
    {
      id: 'securityId',
      Header: t('common.securityId'),
      accessor: 'securityId',
    },
    {
      id: 'projectedChange',
      Header: t(`${tPrefix}.projectedChange`),
      accessor: 'projectedChange',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    {
      id: 'projectedEODTurnover',
      Header: t(`${tPrefix}.projectedEODTurnover`),
      accessor: 'projectedEODTurnover',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    isEquity && isNewMethodology
      ? {
          id: 'liquidationGroup',
          accessor: row => {
            const { liquidationCoefficient, projectedLiquidationGroup } = row
            return `${projectedLiquidationGroup} (${liquidationCoefficient})`
          },
          Header: columns => {
            const { turnoverStatsStartDate, turnoverStatsEndDate } = columns.data[0]
            if (!turnoverStatsStartDate || !turnoverStatsEndDate) return t(`${tPrefix}.liquidationGroup`)
            return (
              <div>
                {t(`${tPrefix}.liquidationGroup`)}
                <TableCellExtraContent
                  popoverText={t(`${tPrefix}.liquidationGroupDetailed`, {
                    startDate: dayjs(turnoverStatsStartDate).format('DD/MM/YYYY'),
                    endDate: dayjs(turnoverStatsEndDate).format('DD/MM/YYYY'),
                  })}
                  content={<FontAwesomeIcon icon={faInfoCircle} />}
                  key="liquidationGroup"
                />
              </div>
            )
          },
        }
      : {
          id: 'avgTurnover',
          Header: t(`${tPrefix}.avgTurnover`),
          accessor: 'avgTurnover',
          cellFormat: { format: 'currency' },
        },
    /************************/
    // BENCHED,
    // This data is currently incorrect/incomplete.
    // todo: fetch and calculate this data from Data hub as soon as available.
    /************************/
    // taseEffectiveDateDaysDiff <= 0 && {
    //   id: 'effectiveDatePercentageChange',
    //   Header: t(`${tPrefix}.effectiveDatePercentageChange`),
    //   accessor: 'effectiveDatePercentageChange',
    //   cellFormat: { format: '%' },
    // },
    taseEffectiveDateDaysDiff <= -1 && {
      id: 'effectiveDateTurnover',
      Header: t(`${tPrefix}.effectiveDateTurnover`),
      accessor: 'effectiveDateTurnover',
      cellFormat: { format: 'currency' },
    },
    {
      id: 'turnoverRatio',
      Header: t(`${tPrefix}.turnoverRatio`),
      accessor: row => {
        return Math.abs(row.projectedChange / row.avgTurnover)
      },
      Cell: row => {
        const { isEnteringIndexes } = row.row.original
        let shouldDisplayWarning = isEquity && row.value > 30 && isEnteringIndexes
        let warningText = t('equity.securityAnalysis.highChangeOverTurnoverText')
        return (
          <TableCell
            value={row.value}
            format="number-short"
            color={shouldDisplayWarning ? 'red' : null}
            extraContent={compact([
              shouldDisplayWarning && (
                <TableCellExtraContent popoverText={warningText} content={<FontAwesomeIcon icon={faInfoCircle} />} key={warningText} />
              ),
            ])}
          />
        )
      },
    },
    isEquity && {
      id: 'changeOverFreeFloat',
      Header: columns => {
        return (
          <div>
            {t(`${tPrefix}.changeOverFreeFloat`)}
            <TableCellExtraContent
              popoverText={t(`${tPrefix}.changeOverFreeFloatDetailed`)}
              content={<FontAwesomeIcon icon={faInfoCircle} />}
              key="specialEvents"
            />
          </div>
        )
      },
      // Sets the column header in the export file (excel).
      getColumnExportValue: column => t(`${tPrefix}.changeOverFreeFloat`),
      accessor: row => {
        // console.log(row)
        return Math.abs(row.projectedChange / row.projectedFreeFloatValue) * 100
      },
      Cell: row => {
        const { isEnteringIndexes } = row.row.original
        let shouldDisplayWarning = row.value > 5 && isEnteringIndexes
        let warningText = t('equity.securityAnalysis.highChangeOverFFVText')
        return (
          <TableCell
            value={row.value}
            format="0,0.0%"
            color={shouldDisplayWarning ? 'red' : null}
            extraContent={compact([
              shouldDisplayWarning && (
                <TableCellExtraContent popoverText={warningText} content={<FontAwesomeIcon icon={faInfoCircle} />} key={warningText} />
              ),
            ])}
          />
        )
      },
    },
    taseEffectiveDateDaysDiff <= -1 && {
      id: 'EODTurnover',
      Header: t(`${tPrefix}.EODTurnover`),
      accessor: 'EODTurnover',
      cellFormat: { format: 'currency' },
    },
    taseEffectiveDateDaysDiff <= -1 && {
      id: 'precision',
      Header: t(`${tPrefix}.precision`),
      accessor: row => (row.EODTurnover ? Math.abs(row.projectedEODTurnover / row.EODTurnover) * 100 : null),
      cellFormat: { format: '%' },
    },
    !isBondsRebalance &&
      recordDateDaysDiff < 0 &&
      !isGovBond && {
        id: 'priceChange',
        Header: t(`${tPrefix}.priceChange`),
        accessor: 'priceChange',
        cellFormat: { format: '%*100.00', greenRedValues: true },
        Cell: row => {
          const {
            closingPrice,
            effectiveDateClosingPrice,
            effectiveDateClosingPriceDate,
            exAdjuestedClosingPrice,
            exDate,
            taseScanDate,
          } = row.row.original

          const popoverText = buildPriceChangeText({
            t,
            recordDate,
            effectiveDate: taseScanDate,
            closingPrice,
            exAdjuestedClosingPrice,
            effectiveDateClosingPrice,
            effectiveDateClosingPriceDate,
          })

          return (
            <TableCell
              value={row.value}
              format="%*100.00"
              greenRedValues={true}
              extraContent={compact([
                <TableCellExtraContent popoverText={popoverText} content={<FontAwesomeIcon icon={faInfoCircle} />} key={popoverText} />,
                exDate && (
                  <TableCellExtraContent
                    popoverText={`${t('equity.allChanges.exDateOccuredOn')} ${dayjs(exDate).format('DD/MM/YYYY')}`}
                    content={<span className="badge badge-secondary">{t('equity.allChanges.ex')}</span>}
                    key="exDateIndication"
                  />
                ),
              ])}
            />
          )
        },
      },
  ])
}
