import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

export const StyledGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 100%;
`

export const StyledControl = styled(Form.Control)``

export const StyledError = styled(Form.Control.Feedback)`
  display: flex;
`

export const Label = styled(Form.Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 0.3rem;
`
