import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import min from 'lodash/min'
import some from 'lodash/some'
import get from 'lodash/get'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Badge from 'react-bootstrap/Badge'
import Popover from 'react-tiny-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import useCalcDescUrl from 'utils/useCalcDescUrl'
import axiosApi from 'utils/axiosApi'
import { AlgoTypes, SecTypes } from 'utils/const'
import auth from 'utils/auth'
import { isUsingLatestData } from 'utils/algo'

import HeaderLogo from 'assets/header_logo_israel.png'
import Context, { useTaseEffectiveDate, useAlgoType } from 'context/Context'

import { NavLink } from 'components/General/navbar/NavLink'
import { DropdownMenu } from 'components/Common/DropdownMenu'
import { SimulationNav } from 'components/Simulation/SimulationNav'
import { SimulationSelector } from 'components/Simulation/SimulationSelector'
import { ExternalIndexes } from 'components/ExternalIndexes/ExternalIndexesSelector'
import { ContactSupport } from 'components/General/ContactSupport/ContactSupport'

import { CustomPopover, MobileNavbarRight, NavBarDate, StyledNavbar } from './styles'

dayjs.extend(isSameOrAfter)

export const NavBarComponent = () => {
  const { pathname } = useLocation()
  const {
    secType,
    recordDate: recordDateStr,
    effectiveDate: effectiveDateStr,
    updateEffectiveDate,
    selectedSim,
    selectedUpdateScheduleRecord,
  } = useContext(Context)

  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const [effectiveDateScanDateStr, setEffectiveDateScanDateStr] = useState(null)
  const [taseEffectiveDateScanDateStr, setTaseEffectiveDateScanDateStr] = useState(null)
  const [windowWidth, setWindowWidth] = useState([0, 0])
  const [usedIntraDay, setUsedIntraDay] = useState(false)
  const [isPopoverOpen, setPopoverIsOpen] = useState(false)
  const calcDescForUrl = useCalcDescUrl()
  const isBondsRebalance = (secType === SecTypes.corpBond || secType === SecTypes.govBond) && algoType === AlgoTypes.parameters

  useEffect(() => {
    const getLatestsCalcDesc = async () => {
      try {
        const res = await axiosApi.get(`/index_calc_results/getLatestCalcDesc?${calcDescForUrl}`)
        setUsedIntraDay(res.data?.usedIntraDay)

        const calcDescEffectiveDate = get(res, 'data.effectiveDate')
        if (calcDescEffectiveDate) {
          setEffectiveDateScanDateStr(get(res, 'data.effectiveScanDate'))
          updateEffectiveDate(calcDescEffectiveDate)
        }

        setTaseEffectiveDateScanDateStr(get(res, 'data.taseEffectiveScanDate'))
      } catch (err) {
        console.log(err)
      }
    }

    selectedUpdateScheduleRecord && taseEffectiveDate && getLatestsCalcDesc()
  }, [calcDescForUrl, updateEffectiveDate, taseEffectiveDate, setUsedIntraDay, selectedUpdateScheduleRecord])

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const currLanguageCode = t('get_current_language_code')
  const effectiveDate = effectiveDateStr && new Date(effectiveDateStr)
  const recordDate = recordDateStr && new Date(recordDateStr)
  const effectiveDateScanDate = effectiveDateScanDateStr && new Date(effectiveDateScanDateStr)
  const taseEffectiveDateScanDate = taseEffectiveDateScanDateStr && new Date(taseEffectiveDateScanDateStr)

  let scanDate
  if (isBondsRebalance && usedIntraDay) {
    scanDate = taseEffectiveDateScanDate ? taseEffectiveDateScanDate : taseEffectiveDate
  } else {
    scanDate = effectiveDateScanDate && recordDate && min([effectiveDateScanDate, recordDate])
  }
  const scanDateFormatted = dayjs(scanDate).format('DD/MM/YYYY')
  const effectiveDateFormatted = dayjs(effectiveDateScanDateStr).format('DD/MM/YYYY')
  const usingLatestData = isUsingLatestData({ effectiveDate, taseEffectiveDate })

  const dataScanStr = `${t('common.dataScan')} ${effectiveDateFormatted}`
  const dataScanPopoverStr = isUsingLatestData
    ? `${t('common.scannedFunds')} ${effectiveDateFormatted} , ${t('common.scannedIndex')} ${scanDateFormatted} ${usedIntraDay ? '(15:00)' : t('common.eod')
    }`
    : `${t('common.missingDataWarning')}`

  return (
    <div
      data-testid="NavBar"
      style={{
        direction: currLanguageCode === 'hb' ? 'rtl' : 'ltr',
      }}
    >
      <StyledNavbar expand="lg" fixed="top" bg={some(selectedSim) ? 'warning' : 'dark'} variant="dark">
        <Navbar.Brand
          className={pathname === '/stocks/all' && 'disabled-link'}
          as={Link}
          to={auth.isSubscribed(SecTypes.stock) ? '/stocks/all' : '/allBonds/all'}
        >
          <img src={HeaderLogo} width={windowWidth < 500 ? '150px' : 'auto'} height="40" alt="SmartBull" />
        </Navbar.Brand>
        <MobileNavbarRight>
          {windowWidth < 992 && <ContactSupport />}
          {windowWidth < 992 && <DropdownMenu />}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </MobileNavbarRight>

        {some(selectedSim) ? (
          // SIMULATION MODE!
          <SimulationNav />
        ) : (
          // REGULAR MODE!
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={currLanguageCode === 'hb' ? '' : 'mr-auto'}>
              <NavLink
                isLocked={!auth.isSubscribed(SecTypes.stock)}
                label={t('menu.stocks')}
                samePath={pathname.includes('stocks')}
                linkTo="/stocks/all"
              />
              <NavLink
                isLocked={!auth.isSubscribed(SecTypes.allBond)}
                label={t('menu.allBonds')}
                samePath={pathname.includes('allBonds')}
                linkTo="/allBonds/all"
              />
              <SimulationSelector />
              <ExternalIndexes />
            </Nav>
          </Navbar.Collapse>
        )}

        {effectiveDate && (
          <Popover
            containerStyle={{ overflow: 'visible' }}
            isOpen={isPopoverOpen}
            position="bottom"
            content={
              <CustomPopover onMouseLeave={() => setPopoverIsOpen(false)} onMouseEnter={() => setPopoverIsOpen(true)}>
                {dataScanPopoverStr}
              </CustomPopover>
            }
          >
            <NavBarDate onMouseLeave={() => setPopoverIsOpen(false)} onMouseEnter={() => setPopoverIsOpen(true)}>
              {usingLatestData ? (
                dataScanStr
              ) : (

                <Badge variant="warning" style={{ whiteSpace: 'unset' }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> {dataScanStr}
                </Badge>
              )}
              <br />
            </NavBarDate>
          </Popover>
        )}
        {windowWidth >= 992 && <ContactSupport />}
        {windowWidth >= 992 && <DropdownMenu />}
      </StyledNavbar>
    </div>
  )
}
