import React from 'react'

import some from 'lodash/some'
import isFunction from 'lodash/isFunction'
import { isEng } from '../../../../i18n'

import { StyledTable, TableFilterRowWrapper, FilterHeader } from './styles'
import { TableLegend } from 'components/Common/Table/TableLegend'
import { GlobalFilter } from 'components/Common/Table/filters'

export const RegularTable = ({ props, ReactTableBag }) => {
  let {
    conditionalRowProps,
    conditionalCellProps,
    renderRowSubComponent,
    size,
    withFilters,
    style,
    legends,
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
  } = ReactTableBag
  return (
    <StyledTable
      {...getTableProps()}
      responsive
      hover
      bordered
      size={size ? size : 'md'}
      {...style}
      dir={isEng() ? 'ltr' : 'rtl'}
    >
      <thead>
        <tr>
          <FilterHeader colSpan={visibleColumns.length}>
            <TableFilterRowWrapper>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              {some(legends) && <TableLegend legends={legends} />}
            </TableFilterRowWrapper>
          </FilterHeader>
        </tr>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={
                  column.isSorted
                    ? column.isSortedDesc
                      ? 'sortingUp'
                      : 'sortingDown'
                    : ''
                }
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        {withFilters &&
          headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().id + 'Format'}
            >
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <React.Fragment key={row.id}>
              <tr {...row.getRowProps()} {...conditionalRowProps(row)}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      {...conditionalCellProps(row, cell)}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>

              {isFunction(renderRowSubComponent) && row.isExpanded && (
                <tr {...row.getRowProps()} key={`${row.id}Expanded`}>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              )}
            </React.Fragment>
          )
        })}
      </tbody>
    </StyledTable>
  )
}
