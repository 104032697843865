import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SCREEN } from 'utils/const'
import { useHistory } from 'react-router-dom'

import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'

export default function useColumnsData() {
  const { secType, selectedUpdateScheduleRecord, recordDate, effectiveDate, selectedSim } = useContext(Context)
  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const history = useHistory()

  const pathPrefix = SCREEN[secType] || ''

  return {
    t,
    pathPrefix,
    secType,
    selectedUpdateScheduleRecord,
    recordDate,
    effectiveDate,
    selectedSim,
    algoType,
    taseEffectiveDate,
    history,
  }
}
