import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Overlay from 'react-bootstrap/Overlay'
import { IconWrapper, CustomPopover } from './styles'

export const Badge = ({ popover, icon }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const wrapperRef = useRef(null)

  return (
    <>
      <IconWrapper ref={wrapperRef} onMouseLeave={() => setIsTooltipOpen(false)} onMouseEnter={() => setIsTooltipOpen(true)}>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>

      <Overlay target={wrapperRef.current} show={isTooltipOpen} placement="bottom">
        {props => (
          <CustomPopover
            id="popover-positioned-bottom"
            {...props}
            onMouseLeave={() => setIsTooltipOpen(true)}
            onMouseEnter={() => setIsTooltipOpen(true)}
          >
            {popover}
          </CustomPopover>
        )}
      </Overlay>
    </>
  )
}
