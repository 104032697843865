import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { cloneDeep } from 'lodash'
import ceil from 'lodash/ceil'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import includes from 'lodash/includes'
import max from 'lodash/max'
import partition from 'lodash/partition'
import some from 'lodash/some'

import { useDefaultSimValues } from 'components/Simulation/simulationHooks'
import Context from 'context/Context'
import { Analytics } from 'services/analytics'

import { faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getReasonFieldByName } from './index'
import { GoalSeekBtn } from './styles'

const tPrefix = 'goalSeek'

export const GoalSeekCell = row => {
  let { criteria, security, indexAlgoData } = row.row.original
  const { selectedSim, setSelectedSim, setSimInitialValuesOverride, secType } = useContext(Context)
  const newSimValues = useDefaultSimValues()

  const isAllBonds = secType === 'allBond'

  const { t } = useTranslation()

  const buildOverrideFieldList = React.useCallback(() => {
    const inheritBaseIndexesReasons = (row, orgIndexObj) => {
      // create a clone from table data to prevent mutation and react re-renders
      const indexObj = cloneDeep(orgIndexObj)
      const { reasons } = indexObj
      const baseIndexesMissing = filter(reasons, r => r.t === 'requiredBaseIndex')

      if (some(baseIndexesMissing)) {
        for (const missingIndex of baseIndexesMissing) {
          let missingIndexObj = get(
            find(row.rows, row => row.original.indexNo === missingIndex.missing),
            'original',
          )
          if (!missingIndexObj) {
            console.log('skipping index as its not on the list..')
          } else {
            missingIndexObj = inheritBaseIndexesReasons(row, missingIndexObj)
            for (const missingIndexReason of missingIndexObj.reasons) {
              if (
                includes(
                  [
                    'minFreeFloatRate',
                    'minAvgPrice',
                    'minClosingPrice',
                    'minPublicMarketValue',
                    'minMarketValue',
                    'minRatingRequired',
                    'maxRatingRequired',
                    'noRating',
                    'turnoverMedianSpecs',
                  ],
                  missingIndexReason.t,
                )
              ) {
                const reasonIdx = findIndex(indexObj.reasons, r => r.t === missingIndexReason.t)
                if (reasonIdx !== -1) {
                  indexObj.reasons[reasonIdx].missing = max([missingIndexReason.missing, indexObj.reasons[reasonIdx].missing])
                } else {
                  indexObj.reasons.push(missingIndexReason)
                }
              }
            }
          }
        }
      }

      return indexObj
    }

    let fieldList = []

    // mutate security for aggregated calculating:
    const securtiySimValues = { ...security }

    // accumulate reasons from base indexes missing (recursive):
    const accReasons = inheritBaseIndexesReasons(row, row.row.original).reasons

    // first handle strict thresholds (as it's affect publicMarketValue)
    let [strictReasons, leftOverReasons] = partition(accReasons, r => includes(['minFreeFloatRate', 'minAvgPrice', 'minClosingPrice'], r.t))
    for (const reason of strictReasons) {
      const name = getReasonFieldByName(reason.t)

      // override value for next calculations:
      securtiySimValues[name] = securtiySimValues[name] + reason.missing

      // push to override to fieldList
      fieldList.push({
        name,
        value: securtiySimValues[name],
      })
    }

    const ratingReason = filter(leftOverReasons, r => r.t === 'minRatingRequired' || r.t === 'maxRatingRequired' || r.t === 'noRating')

    if (some(ratingReason)) {
      fieldList.push({
        name: 'ratingLevel',
        value: ratingReason[0].missing || criteria.minRating,
      })
    }

    // TODO how do we want to handle that? could be capitalListed, price, rate or mixture...
    // for now stick to simulating marketValue(capitalListed * price) growth:
    // next handle marketValue using overriden values:
    const [publicMarketValueReason] = filter(leftOverReasons, r => r.t === 'minPublicMarketValue')

    const [marketValueReason] = filter(leftOverReasons, r => r.t === 'avgMarketValue' || r.t === 'minMarketValue')

    if (publicMarketValueReason || marketValueReason) {
      const capitalListedField = isAllBonds ? 'currTaseEffectiveDateIans' : 'capitalListedForTrading'
      const { freeFloatRate, avgPrice } = securtiySimValues
      const capitalListedForTrading = securtiySimValues?.[capitalListedField]
      const avgPriceNIS = avgPrice / 100

      let missingAvgMarketValue = 0

      if (publicMarketValueReason) {
        const minPublicMarketValue = criteria.minPublicMarketValue

        const currSimPublicMarketValue = capitalListedForTrading * avgPriceNIS * (freeFloatRate / 100)
        const missing = minPublicMarketValue - currSimPublicMarketValue
        if (missing > 0) {
          missingAvgMarketValue = missing / (freeFloatRate / 100)
        }
      }

      // ensure beating index limit if exists:
      // for this need to beat market value only (FFR does'nt matter)
      if (marketValueReason) {
        missingAvgMarketValue = max([marketValueReason.missing, missingAvgMarketValue])
      }

      if (missingAvgMarketValue) {
        const neededCapitalListed = capitalListedForTrading + missingAvgMarketValue / avgPriceNIS
        // eslint-disable-next-line
        const missingAvgPrice = avgPrice + (missingAvgMarketValue / capitalListedForTrading) * 100

        // TODO how to decide what to increase? for now use capitalListed only.
        // maybe move calculation up to state, and have a form update?
        fieldList.push({
          name: capitalListedField,
          value: ceil(neededCapitalListed),
        })
      }
    }

    const [turnoverMedianSpecsReason] = filter(leftOverReasons, r => r.t === 'turnoverMedianSpecs')
    if (turnoverMedianSpecsReason) {
      const { turnover, turnoverSpeed } = turnoverMedianSpecsReason.missing
      if (turnover) {
        fieldList.push({
          name: 'semiAnnualMedianTurnover',
          value: turnover + securtiySimValues.semiAnnualMedianTurnover,
        })
      }

      if (turnoverSpeed) {
        fieldList.push({
          name: 'semiAnnualTurnoverSpeedMedian',
          value: turnoverSpeed + securtiySimValues.semiAnnualTurnoverSpeedMedian,
        })
      }
    }

    return fieldList
  }, [criteria.minPublicMarketValue, criteria.minRating, row, security, isAllBonds])

  const overrideFieldList = React.useMemo(() => buildOverrideFieldList(), [buildOverrideFieldList])

  const goalSeekSecValues = {
    securityId: get(security, 'securityNo'),
    newSimulatedSecurity: false,
    fields: overrideFieldList,
  }

  const newSimValuesOverride = {
    ...newSimValues,
    conditions: [goalSeekSecValues],
  }

  const createGoalSeekSimulation = async () => {
    let newSim
    if (selectedSim) {
      newSim = selectedSim
      const selectedSecIdx = findIndex(selectedSim.conditions, sec => sec.securityId === goalSeekSecValues.securityId)
      if (selectedSecIdx !== -1) {
        // security is in simulation, override it's values
        for (const field of goalSeekSecValues.fields) {
          const currFieldIdx = findIndex(newSim.conditions[selectedSecIdx].fields, f => f.name === field.name)
          if (currFieldIdx === -1) {
            //field is currently null, add
            newSim.conditions[selectedSecIdx].fields.push(field)
          } else {
            // field is not null, choose highest.
            newSim.conditions[selectedSecIdx].fields[currFieldIdx].value = max([
              newSim.conditions[selectedSecIdx].fields[currFieldIdx].value,
              field.value,
            ])
          }
        }
      } else {
        //security not in sim, push
        newSim.conditions.push(goalSeekSecValues)
      }
    } else {
      newSim = newSimValuesOverride
    }

    // set new sim
    await setSimInitialValuesOverride(newSim)
    setSelectedSim(newSim)

    // push event to GA
    Analytics.event({
      category: 'Simulations',
      action: selectedSim ? 'Update simulation clicked (goal seek)' : 'Create simulation clicked (goal seek)',
      label: `${get(security, 'nameEnglish')}(${get(security, 'securityNo')}) into ${get(indexAlgoData, 'indexNameEng')}`,
    })
  }

  return (
    <div>
      <GoalSeekBtn onClick={() => createGoalSeekSimulation()}>
        <FontAwesomeIcon icon={faCrosshairs} /> {selectedSim ? t(`${tPrefix}.updateSim`) : t(`${tPrefix}.createSim`)}
      </GoalSeekBtn>
    </div>
  )
}
