import React from 'react'
import PropTypes from 'prop-types'

import { TotalElement } from './TotalElement'

import { TotalRowWrapper, Header, Wrapper } from './styles'

export const TotalRow = ({ data, header, headerColor, color, valueColor, flex, margin, isMetadataRow }) => {
  if (!data) return null

  return (
    <Wrapper flex={flex} margin={margin}>
      {header && <Header color={headerColor}>{header}</Header>}
      <TotalRowWrapper color={color}>
        {data.map((item, index) => (
          <TotalElement
            key={item.title + index}
            pos={index}
            value={item.value}
            format={item.format}
            title={item.title}
            tooltip={item.tooltip}
            badge={item.badge}
            color={item.color}
            valueColor={item.valueColor}
            subData={item.subData}
            isMetadataRow={isMetadataRow}
            direction={item.direction}
            valueGroup={item.valueGroup}
          />
        ))}
      </TotalRowWrapper>
    </Wrapper>
  )
}

TotalRow.defaultProps = {
  data: null,
  header: null,
  headerColor: null,
  color: null,
  flex: null,
  margin: null,
  isMetadataRow: false,
}

TotalRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
      subData: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    }),
  ),
  header: PropTypes.string,
  headerColor: PropTypes.string,
  color: PropTypes.string,
  flex: PropTypes.string,
  margin: PropTypes.string,
  isMetadataRow: PropTypes.bool,
}
