import React, { useContext, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import Context from 'context/Context'
import { ConditionSchema } from '../SimulationSchema'

import unionWith from 'lodash/unionWith'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { ConfirmDialog } from 'components/Common/ConfirmDialog'
import { RowForm } from './RowForm.jsx'
import { RowModalForm } from './RowModalForm'
import get from 'lodash/get'
import { defaultIndexInitialValues, SIMULATION_TYPES, equityFieldOrder, telbondFieldOrder } from './const'

const defaultFieldInitialValues = field => ({
  name: field.value,
  value: field.type === 'boolean' ? false : '',
})

const defaultSecurityInitialValues = fields => {
  return {
    securityId: '',
    newSimulatedSecurity: false,
    fields: fields.map(field => defaultFieldInitialValues(field)),
  }
}

export const RowFormik = ({
  condition,
  conditions,
  securityRowIndex,
  showSecurityRowForm,
  onRowUpdate,
  onDelete,
  securityList,
  fieldList,
  newRow,
  handleSimSubmit,
  setShowSecurityRowForm,
}) => {
  const { t } = useTranslation()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [submitCallback, setSubmitCallback] = useState(null)
  const { setSelectedSim, secType } = useContext(Context)
  const [enableValidation, setEnableValidation] = useState(false)

  const defaultValues = useMemo(() => {
    if (isEmpty(fieldList)) return null

    if (!condition) {
      // this is a new condition
      return defaultSecurityInitialValues(fieldList)
    }

    // this is not a new condition
    const { securityId, indexNo, indexName, weightDistMethod, weightCap, followingFundsValue, securityIds } = condition

    const defaultSecurityValues = defaultSecurityInitialValues(fieldList)

    const { newSimulatedSecurity, fields: simFields = [] } = condition
    let mergedFields = unionWith(
      simFields,
      defaultSecurityValues.fields,
      (simulationField, defaultField) => simulationField.name === defaultField.name,
    )
    mergedFields = mergedFields.filter(field => !!field.name)

    mergedFields = sortBy(mergedFields, field => {
      const orderList = secType === 'stock' ? equityFieldOrder : telbondFieldOrder
      const computedPos = findIndex(orderList, i => i === field.name)
      return computedPos !== -1 ? computedPos + 1 : orderList.length + 1
    })

    return {
      indexNo: indexNo || defaultIndexInitialValues.indexNo,
      indexName: indexName || defaultIndexInitialValues.indexName,
      weightDistMethod: weightDistMethod || defaultIndexInitialValues.weightDistMethod,
      weightCap: weightCap || defaultIndexInitialValues.weightCap,
      followingFundsValue: followingFundsValue || defaultIndexInitialValues.followingFundsValue,
      securityIds: securityIds || defaultIndexInitialValues.securityIds,
      securityId: securityId || defaultSecurityValues.securityId,
      newSimulatedSecurity: newSimulatedSecurity || defaultSecurityValues.newSimulatedSecurity,
      fields: mergedFields,
    }
  }, [condition, fieldList, secType])

  const generateNewId = useCallback(() => {
    for (let generatedId = 1000000; generatedId <= 1000100; generatedId++) {
      if (!conditions.find(row => row.securityId === generatedId || row.indexNo === generatedId)) return generatedId
    }
  }, [conditions])

  // filters initial values by new-sec-only/ old-sec-only fields.
  const getFilteredValues = useCallback(
    simType => {
      if (!defaultValues) return null

      // mutating initialValues
      const filteredValues = { ...defaultValues }

      const cleanupIndexFields = () => {
        delete filteredValues.indexNo
        delete filteredValues.securityIds
        delete filteredValues.weightDistMethod
        delete filteredValues.weightCap
        delete filteredValues.followingFundsValue
        delete filteredValues.indexName
      }

      const cleanupSecurityFields = () => {
        delete filteredValues.newSimulatedSecurity
        delete filteredValues.securityId
        delete filteredValues.fields
      }

      const filterFields = (fields, reject) => {
        return fields.filter(f => {
          const fieldOpts = find(fieldList, label => label.value === f.name)
          return !get(fieldOpts, reject)
        })
      }

      switch (simType) {
        case SIMULATION_TYPES.newSecurity:
          // clean up form
          cleanupIndexFields()

          if (filteredValues.fields) {
            filteredValues.fields = filterFields(filteredValues.fields, 'existingSecurityOnly')
          }
          filteredValues.newSimulatedSecurity = true
          filteredValues.securityId = generateNewId()
          break
        case SIMULATION_TYPES.newIndex:
          // clean up form
          cleanupSecurityFields()
          filteredValues.indexNo = defaultValues.indexNo || generateNewId()
          break
        case SIMULATION_TYPES.existingSecurity:
        default:
          // clean up form
          cleanupIndexFields()

          if (filteredValues.fields) {
            filteredValues.fields = filterFields(filteredValues.fields, 'newSecurityOnly')
          }

          filteredValues.newSimulatedSecurity = false
          break
      }

      return filteredValues
    },
    [defaultValues, fieldList, generateNewId],
  )

  const initialValues = useMemo(() => {
    if (!condition || condition.newSimulatedSecurity === false) {
      return getFilteredValues(SIMULATION_TYPES.existingSecurity)
    }

    if (condition.indexNo) {
      return getFilteredValues(SIMULATION_TYPES.newIndex)
    }

    return getFilteredValues(SIMULATION_TYPES.newSecurity)
  }, [condition, getFilteredValues])

  if (!initialValues) return null

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ConditionSchema}
      enableReinitialize
      validateOnChange={enableValidation}
      validateOnBlur={enableValidation}
      onSubmit={async (values, actions) => {
        setCancelDialogOpen(false)
        await onRowUpdate(values)
        actions.resetForm()
        await Promise.resolve()
        if (submitCallback) {
          submitCallback()
          setSubmitCallback(null)
        }
      }}
    >
      {({ resetForm, values, errors, touched }) => {
        return (
          <>
            <ConfirmDialog
              isOpen={cancelDialogOpen}
              text={t('simulation.deleteRowDialog.text')}
              buttons={[
                {
                  text: t('simulation.deleteRowDialog.returnToForm'),
                  color: 'secondary',
                  onClick: () => setCancelDialogOpen(false),
                },
                {
                  text: t('simulation.deleteRowDialog.cancel'),
                  color: 'outline-secondary',
                  onClick: () => {
                    //in case cancel when new sim and no changes we're submitted
                    if (!some(conditions) || !get(conditions[0], 'fields[0].value')) {
                      setSelectedSim(null)
                    } else if (!some(values.fields.filter(field => !!field.value))) {
                      onDelete()
                    }

                    resetForm()
                    setCancelDialogOpen(false)
                    setShowSecurityRowForm(null)
                  },
                },
              ]}
            />

            <RowModalForm
              showSecurityRowForm={showSecurityRowForm}
              setShowSecurityRowForm={setShowSecurityRowForm}
              setSubmitCallback={setSubmitCallback}
              setCancelDialogOpen={setCancelDialogOpen}
              securityRows={conditions}
              onDelete={onDelete}
            >
              <RowForm
                onDelete={onDelete}
                securityList={securityList}
                condition={condition}
                securityRows={conditions}
                showSecurityRowForm={showSecurityRowForm}
                setCancelDialogOpen={setCancelDialogOpen}
                newRow={newRow}
                securityRowIndex={securityRowIndex}
                setSubmitCallback={setSubmitCallback}
                setEnableValidation={setEnableValidation}
                handleSimSubmit={handleSimSubmit}
                setShowSecurityRowForm={setShowSecurityRowForm}
                fieldLabels={fieldList}
                filteredInitialValues={getFilteredValues}
              />
            </RowModalForm>
          </>
        )
      }}
    </Formik>
  )
}
