import styled from 'styled-components'
import { H2 } from 'styles/Typography'

export const LookupsBlockContainer = styled.div`
  margin-bottom: 50px;
  padding: 20px;
  background: #cde7fe;
`

export const LookupsTitle = styled(H2)`
  text-align: left;
`

export const HistoryRowForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  & > div {
    padding: 0px 20px;
  }
  margin-bottom: 10px;
`
