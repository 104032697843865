import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

export const LegendsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  @media (max-width: 1350px) {
    margin-inline-end: 16px;
  }
`

export const ColorBox = styled.rect`
  fill: ${props => props.color};
  stroke-width: 2;
  stroke: rgb(189 189 189);
`

export const LegendLabel = styled.div`
  margin: 0px 10px;
`

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 2px;
  font-weight: 400;
`
export const LegendSelector = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  width: 100%;
`
