import compact from 'lodash/compact'
import SecurityName from 'components/Common/Table/TableCell/SecurityName'

const tPrefix = 'common.table.colHeaders'

export const Columns = ({ t, secType }) => {
  const isEquity = secType === 'stock'
  const isAllBonds = secType === 'allBond'

  return compact([
    {
      id: 'securityName',
      Header: t('common.security'),
      accessor: row => row.securityName,
      Cell: SecurityName,
    },
    {
      id: 'indexName',
      Header: t('common.index'),
      accessor: 'indexName',
      cellFormat: { noFormat: true },
    },
    {
      id: 'securityId',
      Header: t('equity.generalSecurity.securityNo'),
      accessor: 'securityId',
      cellFormat: { noFormat: true },
    },
    {
      id: 'indexNo',
      Header: t(`${tPrefix}.indexNo`),
      accessor: 'indexNo',
      cellFormat: { noFormat: true },
    },
    {
      id: 'weight',
      Header: t(`${tPrefix}.currentWeight`),
      accessor: 'effectiveDateWeight',
      cellFormat: {
        format: '%*100.00',
      },
    },
    {
      id: 'projectedWeight',
      Header: t(`${tPrefix}.projectedWeight`),
      accessor: 'projectedWeight',
      cellFormat: {
        format: '%*100.00',
      },
    },
    {
      id: 'weightFactor',
      Header: t(`${tPrefix}.currentWeightFactor`),
      accessor: 'weightFactor',
      cellFormat: { format: 'float' },
    },
    (isEquity || isAllBonds) && {
      id: 'projectedWeightFactor',
      Header: t(`${tPrefix}.projectedWeightFactor`),
      accessor: 'projectedWeightFactor',
      cellFormat: { format: 'float' },
    },
    (isEquity || isAllBonds) && {
      id: 'taseProjectedWeightFactor',
      Header: t(`${tPrefix}.taseProjectedWeightFactor`),
      accessor: 'taseProjectedWeightFactor',
      cellFormat: { format: 'float' },
    },
    {
      id: 'projectedWeightFactorDelta',
      Header: t(`${tPrefix}.projectedWeightFactorDelta`),
      accessor: row => {
        const { projectedWeightFactor, taseProjectedWeightFactor } = row
        const diff = Math.abs(projectedWeightFactor - taseProjectedWeightFactor)
        return diff && diff >= 0.00001 ? diff : 0
      },
      cellFormat: { format: 'float' },
    },
  ])
}
