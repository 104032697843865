import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

export const NoSubscriptionModal = ({ show, onHide, secType }) => {
  const { t } = useTranslation()
  const tPrefix = 'loginPage'
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`${t(`${tPrefix}.subscribeTo`)} ${secType} ${t(`${tPrefix}.indexes`)}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p> {t(`${tPrefix}.notSubscribeMessage`)}</p>
      </Modal.Body>
      <Modal.Footer centered={true}>
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  )
}
