import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

import { H2 } from 'styles/Typography'

export const ServerError = styled.span`
  display: inline-block;
  color: #dd3d4d;
  height: 1.5rem;
  text-align: center;
  width: 100%;
`

export const RegisterFormWrapper = styled.div`
  padding: 20px;
  background: #d7d7d7;
`

export const Title = styled(H2)`
  margin-bottom: 1rem;
`

export const CustomCol = styled(Col)`
  align-self: flex-start;

  @media (max-width: 567px) {
    flex: 0 0 100%;
  }
`
