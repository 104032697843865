import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
//TODO: update this list.

const TASE_DAYS_OFF_2020 = ['8/4', '9/4', '14/4', '15/4', '28/4', '29/4', '28/5', '29/5', '30/7', '20/9', '27/9', '28/9']
const TASE_DAYS_OFF_2021 = [
  '26/2',
  '23/3',
  '28/3',
  '2/4',
  '14/4',
  '15/4',
  '16/5',
  '17/5',
  '18/7',
  '6/9',
  '7/9',
  '8/9',
  '15/9',
  '16/9',
  '20/9',
  '21/9',
  '27/9',
  '28/9',
]
const TASE_DAYS_OFF_2022 = [
  '17/3',
  '18/3',
  '15/4',
  '21/4',
  '22/4',
  '4/5',
  '5/5',
  '5/6',
  '7/8',
  '25/9',
  '26/9',
  '27/9',
  '4/10',
  '5/10',
  '9/10',
  '10/10',
  '16/10',
  '17/10',
  '1/11', // election day.
]

const TASE_DAYS_OFF_2023 = [
  '7/3',
  '8/3',
  '5/4',
  '6/4',
  '11/4',
  '12/4',
  '25/4',
  '26/4',
  '25/5',
  '26/5',
  '27/7',
  '15/9',
  '17/9',
  '24/9',
  '25/9',
  '29/9',
  '6/10',
]

const TASE_DAYS_OFF_2024 = [
  '27/2',
  '24/3',
  '22/4',
  '23/4',
  '28/4',
  '29/4',
  '13/5',
  '14/5',
  '11/6',
  '12/6',
  '13/8',
  '2/10',
  '3/10',
  '4/10',
  '11/10',
  '16/10',
  '17/10',
  '23/10',
  '24/10',
]

const TASE_DAYS_OFF_2025 = [
  '14/3',
  '13/4',
  '18/4',
  '30/4',
  '1/5',
  '1/6',
  '2/6',
  '3/8',
  '22/9',
  '23/9',
  '24/9',
  '1/10',
  '2/10',
  '6/10',
  '7/10',
  '13/10',
  '14/10',
]

const addYear = (taseShortDate, year) => [year, ...taseShortDate.split('/').reverse()].join('-')

const TASE_DAYS_OFF = new Set(
  [
    TASE_DAYS_OFF_2020.map(e => addYear(e, 2020)),
    TASE_DAYS_OFF_2021.map(e => addYear(e, 2021)),
    TASE_DAYS_OFF_2022.map(e => addYear(e, 2022)),
    TASE_DAYS_OFF_2023.map(e => addYear(e, 2023)),
    TASE_DAYS_OFF_2024.map(e => addYear(e, 2024)),
    TASE_DAYS_OFF_2025.map(e => addYear(e, 2025)),
  ].flat(),
)

export const isTaseValidDay = refTime => {
  const refTimeDayjs = dayjs(refTime)
  if (TASE_DAYS_OFF.has(refTimeDayjs.format('YYYY-M-D'))) {
    return false
  }

  const dow = refTimeDayjs.day()
  if (dow > 4) {
    return false
  }

  return true
}

function calcTASETradingDays(numOfDays, refTime, directionMomentMethod) {
  refTime = dayjs(refTime)
    .utc()
    .startOf('day')
  const res = []
  if (isTaseValidDay(refTime)) {
    res.push(refTime)
  }
  let dayAhead = refTime[directionMomentMethod](1, 'days')
  let retries = numOfDays + 10
  while (numOfDays > res.length && retries > 0) {
    if (isTaseValidDay(dayAhead)) {
      res.push(dayAhead)
    } else {
      retries--
    }
    dayAhead = dayAhead[directionMomentMethod](1, 'days')
  }

  return res
}

export const calcNextTASETradingDays = (numOfDays, refTime) => calcTASETradingDays(numOfDays, refTime, 'add')
export const calcLastTASETradingDays = (numOfDays, refTime) => calcTASETradingDays(numOfDays, refTime, 'subtract')
