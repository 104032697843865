import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import FullStory from 'react-fullstory'
import Container from 'react-bootstrap/Container'

import { Admin } from 'screens/admin'
import { SimulationsManagement } from 'screens/SimulationsManagement'
import { User } from 'screens/user'
import { ForgotPassword, ResetPassword } from 'screens/login/resetPassword'
import { Login } from 'screens/login'
import { DataManagment } from 'screens/dataManagment'
import { StocksScreen } from 'screens/stocks'
import { AllBondsScreen } from 'screens/allBonds'
import { NoSubscription } from 'screens/noSubscription'
import { ProtectedRoute } from 'components/Common/ProtectedRoute'
import { Navbar } from 'components/General/navbar'
import { ContextProvider } from 'context/Context'
import { SimulationWrapper } from 'components/Simulation/SimulationWrapper'
import { Title } from 'components/Title/Title'

import { isProduction } from './utils/appEnv'
import { useGoogleAnalytics, initializeFullStoryAnalytics, useOpenReplay } from './services/analytics'

import 'scss/custom.scss'
import ReformTab from 'components/General/navbar/Reform/ReformMsg'

const NotFound = () => <h1>404 NOT FOUND</h1>

if (isProduction) {
  initializeFullStoryAnalytics()
  setTimeout(initializeFullStoryAnalytics, 5000)
} else {
  console.log('not prod, analytics not initialized')
}

const App = () => {
  useOpenReplay()
  useGoogleAnalytics()
  const { t } = useTranslation()
  return (
    <div data-testid="App">
      {isProduction && <FullStory org="RZVB5" />}
      <ContextProvider>
        <span
          style={{
            direction: t('get_current_language_code') === 'en' ? 'ltr' : 'rtl',
          }}
        >
          <Title />
          <SimulationWrapper>
            <Navbar />
          </SimulationWrapper>

          <Container fluid>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/resetPassword" component={ResetPassword} />
              <ProtectedRoute adminOnly={false} exact path="/user" component={User} />
              <ProtectedRoute adminOnly={false} exact path="/allBonds/:tab" component={AllBondsScreen} />
              <ProtectedRoute adminOnly={false} exact path="/stocks/:tab" component={StocksScreen} />
              <ProtectedRoute adminOnly={false} exact path="/NewBondIndices" component={ReformTab} />
              <ProtectedRoute adminOnly={false} exact path="/noSubscription" component={NoSubscription} />
              <ProtectedRoute adminOnly exact path="/admin/users" component={Admin} />
              <ProtectedRoute adminOnly exact path="/admin/users/simulations" component={SimulationsManagement} />
              <ProtectedRoute adminOnly exact path="/data-managment" component={DataManagment} />
              <Redirect to="/" />
              <Route path="*" component={NotFound} />
            </Switch>
          </Container>
        </span>
      </ContextProvider>
    </div>
  )
}

export default App
