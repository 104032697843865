import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link } from 'react-router-dom'

import axiosApi from 'utils/axiosApi'
import { NoSubscriptionModal } from 'components/General/navbar/NoSubscriptionModal'
import { StyledBadge, StyledNavLink } from './styles'
import { isEng } from 'i18n'

export const NavLink = ({ linkTo, label, samePath, badge, comingSoon, striked, isLocked }) => {
  const [showNoSubscriptionModal, setShowNoSubscriptionModal] = useState(false)

  const handleClick = e => {
    if (comingSoon || isLocked) e.preventDefault()

    if (isLocked) {
      setShowNoSubscriptionModal(true)
      axiosApi.post('/users/track', { action: 'click', target: 'locked-link', metadata: { linkTo } })
    }
  }
  const marginRight = isEng ? '0' : '5px'
  return (
    <>
      <NoSubscriptionModal show={showNoSubscriptionModal} onHide={() => setShowNoSubscriptionModal(false)} secType={label} />

      <StyledNavLink
        className={[comingSoon && 'disabled-link', samePath && 'current']}
        striked={striked}
        as={Link}
        to={linkTo}
        onClick={e => handleClick(e)}
      >
        {`${label} ${isLocked ? '🔒' : ''}`}
        {badge && <StyledBadge style={{ marginRight }}>{badge}</StyledBadge>}
      </StyledNavLink>
    </>
  )
}

NavLink.defaultProps = {
  comingSoon: null,
  samePath: false,
}

NavLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  samePath: PropTypes.bool,
  comingSoon: PropTypes.string,
}
