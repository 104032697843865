import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import axiosApi from 'utils/axiosApi'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { Loader } from 'components/Common/Loader'
import { DataManagmentTaseBlock } from 'components/Admin/JobSelector/DataManagmentTaseBlock'
import { DataManagmentLookupsBlock } from 'components/Admin/JobSelector/DataManagmentLookupsBlock'
import { DataManagmentHistoryBlock } from 'components/Admin/JobSelector/DataManagmentHistoryBlock'
import { DataManagmentAlgoBlock } from 'components/Admin/JobSelector/DataManagmentAlgoBlock'
import { DataManagementWatchDogBlock } from 'components/Admin/JobSelector/DataManagementWatchDogBlock'
import { DataManagementAgendaBlock } from './DataMangementAgendaBlock'
import { JobsQueue } from './JobsQueue'

import { DataManagmentWrapper } from './styles'

export const JobSelector = () => {
  // for tasks data - status, last run, etc.
  const [allJobs, setAllJobs] = useState({})
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    const fetchJobs = async () => {
      setFetching(true)
      const res = await axiosApi.get('/jobs')
      setAllJobs(get(res, 'data') ? res.data : [])
      setFetching(false)
    }

    fetchJobs()
  }, [])

  const formatTime = time => {
    if (!time) return 'N/A'
    return dayjs(time).format('DD/MM/YYYY, h:mm:ss a')
  }

  const getJobDefinition = (name, type) => {
    if (type === 'tase' || type === 'lookup') {
      if (name === 'ALL LOOKUPS' || name === 'ALL TASE') return 'REPROCESS_ALL'
      return 'REPROCESS'
    }

    if (name.includes('algo')) return 'RUN_ALGO'

    if (name.includes('History')) return 'HISTORY'

    return null
  }

  const buildJobData = (definition, job) => {
    switch (definition) {
      case 'RUN_ALGO':
        const { category, updateType, effectiveDate, ...rest } = job
        return {
          secType: category,
          algoType: updateType,
          taseEffectiveDate: effectiveDate,
          ...rest,
        }
      case 'REPROCESS_ALL':
        return { registry: job.name, effectiveDate: job.effectiveDate }
      case 'HISTORY':
      case 'REPROCESS':
      default:
        return { model: job.name, effectiveDate: job.effectiveDate }
    }
  }

  const updateJobStatus = (type, name, status, label) => {
    const _allJobs = { ...allJobs }
    const jobPos = label ? findIndex(_allJobs[type], { name, label }) : findIndex(_allJobs[type], { name })
    _allJobs[type][jobPos].status = status
    setAllJobs(_allJobs)
  }

  const startJob = async (job, type) => {
    const { name, label } = job
    updateJobStatus(type, name, 'pendning', label)

    const definition = getJobDefinition(name, type)
    const data = buildJobData(definition, job)

    try {
      const res = await axiosApi.post('jobs/', { definition, data })

      if (get(res, 'data.ok')) {
        updateJobStatus(type, name, 'done', label)
      } else {
        console.log(`error in starting job '${name}'`, res)
        updateJobStatus(type, name, 'failed', label)
      }
    } catch (err) {
      console.log(`error in starting job '${name}'`, err)
      updateJobStatus(type, name, 'failed', label)
    }
  }

  const getLastRecords = job => {
    return axiosApi
      .get('jobs/getLastRecords', { params: { model: job } })
      .then(response => {
        if (response.data) {
          console.log(`printing all recoreds from past 30 days for job ${job}`, response.data)
        }
      })
      .catch(e => {
        console.log(`error in fetching records at job '${job}'`)
      })
  }

  const getJobBtnStyle = job => {
    const status = get(job, 'status')
    switch (status) {
      case 'pending':
        return 'outline-warning'
      case 'done':
        return 'outline-success'
      case 'failed':
        return 'outline-danger'
      default:
        return 'outline-secondary'
    }
  }

  const blocks = [
    DataManagmentAlgoBlock,
    DataManagmentLookupsBlock,
    DataManagmentTaseBlock,
    DataManagmentHistoryBlock,
    DataManagementWatchDogBlock,
    DataManagementAgendaBlock,
  ]

  return (
    <DataManagmentWrapper>
      {fetching ? (
        <Loader />
      ) : (
        <>
          <JobsQueue />
          {blocks.map((Block, i) => (
            <Block
              update={startJob}
              allJobs={allJobs}
              formatTime={formatTime}
              getLastRecords={getLastRecords}
              getJobBtnStyle={getJobBtnStyle}
              key={i}
            />
          ))}
        </>
      )}
    </DataManagmentWrapper>
  )
}
