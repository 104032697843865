import React, { useContext } from 'react'

import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import axiosApi from 'utils/axiosApi'
import { AuthContext } from 'context/AuthContext'

import { Input } from 'components/Common/input'
import { SmsLoginSchema } from './smsLoginSchema'
import { LoginButton, Separator, FormDescription } from '../styles'
import { ERROR_RESPONSES } from 'errors/login/smsLogin'

export const SmsLogin = ({ handleSuccessfulLoginResponse }) => {
  const { t } = useTranslation()
  const { setLoginResponse, email, phoneEnding } = useContext(AuthContext)

  const submitForm = async (formValues, { setErrors }) => {
    try {
      const pinCode = formValues.pinCode
      const response = await axiosApi.post('/checkPincodeAndLogin', { email, pinCode })
      setLoginResponse(response)
      handleSuccessfulLoginResponse(response)
    } catch (error) {
      if (error.message === 'Request failed with status code 504') {
        setErrors({ email: t('generalErrors.noResponse') })
        return
      }
      const errorName = error?.response?.data?.errors?.msg?.[0]?.param
      switch (errorName) {
        case ERROR_RESPONSES.BAD_PHONE_NUMBER:
          setErrors({ pinCode: t('smsLogin.form.pinCode.errors.badPhoneEntered') })
          break
        case ERROR_RESPONSES.WRONG_PIN_CODE:
          setErrors({ pinCode: t('smsLogin.form.pinCode.errors.invalidPinCode') })
          break
        case ERROR_RESPONSES.PIN_CODE_EXPIRED:
          setErrors({ pinCode: t('smsLogin.form.pinCode.errors.pinCodeExpired') })
          break
        case ERROR_RESPONSES.BLOCKED_USER:
          setErrors({ pinCode: t('smsLogin.form.pinCode.errors.blockedUser') })
          break
        case ERROR_RESPONSES.BLOCKED_SUBSCRIPTION:
          setErrors({ pinCode: t('smsLogin.form.pinCode.errors.blockedSubscription') })
          break
        default:
          setErrors({ pinCode: t('generalErrors.unknownError') })
      }
    }
  }

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Formik initialValues={{ pinCode: '' }} onSubmit={submitForm} validationSchema={SmsLoginSchema} validateOnChange={false} validateOnBlur={false}>
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <FormDescription>{`${t('smsLogin.form.description')}: ${phoneEnding}`}</FormDescription>
              <Input
                name="pinCode"
                type="text"
                onBlur={handleBlur}
                label={null}
                placeholder={t('smsLogin.form.pinCode.label')}
                isValid={false}
                isInvalid={!!errors.pinCode && touched.pinCode}
                value={values.pinCode}
                errorText={t(errors.pinCode)}
                maxLength="6"
                onChange={handleChange}
                style={{ direction: 'ltr', textAlign: 'center' }}
              />
              <div style={{ height: '1.5rem' }} />
              <LoginButton type="submit" icon={faLock} margin={errors.pinCode ? '1rem' : '0.8rem'}>
                {t('smsLogin.form.submit')}
              </LoginButton>
              <Separator />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
