import styled from 'styled-components'

export const DialogLayout = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: absolute;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  background: #00000085;
  position: fixed;
  top: 0;
  z-index: 9999999;
`

export const DialogContainer = styled.div`
  width: max-content;
  height: max-content;
  z-index: 9;
  background: white;
  margin-top: 50px;
  padding: 30px;
  border-radius: 5px;
`

export const DialogText = styled.div`
  text-align: center;
  color: ${props => (props.textColor ? props.textColor : 'black')};
`

export const DialogButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
`
