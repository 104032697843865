import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

export const InlinedFormGroup = styled(Form.Group)`
  display: flex;
  align-items: felx-start;
  margin-bottom: unset;
  gap: 0.5rem;
  width: 100%;
`

export const TrashButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => (props.isFirst ? '2.4rem;' : '0.5rem')};
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const RowModal = styled(Modal)`
  direction: ltr;
  text-align: left;
`

export const RowModalRTL = styled(Modal)`
  direction: rtl;
  text-align: right;
`
export const RowModalHeader = styled(Modal.Header)`
  & > .close {
    margin: -1rem;
  }
`

export const WeekSpan = styled.span`
  font-size: 0.9rem;
  color: #6c757d;
  font-weight: 400;
`
