import React from 'react'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import isFunction from 'lodash/isFunction'

import {
  ColorBox,
  LegendsWrapper,
  LegendLabel,
  LegendItem,
  LegendSelector
} from './styles'

export const TableLegend = ({
  legends,
  globalFilter,
  setGlobalFilter,
}) => {
  const handleLegendClick = React.useCallback(
    legend => {
      let legendFilters = get(globalFilter, 'legendFilters')

      if (some(legendFilters)) {
        const index = legendFilters.findIndex(legendFilter =>
          isEqual(legendFilter, legend.filter),
        )
        if (index !== -1) {
          legendFilters.splice(index, 1)
        } else {
          legendFilters.push(legend.filter)
        }
      } else {
        legendFilters = [legend.filter]
      }

      setGlobalFilter({ ...globalFilter, legendFilters })
    },
    [globalFilter, setGlobalFilter],
  )

  const isPressed = React.useCallback(
    legend => {
      const legendFilters = get(globalFilter, 'legendFilters')

      if (some(legendFilters)) {
        const index = legendFilters.findIndex(legendFilter =>
          isEqual(legendFilter, legend.filter),
        )
        return index !== -1
      } else return false
    },
    [globalFilter],
  )

  const legendBlock = legend => (
    <>
      {legend.color && <svg width="15" height="15">
        <ColorBox width="15" height="15" color={legend.color} />
      </svg>}
      <LegendLabel>{legend.label}</LegendLabel>
    </>
  )

  return (
    <LegendsWrapper>
      {legends.map(legend => (
        <LegendItem key={legend.label}>
          {isFunction(legend.filter) ? (
            <LegendSelector
              variant={isPressed(legend) ? 'secondary' : 'light'}
              onClick={() => handleLegendClick(legend)}
            >
              {legendBlock(legend)}
            </LegendSelector>
          ) : (
            legendBlock(legend)
          )}
        </LegendItem>
      ))}
    </LegendsWrapper>
  )
}
