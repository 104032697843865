import React, { useContext } from 'react'
import Form from 'react-bootstrap/Form'

import { Formik } from 'formik'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import axiosApi from 'utils/axiosApi'
import { AuthContext } from 'context/AuthContext'

import { loginScenariosDictionary } from '../loginScenariosDictionary'
import { InitializeLoginSchema } from './initializeLoginSchema'
import { Input } from 'components/Common/input'
import { LoginButton, Separator } from '../styles'
import { LanguageSwitcher } from 'components/General/LanguageSwitcher'
import { Text } from 'styles/Typography'
import { Button } from 'components/Common/button'

export const InitializeLogin = () => {
  const { t } = useTranslation()
  const { setLoginScenario, setEmail, setPhoneEnding } = useContext(AuthContext)

  const submitForm = async (formValues, { setErrors }) => {
    try {
      const email = formValues.email
      setEmail(email)
      const response = await axiosApi.post('/initializeLogin', { email })
      setNextLoginStepByResponse(response, setLoginScenario)
    } catch (error) {
      submitFormErrorHandling(error, setErrors)
    }
  }

  const setNextLoginStepByResponse = response => {
    switch (response.data.method) {
      case 'sms login':
        setLoginScenario(loginScenariosDictionary.smsLogin)
        setPhoneEnding(response.data.phoneEnding)
        break
      case 'password login':
        setLoginScenario(loginScenariosDictionary.passwordLogin)
        break
      case 'phone registration':
        setLoginScenario(loginScenariosDictionary.phoneRegistration)
        break
      default:
        throw new Error('Unknown login method')
    }
  }

  const submitFormErrorHandling = (error, setErrors) => {
    if (error.message === 'Request failed with status code 504') {
      setErrors({ email: t('generalErrors.noResponse') })
      return
    }
    const errorName = error?.response?.data?.errors?.msg?.[0]?.param
    const errorsPrefix = 'initializeLogin.form.email.errors.'
    switch (errorName) {
      case 'email':
        setErrors({ email: t(errorsPrefix + 'badEmailFormat') })
        break
      case 'USER_NOT_FOUND':
        setErrors({ email: t(errorsPrefix + 'userNotFound') })
        break
      case 'TOO_MANY_SMS_SENT':
        setErrors({ email: t(errorsPrefix + 'tooManySmsSent') })
        break
      case 'BAD_PHONE_NUMBER':
        setErrors({ email: t(errorsPrefix + 'badPhoneNumber') })
        break
      default:
        setErrors({ email: t('generalErrors.unknownError') })
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={submitForm}
        validationSchema={InitializeLoginSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Input
                name="email"
                type="email"
                onBlur={handleBlur}
                label={null}
                placeholder={t('initializeLogin.form.email.label')}
                isValid={false}
                isInvalid={!!errors.email && touched.email}
                value={values.email}
                errorText={t(errors.email)}
                onChange={handleChange}
                style={{ direction: 'ltr', textAlign: 'center' }}
              />

              <LoginButton type="submit" icon={faLock} margin={errors.email ? '1rem' : '0.8rem'}>
                {t('initializeLogin.form.submit')}
              </LoginButton>
              <Separator />
            </Form>
          )
        }}
      </Formik>
      <Text>{t('new_to_smart_bull')}</Text>
      <Button block secondary="true">
        <a
          href={'https://docs.google.com/forms/d/e/1FAIpQLSetataMQJxlD1Y5W554jC7xi-y82-s_94qSiaoImP9NyuAdvA/viewform?usp=sf_link'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('loginPage.createAccount')}
        </a>
      </Button>
      <LanguageSwitcher />
    </React.Fragment>
  )
}
