const { isProduction, isStaging, isDevelopment } = (() => {
  if (window && window.location) {
    const isProduction = /indexes.smartbull.co.il/.test(window.location.href)
    const isStaging = !isProduction && /herokuapp/.test(window.location.href)
    const isDevelopment = !(isProduction || isStaging)

    return {
      isProduction,
      isStaging,
      isDevelopment,
    }
  } else {
    console.error('no window nor window.location...')
    return {
      isProduction: false,
      isStaging: false,
      isDevelopment: true,
    }
  }
})()

if (isStaging) console.log('staging env')
if (isDevelopment) console.log('development env')
if (isProduction) console.log('Welcome to SmartBull Indexes')

export { isDevelopment, isStaging, isProduction }
