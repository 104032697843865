import React, { useState } from 'react'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { AlgoFormLabel, AlgoRowForm } from './styles'

export const RunAllAlgoRow = ({ job, handleAlgoSubmit, getJobBtnStyle }) => {
  const [includeHistoryFlag, setIncludeHistoryFlag] = useState(false)

  return (
    <AlgoRowForm>
      <AlgoFormLabel>{job.label}:</AlgoFormLabel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form.Check
          type="switch"
          id={`include-history-switch_${job.label}`}
          label="include History"
          checked={includeHistoryFlag}
          onChange={e => setIncludeHistoryFlag(e.target.checked)}
        />
      </div>
      <Button
        variant={getJobBtnStyle}
        onClick={() =>
          handleAlgoSubmit(
            {
              ...job,
              includeHistory: includeHistoryFlag,
            },
            'algo',
          )
        }
      >
        Run {job.category} {job.updateType} Algo
      </Button>
    </AlgoRowForm>
  )
}
