import styled from 'styled-components'
import Popover from 'react-bootstrap/Popover'

export const CustomPopover = styled(Popover)`
  padding: 5px;
  max-width: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
  overflow: auto;
`

export const IconWrapper = styled.span`
  padding: 5px;
  line-height: initial;
`
