import styled from 'styled-components'

export const ExternalLinkInner = styled.a`
  margin: 0px 0.2rem;
  &:hover {
    color: #4379b3;
  }
  ${props =>
    props.color &&
    `
		color: ${props.color}!important;
	`}
`

export const WithDirection = styled.div`
  ${props =>
    props.direction &&
    `
    direction: ${props.direction};
    `}
`

export const CellImage = styled.img`
  max-height: 20px;
`
