import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'

import { isHeb } from '../../../i18n'
import some from 'lodash/some'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import {
  StyledToggleButton,
  StyledToggleButtonInner,
  StyledToggleButtonExtras,
} from './styles'

dayjs.extend(weekday)

export const DatePicker = ({
  dates,
  onDateChange,
  initialValue,
  extraData,
  disabled
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(initialValue || 0)

  useEffect(() => {
    onDateChange(dates[value])
  }, [value, dates, onDateChange])

  return (
    <ToggleButtonGroup
      size="md"
      value={value}
      onChange={index => setValue(index)}
      type="radio"
      defaultValue={0}
      name="effectiveDate"
      style={
        isHeb()
          ? {
              marginRight: '10px',
            }
          : { marginLeft: '10px' }
      }
    >
      {dates.map((date, index) => (
        <StyledToggleButton
          variant="outline-warning"
          key={index}
          value={index}
          direction={isHeb() ? 'rtl' : 'ltr'}
          disabled={disabled}

        >
          <StyledToggleButtonInner className="data-picker-inner"
            style={dayjs(date).isBefore(dayjs()) ? { color: 'orange' } : {}}
            disabled={disabled}
          >
            <div>{dayjs(date).format('DD/MM')} </div>{' '}
            <div>{t(`dayOfWeek.${dayjs(date).weekday()}`)}</div>
          </StyledToggleButtonInner>
          {some(extraData) && (
            <StyledToggleButtonExtras>
              {extraData[index]}
            </StyledToggleButtonExtras>
          )}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
