import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import urljoin from 'url-join'

import Context from 'context/Context'
import compact from 'lodash/compact'
import { isEng } from '../../../../i18n'
import { SCREEN } from 'utils/const'

import { TableCell } from 'components/Common/Table/TableCell'
import DualListedBadge from 'components/Common/Table/TableCellExtraContent/DualListedBadge'
import LinkToTase from 'components/Common/Table/TableCellExtraContent/LinkToTase'
import NewStockBadge from 'components/Common/Table/TableCellExtraContent/NewStockBadge'
import RemovalBadge from 'components/Common/Table/TableCellExtraContent/RemovalBadge'
import FastTrackIndexes from 'components/Common/Table/TableCellExtraContent/FastTrackIndexes'
import SpecialEventBadge from 'components/Common/Table/TableCellExtraContent/SpecialEventBadge'
import GradualUniverseFactorBadge from 'components/Common/Table/TableCellExtraContent/GradualUniverseFactorBadge'
import IssuerLimitBadge from 'components/Common/Table/TableCellExtraContent/IssuerLimitBadge'

export default function(row) {
  const history = useHistory()
  const { secType } = useContext(Context)

  const pathPrefix = SCREEN[secType]

  const { original } = row.row
  const secId = original.securityId || original.securityNo
  let secName = isEng() ? original.nameEnglish || original.securityName : original.nameHebrew || original.securityNameHebrew

  // minor patch, sometimes only securityName is returned
  if (!secName && original.securityName) {
    secName = original.securityName
  }
  const tradeCodeNo = row.row.original.tradeCodeNo

  let search
  if (history?.location?.query) {
    const { algoType, taseEffectiveDate } = history.location.query
    search = `?algoType=${algoType}&taseEffectiveDate=${taseEffectiveDate}`
  } else {
    search = history?.location?.search
  }

  const linkToSec = secId && urljoin(`/${pathPrefix}/security/`, search, `&security=${secId}`)
  let extraContent = [
    LinkToTase(row),
    FastTrackIndexes(row),
    DualListedBadge({ tradeCodeNo }),
    NewStockBadge({ data: original }),
    RemovalBadge({ data: original }),
    GradualUniverseFactorBadge({ data: original }),
    SpecialEventBadge({ data: original }),
    IssuerLimitBadge({ data: original }),
  ]
  return <TableCell value={secName} link={linkToSec} extraContent={compact(extraContent)} direction={isEng() ? 'ltr' : 'rtl'} />
}
