import { useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'

import axiosApi from 'utils/axiosApi'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import { isEng } from '../../i18n'
import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'
import { AlgoTypes, SecTypes } from 'utils/const'
import { RatingOpts } from 'utils/ratingUtils'
import { getSectorSelectOptions } from 'utils/sectorUtils'

import some from 'lodash/some'
import isString from 'lodash/isString'
import get from 'lodash/get'
import find from 'lodash/find'
import toNumber from 'lodash/toNumber'
import trim from 'lodash/trim'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'

export const useDefaultSimName = values => {
  const [securityList] = useSimulationSecurityList()
  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const { secType } = useContext(Context)
  const [computedName, setComputedName] = useState('')
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    const conditions = get(values, 'conditions')

    const buildSecuritiesString = () => {
      const securities = conditions?.filter(condition => condition.securityId)

      if (securities && some(securities) && some(securityList)) {
        const distinctSecurities = securities.map(securityRow => {
          const { securityId, newSimulatedSecurity } = securityRow
          let secName = newSimulatedSecurity
            ? // get from form:
              get(
                find(securityRow.fields, field => field.name === 'securityName'),
                'value',
              )
            : //get from security list:
              get(
                // eslint-disable-next-line
                find(securityList, secItem => secItem.value === securityId),
                'label',
              )

          //remove secIds for sim name
          secName = secName ? trim(secName.replace(/\([^()]*\)/g, '')) : null

          return secName
        })

        return ` (${compact(distinctSecurities).join(', ')}) `
      } else return ' '
    }

    const buildIndexString = () => {
      const indexes = conditions?.filter(condition => condition.indexNo)

      if (indexes && some(indexes)) {
        const distinctIndexes = indexes.map(indexRow => {
          const { indexNo, indexName } = indexRow
          return indexName ?? indexNo
        })

        return ` (${distinctIndexes.join(', ')}) `
      } else return ' '
    }

    const getDefaultName = () => {
      let _secType
      switch (secType) {
        case SecTypes.allBond:
          _secType = t('menu.allBonds')
          break
        case SecTypes.corpBond:
          _secType = t('menu.corpBonds')
          break
        case SecTypes.govBond:
          _secType = t('menu.govBonds')
          break
        case SecTypes.stock:
        default:
          //default to stock
          _secType = t('menu.stocks')
          break
      }

      let _algoType
      if (algoType === 'components') {
        if (secType === SecTypes.allBond) {
          _algoType = t('simulation.general.monthlyComponents')
        } else {
          _algoType = t('simulation.general.components')
        }
      } else {
        if (secType === 'stock') {
          _algoType = t('simulation.general.parameters')
        } else {
          // bonds rebalance
          _algoType = t('simulation.general.parametersDaily')
        }
      }

      const _date = dayjs(taseEffectiveDate).format('DD/MM/YY')

      const base = `${_secType} - ${_algoType} - ${_date}`

      const securitiesString = buildSecuritiesString()
      const indexesString = buildIndexString()

      return `${base}${securitiesString}${indexesString}`
    }

    const defaultName = getDefaultName()
    setComputedName(defaultName)
  }, [values, algoType, secType, t, taseEffectiveDate, securityList])

  useEffect(() => {
    setFieldValue('computedName', computedName)
  }, [computedName, setFieldValue])

  return computedName
}

export const useDefaultSimValues = () => {
  return {
    computedName: '',
    name: '',
    conditions: [
      {
        // security conditions:
        securityId: '',
        newSimulatedSecurity: false,
        fields: [
          {
            name: '',
            value: '',
          },
        ],

        // index conditions:
        indexNo: '',
        indexName: '',
        hasWeightCap: false,
        weightCap: 0,
        followingFundsValue: 0,
        securityIds: [],
        isEqualWeight: true,
      },
    ],
  }
}

export const useFieldsList = () => {
  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const { secType } = useContext(Context)
  const [issuersList, fetchingIssuersList] = useIssuersList()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const [fieldList, setFieldList] = useState([])

  const buildSelectOptions = useCallback(
    (options, localePrefix) =>
      options.map(opt => ({
        label: t(`${localePrefix}.${opt}`),
        value: opt,
      })),
    [t],
  )

  const linkageOptions = useMemo(() => buildSelectOptions(['CPI', 'Dollar', 'Other', 'Unlinked'], 'simulation.form.linkageTypes'), [
    buildSelectOptions,
  ])

  const interestOptions = useMemo(() => buildSelectOptions(['Fixed', 'Variable'], 'simulation.form.interestTypes'), [buildSelectOptions])

  const commonFields = useMemo(
    () =>
      compact([
        {
          value: 'closingPrice',
          label: t('simulation.form.fields.closingPrice'),
          type: 'number',
          securityOnly: true,
        },
        {
          value: 'securityName',
          label: t('simulation.form.fields.securityName'),
          type: 'text',
          securityOnly: true,
          newSecurityOnly: true,
        },
        {
          value: 'foreignIssuer',
          label: t('simulation.form.fields.foreignIssuer'),
          type: 'boolean',
          securityOnly: true,
          newSecurityOnly: true,
        },
        {
          value: 'sector',
          label: t('simulation.form.fields.sector'),
          type: 'select',
          securityOnly: true,
          newSecurityOnly: true,
          options: getSectorSelectOptions(isEng()),
        },
        {
          value: 'subSector',
          label: t('simulation.form.fields.subSector'),
          type: 'select',
          securityOnly: true,
          newSecurityOnly: true,
        },
        algoType === AlgoTypes.components && {
          value: 'avgPrice',
          label: t('simulation.form.fields.avgPrice'),
          type: 'number',
          securityOnly: true,
          existingSecurityOnly: true,
        },
      ]),
    [algoType, t],
  )

  useEffect(() => {
    setFieldList(
      secType === SecTypes.stock
        ? compact([
            ...commonFields,
            {
              value: 'capitalListedForTrading',
              label: t('simulation.form.fields.capitalListedForTrading'),
              type: 'number',
              securityOnly: true,
            },
            {
              value: 'freeFloatRate',
              label: t('simulation.form.fields.freeFloatRate'),
              type: 'number',
              securityOnly: true,
            },
            dayjs(taseEffectiveDate).isAfter('2023-11-01') && {
              value: 'semiAnnualMedianTurnover',
              label: t('simulation.form.fields.semiAnnualMedianTurnover'),
              type: 'number',
              securityOnly: true,
            },
            dayjs(taseEffectiveDate).isAfter('2023-11-01') && {
              value: 'semiAnnualTurnoverSpeedMedian',
              label: t('simulation.form.fields.semiAnnualTurnoverSpeedMedian'),
              type: 'number',
              securityOnly: true,
            },
            algoType === AlgoTypes.components && {
              value: 'freeFloatRateForSpecificFFRecordDate',
              label: t('simulation.form.fields.freeFloatRateForSpecificFFRecordDate'),
              type: 'number',
              securityOnly: true,
              existingSecurityOnly: true,
            },
            {
              value: 'firstTradingDate',
              label: t('simulation.form.fields.firstTradingDate'),
              type: 'date',
              securityOnly: true,
              max: dayjs(taseEffectiveDate)
                .subtract(1, 'month')
                .format('YYYY-MM-DD'),
              newSecurityOnly: true,
            },
          ])
        : algoType === AlgoTypes.components // allbonds
        ? compact([
            ...commonFields,
            {
              value: 'currTaseEffectiveDateIans',
              label: t('simulation.form.fields.capitalListedForTrading'),
              type: 'number',
              securityOnly: true,
            },
            {
              value: 'ratingLevel',
              label: t('equity.generalSecurity.rating'),
              type: 'select',
              securityOnly: true,
              options: RatingOpts,
              sorted: true,
            },
            {
              value: 'issuer',
              label: t('simulation.form.fields.issuer'),
              type: 'select',
              securityOnly: true,
              options: issuersList,
              newSecurityOnly: true,
              sorted: true,
            },
            {
              value: 'linkage',
              label: t('simulation.form.fields.linkage'),
              type: 'select',
              securityOnly: true,
              options: linkageOptions,
              newSecurityOnly: true,
            },
            {
              value: 'interestType',
              label: t('simulation.form.fields.interestType'),
              type: 'select',
              securityOnly: true,
              options: interestOptions,
              newSecurityOnly: true,
            },
            {
              value: 'firstTradingDate',
              label: t('simulation.form.fields.firstTradingDate'),
              type: 'date',
              securityOnly: true,
              newSecurityOnly: true,
            },
            {
              value: 'maturityDate',
              label: t('simulation.form.fields.maturityDate'),
              type: 'date',
              securityOnly: true,
              newSecurityOnly: true,
            },
          ])
        : compact([
            {
              value: 'capitalListedForTrading',
              label: t('simulation.form.fields.capitalListedForTrading'),
              type: 'number',
              securityOnly: true,
            },
            {
              value: 'securityName',
              label: t('simulation.form.fields.securityName'),
              type: 'text',
              securityOnly: true,
              newSecurityOnly: true,
            },
          ]),
    )
  }, [algoType, secType, taseEffectiveDate, linkageOptions, interestOptions, commonFields, t, issuersList, fetchingIssuersList])

  return [fieldList, fetchingIssuersList]
}

export const useIssuersList = () => {
  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const [fetchingIssuersList, setFetchingIssuersList] = useState(false)
  const { secType, issuersList, setIssuersList } = useContext(Context)
  const [issuersListOpts, setIssuerListOpts] = useState([])
  const calcDescForUrlNoSim = useCalcDescUrl({ allowSimId: false })
  const eng = isEng()

  useEffect(() => {
    const loadIssuers = () => {
      setFetchingIssuersList(true)

      axiosApi
        .get(
          // we don't want simulation data for this one
          `security_overview/getIssuerList?${calcDescForUrlNoSim}`,
        )
        .then(response => {
          if (some(response.data)) {
            setIssuersList(response.data)
          }

          setFetchingIssuersList(false)
        })
        .catch(() => setFetchingIssuersList(false))
    }

    if (!some(issuersList)) {
      loadIssuers()
    }
  }, [eng, algoType, secType, setIssuersList, t, calcDescForUrlNoSim, issuersList])

  useEffect(() => {
    setIssuerListOpts(() => {
      const issuers = issuersList.map(item => {
        return {
          label: `${item[eng ? 'issuerNameEnglish' : 'issuerNameHebrew']} (${item.issuerNo})`,
          value: toNumber(item.issuerNo),
        }
      })

      return [{ label: t('simulation.form.newIssuer'), value: '9999' }, ...sortBy(issuers, opt => opt.label)]
    })
  }, [issuersList, t, eng])

  return [issuersListOpts, fetchingIssuersList]
}

export const useSimulationSecurityList = () => {
  const { t } = useTranslation()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()
  const [fetchingSecuritiesList, setFetchingSecuritiesList] = useState(false)
  const { secType, securityList, setSecurityList } = useContext(Context)
  const calcDescForUrlNoSim = useCalcDescUrl({ allowSimId: false })
  const [fieldList] = useFieldsList()
  const eng = isEng()

  useEffect(() => {
    const loadSecurities = () => {
      setFetchingSecuritiesList(true)

      axiosApi
        .get(
          // we don't want simulation data for this one
          `security_overview/filter?${calcDescForUrlNoSim}&lang=${eng ? 'en' : 'he'}`,
          {
            params: {
              extraFields: fieldList
                .map(e => e.value)
                .filter(e => isString(e))
                .join(' '),
            },
          },
        )
        .then(response => {
          if (some(response.data)) {
            const securitiesList = response.data.map(item => {
              return {
                label: `${item[eng ? 'nameEnglish' : 'nameHebrew']} (${item.securityNo})`,
                value: toNumber(item.securityNo),
                data: item,
              }
            })
            setSecurityList([{ label: t('simulation.form.securityPlaceholder'), value: '' }, ...securitiesList])
          }

          setFetchingSecuritiesList(false)
        })
        .catch(() => setFetchingSecuritiesList(false))
    }

    if (some(fieldList) && taseEffectiveDate) {
      loadSecurities()
    }
  }, [eng, algoType, secType, setSecurityList, t, calcDescForUrlNoSim, fieldList, taseEffectiveDate])

  // cleanup
  useEffect(() => {
    return () => {
      setSecurityList([])
    }
  }, [setSecurityList])

  return [securityList, fetchingSecuritiesList]
}
