import compact from 'lodash/compact'

const tPrefix = 'common.table.colHeaders'

export const Columns = ({ t }) => {
  return compact([
    {
      id: 'nameHebrew',
      Header: t('equity.generalSecurity.securityName'),
      accessor: 'nameHebrew',
      cellFormat: { noFormat: true },
    },
    {
      id: 'securityId',
      Header: t('equity.generalSecurity.securityNo'),
      accessor: 'securityId',
      cellFormat: { noFormat: true },
    },
    {
      id: 'ratingLevel',
      Header: t(`${tPrefix}.ratingLevel`),
      accessor: 'ratingLevel',
      cellFormat: { format: 'rating' },
    },
    {
      id: 'ratingMaalot',
      Header: t(`${tPrefix}.ratingMaalot`),
      accessor: 'ratingMaalot',
      cellFormat: { format: 'rating' },
    },
    {
      id: 'ratingMaalotGlobes',
      Header: t(`${tPrefix}.ratingMaalotGlobes`),
      accessor: 'ratingMaalotGlobes',
      cellFormat: { format: 'rating' },
    },
    {
      id: 'ratingMidroog',
      Header: t(`${tPrefix}.ratingMidroog`),
      accessor: 'ratingMidroog',
      cellFormat: { format: 'rating' },
    },
    {
      id: 'ratingMidroogGlobes',
      Header: t(`${tPrefix}.ratingMidroogGlobes`),
      accessor: 'ratingMidroogGlobes',
      cellFormat: { format: 'rating' },
    },
  ])
}
