import React from 'react'
import compact from 'lodash/compact'
import some from 'lodash/some'
import find from 'lodash/find'
import toNumber from 'lodash/toNumber'

import { TableCell } from 'components/Common/Table/TableCell'
import { MetaCell } from './MetaCell'
export const Columns = ({ t, securityList }) => {
  return compact([
    {
      id: 'level',
      Header: 'level',
      accessor: 'level',
      cellFormat: { noFormat: true },
    },
    {
      id: 'msg',
      Header: 'msg',
      accessor: 'msg',
      Cell: row => {
        let msg = row.value
        if (msg.length > 60) {
          msg = `${msg.slice(0, 57)}...`
        }
        return <TableCell value={msg} noFormat={true} />
      },
    },
    {
      id: 'identifier',
      Header: 'security Id (if present)',
      accessor: row => {
        if (some(row.meta)) {
          const obj = find(row.meta, 'securityId')
          if (obj) {
            const securityId = toNumber(obj.securityId)

            if (some(securityList) && securityList[securityId]) {
              return `${securityList[securityId].nameHebrew} (${securityId})`
            } else {
              return securityId
            }
          }
        }
        return '-'
      },
    },
    {
      id: 'meta',
      Header: 'meta',
      accessor: 'meta',
      Cell: row => <MetaCell row={row} />,
    },
    {
      id: 'time',
      Header: 'time',
      accessor: 'time',
      cellFormat: { format: 'time' },
    },
  ])
}
