import React from 'react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'

import { SuccessCmp } from './success'
import { Container, Title, ButtonsContainer, TextsWrapper, ErrorMsg } from './styles'
import { NewPasswordValidationSchema } from './schemas'

import { Input } from 'components/Common/input'
import { Button } from 'components/Common/button'

export const CreateNewPasswordForm = ({ t, resetPassword, error, onSetError, resetPasswordSuccessfully }) => {
  return (
    <div className="create-new-password-form">
      {resetPasswordSuccessfully ? (
        <SuccessCmp title={t('resetPassword.success.title')} subtitle={t('resetPassword.success.subText')} isLink />
      ) : (
        <Container>
          <TextsWrapper>
            <Title>{t('resetPassword.title')}</Title>
          </TextsWrapper>
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            onSubmit={resetPassword}
            validationSchema={NewPasswordValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
              <Form noValidate onSubmit={handleSubmit} style={{ textAlign: 'center', width: '100%' }}>
                <Input
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  label={null}
                  placeholder={t('resetPassword.form.password.label')}
                  isValid={false}
                  isInvalid={!!errors.password && touched.password}
                  value={values.password}
                  errorText={t(errors.password)}
                  onChange={ev => {
                    onSetError(null)
                    handleChange(ev)
                  }}
                  width="100%"
                  style={{ direction: 'ltr', textAlign: 'center', width: '100%' }}
                />
                <Input
                  name="confirmPassword"
                  type="password"
                  onBlur={handleBlur}
                  label={null}
                  placeholder={t('resetPassword.form.confirmPassword.label')}
                  isValid={false}
                  isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                  value={values.confirmPassword}
                  errorText={t(errors.confirmPassword)}
                  onChange={ev => {
                    onSetError(null)
                    handleChange(ev)
                  }}
                  width="100%"
                  style={{ direction: 'ltr', textAlign: 'center', width: '100%' }}
                />
                {error && <ErrorMsg>{t(error)}</ErrorMsg>}
                <ButtonsContainer marginTop={'1.5rem'}>
                  <Button type="submit" onClick={handleSubmit}>
                    {t('resetPassword.form.submit')}
                  </Button>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </div>
  )
}
