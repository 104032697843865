import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFilters, useGlobalFilter, useSortBy, useTable, useExpanded, useFlexLayout, useRowSelect } from 'react-table'
import { useExportData } from 'react-table-plugins'
import { string, array, object, bool } from 'prop-types'
import XLSX from 'xlsx'
import XLSXStyle from 'xlsx-style'

import Papa from 'papaparse'
import { matchSorter } from 'match-sorter'

import get from 'lodash/get'
import some from 'lodash/some'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import forEach from 'lodash/forEach'

import { isHeb } from '../../../i18n'
import axiosApi from 'utils/axiosApi'
import { useWindowSize } from 'utils/useWindowSize'
import { useExportFileName } from './utils/useExportFileName'
import { useResizeColumns } from './utils/useResizeColumns'
import { useRowSelectColumns } from './utils/useRowSelectColumns'
import { buildDefaultColumn } from './utils/index'

import { TableHeader } from './TableHeader'
import { Loader } from 'components/Common/Loader'

import { VirtualizedTable } from './VirtualizedTable'
import { RegularTable } from './RegularTable'

import { ErrorContainer, TablePageContainer } from './styles'
import FiltersWrapper from './FiltersWrapper'

const Table = props => {
  let {
    apiUrl,
    columns,
    defaultSortBy,
    withExportButton,
    exportFileType,
    exportFileNameContext,
    exportFileNameOverrideDate,
    headerText,
    headerBackground,
    headerTextColor,
    noDataMsg,
    newRequest,
    virtualized,
    onRowSelection,
  } = props

  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)
  const [width] = useWindowSize()

  const { t } = useTranslation()

  const [wrapperWidth, setWrapperWidth] = useState(null)
  const WrapperRef = useRef(null)

  if (defaultSortBy === undefined) defaultSortBy = { id: '0', desc: true }

  useEffect(() => {
    const loadData = () => {
      setFetching(true)

      const url = `${apiUrl}${apiUrl.includes('?') ? '&' : '?'}limit=-1`
      axiosApi
        .get(url)
        .then(response => {
          if (some(response.data)) {
            setData(response.data)
          } else {
            setData([])
          }
          setFetching(false)
        })
        .catch(() => {
          setFetching(false)
        })
    }

    loadData()
  }, [apiUrl, newRequest])

  useEffect(() => {
    setWrapperWidth(() => (WrapperRef.current ? WrapperRef.current.offsetWidth : null))
  }, [width])

  function getExportFileBlob({ columns, data, fileType }) {
    if (fileType === 'csv') {
      // CSV example
      const headerNames = columns.map(col => col.exportValue)
      const csvString = Papa.unparse({ fields: headerNames, data })
      return new Blob([csvString], { type: 'text/csv' })
    } else if (fileType === 'xlsx') {
      // XLSX example
      const header = columns.map(c => c.exportValue)
      const compatibleData = data.map(row => {
        const obj = {}
        header.forEach((col, index) => {
          obj[col] = row[index]
        })
        return obj
      })

      const ws = XLSX.utils.json_to_sheet(compatibleData)
      const sheetName = t('common.table.sheetNames.projectedChanges')

      //styling & formatting:
      const formatMap = {}
      forEach(columns, (col, idx) => {
        const colChar = String.fromCharCode(65 + idx)
        const { cellFormat } = col || {}
        formatMap[colChar] = cellFormat
      })

      for (const key in ws) {
        if (isObject(ws[key])) {
          ws[key].s = {
            font: {
              name: 'Arial',
              sz: 10,
            },
            border: {
              // underscore
              bottom: {
                style: 'thin',
                color: 'FF000000',
              },
              top: {
                style: 'thin',
                color: 'FF000000',
              },
              left: {
                style: 'thin',
                color: 'FF000000',
              },
              right: {
                style: 'thin',
                color: 'FF000000',
              },
            },
          }

          //header row:
          if (key.replace(/[^0-9]/gi, '') === '1') {
            ws[key].s.fill = { fgColor: { rgb: '1b8000' } } // Add background color
            ws[key].s.font.color = { rgb: 'ffffff' }
            ws[key].s.font.bold = true // bold
          } else {
            //all but the header row

            const format = get(formatMap[key.replace(/[0-9]/g, '')], 'format')

            switch (format) {
              case 'currency':
                ws[key].s.numFmt = '₪#,###'
                break
              case '%':
                ws[key].s.numFmt = '0.00%'
                break
              case 'number-short':
                ws[key].s.numFmt = '0.0'
                break
              case 'float':
                ws[key].s.numFmt = '0.00000'
                break
              default:
                break
            }

            if (get(formatMap[key.replace(/[0-9]/g, '')], 'greenRedValues')) {
              ws[key].s.font.color = ws[key].v < 0 ? { rgb: '820900' } : { rgb: '3f6e02' }
              ws[key].s.font.bold = true // bold
            }
          }
        }
      }

      const wb = {
        Workbook: {
          Views: [{ RTL: isHeb() }],
        },
        Sheets: { [sheetName]: ws },
        SheetNames: [sheetName],
      }

      const bookType = 'xlsx'
      const excelBuffer = XLSXStyle.write(wb, {
        bookType: bookType,
        bookSST: false,
        type: 'buffer',
      })
      return new Blob([excelBuffer], { type: fileType })
    }
    return false
  }

  const defaultColumn = React.useMemo(buildDefaultColumn, [])

  const globalFilter = (rows, columnIds, filterValue) => {
    if (!some(filterValue)) return rows

    let filtered = rows
    const searchValue = get(filterValue, 'searchValue')
    const legendFilters = get(filterValue, 'legendFilters')

    if (searchValue) {
      filtered = matchSorter(rows, searchValue, {
        keys: columnIds.map(col => row => row.values[col]),
        threshold: matchSorter.rankings.CONTAINS,
      })
    }

    if (some(legendFilters)) {
      filtered = filtered.filter(row => {
        return legendFilters.reduce((acc, curr) => {
          return acc ? true : curr(row)
        }, false)
      })
    }
    return filtered
  }

  const exportFileName = useExportFileName({
    exportFileNameOverrideDate,
    exportFileNameContext,
    headerText,
  })

  const ReactTableBag = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [defaultSortBy],
        expanded: false,
      },
      defaultColumn,
      getExportFileBlob,
      getExportFileName: () => exportFileName,
      disableSortRemove: true,
      wrapperWidth,
      isHeb: isHeb(),
      globalFilter: globalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    isFunction(onRowSelection) && useRowSelect,
    isFunction(onRowSelection) && useRowSelectColumns,
    useExportData,
    useExpanded,
    virtualized && useFlexLayout,
    virtualized && useResizeColumns,
  )

  const { selectedFlatRows } = ReactTableBag
  useEffect(() => {
    if (isFunction(onRowSelection)) {
      onRowSelection(selectedFlatRows)
    }
  }, [selectedFlatRows, onRowSelection])

  const erroMessageDisplay = <ErrorContainer>{noDataMsg ? noDataMsg : 'No data to display.'}</ErrorContainer>

  const withData = virtualized ? (
    <VirtualizedTable props={props} ReactTableBag={ReactTableBag} />
  ) : (
    <RegularTable props={props} ReactTableBag={ReactTableBag} />
  )

  return (
    <TablePageContainer ref={WrapperRef}>
      <TableHeader
        withExportButton={withExportButton}
        exportFileType={exportFileType || 'xlsx'}
        onExport={type => {
          ReactTableBag.exportData(type, false)
        }}
        headerTextColor={headerTextColor}
        headerBackground={headerBackground}
        headerText={headerText}
      />
      <FiltersWrapper ReactTableBag={ReactTableBag} legends={props.legends} />
      {fetching ? <Loader containerHeight="400px" /> : some(data) ? withData : erroMessageDisplay}
    </TablePageContainer>
  )
}

Table.propTypes = {
  apiUrl: string.isRequired,
  columns: array.isRequired,
  virtualized: bool.isRequired,
  headerText: string.isRequired,
  defaultSortBy: object,
  withExportButton: bool,
  exportFileType: string,
  exportFileNameContext: string,
  headerBackground: string,
  headerTextColor: string,
  noDataMsg: string,
  newRequest: string,
}

Table.defaultProps = {
  conditionalRowProps: () => null,
  conditionalCellProps: () => null,
}

export { Table }
