import React from 'react'
import useColumnsData from 'utils/useColumnsData'

import min from 'lodash/min'
import compact from 'lodash/compact'
import { Columns } from './Columns'

import { Table } from 'components/Common/Table'
export const RatingsTable = ({ calcDesc }) => {
  const isMissingOnAlgoFoundOnGlobes = row => {
    const {
      ratingLevel,
      ratingMaalotGlobes,
      ratingMidroogGlobes,
    } = row.original
    return (
      isNaN(ratingLevel) &&
      (!isNaN(ratingMaalotGlobes) || !isNaN(ratingMidroogGlobes))
    )
  }

  const isMissingOnGlobesFoundOnAlgo = row => {
    const {
      ratingLevel,
      ratingMaalotGlobes,
      ratingMidroogGlobes,
    } = row.original
    return (
      isNaN(ratingMaalotGlobes) &&
      isNaN(ratingMidroogGlobes) &&
      !isNaN(ratingLevel)
    )
  }

  const hasDelta = row => {
    const {
      ratingMaalot,
      ratingMidroog,
      ratingMaalotGlobes,
      ratingMidroogGlobes,
    } = row.original

    return (
      (!isNaN(ratingMaalot) &&
        !isNaN(ratingMaalotGlobes) &&
        ratingMaalot !== ratingMaalotGlobes) ||
      (!isNaN(ratingMidroog) &&
        !isNaN(ratingMidroogGlobes) &&
        ratingMidroog !== ratingMidroogGlobes)
    )
  }

  const hasOverallDelta = row => {
    const {
      ratingLevel,
      ratingMaalotGlobes,
      ratingMidroogGlobes,
    } = row.original
    const globesMinRating = min([ratingMaalotGlobes, ratingMidroogGlobes])
    return (
      !isNaN(globesMinRating) &&
      !isNaN(ratingLevel) &&
      globesMinRating !== ratingLevel
    )
  }

  const conditionalRowProps = row => {
    if (isMissingOnGlobesFoundOnAlgo(row)) return { className: 'successTR' }
    if (isMissingOnAlgoFoundOnGlobes(row)) return { className: 'warningTR' }
    if (hasOverallDelta(row)) return { className: 'dangerTR' }
    if (hasDelta(row)) return { className: 'warningTRAlt' }
  }

  const legends = compact([
    {
      color: '#e4ffe1',
      label: 'Missing from Globes, found on Algo',
      filter: isMissingOnGlobesFoundOnAlgo,
    },
    {
      color: '#fff6e1',
      label: 'Missing from Algo, found on Globes',
      filter: isMissingOnAlgoFoundOnGlobes,
    },
    {
      color: '#fff6e1',
      label: 'has Some Delta',
      filter: hasDelta,
    },
    {
      color: '#ffe4e1',
      label: 'Has Meaning Delta',
      filter: hasOverallDelta,
    },
  ])

  return (
    <Table
      withExportButton
      exportFileType="csv"
      columns={Columns(useColumnsData())}
      apiUrl={`/index_calc_results/getRatingResultsByDescId/${calcDesc._id}`}
      exportFileNameOverrideDate={calcDesc.taseEffectiveDate}
      conditionalRowProps={conditionalRowProps}
      legends={legends}
      defaultSortBy={{ id: 'securityId', desc: true }}
      headerText="Rating Calc Results"
      virtualized={true}
    />
  )
}

// RatingsTable.propTypes = {
//   Prop: Type,
// }
