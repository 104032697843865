import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'

import { Label, DataRowWrapper, DataCol } from './styles'

export const DataRow = ({ label, content, field }) => {
  return (
    <DataRowWrapper>
      <DataCol>
        <Label field={field}>{label}</Label>
      </DataCol>
      <Col>{content}</Col>
    </DataRowWrapper>
  )
}

DataRow.defaultProps = {
  field: false,
}

DataRow.propTypes = {
  field: PropTypes.bool,
  label: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
}
