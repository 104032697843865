import React, { useRef, useState } from 'react'

import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

import { isEng } from '../../../../i18n'
import { ExternalLinkInner, WithDirection } from './styles'

export const TableCellExtraContent = ({ content, href, popoverText, noPopup, color, placement = 'top' }) => {
  const [isPopoverOpen, setPopoverIsOpen] = useState(false)
  const contentRef = useRef(null)

  return (
    <>
      <ExternalLinkInner
        href={href}
        target="_blank"
        ref={contentRef}
        onMouseLeave={() => setPopoverIsOpen(false)}
        onMouseEnter={() => setPopoverIsOpen(true)}
        color={color}
      >
        {content}
      </ExternalLinkInner>

      <Overlay target={contentRef.current} show={isPopoverOpen && !noPopup} placement={placement}>
        {props => (
          <Tooltip id="cellValuePopover" {...props} onMouseLeave={() => setPopoverIsOpen(false)} onMouseEnter={() => setPopoverIsOpen(true)}>
            <WithDirection direction={isEng() ? 'ltr' : 'rtl'}>{popoverText}</WithDirection>
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
