import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Tab } from './Tab'

import { TabsWrapper } from './styles'

export const EquityTabs = ({ tabs, activeTab, changeActiveTab }) => {
  const { t } = useTranslation()

  return (
    <TabsWrapper>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.title}
          title={t(tab.title)}
          isActive={index === activeTab}
          changeActiveTab={changeActiveTab}
          tabIndex={index}
        />
      ))}
    </TabsWrapper>
  )
}

EquityTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.element,
    }),
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
}
