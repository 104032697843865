import React from 'react'

import { SelectSearch } from 'components/Common/SelectSearch'
import { useFormikContext } from 'formik'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { DataRowWrapper, FlexContainer, ServerError, StyledButton, UpdateFormWrapper } from './styles'

export const ShareModalForm = ({ selectOptions }) => {
  const { t } = useTranslation()
  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormikContext()

  return (
    <Form noValidate onSubmit={handleSubmit} autoComplete="off">
      <UpdateFormWrapper>
        <DataRowWrapper>
          <SelectSearch
            options={selectOptions}
            value={values.user}
            onChange={val => {
              setFieldValue('user', val.value)
            }}
            onBlur={handleBlur}
            name="user"
            placeholder={t('selectUser')}
            error={errors.user && touched.user}
          />
        </DataRowWrapper>

        <DataRowWrapper>
          <FlexContainer>
            <StyledButton type="submit">{t('share')}</StyledButton>
          </FlexContainer>
        </DataRowWrapper>
        {errors.general && <ServerError>{errors.general}</ServerError>}
      </UpdateFormWrapper>
    </Form>
  )
}
