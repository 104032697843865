import React from 'react'

import { FixedSizeList } from 'react-window'
import {
  StyledTable,
  TableWrapper,
  StyledHead,
  StyledRow,
  StyledHeaderRow,
  StyledCell,
  StyledHeaderCell,
  StyledHeaderCellInner,
  Resizer,
} from './styles'
import min from 'lodash/min'

import { isEng, isHeb } from '../../../../i18n'

export const VirtualizedTable = ({ props, ReactTableBag }) => {
  const { rowHeight, conditionalRowProps, conditionalCellProps, rowBackgroundColor, withFilters } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = ReactTableBag

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <StyledRow
          {...row.getRowProps({
            style,
          })}
          rowBackgroundColor={rowBackgroundColor}
          {...conditionalRowProps(row)}
        >
          {row.cells.map(cell => {
            return (
              <StyledCell {...cell.getCellProps()} {...conditionalCellProps(row, cell)}>
                {cell.render('Cell')}
              </StyledCell>
            )
          })}
        </StyledRow>
      )
    },
    [prepareRow, rows, conditionalCellProps, conditionalRowProps, rowBackgroundColor],
  )

  const itemSize = rowHeight || 45

  const withDataForTable = (
    <TableWrapper>
      <StyledTable {...getTableProps()} isEng={isEng()}>
        <StyledHead>
          {headerGroups.map(headerGroup => (
            <StyledHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledHeaderCell {...column.getHeaderProps()} className={column.isSorted ? (column.isSortedDesc ? 'sortingUp' : 'sortingDown') : ''}>
                  <StyledHeaderCellInner {...column.getSortByToggleProps()}>{column.render('Header')}</StyledHeaderCellInner>
                  <Resizer {...column.getResizerProps()} isEng={isEng()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                </StyledHeaderCell>
              ))}
            </StyledHeaderRow>
          ))}
          {withFilters &&
            headerGroups.map(headerGroup => (
              <StyledHeaderCell {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().id + 'Format'}>
                {headerGroup.headers.map(column => (
                  <StyledHeaderCell {...column.getHeaderProps()}>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </StyledHeaderCell>
                ))}
              </StyledHeaderCell>
            ))}
        </StyledHead>

        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={min([700, itemSize * rows.length])}
            itemCount={rows.length}
            itemSize={itemSize}
            direction={isHeb() ? 'rtl' : 'ltr'}
            style={{ overflowY: 'overlay', overflowX: 'hidden' }}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </StyledTable>
    </TableWrapper>
  )

  return withDataForTable
}
