import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const TableCellContainer = styled.div`
  direction: ${props => props.direction};
  display: inline-flex;
  align-items: center;
  ${props =>
    props.link &&
    `
		color: blue;
		text-decoration: underline;
		cursor: pointer;
	`}
  ${props =>
    props.color &&
    `
		color: ${props.color};
	`}
  ${props =>
    props.greenRedValues && props.value !== 0 && props.value !== '0' && props.value !== '₪0' && `color: ${props.value >= 0 ? 'green' : 'red'};`}
`
export const ContentWrapper = styled.div`
  direction: ${props => props.direction};
  width: max-content;
`

export const CustomPopover = styled.div`
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  border-radius: 5px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.75);

  @media (max-width: 768px) {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: 0;
    width: 20px;
    height: 20px;
    margin: -10px;
    background: #ffffff;
    border-radius: 0 0 5px 0;
    transform: rotate(45deg);
  }
`
export const LinkInner = styled(Link)`
  &:hover {
    color: #4379b3;
  }
`
export const ExternalLinkInner = styled.a`
  margin: 0.5rem;
  &:hover {
    color: #4379b3;
  }
`

export const CellImage = styled.img`
  max-height: 20px;
`
