import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEng } from '../../../i18n'

import { TableHeaderContainer, TableHeaderText, ExportButton } from './styles'

export const TableHeader = ({
  headerText,
  headerBackground,
  headerTextColor,
  withExportButton,
  onExport,
  exportFileType,
}) => {
  const { t } = useTranslation()
  return (
    <TableHeaderContainer background={headerBackground} color={headerTextColor}>
      <TableHeaderText>
        {headerText}
        {withExportButton && (
          <ExportButton
            variant={'light'}
            onClick={() => onExport(exportFileType)}
            direction={isEng() ? 'ltr' : 'rtl'}
          >
            {t('equity.table.exportTo')}
            {t(`equity.table.${exportFileType}`)}
          </ExportButton>
        )}
      </TableHeaderText>
    </TableHeaderContainer>
  )
}
