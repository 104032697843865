const getLiquidationGroupDesc = value => {
  switch (value) {
    case 'A':
    case '1.0':
    case '1':
    case 1:
      return 'A (1.0)'
    case 'B':
    case '0.8':
    case 0.8:
      return 'B (0.8)'
    case 'C':
    case '0.6':
    case 0.6:
      return 'C (0.6)'
    case 'D':
    case '0.45':
    case 0.45:
      return 'D (0.45)'
    case 'E':
    case '0.35':
    case 0.35:
      return 'E (0.35)'
    case 'F':
    case '0.25':
    case 0.25:
      return 'F (0.25)'
    case 'G':
    case '0.2':
    case 0.2:
      return 'G (0.2)'
    case 'H':
    case '0.1':
    case 0.1:
      return 'H (0.1)'
    default:
      return value
  }
}

module.exports = { getLiquidationGroupDesc }
