import React from 'react'
import PropTypes from 'prop-types'

import { StyledButton, ButtonIcon, LoadingIcon } from './styles'

export const Button = ({
  icon,
  loading,
  children,
  disabled,
  onClick,
  ...rest
}) => {
  return (
    <StyledButton {...rest} onClick={onClick} disabled={disabled || loading}>
      {loading && <LoadingIcon />}
      {icon ? <ButtonIcon icon={icon} /> : null}
      {children}
    </StyledButton>
  )
}

Button.defaultProps = {
  disabled: false,
  icon: null,
  loading: false,
  onClick: () => {},
}

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}
