import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Badge from 'react-bootstrap/Badge'

import Context from 'context/Context'

import { hasFieldUpdated } from 'components/Simulation/SimulationWrapper'
import { SimStatusWrapper, RunSimLink } from './styles'

export const SimulationStatusBanner = () => {
  const { handleSubmit, values } = useFormikContext()
  const { selectedSim, isAlgoRunning } = useContext(Context)
  const { t } = useTranslation()

  const showSaveAndRun = hasFieldUpdated(values, selectedSim, 'conditions')

  return (
    showSaveAndRun &&
    !isAlgoRunning && (
      <SimStatusWrapper>
        <Badge pill variant="warning">
          {t('simulation.form.warnings.needToCalculate')}
          <RunSimLink onClick={() => handleSubmit()}> {t('simulation.form.saveAndRun')}</RunSimLink>
        </Badge>
      </SimStatusWrapper>
    )
  )
}
