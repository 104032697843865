import React, { createContext, useState } from 'react'

export const AuthContext = createContext(null)

export const AuthContextProvider = ({ children }) => {
  const [loginScenario, setLoginScenario] = useState(null)
  const [loginResponse, setLoginResponse] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneEnding, setPhoneEnding] = useState(null)

  const contextValue = {
    loginScenario,
    setLoginScenario,
    loginResponse,
    setLoginResponse,
    email,
    setEmail,
    phoneEnding,
    setPhoneEnding,
  }
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
