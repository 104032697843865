import React from 'react'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'

import { TaseRow} from './styles'

export const TaseBlockRow = ({ firstCol, secondCol, thirdCol }) => (
  <TaseRow>
    <Col sm={4}>{firstCol}</Col>
    <Col sm={4}>{secondCol}</Col>
    <Col sm={4}>{thirdCol}</Col>
  </TaseRow>
)

TaseBlockRow.propTypes = {
  firstCol: PropTypes.node.isRequired,
  secondCol: PropTypes.node.isRequired,
}
