export const defaultIndexInitialValues = {
  indexNo: '',
  indexName: '',
  weightDistMethod: 'hasWeightCap',
  weightCap: 100,
  followingFundsValue: 0,
  securityIds: [],
}

export const SIMULATION_TYPES = {
  newSecurity: 'newSecurity',
  existingSecurity: 'existingSecurity',
  newIndex: 'newIndex',
}

export const equityFieldOrder = [
  'securityName',
  'sector',
  'subSector',
  'foreignIssuer',
  'firstTradingDate',
  'capitalListedForTrading',
  'freeFloatRateForSpecificFFRecordDate',
  'freeFloatRate',
  'closingPrice',
  'avgPrice',
]

export const telbondFieldOrder = [
  'securityName',
  'issuer',
  'sector',
  'subSector',
  'foreignIssuer',
  'firstTradingDate',
  'maturityDate',
  'capitalListedForTrading',
  'ratingLevel',
  'closingPrice',
  'avgPrice',
  'linkage',
]

export const WEIGHT_DIST_METHODS = {
  hasWeightCap: 'hasWeightCap',
  isEqualWeight: 'isEqualWeight',
}
