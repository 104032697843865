import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown'

export const CustomDropdownButton = styled(Dropdown.Toggle)`
  background: transparent;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  ${props =>
    props.direction === 'hb'
      ? 'left: 0; right: unset'
      : `right: 0;  left: unset;
  `}
  width: 170px;
`

export const EmailItem = styled.div`
  padding: 4px 24px;
  font-size: 0.8rem;
  color: #676767;
`

export const AdminMenuButton = styled.div`
  padding: 4px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const AdminMenuElement = styled(Dropdown.Item)``
