import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'

import { SuccessContainer, SuccessTitle, SubText } from './styles'

export const SuccessCmp = ({ title, subtitle, isLink }) => {
    return (
        <SuccessContainer>
            <FontAwesomeIcon
                icon={faCircleCheck}
                style={{
                    color: '#5aac44',
                    fontSize: '7rem',
                    marginTop: '20px',
                }}
            />

            <SuccessTitle>{title}</SuccessTitle>
            {isLink ? <Link to="/">{subtitle}</Link> : <SubText>{subtitle}</SubText>}
        </SuccessContainer>
    )
}