import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Overlay from 'react-bootstrap/Overlay'
import { SecurityItem, CustomPopover } from './styled'
import getFormattedValue from 'utils/getFormattedValue'

export const IndexDataCardItem = ({ indexData, field, format }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const wrapperRef = useRef(null)
  const { t } = useTranslation()

  return (
    <SecurityItem>
      {t(`equity.totalRow.${field}`)}:{' '}
      <span
        ref={wrapperRef}
        onMouseLeave={() => setIsTooltipOpen(false)}
        onMouseEnter={() => setIsTooltipOpen(true)}
      >
        {getFormattedValue({
          value: indexData[field],
          toShort: true,
          t,
          formatOverride: format,
        })}
      </span>
      <Overlay target={wrapperRef.current} show={isTooltipOpen} placement="top">
        {props => (
          <CustomPopover
            id="popover-positioned-bottom"
            {...props}
            onMouseLeave={() => setIsTooltipOpen(false)}
            onMouseEnter={() => setIsTooltipOpen(true)}
          >
            {getFormattedValue({
              value: indexData[field],
              t,
              formatOverride: 'currency',
            })}
          </CustomPopover>
        )}
      </Overlay>
    </SecurityItem>
  )
}
