import React from 'react'
import { useAsyncDebounce } from 'react-table'
import { useTranslation } from 'react-i18next'
import { isHeb } from '../../../i18n'
import { GlobalFilterInput, GlobalFilterWrapper } from './styles'
import get from 'lodash/get'

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, Header },
}) => {
  const { t } = useTranslation()

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`${t('searchby')} ${Header}`}
    />
  )
}

// Define a default UI for filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length

  const [value, setValue] = React.useState(get(globalFilter, 'searchValue'))
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value ? {...globalFilter, searchValue: value} : {...globalFilter, searchValue: null} )
  }, 200)

  const { t } = useTranslation()

  return (
    <GlobalFilterWrapper>
      {t('search')}:{' '}
      <GlobalFilterInput
        value={value || ''}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${count} ${t('records')}...`}
      />
    </GlobalFilterWrapper>
  )
}

export const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the min and max
  // using the preFilteredRows
  const { t } = useTranslation()

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        const sanitized = row.values[id].toString().replace(/[^0-9.]+/g, '')
        min = Math.min(sanitized, min)
        max = Math.max(sanitized, max)
      }
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        className={isHeb(t) ? 'rangeInputRtl' : ''}
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
    </>
  )
}
