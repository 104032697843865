/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { UserUpdateForm } from './UserUpdateForm'

const userUpdateForm = props => {
  return <UserUpdateForm {...props} />
}

export default userUpdateForm
