import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

import { H3, BoldText } from 'styles/Typography'

import axiosApi from 'utils/axiosApi'

import { TOSProps } from '../../types'

import {
  ModalLayout,
  ModalWindowContainer,
  FormTitle,
  TosFormWrapper,
  BtnContainer,
  CloseButtonWrapper,
  BtnWrapper,
  ServerError,
} from './styles'

export const TOS = ({ tos, onApprove, onClose, user }) => {
  const { t } = useTranslation()
  const [check, setCheck] = useState(false)
  const [errors, setErrors] = useState(null)

  // const currLanguageCode = t('get_current_language_code')
  const currLanguageCode = 'en'
  return (
    <ModalLayout
      style={{
        direction: currLanguageCode === 'hb' ? 'rtl' : 'ltr',
      }}
    >
      <ModalWindowContainer>
        <CloseButtonWrapper>
          <FontAwesomeIcon onClick={onClose} icon={faTimes} />
        </CloseButtonWrapper>
        <FormTitle>{t('tosModal.title')}</FormTitle>

        {tos.content[currLanguageCode] &&
          Object.keys(tos.content[currLanguageCode]).map(
            (title, index) => (
              <div key={title}>
                <H3>
                  {index + 1}
                  .
                  {title}
                </H3>
                <div>{tos.content[currLanguageCode][title]}</div>
              </div>
            ),
          )}

        {user ? (
          <TosFormWrapper>
            <form
              onSubmit={e => {
                e.preventDefault()

                axiosApi
                  .patch(
                    `profile/agreement/${user._id}`,
                    { tosId: tos._id },
                    { headers: { Authorization: `Bearer ${user.token}` } },
                  )
                  .then(() => {
                    onApprove()
                  })
                  .catch(error => {
                    const errorMsg =
                      error.response &&
                      error.response.data &&
                      error.response.data.errors
                        ? error.response.data.errors.msg
                        : error.message
                    setErrors(errorMsg)
                  })
              }}
            >
              <label htmlFor="checkboxId">
                <input
                  name="check"
                  type="checkbox"
                  value={check}
                  onChange={() => setCheck(!check)}
                />
                <BoldText>{t('tosModal.label')}</BoldText>
              </label>

              <BtnContainer>
                <BtnWrapper type="submit" disabled={!check}>
                  {t('tosModal.btn')}
                </BtnWrapper>
              </BtnContainer>

              {errors && <ServerError>{errors}</ServerError>}
            </form>
          </TosFormWrapper>
        ) : (
          <TosFormWrapper>
            <label htmlFor="checkboxId">
              <input disabled type="checkbox" checked />
              <BoldText>{t('tosModal.label')}</BoldText>
            </label>
            {dayjs(tos.agreementDate).format('DD/MM/YYYY, h:mm:ss a')}
          </TosFormWrapper>
        )}
      </ModalWindowContainer>
    </ModalLayout>
  )
}

TOS.defaultProps = {
  user: null,
  onApprove: null,
}

TOS.propTypes = {
  tos: TOSProps.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  onApprove: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}
