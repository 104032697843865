import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

export const MissingDataModal = ({ show, onHide }) => {
  const { t } = useTranslation()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('simulation.form.warnings.notAvailable')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('simulation.form.warnings.missingData')}</p>
      </Modal.Body>
    </Modal>
  )
}
