import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Alert, Container } from 'react-bootstrap'
import { isHeb } from '../../../i18n'
import { Link } from 'react-router-dom'
const StyledAlert = styled(Alert)`
  text-align: ${props => props.textalign};

  .close {
    ${props => (props.dir === 'ltr' ? 'right: 0; left: unset;' : 'left: 0; right: unset;')}
  }
`

// highly specific, might want to generalize later
export const Announcement = ({ onClose, linkTo }) => {
  const { t } = useTranslation()
  const dir = isHeb() ? 'rtl' : 'ltr'
  const textAlign = dir === 'rtl' ? 'right' : 'left'

  return (
    <Container style={{ marginTop: '100px' }}>
      <StyledAlert variant="success" onClose={onClose} dismissible textalign={textAlign} dir={dir}>
        <Alert.Heading>{t('announcement.announcementHeader')}</Alert.Heading>
        <p>
          {t('announcement.announcementBody')}
          <br />
          <Link to={linkTo}>{t('announcement.announcementLink')}</Link>
        </p>
        <hr />
        <p className="mb-0">{t('announcement.announcementFooter')}</p>
      </StyledAlert>
    </Container>
  )
}
