import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import axiosApi from 'utils/axiosApi'
import { useWindowSize } from 'utils/useWindowSize'
import Context, { useAlgoType } from 'context/Context'
import useSimDataUpdater from 'utils/useSimDataUpdater'
import useCalcDescUrl from 'utils/useCalcDescUrl'
import { isEng } from '../../i18n'

import keys from 'lodash/keys'
import some from 'lodash/some'

import { Loader } from 'components/Common/Loader'
import Masonry from 'react-masonry-css'
import { IndexSummaryCard } from './IndexSummaryCard'
import { MasonryWrapper } from './styled'

export const ChangesSummary = () => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)
  const [algoType] = useAlgoType()
  const [fetching, setFetching] = useState(false)
  const [indexesData, setIndexesData] = useState({})

  const [width] = useWindowSize()

  const calcDescForUrl = useCalcDescUrl()

  useEffect(() => {
    const loadRows = () => {
      setFetching(true)

      axiosApi
        .get(`changes_summary/getIndexes/?${calcDescForUrl}`)
        .then(response => {
          if (response.data) {
            setIndexesData(response.data)
          }

          setFetching(false)
        })
        .catch(() => {
          console.log('failed to fetch index data')
          setIndexesData(null)
          setFetching(false)
        })
    }

    if (algoType) {
      loadRows()
    }
  }, [t, algoType, secType, calcDescForUrl])

  const getRowNumber = width => {
    if (width >= 1400) return 5
    else if (width >= 1200) return 4
    else if (width >= 768) return 3
    else return 1
  }

  return useSimDataUpdater(
    fetching ? (
      <Loader />
    ) : (
      <MasonryWrapper direction={isEng() ? 'ltr' : 'rtl'}>
        <Masonry
          breakpointCols={getRowNumber(width)}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {some(indexesData) &&
            keys(indexesData).map((index, pos) => (
              <IndexSummaryCard key={pos} data={indexesData[pos]} />
            ))}
        </Masonry>
      </MasonryWrapper>
    ),
  )
}
