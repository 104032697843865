import React, { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import findIndex from 'lodash/findIndex'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { AlgoFormLabel, AlgoRowForm } from './styles'
import { DatePicker } from 'components/Common/DatePicker'

import { SecTypes, AlgoTypes } from 'utils/const'

export const BondsRebalanceAlgoRow = ({ job, handleAlgoSubmit, getJobBtnStyle, updateSchedules }) => {
  const [runAllAlgosFlag, setRunAllAlgosFlag] = useState(true)
  const [includePastAlgosFlag, setIncludePastAlgosFlag] = useState(false)

  const [selectedDate, setSelectedDate] = useState(null)
  const nextTaseDays = useMemo(() => updateSchedules[SecTypes.corpBond][AlgoTypes.parameters].map(us => us.recordDate), [updateSchedules])

  const onDateChange = useCallback(date => {
    setSelectedDate(date)
  }, [])

  return (
    <AlgoRowForm>
      <AlgoFormLabel>{job.label}:</AlgoFormLabel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form.Check
          type="switch"
          id={`runAll_${job.label}`}
          label="Run All"
          checked={runAllAlgosFlag}
          onChange={e => setRunAllAlgosFlag(e.target.checked)}
        />
        {runAllAlgosFlag && (
          <Form.Check
            type="switch"
            id={`includePast${job.label}`}
            label="Include Past"
            checked={includePastAlgosFlag}
            onChange={e => setIncludePastAlgosFlag(e.target.checked)}
          />
        )}
      </div>
      {!runAllAlgosFlag && (
        <div
          style={{
            overflowX: 'scroll',
          }}
        >
          <DatePicker
            dates={nextTaseDays}
            onDateChange={onDateChange}
            initialValue={selectedDate ? findIndex(nextTaseDays, date => dayjs(date).isSame(dayjs(selectedDate))) : null}
          />
        </div>
      )}
      <Button
        variant={getJobBtnStyle}
        onClick={() =>
          handleAlgoSubmit(
            {
              ...job,
              effectiveDate: selectedDate && !runAllAlgosFlag ? selectedDate : null,
              rebalanceWindowSize: selectedDate && !runAllAlgosFlag ? 1 : null,
              includePast: !runAllAlgosFlag ? false : includePastAlgosFlag,
            },
            'algo',
          )
        }
      >
        Run {job.category} {job.updateType} Algo
      </Button>
    </AlgoRowForm>
  )
}
