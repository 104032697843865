import React from 'react'
import { useTranslation } from 'react-i18next'

import { SuccessAlertTitle, SuccessAlertContainer, SuccessAlertSubText } from './styles'

const SuccessAlert = ({ style }) => {
  const { t } = useTranslation()

  return (
    <SuccessAlertContainer style={style}>
      <SuccessAlertTitle>{t('contactSupport.success.title')}</SuccessAlertTitle>
      <SuccessAlertSubText>{t('contactSupport.success.subtext')}</SuccessAlertSubText>
    </SuccessAlertContainer>
  )
}

export { SuccessAlert }
