import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: 2.7rem;
  text-align: center;
`

export const H2 = styled.h2`
  font-size: 1.8rem;
  text-align: center;
`

export const H3 = styled.h2`
  font-size: 1rem;
`

export const Text = styled.p`
  font-size: 1rem;
  text-align: center;
`

export const BoldText = styled.span`
  font-weight: 600;
`
