import React from 'react'
import { Formik } from 'formik'

import axiosApi from 'utils/axiosApi'
import PropTypes from 'prop-types'
import head from 'lodash/head'
import dayjs from 'dayjs'
import Button from 'react-bootstrap/Button'
import { Input } from 'components/Common/input'
import { Select } from 'components/Common/Select'
import { LookupsBlockContainer, LookupsTitle, HistoryRowForm } from './styles'

export const DataManagmentHistoryBlock = ({ allJobs, update }) => {
  const today = dayjs()
    .startOf('day')
    .toDate()

  const inputDateFormat = 'YYYY-MM-DD'
  return (
    <LookupsBlockContainer>
      <LookupsTitle>History</LookupsTitle>

      <Formik
        initialValues={{
          model: head(allJobs.history).value,
          startDate: dayjs(today)
            .subtract(20, 'day')
            .format(inputDateFormat),
          endDate: dayjs(today).format(inputDateFormat),
        }}
        onSubmit={async values => {
          await axiosApi.post('jobs/', {
            definition: 'HISTORY',
            data: values,
          })
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <HistoryRowForm>
            <Select
              name="model"
              value={values.model}
              onChange={handleChange}
              getChangedValueWithEvent
              data={allJobs.history}
            />

            <Input
              name="startDate"
              type="date"
              label={null}
              max={dayjs(values.endDate)
                .subtract(1, 'day')
                .format(inputDateFormat)}
              value={values.startDate}
              onChange={handleChange}
            />

            <Input
              name="endDate"
              type="date"
              max={dayjs(today).format(inputDateFormat)}
              label={null}
              value={values.endDate}
              onChange={handleChange}
            />

            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Run History
            </Button>
          </HistoryRowForm>
        )}
      </Formik>
    </LookupsBlockContainer>
  )
}

DataManagmentHistoryBlock.propTypes = {
  allJobs: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
}
