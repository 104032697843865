import React from 'react'
import styled from 'styled-components'

import LogoImg from 'assets/logo_israel.png'

const StyledLogo = styled.img.attrs({
  alt: 'Smart Bull',
})`
  width: auto;
  height: 220px;
  margin: 3rem 1rem 1rem;
  padding: 0.4rem;
`

const Logo = () => <StyledLogo src={LogoImg} />

export default Logo
