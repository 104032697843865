import styled from 'styled-components'
import { Table } from 'react-bootstrap'

export const StyledTable = styled(Table)`
  background: white;
  font-size: 0.87rem;

  thead tr th {
    background-color: rgb(242, 242, 242);
    border-bottom: none !important;
    text-align: ${props => (props.dir === 'rtl' ? 'right' : 'left')};
  }

  tbody tr td {
    text-align: ${props => (props.dir === 'rtl' ? 'right' : 'left')};
  }

  thead tr:first-child th {
    height: 53px !important;
  }

  thead tr:nth-child(2) th {
    position: sticky;
    position: -webkit-sticky;
    top: 61px;
    box-shadow: 0 0px 2px -2px gray;
  }

  .sortingUp {
    -webkit-box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
    -moz-box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
    box-shadow: inset 0px 8px 0px 0px rgba(209, 209, 209, 1) !important;
  }

  .sortingDown {
    -webkit-box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
    -moz-box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
    box-shadow: inset 0px -8px 0px 0px rgba(209, 209, 209, 1) !important;
  }

  .dangerTR td {
    background: #ffe4e1;
  }
  .dangerTR:hover td {
    background: #f5bfb9;
  }

  .successTR td {
    background: #e4ffe1;
  }
  .successTR:hover td {
    background: #c4ffbd;
  }

  .warningTR td {
    background: #fff6e1;
  }

  .warningTR:hover td {
    background: #ffe5bd;
  }

  .italic {
    font-style: italic;
  }

  .warningCell {
    background: #fff6e1;
  }

  .warningCellStrong {
    background: #e6b64a !important;
    color: white;
  }

  .dangerCell {
    background: ##b71c1b;
    color: white;
  }

  .rangeInputRtl {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
`
export const TableFilterRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FilterHeader = styled.th`
  height: 63px !important;
  position: sticky;
  top: -1px;
`
