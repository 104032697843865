import styled from 'styled-components'
import { Text } from 'styles/Typography'

export const SwitcherWrapper = styled(Text)`
  margin-top: 10px;
`

export const LangButton = styled.span`
  cursor: pointer;
  ${props =>
    props.isActive &&
    `
    font-weight: 600
  `}
`

export const SeparatorWrapper = styled.span`
  margin: 0 5px;
`
