import styled from 'styled-components'
import Popover from 'react-bootstrap/Popover'

export const Wrapper = styled.div`
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.margin && `margin: ${props.margin};`}
`

export const TotalRowWrapper = styled.div`
  background: ${props => (props.color ? props.color : 'rgba(204, 229, 255, 1)')};
  display: flex;
  padding: 20px;
  border: 1px solid rgba(215, 215, 215, 1);
  height: 100%;
`

export const Header = styled.div`
  background: ${props => (props.color ? props.color : 'rgba(2, 125, 180, 1)')};
  text-align: center;
  color: white;
  padding: 5px;
`

export const TotalElementWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  ${props => (props.pos === 0 ? '' : props.direction === 'hb' ? 'border-right: 1px solid #868686' : 'border-left: 1px solid #868686;')}
`

export const TotalTitleStyled = styled.div`
  color: ${props => (props.color ? props.color : '#7F7F7F')};
  font-size: 0.94rem;
`

export const TotalValueStyled = styled.div`
  direction: ${props => (props.direction ? props.direction : 'ltr')};
  color: ${props => (props.color ? props.color : 'black')};
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TotalSubDataStyled = styled.div`
  color: ${props => (props.color ? props.color : '#7F7F7F')};
  font-size: 0.82rem;
  font-weight: bold;
`

export const TotalMDTitleStyled = styled.div`
  color: black;
  font-size: 0.82rem;
  font-weight: 700;
  display: inline-block;
`

export const TotalMDValueStyled = styled.div`
  direction: ltr;
  color: ${props => (props.color ? props.color : 'black')};
  font-size: 0.82rem;
  display: inline-block;
`
export const TotalMDSubDataStyled = styled.div`
  margin-top: 13px;
  color: ${props => (props.color ? props.color : '#7F7F7F')};
  font-size: 0.79rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
`

export const CustomPopover = styled(Popover)`
  padding: 5px;
  max-width: unset;
  max-height: 500px;
`
