import dayjs from 'dayjs'

const isEffectiveAfterFriday=(effectiveDate)=>{
  const latestFriday = dayjs().subtract(2, 'day')
  return dayjs(effectiveDate).isSameOrAfter(latestFriday, 'day')
}

const isUsingLatestData = ({ effectiveDate, taseEffectiveDate }) => {
  // if no effective date, return default false
  if (!effectiveDate || !taseEffectiveDate) {
    return false
  }

  // if tase effective date has passed, and effective date is greater or equal than tase effecitve date, return true
  if (dayjs().isSameOrAfter(taseEffectiveDate, 'day')) {
    if(dayjs(taseEffectiveDate).day()===0)
    {
      return isEffectiveAfterFriday(effectiveDate);
    }
    return dayjs(effectiveDate).isSameOrAfter(taseEffectiveDate, 'day')
  }
  // else if tase effective date has not passed, but effective date is today, return true
  else if (dayjs().isSame(effectiveDate, 'day')) {
    return true
  }
  // else if it's sunday and effective date is latest friday, return true
  else if (dayjs().day() === 0) {
    return isEffectiveAfterFriday(effectiveDate);
  }
  // else return false
  return false
}

export { isUsingLatestData }
