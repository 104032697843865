import React from 'react'

import { i18n } from '../../../i18n'

import { SwitcherWrapper, LangButton, SeparatorWrapper } from './styles'

export const LanguageSwitcher = () => {
  const changeLanguage = lng => i18n.changeLanguage(lng)

  return (
    <SwitcherWrapper>
      <LangButton
        onClick={() => changeLanguage('en')}
        isActive={i18n.language === 'en'}
      >
        ENG
      </LangButton>
      <SeparatorWrapper>|</SeparatorWrapper>
      <LangButton
        onClick={() => changeLanguage('hb')}
        isActive={i18n.language === 'hb'}
      >
        HEB
      </LangButton>
    </SwitcherWrapper>
  )
}
