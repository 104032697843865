import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import axiosApi from 'utils/axiosApi'
import useColumnsData from 'utils/useColumnsData'
import { useSelectedCalcDescId } from 'context/Context'
import { Columns } from './Columns'
import { SecTypes, AlgoTypes } from 'utils/const'

import { Table } from 'components/Common/Table'
import Button from 'react-bootstrap/Button'
import { DataMgmtWrapper } from './style'

const tPrefix = 'dataManagement.algoManagementTable'

export const AlgoManagementTable = ({ secType, algoType }) => {
  // eslint-disable-next-line
  const [selectedCalcDescId, setSelectedCalcDescId] = useSelectedCalcDescId()
  const [selectedRows, setSelectedRows] = useState()
  const [runAlgoBtnVariant, setRunAlgoBtnVariant] = useState('info')
  const [runLookupBtnVariant, setRunLookupBtnVariant] = useState('info')
  const { t } = useTranslation()
  const isBondsRebalance = secType === SecTypes.allBond && algoType === AlgoTypes.parameters

  // unmount
  useEffect(() => {
    return () => {
      setSelectedCalcDescId(undefined, 'replaceIn')
    }
  }, [setSelectedCalcDescId])

  const onRowSelection = React.useCallback(
    selectedFlatRows => {
      const rows = selectedFlatRows.map(d => d.original)
      const isEqualRows = isEqual(selectedRows, rows)

      if (!isEqualRows) {
        setSelectedRows(rows)
      }
    },
    [selectedRows],
  )

  const buildJobsData = selectedRows => {
    const data = selectedRows.map(row => {
      const { category, updateType, effectiveDate, ...rest } = row
      let secType
      switch (category) {
        case 'Tel-Bond':
          secType = SecTypes.corpBond
          break
        case 'Gov-Bond':
          secType = SecTypes.govBond
          break
        case 'Equity':
          secType = SecTypes.stock
          break
        case 'All-Bond':
          secType = SecTypes.allBond
          break
        default:
          secType = 'undefined'
      }

      const parametersUpdateTypes = ['Monthly Rebalance', 'Semi Annual Rebalance']
      const algoType = parametersUpdateTypes.includes(updateType) ? AlgoTypes.parameters : AlgoTypes.components

      return {
        secType,
        algoType,
        taseEffectiveDate: effectiveDate,
        ...rest,
      }
    })

    return sortBy(data, d => d.taseEffectiveDate)
  }

  const runSelectedAlgos = async () => {
    setRunAlgoBtnVariant('info')
    const res = await axiosApi.post('jobs/', {
      definition: 'RUN_ALGO',
      data: buildJobsData(selectedRows),
    })

    if (get(res, 'data.ok')) {
      setRunAlgoBtnVariant('success')
    } else {
      setRunAlgoBtnVariant('danger')
    }
  }

  const runRelatedLookups = async () => {
    setRunLookupBtnVariant('info')
    const res = await axiosApi.post('jobs/', {
      definition: 'REPROCESS_ALL_FOR_SECTYPE',
      data: { secType },
    })

    if (get(res, 'data.ok')) {
      setRunLookupBtnVariant('success')
    } else {
      setRunLookupBtnVariant('danger')
    }
  }

  const noAlgoRun = row => {
    const { latestAlgoCalc } = row.original
    return !latestAlgoCalc
  }

  const noTaseData = row => {
    const { parametersStats, weightStats } = row.original?.latestAlgoCalc
    return secType === SecTypes.stock && !parametersStats && !weightStats
  }

  const isFutureUpdate = row => {
    const { effectiveDate } = row.original
    return dayjs()
      .startOf('day')
      .isBefore(dayjs(effectiveDate), 'day')
  }

  const conditionalRowProps = row => {
    if (noAlgoRun(row)) return { className: 'dangerTR' }
    if (noTaseData(row)) return { className: 'warningTR' }
    if (isBondsRebalance && isFutureUpdate(row)) return { className: 'warningTR' }
  }

  let apiUrl = `/jobs/getAlgoManagementTable?secType=${secType}`
  if (algoType) apiUrl += '&algoType=' + algoType

  return (
    <DataMgmtWrapper>
      {some(selectedRows) && (
        <Button variant={runAlgoBtnVariant} size="sm" onClick={() => runSelectedAlgos()}>
          {t(`${tPrefix}.runSelected`)}
        </Button>
      )}
      <Button style={{ marginLeft: '10px' }} variant={runLookupBtnVariant} size="sm" onClick={() => runRelatedLookups()}>
        {t(`${tPrefix}.runLookups`)}
      </Button>
      <Table
        withExportButton
        exportFileType="csv"
        columns={Columns({ ...useColumnsData(), secType, algoType })}
        apiUrl={apiUrl}
        headerText="Index Calc Results"
        virtualized={true}
        onRowSelection={onRowSelection}
        conditionalRowProps={conditionalRowProps}
        defaultSortBy={{ id: 'effectiveDate', desc: false }}
        rowHeight={70}
      />
    </DataMgmtWrapper>
  )
}

AlgoManagementTable.propTypes = {
  secType: PropTypes.string.isRequired,
  algoType: PropTypes.string,
}
