import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from 'assets/loading.svg'

export const LoadingIcon = styled.img.attrs({
  src: Loading,
  alt: 'loading...',
})`
  width: 1rem;
  margin-right: 0.4rem;
`

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.3rem;
  padding-bottom: 1px;
`

export const StyledButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
  ${props =>
    !props.variant &&
    props.secondary &&
    `color: ${props.secondary ? 'black' : 'white'};
  background: ${props.secondary ? '#e7e7e7' : '#3f98bd'};
  border-color: ${props.secondary ? '#d4d4d4' : '#5aabcb'};`}

  &:focus {
    box-shadow: none;
  }

  &:hover {
    ${props =>
    !props.variant &&
      props.secondary &&
      `background: ${props.secondary ? '#b9b5b5' : '#0d7ec5'};
      border-color: ${props.secondary ? 'grey' : '#2a728e'};`}
  }

  &:disabled {
    background: #a1b1c3;
    border-color: #818f9e;
    cursor: not-allowed;
  }
`
