import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'

import { AlgoTypes, SecTypes } from 'utils/const'
import some from 'lodash/some'
import split from 'lodash/split'
import compact from 'lodash/compact'
import dayjs from 'dayjs'

export const useExportFileName = ({ exportFileNameContext, exportFileNameOverrideDate, headerText }) => {
  const { selectedSim, secType } = useContext(Context) // feels a bit wrong to do it so low level

  const history = useHistory()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const { t } = useTranslation()

  const getTabName = tabUrlParam => {
    switch (tabUrlParam) {
      case 'index':
        return t('equity.tabs.indexOverview')
      case 'security':
        return t('equity.tabs.securityAnalysis')
      case 'general':
        return t('equity.tabs.securityGeneral')
      case 'all':
      default:
        return ''
    }
  }

  const getSectionName = section => {
    switch (section) {
      case 'stocks':
        return t('menu.stocks')
      case 'allBonds':
        return t('menu.allBonds')
      case 'data-managment':
        return t('menu.adminMenu.dataManagement')
      default:
        return section
    }
  }

  const getUpdateTypeStr = (algoType, secType) => {
    if (!algoType) return null

    if (secType && secType === SecTypes.allBond) {
      if (algoType === AlgoTypes.parameters) {
        return t(`simulation.general.dailyRebalance`)
      } else {
        return t(`simulation.general.monthlyComponents`)
      }
    }

    return t(`simulation.general.${algoType}`)
  }

  let fileName = '',
    internalTabContext = exportFileNameContext || headerText || '',
    updateType = getUpdateTypeStr(algoType, secType),
    [section, tab] = compact(split(history.location.pathname, '/'))

  if (section) {
    section = getSectionName(section)
  }

  if (tab) {
    tab = getTabName(tab)
  }

  if (some(selectedSim)) {
    fileName += `${t('simulation.general.simulation')}: ${selectedSim.name} - `
  } else {
    fileName += `${section} - `
    if (updateType) {
      fileName += `${updateType} `
      if (updateType === t('simulation.general.dailyRebalance')) {
        fileName += `(${dayjs(exportFileNameOverrideDate || taseEffectiveDate).format('DD.MM.YY')}) - `
      } else {
        fileName += `(${t('equity.table.scheduleDate')} ${dayjs(exportFileNameOverrideDate || taseEffectiveDate).format('DD.MM.YY')}) - `
      }
    }
  }

  if (tab) fileName += `${tab} - `
  if (internalTabContext) fileName += internalTabContext

  return fileName
}
