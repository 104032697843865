import compact from 'lodash/compact'

const tPrefix = 'common.table.colHeaders'

export const Columns = ({ t }) => {
  return compact([
    {
      id: 'securityName',
      Header: t('equity.generalSecurity.securityName'),
      accessor: 'securityNameHebrew',
      cellFormat: { noFormat: true },
    },
    {
      id: 'securityId',
      Header: t('equity.generalSecurity.securityNo'),
      accessor: 'securityId',
      cellFormat: { noFormat: true },
    },
    {
      id: 'projectedChange',
      Header: t(`${tPrefix}.projectedChange`),
      accessor: 'projectedChange',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    {
      id: 'effectiveDateTurnover',
      Header: t(`${tPrefix}.effectiveDateTurnover`),
      accessor: 'effectiveDateTurnover',
      cellFormat: { format: 'currency' },
    },
    {
      id: 'projectedChangeTurnoverDiff',
      Header: 'projected Change / effectiveDateTurnover (%)',
      accessor: row => {
        const { projectedChange, effectiveDateTurnover } = row
        return Math.abs((projectedChange / effectiveDateTurnover) * 100)
      },
      cellFormat: { format: '%-short' },
    },
    {
      id: 'avgTurnover',
      Header: t(`${tPrefix}.avgTurnover`),
      accessor: 'avgTurnover',
      cellFormat: { format: 'currency' },
    },
    {
      id: 'turnoverRatio',
      Header: t(`${tPrefix}.turnoverRatio`),
      accessor: row => {
        return Math.abs((row.projectedValueInFunds - row.currentValueInFunds) / row.avgTurnover)
      },
      cellFormat: { format: 'number-short' },
    },
  ])
}
