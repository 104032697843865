import styled from 'styled-components'

export const ServerError = styled.span`
  color: #dd3d4d;
  height: 1.5rem;
  text-align: center;
`
export const NameForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: baseline;

  & > button {
    margin: 0 7px;
  }
`

export const StatusBox = styled.span`
  color: ${props => props.color};
  margin: 0 7px;
  font-size: 1.1rem;
`

export const SimFormRow = styled.div`
  width: -webkit-fill-available;
  margin-bottom: 10px;
  text-align: end;

  display: grid;
  grid-template-columns: 28% 28% 18% 18% 8%;

  & > label {
    align-items: start;
    margin-top: 6px;
  }
`

export const SimFormSubmitRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: webkit-fill-available;
`
export const SimFormSection = styled.div`
  & > * {
    display: contents !important;
  }
`

export const RowControls = styled.div`
  padding: 0.4rem 0px 0px 1rem;

  & > svg {
    fontsize: 1rem;
    margin: 0 10px 0px 0px;
  }

  & > svg:hover {
    color: gray;
    cursor: pointer;
  }
`

export const SimStatusWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100vw;
  font-size: 1.5rem;
  transition: all 300ms ease-in;
  bottom: 15px;
  z-index: 1;
`

export const RunSimLink = styled.span`
  color: #007bff;

  &:hover {
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }

  &:active {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }
`
