import React from 'react'
import ReactDOM from 'react-dom'

import { BlurScreenContainer } from './styles'

const BlurScreen = ({ show, cursor, children, withPortal, portalElementId, onClick }) => {
  const handleNonChildClick = e => {
    e.preventDefault()

    if (e.target === e.currentTarget) {
      onClick()
    }
  }

  const cursorStyle = cursor || 'pointer'

  return show ? (
    <BlurScreenContainer style={{ cursor: cursorStyle }} onClick={handleNonChildClick}>
      {withPortal ? ReactDOM.createPortal(children, document.getElementById(portalElementId)) : children}
    </BlurScreenContainer>
  ) : null
}

export { BlurScreen }
