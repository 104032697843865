import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

import { StyledGroup, StyledControl, StyledError, Label } from './styles'

export const Input = ({
  type,
  isValid,
  isInvalid,
  label,
  placeholder,
  text,
  value,
  name,
  onChange,
  onBlur,
  errorText,
  wrappermarginbottom,
  width,
  lpignore,
  style,
  readOnly,
  min,
  max,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <StyledGroup wrappermarginbottom={wrappermarginbottom} width={width}>
      {label && <Label>{t(label)}</Label>}
      <StyledControl
        type={type}
        onBlur={onBlur}
        isValid={isValid}
        isInvalid={isInvalid}
        placeholder={t(placeholder)}
        value={value}
        name={name}
        onChange={onChange}
        data-lpignore={lpignore || false}
        readOnly={readOnly}
        style={style}
        min={min}
        max={max}
        {...props}
      />
      {text && <Form.Text className="text-muted">{t(text)}</Form.Text>}
      {errorText && isInvalid && <StyledError type="invalid">{errorText}</StyledError>}
    </StyledGroup>
  )
}

Input.defaultProps = {
  label: null,
  placeholder: null,
  isValid: null,
  isInvalid: null,
  value: '',
  onBlur: null,
  errorText: null,
  text: null,
  wrappermarginbottom: null,
  style: {},
}

Input.propTypes = {
  wrappermarginbottom: PropTypes.string,
  type: PropTypes.oneOf(['email', 'password', 'text', 'number', 'date', 'day']).isRequired,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
}
