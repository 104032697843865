import styled from 'styled-components'
import Popover from 'react-bootstrap/Popover'
import { Link } from 'react-router-dom'

export const SecurityItem = styled.div`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e2e2;
`
export const MasonryWrapper = styled.span`
  & > .my-masonry-grid {
    display: flex;
    width: auto;
    direction: ${props => props.direction};
    margin-top: 20px;

    & > .my-masonry-grid_column {
      padding: 0 30px;
      background-clip: padding-box;
      text-align: ${props => (props.direction === 'rtl' ? 'right' : 'left')};

      & > div {
        margin-bottom: 50px;
      }
    }

    @media (max-width: 768px) {
      & > .my-masonry-grid_column {
        padding: 0;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  &:hover {
    color: #007bff !important;
    opacity: 0.5 !important;
  }
`

export const CustomPopover = styled(Popover)`
  padding: 5px;
  max-width: unset;
  max-height: 500px;
`
