import React, { useContext } from 'react'
import Context, { useAlgoType } from 'context/Context'
import { SCREEN } from 'utils/const'
import { isEng } from '../../i18n'
import { useTranslation } from 'react-i18next'

import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { SecurityItem, StyledLink } from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export const SecurityCardItem = ({ sec }) => {
  const [algoType] = useAlgoType()
  const { secType } = useContext(Context)
  const pathPrefix = SCREEN[secType]
  const { t } = useTranslation()

  const { projectedComponentsRank } = sec
  const isSpecial = projectedComponentsRank >= 1000

  return (
    <SecurityItem>
      <StyledLink
        to={`/${pathPrefix}/security?algoType=${algoType}&security=${sec.securityId}`}
      >
        {isEng() ? sec.securityName : sec.securityNameHebrew}
      </StyledLink>{' '}
      {sec.fastTrack && t('equity.securityAnalysis.exceptions.fastTrack')}{' '}
      <small>({sec.securityId})</small>
      {isSpecial && (
        <TableCellExtraContent
          popoverText={t(`equity.componentRanks.${projectedComponentsRank}`)}
          content={<FontAwesomeIcon icon={faInfoCircle} />}
          color="black!important"
        />
      )}
    </SecurityItem>
  )
}
