import React from 'react'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'

import { Button } from 'components/Common/button'

import { LookupsRow, DataSource, Text, SecondCol } from './styles'

export const DataManagmentRow = ({
  job,
  onButtonClick,
  time,
  getJobBtnStyle,
}) => {
  return (
    <LookupsRow>
      <Col sm={6} lg={3}>
        <Button variant={getJobBtnStyle(job)} onClick={onButtonClick}>
          {job.name}
        </Button>
      </Col>
      <SecondCol sm={6}>
        {job.link && (
          <DataSource target="blank" href={job.link}>
            Src
          </DataSource>
        )}
        <Text>Latest Refresh :{time}</Text>
      </SecondCol>
    </LookupsRow>
  )
}

DataManagmentRow.propTypes = {
  job: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired,
}
