import React from 'react'
import { Helmet } from 'react-helmet'

import { useContextualTitle } from 'utils/useContextualTitle'

export const Title = () => {
    const title = useContextualTitle()
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
}

