import React from 'react'
import { useField } from 'formik'
import 'react-phone-number-input/style.css'
import { PhoneInput } from '../styles'

export const PhoneInputField = ({ label, ...props }) => {
  const fieldProps = useField(props.name)
  const fieldInput = fieldProps[0]
  const fieldHelper = fieldProps[2]

  return (
    <PhoneInput
      {...fieldInput}
      defaultCountry="IL"
      value={fieldInput.value}
      onChange={value => {
        fieldHelper.setValue(value)
      }}
    />
  )
}
