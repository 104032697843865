import React from 'react'
import { IndexRowSummery } from './IndexRowSummery'
import { SecurityRowSummery } from './SecurityRowSummery'

export const RowSummery = ({ arrayHelpers, condition, securityList, index, setShowSecurityRowForm, fieldList }) => {
  const RowSummeryComponent = condition.indexNo ? IndexRowSummery : SecurityRowSummery
  return (
    <RowSummeryComponent
      arrayHelpers={arrayHelpers}
      condition={condition}
      securityList={securityList}
      index={index}
      setShowSecurityRowForm={setShowSecurityRowForm}
      fieldList={fieldList}
    />
  )
}
