/* eslint-disable no-param-reassign */
import axios from 'axios'

import localStorageService from './localStorageService'
import auth from 'utils/auth'

const axiosApi = axios.create({
  baseURL: (process.env.REACT_APP_API_SERVER || '') + process.env.REACT_APP_BASE_API_PATH,
  responseType: 'json',
})

axiosApi.defaults.headers.common['Accept-Language'] = JSON.parse(localStorage.getItem('locale')) || 'en'

// Add a request interceptor
axiosApi.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    // Handle Error
    Promise.reject(error)
  },
)

// bad response error handler
const badResponseErrorHandler = async error => {
  const { config, response } = error

  const { status } = response
  console.log('error, original request', { status, config, response })
  if (auth.isAuthenticated()) {
    const unauthorized = status === 401
    const blocked = status === 402

    console.log('response error, details:', { unauthorized, blocked })

    if (blocked) {
      alert('You need a valid subscription')
      auth.logout(() => {
        window.location = '/'
      })
    }

    if (
      config.url === '/token' ||
      config.url === '/checkPincodeAndLogin' ||
      config.url === '/checkPasswordAndLogin' ||
      config.url === '/initializeLogin' ||
      config.url === '/registerPhoneAndSendPincode' ||
      config.url === '/verify' ||
      config.url === '/subscription'
    ) {
      auth.logout(() => {
        window.location = '/'
      })
    }

    if (unauthorized && !blocked) {
      // try to refresh token
      try {
        const token = await auth.refreshToken()

        if (!token) {
          throw new Error('no token')
        }

        return axiosApi.request(config)
      } catch (err) {
        console.log('refresh token failed', err)
        // refresh token failed, logout
        auth.logout(() => {
          window.location = '/'
        })
      }
    }
  }

  return Promise.reject(error)
}

// Add a response interceptor
axiosApi.interceptors.response.use(response => response, badResponseErrorHandler)

export default axiosApi
