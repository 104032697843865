import auth from 'utils/auth'
import { SecTypes } from 'utils/const'

export function loggedInRedirectUrl(history) {
  let redirectUrl = ''
  if (history?.location?.state?.referrer) {
    redirectUrl = history?.location?.state?.referrer
  } else if (auth.isSubscribed(SecTypes.stock)) {
    redirectUrl = '/stocks/all'
  } else if (auth.isSubscribed(SecTypes.allBond)) {
    redirectUrl = '/allBonds/all'
  } else {
    redirectUrl = '/noSubscription'
  }
  return redirectUrl
}
