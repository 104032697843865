import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import compact from 'lodash/compact'
import { Columns } from './Columns'
import Context from 'context/Context'

import { Table } from 'components/Common/Table'

export const WeightFactorsTable = ({ calcDesc }) => {
  const { t } = useTranslation()
  const { secType } = useContext(Context)

  const isMissingOnAlgo = row => {
    const { taseProjectedWeightFactor, projectedWeightFactor } = row.original
    // eslint-disable-next-line
    return (isNaN(projectedWeightFactor) || projectedWeightFactor == 0) && !isNaN(taseProjectedWeightFactor)
  }

  const hasDelta = row => {
    const { projectedWeightFactor, taseProjectedWeightFactor } = row.original
    return Math.abs(projectedWeightFactor - taseProjectedWeightFactor) >= 0.00001
  }

  const isMissingOnTase = row => {
    const { taseProjectedWeightFactor, projectedWeightFactor, someTaseAPIExpectedFound } = row.original

    return someTaseAPIExpectedFound && isNaN(taseProjectedWeightFactor) && !isNaN(projectedWeightFactor) && projectedWeightFactor !== 0
  }

  const conditionalRowProps = row => {
    if (isMissingOnAlgo(row)) return { className: 'dangerTR' }
    if (hasDelta(row)) return { className: 'warningTR' }
    if (isMissingOnTase(row)) return { className: 'successTR' }
  }

  const legends = compact([
    {
      color: '#ffe4e1',
      label: 'missing from algo, found on TASE',
      filter: isMissingOnAlgo,
    },
    {
      color: '#fff6e1',
      label: 'Delta between results and TASE',
      filter: hasDelta,
    },
    {
      color: '#e4ffe1',
      label: 'missing on TASE, found on algo',
      filter: isMissingOnTase,
    },
  ])

  return (
    <Table
      withExportButton
      exportFileType="csv"
      columns={Columns({ t, secType })}
      apiUrl={`/index_calc_results/getWeightFactorResultsByDescId/${calcDesc._id}`}
      defaultSortBy={{ id: 'securityId', desc: true }}
      exportFileNameOverrideDate={calcDesc.taseEffectiveDate}
      conditionalRowProps={conditionalRowProps}
      headerText="Index Calc Results"
      virtualized={true}
      legends={legends}
    />
  )
}
