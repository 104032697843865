import React, { useState } from 'react'
import PropTypes from 'prop-types'
import min from 'lodash/min'
import get from 'lodash/get'

import { DataManagmentRow } from 'components/Admin/JobSelector/DataManagmentRow'

import { LookupsBlockContainer, LookupsTitle } from './styles'

export const DataManagmentLookupsBlock = ({
  allJobs,
  update,
  formatTime,
  getJobBtnStyle,
}) => {
  const [minDate, setMinDate] = useState(Date.now())

  const minimumDate = min(
    allJobs.lookup
      .map(job => get(job, 'lastRunTime'))
      .filter(e => !!e)
      .map(lastRunTime => lastRunTime),
  )

  if (minDate !== minimumDate) setMinDate(minimumDate)

  return (
    <LookupsBlockContainer>
      <LookupsTitle>Lookups</LookupsTitle>
      {allJobs.lookup.map(job => (
        <DataManagmentRow
          key={job.name}
          job={job}
          onButtonClick={() => update(job, 'lookup')}
          time={formatTime(
            job.name === 'ALL LOOKUPS' ? minDate : job.lastRunTime,
          )}
          getJobBtnStyle={getJobBtnStyle}
        />
      ))}
    </LookupsBlockContainer>
  )
}

DataManagmentLookupsBlock.propTypes = {
  allJobs: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
}
