import React from 'react'
import urljoin from 'url-join'

import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isArray from 'lodash/isArray'

import { isEng } from '../../../../i18n'
import getFormattedValue from 'utils/getFormattedValue'
import { SecTypes } from 'utils/const'

import { TableCell } from 'components/Common/Table/TableCell'
import { GoalSeekCell } from './GoalSeekCell'
import { getReasonFieldByName } from './index'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

const tPrefix = 'goalSeek'

export const columns = ({ t, pathPrefix, history, indexesList, secType }) => {
  const isStocks = secType === SecTypes.stock
  const isBonds = secType === SecTypes.corpBond

  return compact([
    {
      id: 'indexName',
      Header: t('common.index'),
      width: '200',
      accessor: row => get(row.indexAlgoData, isEng() ? 'indexNameEng' : 'indexNameHeb'),
      Cell: row => {
        const linkToIndex = urljoin(`/${pathPrefix}/index/`, history.location.search, `&index=${row.row.original.indexNo}`)

        const sideEffects = (
          <div key="effects" style={{ fontSize: '1rem' }}>
            {map(row.row.original.sideEffects, (effectIdx, idx) => {
              const indexName = get(effectIdx.indexAlgoData, isEng() ? 'indexNameEng' : 'indexNameHeb')

              return (
                <span
                  style={idx === 0 ? {} : isEng() ? { marginLeft: '5px' } : { marginRight: '5px' }}
                  className="badge badge-success"
                  key={effectIdx.indexNo}
                >
                  + {indexName}
                </span>
              )
            })}
          </div>
        )

        return (
          <TableCell
            noPopup
            link={linkToIndex}
            value={row.value}
            direction={isEng() ? 'ltr' : 'rtl'}
            extraContent={sideEffects}
            overrideWrapperStyle={{ display: 'block' }}
          />
        )
      },
    },
    {
      id: 'reasons',
      Header: t(`${tPrefix}.reasons`),
      accessor: () => 'reasons',
      width: '250',
      Cell: row => {
        let { reasons } = row.row.original

        const reasonsOrder = [
          'noFastTrackForIndex',
          'noFastTrackForOldStocks',
          'out of universe',
          'requiredBaseIndex',
          'almost in',
          'minAvgPrice',
          'minClosingPrice',
          'minFreeFloatRate',
          'avgMarketValue',
          'minRatingRequired',
          'maxRatingRequired',
          'minPublicMarketValue',
          'low market cap in sub sector',
          'low market cap in yield real estate',
          'low turnover',
          'fluctuatingCapitalListed',
          'illiquid candidate',
          'noRating',
          'restricted',
        ]

        reasons = sortBy(reasons, field => {
          const computedPos = findIndex(reasonsOrder, i => i === field.t)
          return computedPos !== -1 ? computedPos : reasonsOrder.length + 1
        })

        const getReasonPillVariant = reason => {
          switch (reason) {
            case 'almost in':
              return 'success'
            case 'requiredBaseIndex':
            case 'requiredBaseUniverse':
            case 'noFastTrackForOldStocks':
              return 'warning'
            case 'low turnover':
            case 'fluctuatingCapitalListed':
            case 'illiquid candidate':
            case 'maturityDateBeforeNextNextEffectiveDate':
            case 'closeMaturityDate':
            case 'restricted':
            case 'noFastTrackForIndex':
              return 'danger'
            case 'avgMarketValue':
            case 'minFreeFloatRate':
            case 'minAvgPrice':
            case 'minClosingPrice':
            case 'minPublicMarketValue':
            case 'low market cap in sub sector':
            case 'low market cap in yield real estate':
            case 'minRatingRequired':
            case 'maxRatingRequired':
            case 'qualification period':
            case 'noRating':
            default:
              return 'info'
          }
        }

        const reasonPills = reasons.map((reason, index) => {
          let { t: requirement, missing } = reason
          switch (requirement) {
            case 'closeMaturityDate':
            case 'qualification period':
              missing = getFormattedValue({
                value: missing,
                formatOverride: 'date',
              })
              break
            case 'requiredBaseIndex':
              // hack to display 'tamar' instead of allShares.
              if (missing === 168) {
                requirement = 'requiredBaseUniverse'
                missing = t(`${tPrefix}.tamar`)
              } else if (missing === 175) {
                requirement = 'requiredBaseUniverse'
                missing = t(`${tPrefix}.rimon`)
              } else if (isArray(missing)) {
                missing = missing.map(indexNo => {
                  const index = find(indexesList, i => i.value === indexNo)
                  return get(index, 'label') || missing
                })
              } else {
                const index = find(indexesList, i => i.value === missing)
                missing = get(index, 'label') || missing
              }
              break
            case 'out of universe':
              requirement = 'requiredBaseUniverse'
              const index = find(indexesList, i => i.value === missing)
              missing = get(index, 'label') || missing
              break
            case 'fluctuatingCapitalListed':
              missing = getFormattedValue({ value: missing, formatOverride: '%-short' })
              break
            case 'minRatingRequired':
            case 'maxRatingRequired':
              missing = getFormattedValue({
                value: missing,
                formatOverride: 'rating',
              })
              break
            case 'notInTopOfRimonForFastTrack':
            case 'low market cap in yield real estate':
            case 'low market cap in sub sector':
              missing = getFormattedValue({
                value: missing,
                formatOverride: 'currency',
              })
              break
            case 'turnoverMedianSpecs':
              missing = getFormattedValue({
                value: missing,
                formatOverride: 'turnoverMedianSpecs',
                t,
              })
              break
            default:
              break
          }

          return (
            <TableCellExtraContent
              key={`${requirement}||${missing}||${index}`}
              content={
                <span
                  style={{
                    fontSize: '0.75rem',
                    margin: isEng() ? '0 0 5px 5px' : '0 5px 5px 0',
                  }}
                  className={`badge badge-${getReasonPillVariant(requirement)}`}
                >
                  {t(`goalSeek.${requirement}`)}
                  {missing &&
                    `: ${getFormattedValue({
                      value: missing,
                      name: getReasonFieldByName(requirement),
                      toShort: true,
                      t,
                    })}`}
                </span>
              }
              popoverText={
                missing && (
                  <>
                    {t('goalSeek.missing')}{' '}
                    {getFormattedValue({
                      value: missing,
                      name: getReasonFieldByName(requirement),
                      t,
                    })}
                  </>
                )
              }
              noPopup={!missing}
            />
          )
        })
        return <div key="reasons">{reasonPills}</div>
      },
    },
    isStocks && {
      id: 'requiredBaseIndex',
      Header: t(`${tPrefix}.requiredBaseIndex`),
      accessor: () => 'requiredBaseIndex',
      Cell: row => {
        const { criteria } = row.row.original
        if (get(criteria, 'tamarBased')) {
          return t(`${tPrefix}.tamar`)
        } else {
          return t(`${tPrefix}.rimon`)
        }
      },
    },
    isStocks && {
      id: 'minFreeFloatRate',
      Header: t(`${tPrefix}.minFreeFloatRate`),
      accessor: row => {
        const minFreeFloatRate = get(row, 'criteria.minFreeFloatRate')
        return minFreeFloatRate
      },
      Cell: row => {
        const minFreeFloatRate = get(row.row.original, 'criteria.minFreeFloatRate')

        return (
          <TableCell
            value={minFreeFloatRate || t(`${tPrefix}.seeBaseUniverse`)}
            format={minFreeFloatRate ? '%' : 'noFormat'}
            overrideWrapperStyle={{ display: 'block' }}
          />
        )
      },
    },
    isStocks && {
      id: 'minAvgPrice',
      Header: t(`${tPrefix}.minAvgPrice`),
      accessor: row => {
        const minAvgPrice = get(row, 'criteria.minAvgPrice')
        return minAvgPrice || t(`${tPrefix}.seeBaseUniverse`)
      },
      Cell: row => {
        const minAvgPrice = get(row.row.original, 'criteria.minAvgPrice')

        return (
          <TableCell
            value={minAvgPrice || t(`${tPrefix}.seeBaseUniverse`)}
            format={minAvgPrice ? 'agorot' : 'noFormat'}
            overrideWrapperStyle={{ display: 'block' }}
          />
        )
      },
    },
    isStocks && {
      id: 'minPublicMarketValue',
      Header: t(`${tPrefix}.minPublicMarketValue`),
      accessor: row => {
        const minPublicMarketValue = get(row, 'criteria.minPublicMarketValue')
        return minPublicMarketValue || '-'
      },
      cellFormat: { format: 'currency' },
    },
    isBonds && {
      id: 'minMarketValue',
      Header: t(`${tPrefix}.minMarketValue`),
      accessor: row => {
        const minMarketValue = get(row, 'criteria.minMarketValue')
        return minMarketValue
          ? getFormattedValue({
              value: minMarketValue,
              formatOverride: 'currency',
            })
          : t(`${tPrefix}.seeBaseUniverse`)
      },
      cellFormat: { format: 'noFormat' },
    },
    isBonds && {
      id: 'minRating',
      Header: t(`${tPrefix}.minRating`),
      accessor: row => {
        const minRating = get(row, 'criteria.minRating')
        return minRating || '-'
      },
      cellFormat: { format: 'rating' },
    },
    {
      id: 'avgMarketValue',
      Header: t(`${tPrefix}.marketValueForEntryRanking`),
      accessor: row => {
        const { indexAlgoData, secToBeatField } = row

        const secToBeat = get(indexAlgoData, `goalSeek.${secToBeatField}`)

        return secToBeat ? secToBeat.projectedComponentsMarketValue : '-'
      },
      Cell: row => {
        const { indexAlgoData, secToBeatField } = row.row.original
        const secToBeat = get(indexAlgoData, `goalSeek.${secToBeatField}`)

        if (!secToBeat) return '-'

        const { projectedComponentsMarketValue, projectedComponentsRank } = secToBeat
        return (
          <TableCell
            value={projectedComponentsMarketValue}
            format={'currency'}
            overrideWrapperStyle={{ display: 'block' }}
            extraContent={
              <span className="badge badge-secondary" style={{ fontSize: '0.75rem' }}>
                {t(`${tPrefix}.rank`)} {projectedComponentsRank}
              </span>
            }
          />
        )
      },
    },
    {
      id: 'goalSeek',
      Header: t(`${tPrefix}.goalSeek`),
      accessor: 'goalSeek',
      Cell: GoalSeekCell,
    },
  ])
}
