import React from 'react'
import { useTranslation } from 'react-i18next'

import useCalcDescUrl from 'utils/useCalcDescUrl'
import useColumnsData from 'utils/useColumnsData'

import { Table } from 'components/Common/Table'
import { columns } from './columns'

export const BondsRebalanceChangesSummary = () => {
  const { t } = useTranslation()
  const calcDescForUrl = useCalcDescUrl()

  return (
    <Table
      withExportButton
      headerText={t('equity.changesSummary.tableTitle')}
      columns={columns(useColumnsData())}
      apiUrl={`changes_summary/bondsRebalanceTable?${calcDescForUrl}`}
      defaultSortBy={{ id: 'projectedPayment', desc: true }}
      virtualized={true}
      noDataMsg={t('equity.changesSummary.noPayments')}
    />
  )
}

