import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

export const FormGroupStyled = styled.div`
  text-align: center;
  margin-bottom: 0;

  ${props => props.fullWidth && 'width: 100%;'}
`

export const Label = styled(Form.Label)`
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledError = styled(Form.Control.Feedback)`
  text-align: center;
  display: flex !important;
`
