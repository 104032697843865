import React from 'react'
import { NavLink as Link } from 'react-router-dom'

import { ErrorMsg } from './styles'

export const CreateNewPasswordInvalidToken = ({ t, error }) => {
  return (
    <div className="create-new-password-invalid-token"style={{ textAlign: 'center' }}>
      <ErrorMsg>{t(error)}</ErrorMsg>
      <Link to={'/forgotPassword'}>{t('passwordLogin.revisitForgotPasswordLink')}</Link>
    </div>
  )
}
