import React, { useContext, useMemo } from 'react'
import { isEng } from '../../i18n'
import Context, { useAlgoType } from 'context/Context'
import { SCREEN } from 'utils/const'
import { useTranslation } from 'react-i18next'

import get from 'lodash/get'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'

import Card from 'react-bootstrap/Card'
import { SecurityCardItem } from './SecurityCardItem'
import { StyledLink } from './styled'
import { IndexDataCardItem } from './IndexDataCardItem'

export const IndexSummaryCard = ({ data }) => {
  const [algoType] = useAlgoType()
  const { secType } = useContext(Context)
  const pathPrefix = SCREEN[secType]
  const { t } = useTranslation()

  const indexData = useMemo(() => get(data, 'indexData'), [data])
  const entries = sortBy(get(data, 'entries'), [isEng() ? 'securityName' : 'securityNameHebrew'])
  const exits = sortBy(get(data, 'exits'), [isEng() ? 'securityName' : 'securityNameHebrew'])
  const tPrefix = 'equity.changesSummary'

  const FollowingFunds = get(indexData, 'followingFundsValue') ? (
    <Card.Body style={{ background: 'rgb(242,242,242)' }}>
      <strong>{t(`${tPrefix}.indexData`)}</strong>

      <IndexDataCardItem indexData={indexData} field="followingFundsValue" format="currency" />
      <IndexDataCardItem indexData={indexData} field="projectedChangeValue" format="currency" />
    </Card.Body>
  ) : null

  return (
    some(indexData) && (
      <Card>
        <Card.Header>
          <StyledLink style={{ fontSize: '1.1rem' }} to={`/${pathPrefix}/index?algoType=${algoType}&index=${data.indexNo}`}>
            {indexData.indexData[isEng() ? 'indexNameEng' : 'indexNameHeb']}
          </StyledLink>
        </Card.Header>
        <Card.Body style={{ background: '#e4ffe1' }}>
          <strong>{t(`${tPrefix}.inflows`)}</strong>
          {some(entries) ? (
            entries.map(sec => <SecurityCardItem sec={sec} key={sec.securityId} />)
          ) : (
            <Card.Text>{t(`${tPrefix}.noSecuritiesInflows`)} </Card.Text>
          )}
        </Card.Body>
        <Card.Body style={{ background: '#ffe4e1' }}>
          <strong>{t(`${tPrefix}.outflows`)}</strong>
          {some(exits) ? (
            exits.map(sec => <SecurityCardItem sec={sec} key={sec.securityId} />)
          ) : (
            <Card.Text>{t(`${tPrefix}.noSecurityOutflows`)} </Card.Text>
          )}
        </Card.Body>
        {FollowingFunds}
      </Card>
    )
  )
}
