import styled from 'styled-components'

import { H2 } from 'styles/Typography'

export const IndexSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export const SelectorText = styled(H2)`
  margin-right: 8px;
`
