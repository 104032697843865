import styled from 'styled-components'

export const BlurScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #80808078;
  z-index: 990;
  cursor: pointer;
`
