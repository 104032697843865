import React, { useRef } from 'react'

import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

export const MetaCell = ({ row }) => {
  const ref = useRef(null)

  let { meta, msg } = row.row.original
  return (
    <div ref={ref}>
      <OverlayTrigger
        trigger="click"
        key={'meta'}
        placement={'top'}
        overlay={
          <Popover
            id={`popover-positioned-${'top'}`}
            style={{ minWidth: '50vw' }}
          >
            <Popover.Title as="h3">meta:</Popover.Title>
            <Popover.Content>
              <strong>{msg}</strong>
              <pre
                style={{
                  fontSize: '10px',
                }}
              >
                <code>{JSON.stringify({ meta }, null, 2)}</code>
              </pre>
            </Popover.Content>
          </Popover>
        }
      >
        <div style={{ width: '-webkit-fill-available', textAlign: 'center' }}>
          <Button variant="secondary" size="sm">
            show meta
          </Button>
        </div>
      </OverlayTrigger>
    </div>
  )
}
