import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import getFormattedValue from 'utils/getFormattedValue'
import Overlay from 'react-bootstrap/Overlay'

import some from 'lodash/some'

import {
  TotalElementWrapper,
  TotalTitleStyled,
  TotalValueStyled,
  TotalSubDataStyled,
  TotalMDTitleStyled,
  TotalMDValueStyled,
  TotalMDSubDataStyled,
  CustomPopover,
} from './styles'

export const TotalElement = ({ pos, title, tooltip, badge, value, color, valueColor, format, subData, isMetadataRow, direction, valueGroup }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const wrapperRef = useRef(null)
  const { t } = useTranslation()

  const mdRowInner = (title, value, format, idx, size) => (
    <>
      <TotalMDTitleStyled>{title}:&nbsp;</TotalMDTitleStyled>
      <TotalMDValueStyled color={color}>
        {getFormattedValue({
          value,
          name: title,
          toShort: true,
          t,
          formatOverride: format,
        })}
        {badge && badge}
        {idx < size - 1 && ' ; '}
      </TotalMDValueStyled>
    </>
  )

  const TotalElementData =
    isMetadataRow === true ? (
      some(valueGroup) ? (
        valueGroup.map((valueItem, idx) => mdRowInner(valueItem.title, valueItem.value, valueItem.format, idx, valueGroup.length))
      ) : (
        mdRowInner(title, value, format)
      )
    ) : (
      <>
        <TotalTitleStyled color={color}>{title}</TotalTitleStyled>
        <TotalValueStyled color={valueColor || color} direction={direction}>
          {getFormattedValue({
            value,
            name: title,
            toShort: true,
            t,
            formatOverride: format,
          })}
          {badge && badge}
        </TotalValueStyled>
      </>
    )

  const buildTotalElementSubData = subData => {
    if (!some(subData)) return null

    return isMetadataRow === true ? (
      <>
        {subData.map((item, index) => (
          <TotalMDSubDataStyled key={`${item.title} + ${item.value}`} color={item.color}>
            <span>{item.title}:</span>
            <span>
              {getFormattedValue({
                value: item.value,
                toShort: true,
                t,
                formatOverride: item.format,
              })}
            </span>
          </TotalMDSubDataStyled>
        ))}
      </>
    ) : (
      <>
        {subData.map(item => (
          <TotalSubDataStyled key={`${item.title} + ${item.index}`} color={item.color}>
            {item.title}:{' '}
            {getFormattedValue({
              value: item.value,
              toShort: true,
              t,
              formatOverride: item.format,
            })}
          </TotalSubDataStyled>
        ))}
      </>
    )
  }

  return (
    <>
      <TotalElementWrapper
        direction={i18n.language}
        ref={wrapperRef}
        onMouseLeave={() => setIsTooltipOpen(false)}
        onMouseEnter={() => setIsTooltipOpen(true)}
        pos={pos}
      >
        {TotalElementData}
        {subData && buildTotalElementSubData(subData)}
      </TotalElementWrapper>

      {(format === 'currency' || tooltip) && (
        <Overlay target={wrapperRef.current} show={isTooltipOpen} placement="bottom">
          {props => (
            <CustomPopover
              id="popover-positioned-bottom"
              {...props}
              onMouseLeave={() => setIsTooltipOpen(false)}
              onMouseEnter={() => setIsTooltipOpen(true)}
            >
              {tooltip
                ? tooltip
                : getFormattedValue({
                    value,
                    name: title,
                    t,
                    formatOverride: format,
                  })}
            </CustomPopover>
          )}
        </Overlay>
      )}
    </>
  )
}

TotalElement.defaultProps = {
  format: 'number',
  color: null,
  value: 0,
  subData: null,
  isMetadataRow: false,
}

TotalElement.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  format: PropTypes.string,
  isMetadataRow: PropTypes.bool,
}
