import styled from 'styled-components'

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const TabStyled = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 0px;
  text-align: center;
  border: 1px solid rgba(215, 215, 215, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.isActive
      ? `
        color: 'black';
        border-bottom: 0;
     `
      : `
        color: #F59A23;
        background: rgba(242, 242, 242, 1);
        cursor: pointer;
      `};
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
