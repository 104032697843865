import styled from 'styled-components'
import { H2 } from 'styles/Typography'

export const BlockContainer = styled.div`
  margin-bottom: 50px;
  padding: 20px;
  background: #cde7fe;
`

export const LookupsTitle = styled(H2)`
  text-align: left;
`
export const AlgoRowForm = styled.form`
  display: grid;
  grid-template-columns: 245px 1fr 1fr 1fr;
  & > * {
    margin-right: 20px;
  }
  margin-bottom: 10px;
`
export const AlgoFormLabel = styled.div`
  display: flex;
  align-items: center;
`
