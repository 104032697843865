import React from 'react'
import PropTypes from 'prop-types'
import { isEng } from '../../../i18n'
import get from 'lodash/get'
import { Button } from 'components/Common/button'

import {
  DialogLayout,
  DialogContainer,
  DialogText,
  DialogButtonsContainer,
} from './styles'

export const ConfirmDialog = ({ isOpen, text, textColor, buttons }) => {
  if (!isOpen) return null
  return (
    <DialogLayout>
      <DialogContainer>
        <DialogText textColor={textColor}>{text}</DialogText>
        <DialogButtonsContainer>
          {buttons.map((button, index) => (
            <Button
              onClick={get(button, 'onClick')}
              variant={get(button, 'color')}
              style={
                isEng()
                  ? { marginLeft: index !== 0 ? '20px' : 0 }
                  : { marginRight: index !== 0 ? '20px' : 0 }
              }
              key={index}
            >
              {get(button, 'text')}
            </Button>
          ))}
        </DialogButtonsContainer>
      </DialogContainer>
    </DialogLayout>
  )
}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
}

ConfirmDialog.defaultProps = {
  textColor: 'black',
}
