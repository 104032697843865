import compact from 'lodash/compact'
import { SecTypes } from 'utils/const'
const tPrefix = 'common.table.colHeaders'

export const Columns = ({ t, secType }) => {
  const isEquity = secType === SecTypes.stock
  return compact([
    {
      id: 'nameHebrew',
      Header: t(`equity.generalSecurity.securityName`),
      accessor: 'nameHebrew',
      cellFormat: { noFormat: true },
    },
    {
      id: 'securityNo',
      Header: t('equity.generalSecurity.securityNo'),
      accessor: 'securityNo',
      cellFormat: { noFormat: true },
    },
    {
      id: 'invalid',
      Header: 'SB invalid reason',
      accessor: 'invalid',
      cellFormat: { noFormat: true },
    },
    isEquity && {
      id: 'projectedIndexAdjustedFreeFloatRate',
      Header: t(`${tPrefix}.projectedIndexAdjustedFreeFloatRate`),
      accessor: 'projectedIndexAdjustedFreeFloatRate',
      cellFormat: {
        format: '%-shorter',
      },
    },
    isEquity && {
      id: 'taseProjectedIndexAdjustedFreeFloatRate',
      Header: t(`${tPrefix}.taseProjectedIndexAdjustedFreeFloatRate`),
      accessor: 'taseProjectedIndexAdjustedFreeFloatRate',
      cellFormat: {
        format: '%-shorter',
      },
    },
    isEquity && {
      id: 'projectedFreeFloatRateDelta',
      Header: t(`${tPrefix}.projectedFreeFloatRateDelta`),
      accessor: row => {
        const { projectedIndexAdjustedFreeFloatRate, taseProjectedIndexAdjustedFreeFloatRate } = row
        const diff = Math.abs(projectedIndexAdjustedFreeFloatRate - taseProjectedIndexAdjustedFreeFloatRate)
        return diff && diff >= 0.00001 ? diff : 0
      },
      cellFormat: { format: '%-shorter' },
    },
    {
      id: 'capitalListedForTrading',
      Header: t(`${tPrefix}.indexAdjustedNoOfSharesIans`),
      accessor: 'capitalListedForTrading',
      cellFormat: { format: 'number-long' },
    },
    {
      id: 'taseIndexAdjustedNoOfSharesIans',
      Header: t(`${tPrefix}.taseIndexAdjustedNoOfSharesIans`),
      accessor: 'taseIndexAdjustedNoOfSharesIans',
      cellFormat: { format: 'number-long' },
    },

    {
      id: 'projectedIANSDelta',
      Header: t(`${tPrefix}.projectedIANSDelta`),
      accessor: row => {
        const { capitalListedForTrading, taseIndexAdjustedNoOfSharesIans } = row
        const diff = Math.abs(capitalListedForTrading - taseIndexAdjustedNoOfSharesIans)
        return diff && diff >= 0.00001 ? diff : 0
      },
      cellFormat: { format: 'number-long' },
    },
    {
      id: 'projectedLiquidityRatio',
      Header: t(`${tPrefix}.projectedLiquidityRatio`),
      accessor: 'calculatedProjectedLiquidationGroup',
      cellFormat: { format: 'liquidityRatio' },
    },
    {
      id: 'taseNewLiquidityRatio',
      Header: t(`${tPrefix}.taseNewLiquidityRatio`),
      accessor: 'taseNewLiquidityRatio',
      cellFormat: { format: 'liquidityRatio' },
    },
  ])
}
