import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

export const StyledGroup = styled(Form.Group)`
  display: block !important;
  margin-bottom: ${props => (props.wrappermarginbottom ? props.wrappermarginbottom : '0.3rem')};
  ${props => props.width && `width:${props.width}`}
`

export const StyledControl = styled(Form.Control)``

export const StyledError = styled(Form.Control.Feedback)`
  display: flex;
`

export const Label = styled(Form.Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
  margin-bottom: 0.3rem;
`
