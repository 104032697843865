import React from 'react'

import { useTranslation } from 'react-i18next'

import { isEng } from '../../../../i18n'
import some from 'lodash/some'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function(row) {
  const { t } = useTranslation()

  const { fastTrackIndexes, fastTrack } = row.row.original

  if (some(fastTrackIndexes) || fastTrack) {
    if (!some(fastTrackIndexes)) {
      return (
        <span key="fastTrackIndication">
          {t('equity.securityAnalysis.exceptions.fastTrack')}
        </span>
      )
    } else {
      const fastTrackIndexNames = fastTrackIndexes.map(
        indexDoc => indexDoc[isEng() ? 'indexNameEng' : 'indexNameHeb'],
      )

      const popoverText = `${t(
        'equity.table.fastTrackIndexes',
      )} ${fastTrackIndexNames.join(', ')}`
      return (
        <span key="fastTrackIndication">
          {t('equity.securityAnalysis.exceptions.fastTrack')}
          <TableCellExtraContent
            popoverText={popoverText}
            content={<FontAwesomeIcon icon={faInfoCircle} />}
            color="#6f6f6f!important"
            key="fastTrackInfo"
          />
        </span>
      )
    }
  } else return null
}
