import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity } from '@fortawesome/free-solid-svg-icons'
import getFormattedValue from 'utils/getFormattedValue'

const formatWeight = (weight, format) => {
  return getFormattedValue({ value: weight, formatOverride: format })
}

export default function({ data }) {
  if (!data) return null

  const { t } = useTranslation()

  let { originalWeightCap, maxWeight } = data
  if (!originalWeightCap) return null
  let popoverText

  popoverText = `${t('equity.changesSummary.weightIssuerLimitApplied')}:\n`
  popoverText += `${formatWeight(maxWeight, '%*100.00')} ${t('lang.insteadOf')} ${formatWeight(originalWeightCap, '%*100')}`

  return <TableCellExtraContent popoverText={popoverText} content={<FontAwesomeIcon icon={faUniversity} />} color={'red'} key="weightFactor" />
}
