import { getIn, useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RowFormIndexFields } from './RowFormIndexFields'
import { RowFormSecurityFields } from './RowFormSecurityFields'

export const useErrorProps = () => {
  const { errors, touched } = useFormikContext()
  const { t } = useTranslation()

  const getErrorProps = useCallback(
    name => {
      const error = getIn(errors, name)
      const touch = () => {
        try {
          return getIn(touched, name)
        } catch (err) {
          return false
        }
      }

      return {
        isValid: false,
        isInvalid: !!error && touch(),
        errorText: t(error) || null,
      }
    },
    [errors, t, touched],
  )

  return { getErrorProps }
}

export const RowFormFields = ({ securityList, fieldLabels }) => {
  const { values } = useFormikContext()

  if (values.indexNo) {
    return <RowFormIndexFields securityList={securityList} />
  }

  return <RowFormSecurityFields securityList={securityList} fieldLabels={fieldLabels} />
}
