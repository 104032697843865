import { AuthContextProvider } from 'context/AuthContext'
import React from 'react'
import { LoginScreen } from './LoginScreen'

export const Login = () => {
  return (
    <React.Fragment>
      <AuthContextProvider>
        <LoginScreen />
      </AuthContextProvider>
    </React.Fragment>
  )
}
