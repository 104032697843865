import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

export const SelectorContainer = styled.div`
  align-items: center;
`

export const SelectorRow = styled(({ navClearance, ...props }) => <Row {...props} />)`
  padding: 15px 0;
  justify-content: center;
  ${props => props.navClearance && 'margin-top: 60px;'}
  align-items: center;
`
export const ProjectedChangesCol = styled.div`
  padding: 0 2px;
`

export const SelectorWrapper = styled.div`
  display: contents;
  max-width: 200px;
  @media (max-width: 768px) {
    margin: auto;
  }
`

export const SelectorLabelCol = styled(ProjectedChangesCol)`
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 0px 2px;
`

export const SelectorLabel = styled.div`
  margin-right: 10px;
  font-size: 14px;
`

export const DateElementWrapper = styled.div`
  border: 2px solid rgb(245, 154, 35);
  border-radius: 3px;
  font-size: 12px;
  height: 100%;
  padding: 0px 10px;
`
export const DateLineWrapper = styled.div`
  text-align: center;
  color: rgb(245, 154, 35);
`

export const DateWrapper = styled.span`
  margin-left: 2px;
  font-weight: 600;
`

export const DateElementSecondRowWrapper = styled.div`
  text-align: center;
`
export const DateElementSecondRowValue = styled.span`
  margin: 0 2px;
`
