import React from 'react'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

export default function({ data }) {
  const { t } = useTranslation()

  if (!data) return null

  const removal = get(data, 'currTaseEffectiveDate.removal')

  if (!removal) return null
  return (
    <TableCellExtraContent
      content={
        <span className="badge badge-danger">
          {t('equity.securityAnalysis.removal')}
        </span>
      }
      noPopup={true}
      key="removalBadge"
    />
  )
}
