import React from 'react'
import compact from 'lodash/compact'
import { TableCell } from 'components/Common/Table/TableCell'

export const columns = onShareClicked => {
  return compact([
    {
      Header: 'Id',
      accessor: '_id',
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => row.value || row.original.computedName,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
    },
    {
      Header: 'Shared By',
      accessor: 'sharingUser.email',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: row => {
        return (
          <TableCell
            value={'Share'}
            onCellClick={() => {
              onShareClicked(row.row.original)
            }}
            noFormat
          />
        )
      },
    },
  ])
}
