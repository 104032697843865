import * as yup from 'yup'

export const ForgotPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .required('resetPassword.form.email.error.required')
    .email('resetPassword.form.email.error.notValid'),
})

export const NewPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .required('resetPassword.form.password.error.required')
    .min(8, 'resetPassword.form.password.error.short')
    .max(32, 'resetPassword.form.password.error.long'),
  confirmPassword: yup
    .string()
    .required('resetPassword.form.password.error.required')
    .oneOf([yup.ref('password'), null], 'resetPassword.form.confirmPassword.error.noMatch'),
})
