import { useContext } from 'react'
import get from 'lodash/get'
import Context, { useAlgoType, useTaseEffectiveDate, useExternalIndexes } from 'context/Context'
export default function useCalcDescUrl({ allowSimId = true } = {}) {
  const { secType, selectedSim } = useContext(Context)
  const [displayExternalIndexes] = useExternalIndexes()
  const [algoType] = useAlgoType()
  const [taseEffectiveDate] = useTaseEffectiveDate()

  const simGetParam = allowSimId && get(selectedSim, '_id') && !get(selectedSim, 'isFirstRun') ? `&simId=${selectedSim._id}` : ''
  const externalIndexesGetParam = displayExternalIndexes ? '&displayExternalIndexes=true' : ''
  return `algoType=${algoType}&secType=${secType}&taseEffectiveDate=${taseEffectiveDate}${simGetParam}${externalIndexesGetParam}`
}
