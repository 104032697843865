import React, { useEffect, useState } from 'react'

import get from 'lodash/get'
import some from 'lodash/some'

import { BlockContainer, LookupsTitle } from './styles'
import { AlgoRow } from './AlgoRow'
import { BondsRebalanceAlgoRow } from './BondsRebalanceAlgoRow'
import { Loader } from 'components/Common/Loader'

import { RunAllAlgoRow } from './RunAllAlgoRow'
import axiosApi from 'utils/axiosApi'

export const DataManagmentAlgoBlock = ({ allJobs, update, formatTime, getJobBtnStyle }) => {
  const [updateSchedules, setUpdateSchedules] = useState([])
  const [fetchingUpdateSchedules, setFetchingUpdateSchedules] = useState(true)

  useEffect(() => {
    const fetchCalcDescs = async () => {
      console.log('fetching update schedules for Algo select')
      try {
        setFetchingUpdateSchedules(true)
        const res = await axiosApi.get('/jobs/getEffectiveDatesForAlgos')
        if (get(res, 'data')) setUpdateSchedules(res.data)
      } catch (err) {
        console.log('error fetching effective dates: ', err)
      }
      setFetchingUpdateSchedules(false)
    }

    fetchCalcDescs()
  }, [])

  return (
    <BlockContainer>
      <LookupsTitle>Algo</LookupsTitle>
      {some(allJobs) && some(updateSchedules) && !fetchingUpdateSchedules ? (
        allJobs.algo.map(job => {
          switch (job.name) {
            case 'stocks-algo-all':
            case 'bonds-algo-all':
              return <RunAllAlgoRow job={job} key={job.label} handleAlgoSubmit={update} getJobBtnStyle={getJobBtnStyle(job)} />
            case 'bonds-algo-parameters':
              return (
                <BondsRebalanceAlgoRow
                  job={job}
                  key={job.label}
                  handleAlgoSubmit={update}
                  getJobBtnStyle={getJobBtnStyle(job)}
                  updateSchedules={updateSchedules}
                />
              )
            default:
              return (
                <AlgoRow
                  key={job.label}
                  job={job}
                  handleAlgoSubmit={update}
                  time={formatTime(job.lastRunTime)}
                  getJobBtnStyle={getJobBtnStyle(job)}
                  updateSchedules={get(updateSchedules, [job.category, job.updateType].join('.'))}
                />
              )
          }
        })
      ) : (
        <Loader />
      )}
    </BlockContainer>
  )
}
