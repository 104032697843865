import React from 'react'
import PropTypes from 'prop-types'

import { LoaderWrapper, LoaderIcon } from './styles'

export const Loader = ({
  containerHeight,
  loaderIconWidth,
  withText,
}) => (
  <LoaderWrapper containerHeight={containerHeight}>
    <LoaderIcon loaderIconWidth={loaderIconWidth} />
    {withText && <div>{withText}</div>}
  </LoaderWrapper>
)

Loader.propTypes = {
  containerHeight: PropTypes.string,
  loaderIconWidth: PropTypes.string,
}

Loader.defaultProps = {
  containerHeight: '100px',
  loaderIconWidth: '3rem',
}
