import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'

import axiosApi from 'utils/axiosApi'
import get from 'lodash/get'

import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { AlgoLogsTable } from './AlgoLogsTable'
import { WeightFactorsTable } from './WeightFactorsTable'
import { TurnOversTable } from './TurnOversTable'
import { ParametersTable } from './ParametersTable'
import { CalcDescCard } from './CalcDescCard'
import { RatingsTable } from './RatingsTable'
import { TabSelector, TabSelectorNav } from './styles'
import { SecTypes } from 'utils/const'
import { Loader } from 'components/Common/Loader'

export const CalcResults = ({ calcDescId }) => {
  const [calcDesc, setCalcDesc] = useState(null)
  const [fetchingCalcDesc, setFetchingCalcDesc] = useState(null)
  // eslint-disable-next-line
  const [algoType, setAlgoType] = useAlgoType()
  // eslint-disable-next-line
  const [taseEffectiveDate, updateTaseEffectiveDate] = useTaseEffectiveDate()
  const { setSecType } = useContext(Context)

  const selectModalAndState = useCallback(
    calcDesc => {
      setCalcDesc(calcDesc)
      setSecType(calcDesc.secType)
      updateTaseEffectiveDate(calcDesc.taseEffectiveDate)
      setAlgoType(calcDesc.algoType)
      setFetchingCalcDesc(false)
    },
    [setCalcDesc, setSecType, updateTaseEffectiveDate, setAlgoType],
  )

  useEffect(() => {
    const fetchCalcDesc = async () => {
      try {
        setFetchingCalcDesc(true)

        const res = await axiosApi.get(
          `/index_calc_results/getAlgoCalcDescById?calcDescId=${calcDescId}`,
        )
        if (get(res, 'data')) {
          selectModalAndState(res.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (!calcDesc) {
      fetchCalcDesc()
    }
  }, [calcDesc, calcDescId, selectModalAndState])

  const pageTitle = useMemo(() => {
    if (calcDesc) {
      const { secType, algoType, taseEffectiveDate } = calcDesc
      return `${secType} - ${algoType} - ${dayjs(taseEffectiveDate).format(
        'DD/MM/YYYY',
      )}`
    } else return 'SB Algo Results'
  }, [calcDesc])

  return fetchingCalcDesc || !calcDesc ? (
    <Loader />
  ) : (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <CalcDescCard calcDesc={calcDesc} title="Calc Description:" />
      <hr />

      <Tab.Container defaultActiveKey="WeightFactors" id="AlgoResultsTabs">
        <TabSelectorNav variant="tabs">
          <TabSelector>
            <Nav.Link eventKey="WeightFactors">Weight Factors</Nav.Link>
          </TabSelector>
          <TabSelector>
            <Nav.Link eventKey="Parameters">Parameters</Nav.Link>
          </TabSelector>
          <TabSelector>
            <Nav.Link eventKey="Turnover">Turnover</Nav.Link>
          </TabSelector>
          <TabSelector>
            <Nav.Link eventKey="AlgoLogs">Logs</Nav.Link>
          </TabSelector>
          {calcDesc.secType === SecTypes.corpBond && (
            <TabSelector>
              <Nav.Link eventKey="Ratings">Ratings</Nav.Link>
            </TabSelector>
          )}
        </TabSelectorNav>

        <Tab.Content>
          <Tab.Pane eventKey="WeightFactors">
            <WeightFactorsTable calcDesc={calcDesc} />
          </Tab.Pane>
          <Tab.Pane eventKey="Parameters">
            <ParametersTable calcDesc={calcDesc} />
          </Tab.Pane>
          <Tab.Pane eventKey="Turnover">
            <TurnOversTable calcDesc={calcDesc} />
          </Tab.Pane>
          <Tab.Pane eventKey="AlgoLogs">
            <AlgoLogsTable calcDesc={calcDesc} />
          </Tab.Pane>
          {calcDesc.secType === SecTypes.corpBond && (
            <Tab.Pane eventKey="Ratings">
              <RatingsTable calcDesc={calcDesc} />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}
