import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { isHeb } from '../../../i18n'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DateElementWrapper,
  DateLineWrapper,
  DateWrapper,
  DateElementSecondRowWrapper,
  DateElementSecondRowValue,
} from './styles'

import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'


const singleWordMap = {
  '-1': 'yesterday',
  '0': 'today',
  '1': 'tomorrow',
}

export const DateElement = ({ date, text, withTooltip, tooltipText }) => {
  const { t } = useTranslation()

  const dateElementSecondRowWrapper = () => {
    const daysDiff = dayjs(dayjs(date).startOf('day')).diff(
      dayjs().startOf('day'),
      'day',
    )
    const singleWordLocalizationKey = singleWordMap[daysDiff]
    if (singleWordLocalizationKey) {
      return (
        <DateElementSecondRowWrapper>
          <DateElementSecondRowValue>
            {t(`equity.projectedChangesUpcoming.${singleWordLocalizationKey}`)}
          </DateElementSecondRowValue>
        </DateElementSecondRowWrapper>
      )
    }

    if (daysDiff > 0) {
      return (
        <DateElementSecondRowWrapper>
          {t('equity.projectedChangesUpcoming.in')}
          <DateElementSecondRowValue>{daysDiff}</DateElementSecondRowValue>
          {t('equity.projectedChangesUpcoming.days')}
        </DateElementSecondRowWrapper>
      )
    }

    if (daysDiff < 0) {
      return isHeb() ? (
        <DateElementSecondRowWrapper>
          {t('equity.projectedChangesUpcoming.ago')}
          <DateElementSecondRowValue>{-1 * daysDiff}</DateElementSecondRowValue>
          {t('equity.projectedChangesUpcoming.days')}
        </DateElementSecondRowWrapper>
      ) : (
        <DateElementSecondRowWrapper>
          <DateElementSecondRowValue>{-1 * daysDiff}</DateElementSecondRowValue>
          {[
            t('equity.projectedChangesUpcoming.days'),
            t('equity.projectedChangesUpcoming.ago'),
          ].join(' ')}
        </DateElementSecondRowWrapper>
      )
    }
  }

  return (
    <DateElementWrapper>
      <DateLineWrapper>
        {text}
        :
        <DateWrapper>
          {' '}
          {dayjs(date).format('DD/MM/YYYY')}
          {withTooltip && <TableCellExtraContent
            popoverText={tooltipText}
            content={<FontAwesomeIcon icon={faInfoCircle} />}
            color="black!important"
          />}
        </DateWrapper>
      </DateLineWrapper>
      {dateElementSecondRowWrapper()}
    </DateElementWrapper>
  )
}

DateElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  date: PropTypes.object.isRequired, // PropTypes.instanceOf(Date) creates a warning in log...
  text: PropTypes.string.isRequired,
}
