import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { StyledGroup, StyledError, Label } from './styles'
import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'

import { i18n } from '../../../i18n'

const Styles = {
  control: styles => {
    return {
      ...styles,
      width: '20vw',
      zIndex: '100',
      '@media only screen and (max-width: 450px)': {
        ...styles['@media only screen and (max-width: 450px)'],
        width: '80vw',
      },
    }
  },
  menu: styles => {
    return {
      ...styles,
      marginTop: 0,
      zIndex: '150',
    }
  },
  option: styles => {
    return {
      ...styles,
      zIndex: '150',
      textAlign: i18n.language === 'hb' ? 'right' : 'left',
    }
  },
}

const errorStyles = {
  control: styles => {
    return {
      ...styles,
      borderColor: '#dc3545',
      boxShadow: '0 0 0 0.2rem rgba(220,53,69,.25)',
      width: '20vw',
      zIndex: '100',
      '@media only screen and (max-width: 450px)': {
        ...styles['@media only screen and (max-width: 450px)'],
        width: '80vw',
      },
    }
  },
}

export const SelectSearch = ({
  options,
  label,
  text,
  errorText,
  isInvalid,
  value,
  onChange,
  customStyle,
  isDisabled,
  getChangedValueWithEvent,
  onBlur,
  name,
}) => {
  const { t } = useTranslation()
  const ready = !isEmpty(options)

  let styles = { ...Styles, ...customStyle }
  if (isInvalid) {
    styles = { ...styles, ...customStyle, ...errorStyles }
  }
  return (
    <StyledGroup>
      {label && <Label>{t(label)}</Label>}

      <Select
        name={name}
        isDisabled={!ready || isDisabled}
        isLoading={!ready}
        options={options}
        placeholder=""
        value={ready && find(options, { value })}
        getChangedValueWithEvent={getChangedValueWithEvent}
        onChange={onChange}
        onBlur={onBlur}
        styles={styles}
      />

      {text && <Form.Text className="text-muted">{t(text)}</Form.Text>}
      {errorText && isInvalid && <StyledError type="invalid">{errorText}</StyledError>}
    </StyledGroup>
  )
}

SelectSearch.defaultProps = {
  options: null,
}

SelectSearch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
  errorText: PropTypes.string,
  isInvalid: PropTypes.bool,
}
