import React, { useContext, useState } from 'react'

import axiosApi from 'utils/axiosApi'
import { useTranslation } from 'react-i18next'
import { Analytics } from 'services/analytics'
import round from 'lodash/round'

import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import Context, { useSelectedSecurity, useSimulationId } from 'context/Context'

import { SimNameForm } from 'components/Simulation/SimulationNameForm'
import { ConfirmDialog } from 'components/Common/ConfirmDialog'
import { Loader } from 'components/Common/Loader'

export const SimulationNav = () => {
  const { t } = useTranslation()
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedSecurity, setSelectedSecurity] = useSelectedSecurity()
  const { selectedSim, setSelectedSim, setIsAlgoRunning, secType } = useContext(Context)
  const [, setSimulationId] = useSimulationId()

  const deleteSimulation = async () => {
    if (selectedSim && selectedSim._id) {
      try {
        setDeleteDialogOpen(false)
        setDeleteInProgress(true)
        await axiosApi.delete(`simulation/${selectedSim._id}`)
        resetSelectedSimSecurities()
        setSelectedSim(null)
        setSimulationId(null)
        setDeleteInProgress(false)

        // send a GA event
        Analytics.event({
          category: 'Simulations',
          action: 'Simulation Deleted',
          label: JSON.stringify({ securities: selectedSim.securities }),
        })
      } catch (error) {
        console.log(error)
      }
    } else console.log('no sim selected for delete.')
  }

  const renderSimTitle = () => {
    return `${t('simulation.general.simulation')} - ${secType === 'stock' ? t('common.stocks') : t('common.bonds')}`
  }

  const resetSelectedSimSecurities = () => {
    // simulated securitiy's secIds are inc of 1 starting from 1000000
    const selectedSecurityIsSimulated = round(selectedSecurity / 10) === 100000
    if (selectedSecurityIsSimulated) {
      setSelectedSecurity(null)
    }
  }

  return (
    <Navbar.Collapse id="sim-navbar-nav">
      <ConfirmDialog
        isOpen={deleteDialogOpen}
        text={t('simulation.deleteDialog.text')}
        buttons={[
          {
            text: t(t('simulation.deleteDialog.cancel')),
            color: 'outline-secondary',
            onClick: () => setDeleteDialogOpen(false),
          },
          {
            text: t('simulation.deleteDialog.confirm'),
            color: 'outline-danger',
            onClick: () => {
              deleteSimulation()
              setDeleteDialogOpen(false)
            },
          },
        ]}
      />

      <Nav className="container-fluid">
        {deleteInProgress ? (
          <Loader />
        ) : (
          <>
            {' '}
            <Nav.Item>
              <Navbar.Brand>{renderSimTitle()}</Navbar.Brand>
            </Nav.Item>
            <Nav.Item>
              <SimNameForm />
            </Nav.Item>
            <Nav.Item>
              {selectedSim && selectedSim._id && (
                <Button variant="danger" onClick={() => setDeleteDialogOpen(true)}>
                  {t('simulation.deleteDialog.confirm')}
                </Button>
              )}
              <Button
                variant="outline-dark"
                onClick={() => {
                  resetSelectedSimSecurities()
                  setIsAlgoRunning(false)
                  setSelectedSim(null)
                  setSimulationId(null)
                }}
                style={{ margin: '0px 10px' }}
              >
                {t('simulation.nav.exitSim')}
              </Button>
            </Nav.Item>
          </>
        )}
      </Nav>
    </Navbar.Collapse>
  )
}
