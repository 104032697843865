export const initialValues = {
  name: '',
  securities: [
    {
      securityId: '',
      projectedChange: '',
    },
    {
      securityId: '',
      projectedChange: '',
    },
    {
      securityId: '',
      projectedChange: '',
    },
    {
      securityId: '',
      projectedChange: '',
    },
    {
      securityId: '',
      projectedChange: '',
    },
    {
      securityId: '',
      projectedChange: '',
    },
  ],
}
// export const initialValues = {
//   name: 'External Index Name',
//   securities: [
//     {
//       securityId: '1187962',
//       projectedChange: '21000000',
//     },
//     {
//       securityId: '281014',
//       projectedChange: '-40000000',
//     },
//     {
//       securityId: '604611',
//       projectedChange: '100000000',
//     },
//   ],
// }
