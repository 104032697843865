import React, { useCallback, useEffect, useState } from 'react'
import { useSelectedCalcDescId } from 'context/Context'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import axiosApi from 'utils/axiosApi'
import get from 'lodash/get'

import { CalcDescCard } from 'components/Admin/RebalanceResults/CalcDescCard'
import { Loader } from 'components/Common/Loader'
import { Table } from 'components/Common/Table'
import { Columns } from './Columns'

const PrevRunsTable = () => {
  const [calcDescId] = useSelectedCalcDescId()
  const [secType, setSecType] = useState(null)
  const [calcDesc, setCalcDesc] = useState(null)
  const [fetchingCalcDesc, setFetchingCalcDesc] = useState(null)
  const [apiUrl, setApiUrl] = useState(null)
  const { t } = useTranslation()
  const location = useLocation()

  const selectModalAndState = useCallback(
    calcDesc => {
      const { secType, taseEffectiveDate, algoType } = calcDesc
      setCalcDesc(calcDesc)
      setSecType(secType)
      setFetchingCalcDesc(false)
      setApiUrl(`/jobs/getPrevAlgoRunsTable?&secType=${secType}&taseEffectiveDate=${taseEffectiveDate}&algoType=${algoType}`)
    },
    [setCalcDesc, setSecType, setFetchingCalcDesc, setApiUrl],
  )

  useEffect(() => {
    const fetchCalcDesc = async () => {
      try {
        setFetchingCalcDesc(true)

        const res = await axiosApi.get(`/index_calc_results/getAlgoCalcDescById?calcDescId=${calcDescId}`)
        if (get(res, 'data')) {
          selectModalAndState(res.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (!calcDesc) {
      fetchCalcDesc()
    }
  }, [calcDesc, calcDescId, selectModalAndState])

  return fetchingCalcDesc || !calcDesc ? (
    <Loader />
  ) : (
    <div>
      <CalcDescCard calcDesc={calcDesc} title="Calc Description:" />

      {apiUrl && (
        <Table
          withExportButton
          exportFileType="csv"
          columns={Columns({ t, secType, location })}
          apiUrl={apiUrl}
          defaultSortBy={{ id: 'securityId', desc: true }}
          headerText="Prev Algo Results"
          virtualized={true}
        />
      )}
    </div>
  )
}

export default PrevRunsTable
