import React from 'react'
import dayjs from 'dayjs'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import { ActionsContainer, CalcDescCardContainer } from './styles'

export const CalcDescCard = ({ calcDesc, title, selectModalAndState, showActions }) => {
  return (
    <CalcDescCardContainer>
      <Card.Header>
        {title}
        <span className="text-muted" style={{ fontSize: 'x-small' }}>
          (_id: {calcDesc._id})
        </span>
      </Card.Header>
      <ListGroup>
        <ListGroup.Item>algoType: {calcDesc.algoType}</ListGroup.Item>
        <ListGroup.Item>secType: {calcDesc.secType}</ListGroup.Item>
        <ListGroup.Item>status: {calcDesc.status}</ListGroup.Item>
        <ListGroup.Item>TASE effective date: {dayjs(calcDesc.taseEffectiveDate).format('DD/MM/YY')}</ListGroup.Item>
        <ListGroup.Item>last updated: {dayjs(calcDesc.updatedAt).format('DD/MM/YY HH:mm')}</ListGroup.Item>
        {calcDesc.s3ExportUrl && (
          <ListGroup.Item>
            <a href={calcDesc.s3ExportUrl} target="_blank" rel="noopener noreferrer">
              {calcDesc.s3FileName}
            </a>
          </ListGroup.Item>
        )}
      </ListGroup>
      {showActions && (
        <ActionsContainer>
          <Button variant="primary" onClick={() => selectModalAndState(calcDesc)}>
            Get Results
          </Button>
        </ActionsContainer>
      )}
    </CalcDescCardContainer>
  )
}
