import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'

export default function({ data }) {
  const { t } = useTranslation()

  if (!data) return null

  const { newStock, firstTradingDate, qualificationPeriodEndDate } = data

  if (!newStock) return null

  return (
    <TableCellExtraContent
      popoverText={`${t('equity.securityAnalysis.firstTradingDate')} ${dayjs(
        firstTradingDate,
      ).format('DD/MM/YYYY')}; ${t(
        'equity.securityAnalysis.qualificationPeriodEndDate',
      )} ${dayjs(qualificationPeriodEndDate).format('DD/MM/YYYY')}`}
      content={
        <span className="badge badge-success">
          {t('equity.securityAnalysis.newStock')}
        </span>
      }
      key="newStockBadge"
    />
  )
}
