import React from 'react'
import { useTranslation } from 'react-i18next'

export const User = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="UserScreen">
      <h1>{t('user')}</h1>
    </div>
  )
}
