/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import some from 'lodash/some'
import compact from 'lodash/compact'

import auth from 'utils/auth'

import { Table } from 'components/Common/Table'
import getFormattedValue from 'utils/getFormattedValue'
import useColumnsData from 'utils/useColumnsData'

import { columns } from './columns'

export const SecurityGeneralTable = props => {
  const { t } = useTranslation()

  const conditionalCellProps = (row, cell) => {
    const overrides = get(row.original, 'overrides')
    if (some(overrides)) {
      const rel = overrides.filter(or => or.field === cell.column.id)
      if (some(rel)) {
        const format = cell.column.cellFormat?.format || false
        const oldValue = getFormattedValue({value: rel[0].oldValue,t, formatOverride: format})
        const newValue = getFormattedValue({value: rel[0].newValue, t, formatOverride: format})

        const valueForIndexes = t('equity.tooltips.valueForIndexes'),
          publishedValue = t('equity.tooltips.publishedValue')

        cell.popoverContent = `${valueForIndexes}: ${newValue}; ${publishedValue}: ${oldValue}`
        return { className: 'warningCell' }
      }
    }
  }

  const isInQualificationPeriod = row => {
    const {invalid} = row.original
    return invalid === "qualification period"
  }

  const conditionalRowProps = row => {
    if (isInQualificationPeriod(row)) return { className: 'warningTR' }
  }

  const legends = compact([
    {
      color: '#fff6e1',
      label: t('equity.generalSecurity.qualification'),
      filter: isInQualificationPeriod,
    },
  ])

  return (
    <Table
      withExportButton={auth.isAdmin()}
      columns={columns(useColumnsData())}
      defaultSortBy={{ id: 'securityName', desc: false }}
      conditionalCellProps={conditionalCellProps}
      {...props}
      virtualized={true}
      conditionalRowProps={conditionalRowProps}
      legends={auth.isAdmin() && legends}
    />
  )
}
