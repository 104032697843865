import React, { useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import Form from 'react-bootstrap/Form'

import { isEng } from '../../../i18n'
import { ForgotPasswordValidationSchema } from './schemas'

import { Container, Title, SubText, ButtonsContainer, TextsWrapper, ErrorMsg } from './styles'
import { SuccessCmp } from './success'
import { Card } from 'components/Common/Card'
import { Input } from 'components/Common/input'
import { Button } from 'components/Common/button'
import axiosApi from 'utils/axiosApi'

export const ForgotPassword = () => {
  const [serverError, setServerError] = useState('')
  const [isEmailSent, setIsEmailSent] = useState(false)
  const lang = isEng() ? 'en' : 'hb'
  const { t } = useTranslation()

  const sendRecoveryLinkToEmail = async ({ email }) => {
    try {
      const res = await axiosApi.post('reset_password/send-email', { email, lang })

      const { success } = res.data
      if (success) {
        setServerError('')
        setIsEmailSent(true)
      } else {
        setServerError('UNKNOWN')
      }
    } catch (error) {
      const errorMsg = get(error, 'response.data.msg') || 'UNKNOWN'
      setServerError(errorMsg)
    }
  }

  return (
    <div>
      <Card>
        {isEmailSent ? (
          <SuccessCmp title={t('forgotPassword.success.title')} subtitle={t('forgotPassword.success.subText')} />
        ) : (
          <Container>
            <TextsWrapper>
              <Title>{t('forgotPassword.title')}</Title>
              <SubText>{t('forgotPassword.subText')}</SubText>
            </TextsWrapper>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={sendRecoveryLinkToEmail}
              validationSchema={ForgotPasswordValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                  <Input
                    name="email"
                    type="email"
                    onBlur={handleBlur}
                    label={null}
                    placeholder={t('forgotPassword.form.email.label')}
                    isValid={false}
                    isInvalid={!!errors.email && touched.email}
                    value={values.email}
                    errorText={t(errors.email)}
                    onChange={ev => {
                      setServerError('')
                      handleChange(ev)
                    }}
                    width="100%"
                    style={{ direction: 'ltr', textAlign: 'center', width: '100%' }}
                  />
                  {serverError && <ErrorMsg>{t(`forgotPassword.form.errors.server.${serverError}`)}</ErrorMsg>}
                  <ButtonsContainer marginTop={'2.5rem'}>
                    <Button onClick={handleSubmit}>{t('forgotPassword.form.submit')}</Button>
                    <Link to="/">{t('forgotPassword.return')}</Link>
                  </ButtonsContainer>
                </Form>
              )}
            </Formik>
          </Container>
        )}
      </Card>
    </div>
  )
}
