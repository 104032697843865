import compact from 'lodash/compact'
import some from 'lodash/some'
import auth from 'utils/auth'
import { getPaymentsDescription } from 'utils/paymentUtils'

import { isEng } from '../../i18n'

import SecurityName from 'components/Common/Table/TableCell/SecurityName'

const tPrefix = 'equity.changesSummary'

export const columns = ({ t }) => {
  return compact([
    {
      id: 'securityName',
      Header: t('common.security'),
      accessor: row => {
        let name = isEng() ? row.securityName : row.securityNameHebrew
        if (some(row.fastTrackIndexes)) {
          return `${name} ${t('equity.securityAnalysis.exceptions.fastTrack')}`
        } else return name
      },
      Cell: SecurityName,
      width: 205,
    },
    {
      id: 'securityId',
      Header: t('common.securityId'),
      accessor: 'securityId',
    },
    {
      id: 'projectedChange',
      Header: t(`${tPrefix}.projectedChange`),
      accessor: 'projectedChange',
      cellFormat: {
        format: 'currency',
        greenRedValues: true,
      },
    },
    {
      id: 'projectedPayment',
      Header: t('equity.generalSecurity.projectedPayment'),
      accessor: 'projectedPayment',
      cellFormat: {
        format: 'currency',
      },
    },
    {
      id: 'paymentType',
      Header: t(`${tPrefix}.paymentType`),
      accessor: row => {
        let { currTaseEffectiveDate, currTaseEffectiveDate_prices, isIndexChange } = row
        return compact([
          getPaymentsDescription({ t, timeBasedData: currTaseEffectiveDate, isIndexChange }),
          getPaymentsDescription({ t, timeBasedData: currTaseEffectiveDate_prices }),
        ]).join(', ')
      },
    },
    {
      id: 'projectedListedCapitalChange',
      Header: t(`${tPrefix}.projectedListedCapitalChange`),
      accessor: row => {
        const capitalListedExtension = row?.currTaseEffectiveDate?.capitalListedExtension
        return capitalListedExtension || -row?.projectedListedCapitalChange
      },
      cellFormat: {
        format: 'number-long',
        greenRedValues: true,
      },
    },
    {
      id: 'effectiveDateClosingPrice',
      Header: t(`${tPrefix}.effectiveDateClosingPrice`),
      accessor: 'closingPrice',
      cellFormat: {
        format: 'number',
      },
    },
    {
      id: 'adjustedPrice',
      Header: t(`${tPrefix}.adjustedPrice`),
      accessor: row => row.currTaseEffectiveDate_prices.adjustedPrice,
      cellFormat: {
        format: 'number',
      },
      coloredReferenceSelector: 'effectiveDateClosingPrice',
    },
    auth.isAdmin() && {
      id: 'linkageRate',
      Header: t(`${tPrefix}.linkageRateAdmin`),
      accessor: row => row.currTaseEffectiveDate_prices.linkageRate,
      cellFormat: {
        format: '%',
      },
    },
    auth.isAdmin() && {
      id: 'redemptions',
      Header: t(`${tPrefix}.redemptionsAdmin`),
      accessor: row => (some(row.currTaseEffectiveDate.redemptions) ? row.currTaseEffectiveDate.redemptions.join(', ') : null),
      cellFormat: {
        format: 'noFormat',
      },
    },
    auth.isAdmin() && {
      id: 'interest',
      Header: t(`${tPrefix}.interestAdmin`),
      accessor: row => row.currTaseEffectiveDate_prices.interest / 100,
      cellFormat: {
        format: '%-3dcm',
      },
    },
    auth.isAdmin() && {
      id: 'exCoefficient',
      Header: t(`${tPrefix}.exCoefficient`),
      accessor: row => row.currTaseEffectiveDate_prices.adjustedPrice / row.taseEffectiveDateAdjustedPrice,
      cellFormat: {
        format: '%',
      },
    },
  ])
}
