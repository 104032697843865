import React from 'react'

import axiosApi from 'utils/axiosApi'
import Button from 'react-bootstrap/Button'
import { LookupsBlockContainer, LookupsTitle } from './styles'

export const DataManagementAgendaBlock = () => {
  const handleClick = () => {
    axiosApi.post('jobs/agendaReset', {
      definition: 'AGENDA',
    })
  }
  return (
    <LookupsBlockContainer>
      <LookupsTitle>Agenda</LookupsTitle>
      <Button variant="primary" type="button" onClick={handleClick}>
        Refresh Agenda Jobs and Schedule
      </Button>
    </LookupsBlockContainer>
  )
}
