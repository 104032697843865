import React, { useState } from 'react'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'

import min from 'lodash/min'
import get from 'lodash/get'
import head from 'lodash/head'

import { Button } from 'components/Common/button'
import Form from 'react-bootstrap/Form'

import { TaseBlockRow } from './TaseBlockRow'

import {
  TaseBlockContainer,
  TaseTitle,
  TaseSecondColText,
  DataSource,
} from './styles'

dayjs.extend(utc)

const latestRefreshText = 'Latest refresh'
const inputDateFormat = 'YYYY-MM-DD'

export const DataManagmentTaseBlock = ({
  allJobs,
  update,
  formatTime,
  getLastRecords,
  getJobBtnStyle,
}) => {
  const [minDate, setMinDate] = useState(Date.now())

  const today = dayjs()
    .startOf('day')
    .toDate()

  const taseJobs = allJobs.tase
  const minimumDate = min(
    taseJobs
      .map(job => get(job, 'lastRunTime'))
      .filter(e => !!e)
      .map(lastRunTime => lastRunTime),
  )

  if (minDate !== minimumDate) setMinDate(minimumDate)

  return (
    <Formik
      initialValues={{
        model: head(allJobs.history).value,
        effectiveDate: dayjs(today).format(inputDateFormat),
      }}
    >
      {({ values, handleChange }) => (
        <TaseBlockContainer>
          <TaseTitle>Tase</TaseTitle>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Scan Date: </Form.Label>
            <Form.Control
              name="effectiveDate"
              type="date"
              label={null}
              max={dayjs(today).format(inputDateFormat)}
              value={values.effectiveDate}
              onChange={handleChange}
            />
          </Form.Group>
          {taseJobs.map(job => {
            return (
              <TaseBlockRow
                key={job.name}
                firstCol={
                  <Button
                    onClick={() =>
                      update(
                        {
                          ...job,
                          effectiveDate: dayjs.utc(values.effectiveDate).toISOString(),
                        },
                        'tase',
                      )
                    }
                    variant={getJobBtnStyle(job)}
                  >
                    {job.label || job.name}
                  </Button>
                }
                secondCol={
                  <Button
                    onClick={() => getLastRecords(job.name)}
                    variant="outline-primary"
                  >
                    last 30-day records to console
                  </Button>
                }
                thirdCol={
                  <TaseSecondColText>
                    {job.link && (
                      <DataSource target="blank" href={job.link}>
                        Src
                      </DataSource>
                    )}
                    {latestRefreshText}:
                    {formatTime(
                      job.name === 'ALL TASE' ? minDate : job.lastRunTime,
                    )}
                  </TaseSecondColText>
                }
              />
            )
          })}
        </TaseBlockContainer>
      )}
    </Formik>
  )
}

DataManagmentTaseBlock.propTypes = {
  allJobs: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
}

DataManagmentTaseBlock.defaultProps = {
  allJobs: null,
}
