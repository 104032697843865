import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const LookupsRow = styled(Row)`
  margin-bottom: 10px;
`

export const DataSource = styled.a`
  color: blue;
  margin-right: 10px;
`

export const Text = styled.div``

export const SecondCol = styled(Col)`
  display: flex;
  align-items: center;
`
