import React from 'react'
import dayjs from 'dayjs'
import head from 'lodash/head'
import some from 'lodash/some'

import { useTranslation } from 'react-i18next'
import { TableCellExtraContent } from 'components/Common/Table/TableCellExtraContent'
import { faDownload, faUpload, faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPaymentsDescription } from 'utils/paymentUtils'

export default function({ data }) {
  if (!data) return null

  const { t } = useTranslation()

  let {
    projectedWeight,
    gradualRemovalWeightFactor,
    gradualEntryWeightFactor,
    projectedRemovalDate,
    entryDate,
    currTaseEffectiveDate,
    universeGradualWeightFactor,
  } = data

  if (projectedWeight === 0) {
    // completely irrelevant, sec is not in index
    return null
  }

  let gradualUniverseEntryWeightFactor, gradualUniverseRemovalWeightFactor

  if (some(universeGradualWeightFactor)) {
    const universeData = head(universeGradualWeightFactor)
    if (universeData.entryDate) {
      entryDate = universeData.entryDate
      gradualUniverseEntryWeightFactor = universeData.weightFactor
    } else if (universeData.exitDate) {
      projectedRemovalDate = universeData.exitDate
      gradualUniverseRemovalWeightFactor = universeData.weightFactor
    }
  }

  let icon, color, popoverText, eventTypes
  if (gradualUniverseEntryWeightFactor || gradualUniverseRemovalWeightFactor) {
    eventTypes = getPaymentsDescription({ t, timeBasedData: currTaseEffectiveDate })

    if (gradualUniverseRemovalWeightFactor) {
      color = 'red'
      icon = faUpload
      popoverText = `${t('equity.changesSummary.gradualUniverseExitFactor')} (${gradualUniverseRemovalWeightFactor}) `
      popoverText += `\n ${t('equity.changesSummary.exitDate')}: ${dayjs(projectedRemovalDate).format('DD/MM/YYYY')}`
      if (eventTypes) {
        popoverText += ` (${eventTypes})`
      }
    } else if (gradualUniverseEntryWeightFactor) {
      color = 'green'
      icon = faDownload
      popoverText = `${t('equity.changesSummary.gradualUniverseEntryFactor')} (${gradualUniverseEntryWeightFactor}) `
      popoverText += `${t('equity.changesSummary.entryDate')}: ${dayjs(entryDate).format('DD/MM/YYYY')}`
      if (eventTypes) {
        popoverText += ` (${eventTypes})`
      }
    }
  } else if (gradualRemovalWeightFactor || gradualEntryWeightFactor) {
    eventTypes = getPaymentsDescription({ t, timeBasedData: currTaseEffectiveDate })

    if (gradualRemovalWeightFactor) {
      color = 'red'
      icon = faUpload
      popoverText = `${t('equity.changesSummary.gradualUniverseExitFactor')} (${gradualRemovalWeightFactor}) `
      popoverText += `\n ${t('equity.changesSummary.exitDate')}: ${dayjs(projectedRemovalDate).format('DD/MM/YYYY')}`
      if (eventTypes) {
        popoverText += ` (${eventTypes})`
      }
    } else if (gradualEntryWeightFactor) {
      color = 'green'
      icon = faArrowsAltH
      popoverText = `${t('equity.changesSummary.gradualIndicesEntryFactor')} (${gradualEntryWeightFactor}) `
      popoverText += `${t('equity.changesSummary.entryDate')}: ${dayjs(entryDate).format('DD/MM/YYYY')}`
      if (eventTypes) {
        popoverText += ` (${eventTypes})`
      }
    }
  } else return null

  // handle gradual indices entry separately?

  return <TableCellExtraContent popoverText={popoverText} content={<FontAwesomeIcon icon={icon} />} key="gradualUniverseFactor" color={color} />
}
