import styled from 'styled-components'
import ToggleButton from 'react-bootstrap/ToggleButton'

export const StyledToggleButton = styled(ToggleButton)`
  font-size: 0.6rem;
  color: gray;
  padding: 0;
  background-color: white;
  margin-left: -1px;
  border-radius: 0.25rem !important;
  border-color: #f59a23;
  & > div {
    color: #007bff;
  }

  &.active {
    background-color: #f59a23 !important;
    border-color: #f59a23 !important;
    color: white !important;
    & > .data-picker-inner > * {
      color: white !important;
    }
  }
  ${props =>
    props.direction === 'rtl'
      ? `
  &:not(:last-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &:not(:first-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  `
      : `
  &:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  &:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  `}
`

export const StyledToggleButtonInner = styled.div`
  padding: 0.24rem 0.75rem;
`

export const StyledToggleButtonExtras = styled(StyledToggleButtonInner)`
  border-top: 1px solid #ffc107;
  background-color: rgba(204, 229, 255, 1);
  color: #585858 !important;
`
