import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'

import some from 'lodash/some'

import { ActionRow } from './styles'
import { Button } from 'components/Common/button'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'

export const RowFormActionRow = ({
  securityRowIndex,
  newRow,
  setSubmitCallback,
  setEnableValidation,
  securityRows,
  handleSimSubmit,
  setShowSecurityRowForm,
}) => {
  const { t } = useTranslation()
  const { validateForm, handleSubmit, errors } = useFormikContext()

  const nextSecurity = useCallback(async () => {
    setEnableValidation(true)

    const errors = await validateForm()
    if (some(errors)) {
      return
    }

    if (!(some(securityRows) && some(securityRows[securityRowIndex + 1]))) {
      newRow()
    }

    setSubmitCallback(() => () => {
      setShowSecurityRowForm(securityRowIndex + 1)
    })

    handleSubmit()
  }, [
    securityRows,
    securityRowIndex,
    setSubmitCallback,
    setEnableValidation,
    setShowSecurityRowForm,
    handleSubmit,
    validateForm,
    newRow,
  ])

  const previousSecurity = useCallback(async () => {
    setEnableValidation(true)

    const errors = await validateForm()
    if (some(errors)) {
      return
    }

    setSubmitCallback(() => () => {
      setShowSecurityRowForm(securityRowIndex - 1)
    })
    handleSubmit()
  }, [
    securityRowIndex,
    setSubmitCallback,
    setEnableValidation,
    setShowSecurityRowForm,
    handleSubmit,
    validateForm,
  ])

  const runSim = useCallback(async () => {
    setEnableValidation(true)

    const errors = await validateForm()
    if (some(errors)) {
      return
    }

    setSubmitCallback(() => () => {
      setShowSecurityRowForm(null)
      handleSimSubmit()
    })
    handleSubmit()
  }, [
    validateForm,
    handleSimSubmit,
    setEnableValidation,
    handleSubmit,
    setSubmitCallback,
    setShowSecurityRowForm,
  ])

  return (
    <ActionRow>
      <Col sm={4}>
        <Button
          disabled={!some(errors) || securityRowIndex === 0}
          variant="info"
          onClick={() => previousSecurity()}
        >
          {t('simulation.form.previousCondition')}
        </Button>
      </Col>
      <Col sm={4}>
        <Button
          variant="info"
          disabled={some(errors)}
          onClick={() => nextSecurity()}
        >
          {some(securityRows[securityRowIndex + 1])
            ? t('simulation.form.nextCondition')
            : t('simulation.form.newCondition')}
        </Button>
      </Col>
      <Col sm={4}>
        <Button disabled={some(errors)} onClick={() => runSim()}>
          {t('simulation.form.runSimulation')}
        </Button>
      </Col>
    </ActionRow>
  )
}
