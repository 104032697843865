import styled from 'styled-components'
import { H1, H2 } from 'styles/Typography'
import Row from 'react-bootstrap/Row'
import { Button } from 'components/Common/button'

export const ModalLayout = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  background: #00000085;
  position: fixed;
  top: 0;
  z-index: 9999999;
`

export const ModalWindowContainer = styled.div`
  height: max-content;
  z-index: 9;
  background: white;
  margin: 100px;
  margin-top: 50px;
  padding: 10px;
  @media (max-width: 768px) {
    margin: 50px 0;
  }
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ServerError = styled.span`
  display: inline-block;
  color: #dd3d4d;
  height: 1.5rem;
  text-align: center;
  width: 100%;
`

export const ValidationError = styled.span`
  display: inline-block;
  color: #dd3d4d;
  height: 1.5rem;
  text-align: center;
  width: 100%;
`

export const TosFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormTitle = styled(H1)`
  padding: 15px;
`
export const UserName = styled(H2)`
  text-align: left;
`

export const DataRowWrapper = styled(Row)`
  margin-bottom: 5px;
`

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const BtnWrapper = styled(Button)`
  width: initial;
`
