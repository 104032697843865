import * as yup from 'yup'

export const RegisterSchema = yup.object({
  name: yup.string().required('RegisterUserForm.form.name.error.required'),
  password: yup.string().required('RegisterUserForm.form.password.error.required'),
  email: yup
    .string()
    .email('RegisterUserForm.form.email.error.notValid')
    .required('RegisterUserForm.form.email.error.required'),
})
