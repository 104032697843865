/* eslint-disable class-methods-use-this */
class LocalStorageService {
  setAccessToken(token) {
    localStorage.setItem('token', token)
  }

  setRefreshToken(token) {
    localStorage.setItem('refreshToken', token)
  }

  setRole(role) {
    localStorage.setItem('role', role)
  }

  setEmail(email) {
    localStorage.setItem('email', email)
  }

  setName(name) {
    localStorage.setItem('name', name)
  }

  setId(id) {
    localStorage.setItem('id', id)
  }

  setEquitySubscription(subscription) {
    localStorage.setItem('equitySubscription', subscription)
  }

  setAllBondsSubscription(subscription) {
    localStorage.setItem('allBondsSubscription', subscription)
  }

  setIsNotFirstLogin(inNotFirstLogin) {
    localStorage.setItem('setIsNotFirstLogin', inNotFirstLogin)
  }

  setShowAnnouncement(showAnnouncement) {
    localStorage.setItem('showAnnouncement', showAnnouncement)
  }

  getAccessToken() {
    return localStorage.getItem('token')
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken')
  }

  getRole() {
    return localStorage.getItem('role')
  }

  getEmail() {
    return localStorage.getItem('email')
  }

  getName() {
    return localStorage.getItem('name')
  }

  getId() {
    return localStorage.getItem('id')
  }

  getEquitySubscription() {
    return localStorage.getItem('equitySubscription')
  }

  getAllBondsSubscription() {
    return localStorage.getItem('allBondsSubscription')
  }

  getIsNotFirstLogin() {
    return localStorage.getItem('setIsNotFirstLogin')
  }

  getShowAnnouncement() {
    return localStorage.getItem('showAnnouncement')
  }

  cleanStorage() {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('email')
    localStorage.removeItem('name')
    localStorage.removeItem('id')
    localStorage.removeItem('equitySubscription')
    localStorage.removeItem('allBondsSubscription')
    localStorage.removeItem('showAnnouncement')
  }
}

export default new LocalStorageService()
