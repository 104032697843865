import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import toNumber from 'lodash/toNumber'
import { NumberParam, StringParam, BooleanParam, useQueryParam } from 'use-query-params'

const ISODateParam = {
  encode(date) {
    // console.log({ date, form: dayjs(date).format('DD-MM-YYYY') })
    if (date == null) return date
    return dayjs(date).format('DD-MM-YYYY')
  },

  decode(strDate) {
    if (strDate == null) return strDate

    const [day, month, year] = strDate.split('-').map(dateItem => toNumber(dateItem))
    const decoded = dayjs(Date.UTC(year, month - 1, day)).toDate()

    // console.log(decoded)

    if (isNaN(decoded.getTime())) {
      return null
    }
    return decoded.toISOString()
  },
}

// Create Context Object
const Context = createContext(null)

const UrlStateParams = {
  algoType: 'algoType',
  security: 'security',
  index: 'index',
  taseEffectiveDate: 'taseEffectiveDate',
  calcDescId: 'calcDescId',
  selectedTab: 'selectedTab',
  showExternalIndex: 'showExternalIndex',
  prevRuns: 'prevRuns',
  simulationId: 'simulationId',
  userId: 'userId',
}

const UrlStateConfig = {
  [UrlStateParams.algoType]: StringParam,
  [UrlStateParams.security]: NumberParam,
  [UrlStateParams.index]: NumberParam,
  [UrlStateParams.taseEffectiveDate]: ISODateParam,
  [UrlStateParams.calcDescId]: StringParam,
  [UrlStateParams.selectedTab]: StringParam,
  [UrlStateParams.showExternalIndex]: BooleanParam,
  [UrlStateParams.prevRuns]: BooleanParam,
  [UrlStateParams.simulationId]: StringParam,
  [UrlStateParams.userId]: StringParam,
}

export const useAlgoType = () => useQueryParam(UrlStateParams.algoType, UrlStateConfig[UrlStateParams.algoType])

export const useTaseEffectiveDate = () => useQueryParam(UrlStateParams.taseEffectiveDate, UrlStateConfig[UrlStateParams.taseEffectiveDate])

export const useSelectedSecurity = () => useQueryParam(UrlStateParams.security, UrlStateConfig[UrlStateParams.security])

export const useSelectedCalcDescId = () => useQueryParam(UrlStateParams.calcDescId, UrlStateConfig[UrlStateParams.calcDescId])

export const usePrevRuns = () => useQueryParam(UrlStateParams.prevRuns, UrlStateConfig[UrlStateParams.prevRuns])

export const useSelectedTab = () => useQueryParam(UrlStateParams.selectedTab, UrlStateConfig[UrlStateParams.selectedTab])

export const useSelectedIndex = () => useQueryParam(UrlStateParams.index, UrlStateConfig[UrlStateParams.index])

export const useExternalIndexes = () => useQueryParam(UrlStateParams.showExternalIndex, UrlStateConfig[UrlStateParams.showExternalIndex])

export const useSimulationId = () => useQueryParam(UrlStateParams.simulationId, UrlStateConfig[UrlStateParams.simulationId])

export const useUserId = () => useQueryParam(UrlStateParams.userId, UrlStateConfig[UrlStateParams.userId])

// Create a provider for components to consume and subscribe to changes
export const ContextProvider = ({ children }) => {
  const [recordDate, updateRecordeDate] = useState(null)
  const [effectiveDate, updateEffectiveDate] = useState(null)
  const [secType, setSecType] = useState(null)
  const [isAlgoRunning, setIsAlgoRunning] = useState(false)
  const [selectedSim, setSelectedSim] = useState(null)
  const [issuersList, setIssuersList] = useState([])
  const [securityName, setSecurityName] = useState(null)
  const [indexName, setIndexName] = useState(null)
  const [externalIndexes, setExternalIndexes] = useState([])
  const [externalIndexesUpdateFlag, setExternalIndexesUpdateFlag] = useState(false)
  const [indexesData, setIndexesData] = useState([])
  const [indexesList, setIndexesList] = useState([])

  // const [selectedSim, setSelectedSim] = useState({
  //   computedName: '',
  //   name: '',
  //   conditions: [
  //     {
  //       // securityId: 1171818,
  //       // newSimulatedSecurity: false,
  //       indexNo: 100000,
  //       indexName: 'אינדקס בנקים',
  //       followingFundsValue: 10000000,
  //       weightDistMethod: 'hasWeightCap',
  //       weightCap: 24,
  //       securityIds: [593038, 691212, 1157403, 604611, 695437, 662577, 763011],
  //     },
  //     {
  //       securityIds: [662577, 593038, 604611, 695437, 691212],
  //       indexNo: 1000001,
  //       indexName: ' אינדקס בנקים משקל שווה  ',
  //       weightDistMethod: 'isEqualWeight',
  //       weightCap: 100,
  //       followingFundsValue: 600000000,
  //     },
  //   ],
  // })

  const [shouldRunSim, setShouldRunSim] = useState(false)
  const [simulationList, setSimulationList] = useState([])
  const [simDataUpdateFlag, setSimDataUpdateFlag] = useState(false)
  const [simInitialValuesOverride, setSimInitialValuesOverride] = useState(null)
  const [selectedUpdateScheduleRecord, setSelectedUpdateScheduleRecord] = useState(null)
  const [securityList, setSecurityList] = useState(null)

  const [, setSimulationId] = useSimulationId()
  useEffect(() => {
    if (selectedSim) {
      setSimulationId(selectedSim._id)
    } else {
      setSimulationId(null)
    }
  }, [selectedSim, setSimulationId])

  const contextValue = {
    selectedUpdateScheduleRecord,
    setSelectedUpdateScheduleRecord,
    recordDate,
    updateRecordeDate,
    effectiveDate,
    updateEffectiveDate,
    secType,
    setSecType,
    isAlgoRunning,
    setIsAlgoRunning,
    selectedSim,
    setSelectedSim,
    setSimulationId,
    shouldRunSim,
    setShouldRunSim,
    simDataUpdateFlag,
    setSimDataUpdateFlag,
    simulationList,
    setSimulationList,
    simInitialValuesOverride,
    setSimInitialValuesOverride,
    issuersList,
    setIssuersList,
    securityName,
    setSecurityName,
    indexName,
    setIndexName,
    externalIndexes,
    setExternalIndexes,
    externalIndexesUpdateFlag,
    setExternalIndexesUpdateFlag,
    securityList,
    setSecurityList,
    indexesData,
    setIndexesData,
    indexesList,
    setIndexesList,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Context
