import React from 'react'
import useColumnsData from 'utils/useColumnsData'

import { Columns } from './Columns'

import { Table } from 'components/Common/Table'

export const TurnOversTable = ({ calcDesc }) => {
  return (
    <Table
      withExportButton
      exportFileType="csv"
      columns={Columns(useColumnsData())}
      apiUrl={`/index_calc_results/getTurnoverResultsByDescId/${calcDesc._id}`}
      exportFileNameOverrideDate={calcDesc.taseEffectiveDate}
      defaultSortBy={{ id: 'securityId', desc: true }}
      headerText="Index Calc Results"
      virtualized={true}
    />
  )
}
