import React, { useContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDefaultSimValues } from './simulationHooks'
import { useHistory } from 'react-router-dom'
import { Analytics } from 'services/analytics'

import axiosApi from 'utils/axiosApi'
import { isUsingLatestData } from 'utils/algo'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

import Context, { useAlgoType, useTaseEffectiveDate } from 'context/Context'
import { MissingDataModal } from 'components/Simulation/MissingDataModal'
import { StyledNavDropdown } from 'components/General/navbar/styles'
import { Loader } from 'components/Common/Loader'
import { SCREEN } from 'utils/const'
import { useSimulationId } from 'context/Context'

export const SimulationSelector = () => {
  const { t } = useTranslation()

  const { secType, effectiveDate, selectedSim, setSelectedSim, simulationList, setSimulationList, setSelectedUpdateScheduleRecord } = useContext(
    Context,
  )

  //setAlgoType has to be the second arg here!
  // eslint-disable-next-line
  const [algoType, setAlgoType] = useAlgoType()
  const [taseEffectiveDate, setTaseEffectiveDate] = useTaseEffectiveDate()
  const [fetchingSimulationList, setFetchingSimulationList] = useState(false)
  const [showMissingDataModal, setShowMissingDataModal] = useState(false)
  const [simulationId, setSimulationId] = useSimulationId()
  const newSimValues = useDefaultSimValues()
  const history = useHistory()

  useEffect(() => {
    const getSimulationList = async () => {
      setFetchingSimulationList(true)
      try {
        const res = await axiosApi.get('simulation/getList')

        if (res && res.data) setSimulationList(res.data)

        setFetchingSimulationList(false)
      } catch (err) {
        console.log('error fetching simulations list', err)
        setFetchingSimulationList(false)
      }
    }

    if (taseEffectiveDate) {
      getSimulationList()
    }
  }, [selectedSim, setSelectedSim, taseEffectiveDate, setSimulationList])

  const selectSim = useCallback(
    sim => {
      if (isUsingLatestData(sim?.latestGeneralAlgoCalc)) {
        if (secType !== sim.secType) {
          console.log('accessing sim with different secType, redirecting', sim)
          history.replace(`/${SCREEN[sim.secType]}/all`)
        }

        // merge conditions from securities and indexes
        // believe me, this was the only way to do it without rewriting the whole fucking system, which i tried actually.
        sim.conditions = [...sim.securities, ...sim.indexes]

        setSelectedSim(sim)
        setAlgoType(sim.algoType)
        setTaseEffectiveDate(dayjs(sim.taseEffectiveDate).toDate())
        setSelectedUpdateScheduleRecord(null)
      } else {
        setShowMissingDataModal(true)
      }
    },
    [history, secType, setAlgoType, setSelectedSim, setSelectedUpdateScheduleRecord, setTaseEffectiveDate],
  )

  useEffect(() => {
    if (isEmpty(simulationList) || selectedSim) return

    if (simulationId) {
      const sim = simulationList.find(sim => sim._id === simulationId)
      if (sim) {
        selectSim(sim)
      } else {
        setSimulationId(null)
      }
    }
  }, [simulationList, selectedSim, selectSim, simulationId, setSimulationId])

  if (!taseEffectiveDate) {
    return null
  }

  const createNewSimulation = () => {
    setSelectedSim(newSimValues)

    if (isUsingLatestData({ effectiveDate, taseEffectiveDate })) {
      setSelectedSim(newSimValues)

      // push event to GA
      Analytics.event({
        category: 'Simulations',
        action: 'Create simulation clicked (manual)',
      })
    } else {
      // setShowMissingDataModal(true)
    }
  }

  const renderSimulations = () => {
    return fetchingSimulationList ? (
      <>
        <StyledNavDropdown.Divider />
        <StyledNavDropdown.Item>
          <Loader />
        </StyledNavDropdown.Item>
      </>
    ) : some(simulationList) ? (
      <>
        <StyledNavDropdown.Divider />
        {simulationList.map(simItem => (
          <StyledNavDropdown.Item key={simItem._id} onClick={() => selectSim(simItem)}>
            {simItem.name || simItem.computedName}
          </StyledNavDropdown.Item>
        ))}
      </>
    ) : null
  }

  return (
    <StyledNavDropdown title={t('simulation.nav.mySims')} id="simulation-nav-dropdown">
      <MissingDataModal show={showMissingDataModal} onHide={() => setShowMissingDataModal(false)} />
      <StyledNavDropdown.Item onClick={() => createNewSimulation()}>{t('simulation.nav.newSim')}</StyledNavDropdown.Item>
      {renderSimulations()}
    </StyledNavDropdown>
  )
}
