import reduce from 'lodash/reduce'

const sectorSubSectorMap = {
  84: {
    sector0803Code: 84,
    companySectorEnglish: 'Financial Services',
    companySectorHebrew: 'שרותים פיננסיים',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Financial Services',
        companySubsectorHebrew: 'שרותים פיננסיים',
      },
    },
  },
  87: {
    sector0803Code: 87,
    companySectorEnglish: 'Biomed',
    companySectorHebrew: 'ביומד',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Biotechnology',
        companySubsectorHebrew: 'ביוטכנולוגיה',
      },
      2: {
        subSector0803Code: 2,
        companySubsectorEnglish: 'Medical Devices',
        companySubsectorHebrew: 'מכשור רפואי',
      },
      3: {
        subSector0803Code: 3,
        companySubsectorEnglish: 'Investments In Life Science',
        companySubsectorHebrew: 'השקעות במדעי החיים',
      },
      4: {
        subSector0803Code: 4,
        companySubsectorEnglish: 'Pharma',
        companySubsectorHebrew: 'פארמה',
      },
      5: {
        subSector0803Code: 5,
        companySubsectorEnglish: 'Cannabis',
        companySubsectorHebrew: 'קנאביס',
      },
    },
  },
  88: {
    sector0803Code: 88,
    companySectorEnglish: 'Technology',
    companySectorHebrew: 'טכנולוגיה',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'IT Services',
        companySubsectorHebrew: 'שרותי מידע',
      },
      2: {
        subSector0803Code: 2,
        companySubsectorEnglish: 'Software And Internet',
        companySubsectorHebrew: 'תוכנה ואינטרנט',
      },
      3: {
        subSector0803Code: 3,
        companySubsectorEnglish: 'Electronics And Optics',
        companySubsectorHebrew: 'אלקטרוניקה ואופטיקה',
      },
      4: {
        subSector0803Code: 4,
        companySubsectorEnglish: 'Defence',
        companySubsectorHebrew: 'ביטחוניות',
      },
      5: {
        subSector0803Code: 5,
        companySubsectorEnglish: 'Communications Equipment',
        companySubsectorHebrew: 'ציוד תקשורת',
      },
      6: {
        subSector0803Code: 6,
        companySubsectorEnglish: 'Semiconductors',
        companySubsectorHebrew: 'מוליכים למחצה',
      },
      7: {
        subSector0803Code: 7,
        companySubsectorEnglish: 'Investments In High-Tech',
        companySubsectorHebrew: 'השקעות בהייטק',
      },
      8: {
        subSector0803Code: 8,
        companySubsectorEnglish: 'Cleantech',
        companySubsectorHebrew: 'קלינטק',
      },
      9: {
        subSector0803Code: 9,
        companySubsectorEnglish: 'High-Tech Fund',
        companySubsectorHebrew: 'קרן הייטק',
      },
    },
  },
  90: {
    sector0803Code: 90,
    companySectorEnglish: 'Banks',
    companySectorHebrew: 'בנקים',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Banks',
        companySubsectorHebrew: 'בנקים',
      },
    },
  },
  93: {
    sector0803Code: 93,
    companySectorEnglish: 'Insurance',
    companySectorHebrew: 'ביטוח',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Insurance',
        companySubsectorHebrew: 'ביטוח',
      },
    },
  },
  94: {
    sector0803Code: 94,
    companySectorEnglish: 'Commerce & Services',
    companySectorHebrew: 'מסחר ושרותים',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Commerce',
        companySubsectorHebrew: 'מסחר',
      },
      2: {
        subSector0803Code: 2,
        companySubsectorEnglish: 'Services',
        companySubsectorHebrew: 'שרותים',
      },
      3: {
        subSector0803Code: 3,
        companySubsectorEnglish: 'Hotels & Tourism',
        companySubsectorHebrew: 'מלונאות ותיירות',
      },
      6: {
        subSector0803Code: 6,
        companySubsectorEnglish: 'Communications & Media',
        companySubsectorHebrew: 'תקשורת ומדיה',
      },
    },
  },
  95: {
    sector0803Code: 95,
    companySectorEnglish: 'Real-Estate & Construction',
    companySectorHebrew: 'נדל"ן ובנייה',
    subSectors: {
      4: {
        subSector0803Code: 4,
        companySubsectorEnglish: 'Construction',
        companySubsectorHebrew: 'בנייה',
      },
      5: {
        subSector0803Code: 5,
        companySubsectorEnglish: 'Investment-Proerties In Israel',
        companySubsectorHebrew: 'נדל"ן מניב בישראל',
      },
      6: {
        subSector0803Code: 6,
        companySubsectorEnglish: 'Investment-Proerties Abroad',
        companySubsectorHebrew: 'נדל"ן מניב בחו"ל',
      },
    },
  },
  96: {
    sector0803Code: 96,
    companySectorEnglish: 'Industry',
    companySectorHebrew: 'תעשיה',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Food',
        companySubsectorHebrew: 'מזון',
      },
      2: {
        subSector0803Code: 2,
        companySubsectorEnglish: 'Fashion & Clothing',
        companySubsectorHebrew: 'אופנה והלבשה',
      },
      3: {
        subSector0803Code: 3,
        companySubsectorEnglish: 'Metal & Building Products',
        companySubsectorHebrew: 'מתכת ומוצרי בניה',
      },
      4: {
        subSector0803Code: 4,
        companySubsectorEnglish: 'Electrical',
        companySubsectorHebrew: 'חשמל',
      },
      6: {
        subSector0803Code: 6,
        companySubsectorEnglish: 'Chemical, Rubber & Plastic',
        companySubsectorHebrew: 'כימיה, גומי ופלסטיק',
      },
      7: {
        subSector0803Code: 7,
        companySubsectorEnglish: 'Wood & Paper',
        companySubsectorHebrew: 'עץ, נייר ודפוס',
      },
    },
  },
  97: {
    sector0803Code: 97,
    companySectorEnglish: 'Investment & Holdings',
    companySectorHebrew: 'השקעה ואחזקות',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Investment & Holdings',
        companySubsectorHebrew: 'השקעה ואחזקות',
      },
      6: {
        subSector0803Code: 6,
        companySubsectorEnglish: 'Shell Companies',
        companySubsectorHebrew: 'חברות מעטפת',
      },
    },
  },
  98: {
    sector0803Code: 98,
    companySectorEnglish: 'Energy And Oil & Gas Exploration',
    companySectorHebrew: 'אנרגיה וחיפושי נפט וגז',
    subSectors: {
      1: {
        subSector0803Code: 1,
        companySubsectorEnglish: 'Oil & Gas Exploration',
        companySubsectorHebrew: 'חיפושי נפט וגז',
      },
      3: {
        subSector0803Code: 3,
        companySubsectorEnglish: 'Energy',
        companySubsectorHebrew: 'אנרגיה',
      },
    },
  },
}

const getSectorSelectOptions = isEng => {
  return reduce(
    sectorSubSectorMap,
    (acc, curr) => [
      ...acc,
      {
        label: isEng ? curr.companySectorEnglish : curr.companySectorHebrew,
        value: curr.sector0803Code,
      },
    ],
    [],
  )
}

const getSubSectorSelectOptions = (sector, isEng) => {
  return sector
    ? reduce(
        sectorSubSectorMap[sector] && sectorSubSectorMap[sector].subSectors,
        (acc, curr) => [
          ...acc,
          {
            label: isEng ? curr.companySubsectorEnglish : curr.companySubsectorHebrew,
            value: curr.subSector0803Code,
          },
        ],
        [],
      )
    : [{ label: '', value: '' }]
}

export { sectorSubSectorMap, getSectorSelectOptions, getSubSectorSelectOptions }
