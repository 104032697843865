import numbro from 'numbro'
import dayjs from 'dayjs'
import last from 'lodash/last'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { getRatingDescFromScore } from 'utils/ratingUtils'
import { getLiquidationGroupDesc } from 'utils/liquidationGroupUtils'
import { SuggestedFieldFormats } from 'utils/const'

const _dayjs = (value, format) => (value ? dayjs(value).format(format) : value)

/**
 * Returns formatted string of value based on optional param 'format' or suggested.
 *
 * @param {string|number} value - The original value to be formatted
 * @param {string} name - The name of the value, used for suggested
 * @param {bool} toShort - determines which format to use (long/ short syntax)
 * @param {function} t - i18n getTranslation() ref
 * @param {string} [formatOverride=undefined] optional - overrides suggested value.
 * @return {string} formatted value
 */
const getFormattedValue = ({ value, name, toShort, t, formatOverride }) => {
  // console.log('getFormattedValue', { value, name, toShort, t, formatOverride })
  if (!value || value === '-' || value === 'N/A' || value === 'NaN') {
    return value
  }

  const format = (() => {
    if (formatOverride) return formatOverride
    if (name && SuggestedFieldFormats[name]) return SuggestedFieldFormats[name]
    return 'noFormat'
  })()

  if (format.startsWith('localized-')) {
    return t(`common.${last(format.split('localized-'))}.${value}`)
  }
  switch (format) {
    case '%*100.00':
      return toShort ? `${numbro(value * 100.0).format('0,0.00')}%` : `${numbro(value * 100.0).format('0,0.00000')}%`
    case '%*100':
      return `${numbro(value * 100.0).format('0,0')}%`
    case '%':
      return toShort ? `${numbro(value).format('0,0.00')}%` : `${numbro(value).format('0,0.00000')}%`
    case '0,0.0%':
      return toShort ? `${numbro(value).format({ mantissa: 1, thousandSeparated: true })}%` : `${numbro(value).format('0,0.00000')}%`
    case '%-short':
      return toShort ? `${numbro(value).format({ mantissa: 0, thousandSeparated: true })}%` : `${numbro(value).format('0,0.00')}%`
    case '%-shorter':
      return `${numbro(value).format('0,0')}%`
    case '%-3dcm':
      return `${numbro(value).format('0,0.000')}%`
    case 'number':
      return toShort ? numbro(value).format('0,0.0') : numbro(value).format('0,0.00')
    case 'number-short':
      return toShort ? numbro(value).format('0.0a') : numbro(value).format('0.00000')
    case 'float':
      return numbro(value).format('0.00000')
    case '0.00':
      return numbro(value).format('0.00')
    case 'number-long':
      return numbro(value).format({ mantissa: 0, thousandSeparated: true })
    case 'currency':
      value = Math.round(value)
      return toShort
        ? numbro(value).formatCurrency({ thousandSeparated: true, optionalMantissa: true, mantissa: 1, average: true })
        : numbro(value).formatCurrency({ thousandSeparated: true, mantissa: 0 })
    case 'currency-long':
      return toShort
        ? numbro(value).formatCurrency({ thousandSeparated: true, optionalMantissa: true, mantissa: 2, average: true })
        : numbro(value).formatCurrency({ thousandSeparated: true, mantissa: 0 })
    case 'agorot':
      const val = numbro(value).format('0,0.0')
      return isFunction(t) ? `${val} ${t('common.cents')}` : `${val}`
    case 'rating':
      return getRatingDescFromScore(value)
    case 'ratingMidroog':
      return getRatingDescFromScore(value, 'Midroog') || '-'
    case 'ratingMaalot':
      return getRatingDescFromScore(value, 'Maalot') || '-'
    case 'liquidationGroup':
    case 'liquidityRatio':
      return getLiquidationGroupDesc(value)
    case 'date':
      return _dayjs(value, 'DD/MM/YYYY')
    case 'time':
      return _dayjs(value, 'hh:mm:ss:SS')
    case 'date-time':
      return _dayjs(value, 'DD/MM/YYYY HH:mm')
    case 'string':
      return toShort ? value.slice(0, 16) : value
    case 'turnoverMedianSpecs': {
      const turnoverMissing = get(value, 'turnover')
      const turnoverSpeedMissing = get(value, 'turnoverSpeed')

      const turnoverMissingStr = turnoverMissing > 0 ? `${t('goalSeek.turnoverMissing')} - (${numbro(turnoverMissing).format('0,0$')})` : ''
      const turnoverSpeedMissingStr =
        turnoverSpeedMissing > 0 ? `${t('goalSeek.turnoverSpeedMissing')} - (${numbro(turnoverSpeedMissing).format('0.00')})` : ''

      return [turnoverMissingStr, turnoverSpeedMissingStr].filter(Boolean).join(', ')
    }
    case 'noFormat':
    default:
      return value
  }
}

export default getFormattedValue
