import styled from 'styled-components'
import { Container } from 'components/Common/Container'
import Nav from 'react-bootstrap/Nav'

export const DataManagmentWrapper = styled(Container)`
  direction: ltr;
  margin-top: 20px;
`
export const PageContainer = styled.div`
  padding-top: 100px;

  & > .nav {
    margin-top: 40px;
  }
`
export const TabSelector = styled(Nav.Item)`
  flex-grow: 1;
  text-align: center;
`
