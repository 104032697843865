import compact from 'lodash/compact'
import get from 'lodash/get'
import some from 'lodash/some'
import SecurityName from 'components/Common/Table/TableCell/SecurityName'
import { getRatingDescFromScore } from 'utils/ratingUtils'
import getFormattedValue from 'utils/getFormattedValue'
import { AlgoTypes, SecTypes } from 'utils/const'

const tPrefix = 'equity.generalSecurity'

export const columns = ({ t, secType, pathPrefix, algoType }) => {
  const isEquity = secType === SecTypes.stock
  const isBonds = secType === SecTypes.corpBond || secType === SecTypes.govBond || secType === SecTypes.allBond
  const isComponents = algoType === AlgoTypes.components

  const createColumn = (accessor, { cellFormat = null, coloredReferenceSelector = false, Header = null, width = 90 } = {}) => ({
    id: accessor,
    Header: Header || t(`${tPrefix}.${accessor}`),
    accessor: accessor,
    cellFormat: cellFormat || {},
    coloredReferenceSelector: coloredReferenceSelector,
    width,
  })

  return compact([
    {
      id: 'securityName',
      Header: t('common.security'),
      accessor: row => {
        let name = row.securityName
        if (some(row.fastTrackIndexes)) {
          return `${name} ${t('equity.securityAnalysis.exceptions.fastTrack')}`
        } else return name
      },
      Cell: SecurityName,
      disableFilters: false,
      width: 205,
    },
    createColumn('securityNo'),
    createColumn('companySupersector'),
    {
      id: 'companySector',
      Header: t(`${tPrefix}.companySector`),
      accessor: 'companySector',
      width: 225,
    },
    isBonds && {
      id: 'ratingLevel',
      Header: t(`${tPrefix}.rating`),
      accessor: 'ratingLevel',
      cellFormat: { format: 'rating' },
    },
    isBonds && {
      id: 'ratingMaalot',
      Header: t(`${tPrefix}.ratingMaalot`),
      accessor: row => getRatingDescFromScore(get(row, 'ratingMaalot'), 'Maalot'),
    },
    isBonds && {
      id: 'ratingMidroog',
      Header: t(`${tPrefix}.ratingMidroog`),
      accessor: row => getRatingDescFromScore(get(row, 'ratingMidroog'), 'Midroog'),
    },
    isBonds && {
      id: 'maturityDate',
      Header: t(`${tPrefix}.maturityDate`),
      accessor: row =>
        getFormattedValue({
          value: get(row, 'maturityDate'),
          t,
          formatOverride: 'date',
        }),
    },
    isBonds && {
      id: 'yearsToRedemption',
      Header: t(`${tPrefix}.yearsToRedemption`),
      accessor: 'yearsToRedemption',
      cellFormat: { format: 'number' },
    },
    isBonds && {
      id: 'linkage',
      Header: t(`${tPrefix}.linkage`),
      accessor: row =>
        getFormattedValue({
          value: get(row, 'linkage'),
          t,
          formatOverride: 'localized-linkage',
        }),
    },
    isBonds && {
      id: 'interestType',
      Header: t(`${tPrefix}.interestType`),
      accessor: row =>
        getFormattedValue({
          value: get(row, 'interestType'),
          t,
          formatOverride: 'localized-interestType',
        }),
    },
    isEquity &&
      createColumn('indexAdjustedNoOfSharesIans', {
        cellFormat: { format: 'number-long' },
        width: 105,
      }),
    isEquity &&
      createColumn('capitalListedForTrading', {
        cellFormat: { format: 'number-long' },
        coloredReferenceSelector: 'indexAdjustedNoOfSharesIans',
        width: 105,
      }),
    isBonds &&
      createColumn('dayBeforeUpdateIans', {
        cellFormat: { format: 'number-long' },
        width: 105,
      }),
    isBonds &&
      createColumn('currRecordDateCapitalListedForTrading', {
        cellFormat: { format: 'number-long' },
        coloredReferenceSelector: 'dayBeforeUpdateIans',
        width: 105,
      }),
    isBonds &&
      createColumn('currTaseEffectiveDateCapitalListedForTrading', {
        cellFormat: { format: 'number-long' },
        coloredReferenceSelector: 'currRecordDateCapitalListedForTrading',
        width: 105,
      }),
    isEquity &&
      createColumn('indexAdjustedFreeFloatRate', {
        cellFormat: { format: '%-shorter' },
      }),
    isEquity &&
      createColumn('freeFloatRate', {
        cellFormat: { format: '%-short' },
      }),
    isEquity &&
      isComponents &&
      createColumn('freeFloatRateForSpecificFFRecordDate', {
        cellFormat: { format: '%-short' },
      }),
    isEquity &&
      createColumn('projectedIndexAdjustedFreeFloatRate', {
        coloredReferenceSelector: 'indexAdjustedFreeFloatRate',
        cellFormat: { format: '%-shorter' },
      }),
    isEquity &&
      createColumn('indexAdjustedFreeFloat', {
        cellFormat: { format: 'number-long' },
        width: 105,
      }),
    isEquity &&
      createColumn('projectedIndexAdjustedFreeFloat', {
        coloredReferenceSelector: 'indexAdjustedFreeFloat',
        cellFormat: { format: 'number-long' },
        width: 105,
      }),
    isEquity &&
      createColumn('effectiveDateFreeFloatRate', {
        coloredReferenceSelector: 'freeFloatRate',
        cellFormat: { format: '%-short' },
        width: 105,
      }),
    isEquity &&
      createColumn('projectedFreeFloatValue', {
        cellFormat: { format: 'number-long' },
        width: 105,
      }),
    isEquity && createColumn('closingPrice', { cellFormat: { format: 'number' } }),
    isEquity &&
      createColumn('effectiveDateClosingPrice', {
        cellFormat: { format: 'number' },
      }),
    isBonds &&
      createColumn('recordDatePrice', {
        cellFormat: { format: 'number' },
        coloredReferenceSelector: 'effectiveDateClosingPrice',
      }),
    isBonds &&
      createColumn('dayBeforeUpdateAdjustedPrice', {
        cellFormat: { format: 'number' },
        coloredReferenceSelector: 'effectiveDateClosingPrice',
      }),

    isBonds &&
      createColumn('taseEffectiveDateAdjustedPrice', {
        cellFormat: { format: 'number' },
        coloredReferenceSelector: 'effectiveDateClosingPrice',
      }),
    isBonds &&
      createColumn('avgPrice', {
        cellFormat: { format: 'number' },
        Header: t(`${tPrefix}.adjustedAvgPrice`),
        coloredReferenceSelector: 'baseAvgPrice',
      }),
    createColumn('baseMarketValue', {
      cellFormat: { format: 'currency' },
      Header: t(`${tPrefix}.baseMarketValue`),
      width: 105,
    }),
    isBonds &&
      createColumn('dayBeforeUpdateMarketValue', {
        cellFormat: { format: 'currency' },
        Header: t(`${tPrefix}.dayBeforeUpdateMarketValue`),
        width: 105,
        coloredReferenceSelector: 'baseMarketValue',
      }),
    createColumn('taseEffectiveDateMarketValue', {
      cellFormat: { format: 'currency' },
      Header: t(`${tPrefix}.taseEffectiveDateMarketValue`),
      width: 105,
      coloredReferenceSelector: 'baseMarketValue',
    }),
    isBonds &&
      createColumn('avgMarketValueFromTaseApi', {
        cellFormat: { format: 'currency' },
        coloredReferenceSelector: 'baseMarketValue',
        Header: t(`${tPrefix}.averageMarketCapWithoutRedemption`),
        width: 105,
      }),
    isBonds && {
      id: 'calculatedAvgMarketValue',
      Header: t(`${tPrefix}.calculatedAvgMarketValue`),
      coloredReferenceSelector: 'baseMarketValue',
      accessor: 'calculatedAvgMarketValue',
      cellFormat: { format: 'currency' },
    },
    isBonds && {
      id: 'projectedPayment',
      Header: t('equity.generalSecurity.projectedPayment'),
      accessor: row => row?.calcResultsData?.sumProjectedPayment,
      cellFormat: {
        format: 'currency',
      },
    },
    isBonds && {
      id: 'componentsMarketValue',
      Header: t(`${tPrefix}.componentsMarketValue`),
      coloredReferenceSelector: 'baseMarketValue',
      accessor: 'avgMarketValue',
      cellFormat: { format: 'currency' },
    },

    isBonds &&
      createColumn('exitFromTelBond', {
        cellFormat: { format: 'date' },
        Header: t(`${tPrefix}.exitFromTelBond`),
        width: 105,
      }),
    isBonds &&
      createColumn('averageSeniorityPerRedemption', {
        cellFormat: { format: 'number' },
        Header: t(`${tPrefix}.averageSeniorityPerRedemption`),
        width: 105,
      }),
    isBonds &&
      createColumn('earlyRedemptionDateBankAndInsurance', {
        cellFormat: { format: 'date' },
        Header: t(`${tPrefix}.earlyRedemptionDateBankAndInsurance`),
        width: 105,
      }),
    isEquity && createColumn('avgPrice', { cellFormat: { format: 'number' } }),
    isEquity && {
      id: 'calculatedAvgMarketValue',
      Header: t(`${tPrefix}.${algoType === 'components' ? 'avgMarketValue' : 'marketValue'}`),
      accessor: 'calculatedAvgMarketValue',
      cellFormat: { format: 'currency' },
    },

    isEquity &&
      createColumn('effectiveDatePublicMarketValue', {
        cellFormat: { format: 'currency' },
      }),

    isEquity && {
      id: 'publicMarketValueForComponents',
      Header: t(`${tPrefix}.${algoType === 'components' ? 'avgPublicMarketValueForComponents' : 'publicMarketValueForComponents'}`),
      accessor: 'publicMarketValueForComponents',
      cellFormat: { format: 'currency' },
    },
    createColumn('avgTurnover', { cellFormat: { format: 'number' } }),
    createColumn('semiAnnualAverageDailyTurnover', { cellFormat: { format: 'number' } }),
    createColumn('semiAnnualMedianTurnover', { cellFormat: { format: 'number' } }),
    isEquity && createColumn('semiAnnualMedianTurnoverGroup'),
    createColumn('semiAnnualTurnoverSpeedMedian', { cellFormat: { format: 'number' } }),
    isEquity && createColumn('semiAnnualTurnoverSpeedMedianGroup'),
    isEquity && createColumn('liquidationGroupGoal'),
    isEquity && createColumn('projectedLiquidationGroup'),

    createColumn('currTotalIndexes'),
    createColumn('projectedTotalIndexes', {
      coloredReferenceSelector: 'currTotalIndexes',
    }),
    createColumn('invalid'),
  ])
}
