import { calcLastTASETradingDays, isTaseValidDay } from 'utils/calcTaseDays'
import dayjs from 'dayjs'

export const getRecordDateTooltipText = (t, date) => {
  const displayEndDate = getRecordDateTooltipEndDate(date)
  const displayStartDate = getRecordDateTooltipStartDate(date)

  if (!displayEndDate || !displayStartDate) {
    return ''
  }

  return t('equity.projectedChangesUpcoming.recordDateTooltip', {
    startDate: formatDate(displayStartDate),
    endDate: formatDate(displayEndDate),
  })
}

const getRecordDateTooltipEndDate = date => {
  const endDate = calcLastTASETradingDays(1, date)?.[0]?.toDate()
  return endDate
}

const getRecordDateTooltipStartDate = date => {
  let daysBack = 10
  if (!isTaseValidDay(date)) {
    daysBack = 9
  }

  const startDate = calcLastTASETradingDays(daysBack, date)?.[daysBack - 1].toDate()
  return startDate
}

const formatDate = date => dayjs(date).format('DD/MM/YYYY')
